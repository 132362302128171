import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListByOrgIdOrgUser as UsecaseRemoteListByOrgIdOrgUser } from 'domain/usecases/orgUser/remote';
import { ListOrgUserByOrgId as UsecaseReduxListOrgUserByOrgId } from 'domain/usecases/orgUser/redux';

import { RemoteListByOrgIdOrgUser } from 'data/repository/orgUser';
import { ReduxListOrgUserByOrgId } from 'data/store/reducer/orgUsers/usecases';

/**
 * send request via API.
 */
export const makeRemoteListByOrgIdOrgUser =
  (): UsecaseRemoteListByOrgIdOrgUser =>
    new RemoteListByOrgIdOrgUser(
      makeApiUrl('/orgs/{orgId}/users/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListByOrgIdOrgUser = (): UsecaseReduxListOrgUserByOrgId =>
  new ReduxListOrgUserByOrgId();
