import { DeleteByOrgUnitAdmin } from 'domain/usecases/admin/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDeleteByOrgUnitAdmin implements DeleteByOrgUnitAdmin {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteByOrgUnitAdmin.Model>;

  constructor(url: string, httClient: HttpClient<DeleteByOrgUnitAdmin.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  deleteByOrgUnit = async (
    params: DeleteByOrgUnitAdmin.Params,
  ): Promise<DeleteByOrgUnitAdmin.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url
        .replace('{orgId}', `${params.orgId}`)
        .replace('{userId}', `${params.userId}`)
        .replace('{unitId}', `${params.unitId}`),
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
