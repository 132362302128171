import { iModal } from 'domain/interfaces/models/Modal';
import { ModalTypes } from 'domain/interfaces/redux/modal/types';
import { ModalActions } from './actions';

export const initialState: iModal = {
  visible: false,
};

const reducer = (state = initialState, action: ModalActions): iModal => {
  switch (action.type) {
    case ModalTypes.OPEN:
      return { visible: true };
    case ModalTypes.CLOSE:
      return { visible: false };
    default:
      return state;
  }
};

export default reducer;
