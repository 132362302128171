import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { ListOrgUnit } from 'domain/usecases/orgUnit/remote';
import { History } from 'main/routes';
import { IconHealth } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import OrgUnits from 'presentation/components/list/OrgUnit';
import Pagination from 'presentation/components/Pagination';
import { Button } from 'presentation/components/UI';
import SectorDetails from './SectorDetails';
import { Container, Content, ListOrgsUnits } from './styles/StyledSectors';

interface OwnProps {
  handleOrgUnitName?: (name: string | null) => void;
  // TODO: Remove after everything is on Redux
  haveUpdated?: () => void;
}

interface iParams {
  id: string;
  orgUnitId: string;
}

const SectorsPage: React.FC<OwnProps> = ({
  handleOrgUnitName,
  haveUpdated,
}): JSX.Element => {
  const url = window.location.pathname;
  const [selected, setSelected] = useState<number>(0);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(0);
  const [responseOrgUnit, setResponseOrgUnit] = useState<ListOrgUnit.Model>(
    {} as ListOrgUnit.Model,
  );

  const { records } = useSelector((store: iStore) => store.orgUnits);

  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 20;

  const params = useParams<iParams>();

  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    return {
      ...responseOrgUnit,
      records: responseOrgUnit.records?.slice(start, start + itemsPerPage),
    };
  }, [responseOrgUnit, page]);

  useEffect(() => {
    if (Number(params?.id) && records?.length) {
      setResponseOrgUnit(prevState => ({
        ...prevState,
        records: records.filter(
          item => !item?.org?.id || item.org?.id === Number(params?.id),
        ),
      }));
    }

    return () => {
      handleOrgUnitName?.(null);
    };
  }, [handleOrgUnitName, params?.id, records]);

  useEffect(() => {
    if (
      Number(params?.orgUnitId) &&
      window.location.pathname.includes('/unit')
    ) {
      setSelected(Number(params?.orgUnitId));

      const foundOrgUnit = responseOrgUnit.records?.find(
        ou => ou.id === Number(params?.orgUnitId),
      );

      if (foundOrgUnit?.org?.id)
        setSelectedOrgId(Number(foundOrgUnit?.org?.id));

      if (foundOrgUnit?.name) handleOrgUnitName?.(foundOrgUnit.name);

      haveUpdated?.();
    }
  }, [params, handleOrgUnitName, responseOrgUnit.records, haveUpdated]);

  /* help */
  useEffect(() => {
    if (!params?.orgUnitId || !window.location.pathname.includes('/unit')) {
      setSelected(0);
      handleOrgUnitName?.(null);
    } else if (
      !selected &&
      params?.orgUnitId &&
      window.location.pathname.includes('/unit') &&
      displayData.records
    ) {
      const data = displayData.records.find(
        el => el.id === Number(params?.orgUnitId),
      );
      if (data) {
        setSelected(data?.id);
        setSelectedOrgId(data.org?.id as number);
      }
    }
  }, [handleOrgUnitName, params?.orgUnitId, displayData]);

  /* help */
  /* useEffect(() => {
    return () => {
      console.log('### exit selected', selected, params?.id);
      if (selected) {
        setSelected(0);
        // Update pathname without trigger re-render
        window.history.replaceState(
          null,
          'Agenda',
          `/admin/orgs/${params?.id}`,
        );
      }
    };
  }, [params?.id, selected]); */

  return (
    <Container>
      {selected ? (
        <>
          <SectorDetails
            id={selected}
            idOrg={selectedOrgId}
            editing={!window.location.pathname.includes('/unit/create/new')}
            haveUpdated={haveUpdated}
          />
        </>
      ) : (
        <>
          <Content>
            <div>
              <Pagination
                currentPage={page}
                pageSize={Math.ceil(
                  responseOrgUnit.records?.length / itemsPerPage,
                )}
                setPage={setPage}
              />
            </div>
            <div>
              <Button
                data-testid="btn-add-unit"
                icon={IconHealth}
                rounded
                variant="primary"
                size="medium"
                onClick={() =>
                  History.getHistory().push(
                    `/admin/orgs/${params?.id}/unit/create/new`,
                    { from: url.replaceAll('/', '') },
                  )
                }
                resourcers={['ORGUNIT']}
                actions={['CREATE_ANY', 'CREATE_ORG']}
              >
                {Translator('Novo setor')}
              </Button>
            </div>
          </Content>
          <ListOrgsUnits>
            <OrgUnits
              dataList={displayData}
              handleSelected={setSelected}
              handleSelectedOrgId={setSelectedOrgId}
              handleSelectedOrgUnitName={handleOrgUnitName}
            />
          </ListOrgsUnits>
        </>
      )}
    </Container>
  );
};

export default SectorsPage;
