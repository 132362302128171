import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography, Tags } from '@wisecare-tech/design-system-web';
import Actions from 'presentation/components/Actions';
import { iStore } from 'domain/interfaces/models';
import { IconCamAtendeFacil } from 'presentation/base/icons';
import { iListSacRequest } from 'domain/usecases/sacRequest/remote';
import { formatISODateHour } from 'utils/tableDateHour';
import { Information } from '../styles';

export const finalizedColumns: ColumnDef<iListSacRequest>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const data = row.getValue('id') as iListSacRequest['id'];

      return <div>{data}</div>;
    },
  },
  {
    accessorKey: 'created',
    header: 'Solicitado em',
    cell: ({ row }) => {
      const data = row.getValue('created') as iListSacRequest['created'];

      const dateHour = formatISODateHour(data);

      return (
        <Information>
          <Typography variant="b4_14regular">{dateHour.date}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {dateHour.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'specialist',
    header: 'Solicitante',
    cell: ({ row }) => {
      const data = row.getValue('specialist') as iListSacRequest['specialist'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.email ?? ''}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.phone ?? ''}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorFn: row => row,
    header: 'Contato',
    cell: ({ cell }) => {
      const data = cell.getValue() as iListSacRequest;

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.phone}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.email}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'outcome',
    header: 'Status',
    cell: ({ row }) => {
      const data = row.getValue('outcome') as iListSacRequest['outcome'];

      const mapStatusColor: Record<string, string> = {
        Resolvido: 'green',
        Transferido: 'blue',
        'Não Resolvido': 'red',
        Interrompido: 'white',
        undefined: 'white',
      };

      return <Tags text={data} variant={mapStatusColor[data] as any} />;
    },
  },
];
