import { call, put } from 'redux-saga/effects';
import {
  getDashboardOrgFailed,
  getDashboardOrgSuccess,
} from 'data/store/reducer/dashboard/actions/getDashboardOrg';
import { iActionGetDashboardOrg } from 'domain/interfaces/redux/dashboard/getDashboardOrg';
import { GetDashboardOrg } from 'domain/usecases/dashboard/redux/GetDashboardOrg';
import { makeRemoteGetDashboardOrg } from 'main/factories/usecases/dashboard/GetDashboardOrgFactory';

export function* onGetDashboardOrg(action: iActionGetDashboardOrg) {
  const remoteGetDashboardOrg = makeRemoteGetDashboardOrg();

  try {
    const response: GetDashboardOrg.Model = yield call(
      remoteGetDashboardOrg.getDashboardOrgData,
      action.payload,
    );
    yield put(
      getDashboardOrgSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getDashboardOrgFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardOrgSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardOrgFailed() {}
