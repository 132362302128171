import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListFilteredConference as UsecaseRemoteListFilteredConference } from 'domain/usecases/conference/remote';
import { ListFilteredConference as UsecaseReduxListFilteredConference } from 'domain/usecases/conference/redux';

import { RemoteListFilteredConference } from 'data/repository/conference';
import { ReduxListFilteredConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListFilteredConference =
  (): UsecaseRemoteListFilteredConference =>
    new RemoteListFilteredConference(
      makeApiUrl('/conferences/filtered'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListFilteredConference =
  (): UsecaseReduxListFilteredConference => new ReduxListFilteredConference();
