import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgUser as UsecaseRemoteListOrgUser } from 'domain/usecases/orgUser/remote';
import { ListOrgUser as UsecaseReduxListOrgUser } from 'domain/usecases/orgUser/redux';

import { RemoteListOrgUser } from 'data/repository/orgUser';
import { ReduxListOrgUser } from 'data/store/reducer/orgUsers/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgUser = (): UsecaseRemoteListOrgUser =>
  new RemoteListOrgUser(makeApiUrl('/orgs/users/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUser = (): UsecaseReduxListOrgUser =>
  new ReduxListOrgUser();
