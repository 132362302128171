/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Formik, FormikErrors, FormikProps } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Translator from 'presentation/components/i18n/Translator';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { GetUser } from 'domain/usecases/user/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteCreateUser } from 'main/factories/usecases/user/CreateUserFactory';
import { makeRemoteGetUser } from 'main/factories/usecases/user/GetUserFactory';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUserFactory';

import HeaderMenu from 'presentation/components/MenuNavigation/MenuNavigation';
import { Button } from 'presentation/components/UI';
import history from 'routes/History';
import { closeModal } from 'utils/closeModal';
import { convertDateToUTC } from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import { createUserSchema } from 'validation/users/CreateUserValidator';
import Address from './Address';
import Bond from './Bond';
import GeneralData from './GeneralData';
import {
  Body,
  Container,
  ContentButton,
  Left,
  Right,
} from './styles/StyledUserDetails';

interface ownProps {
  idUser?: number;
  editing?: boolean;
}

export interface UserFieldsValidation {
  name: string;
  email: string;
  phone: string;
  birthdate: Date;
  zipcode?: string;
  province?: string;
  city?: string;
  neighborhood?: string;
  street?: string;
  number?: number;
  complement?: string;
  organization: number;
  sector: number;
  country?: string;
}

export interface UserByOrg extends GetUser.Model {
  orgUnitId?: number;
  orgId?: number;
  role?: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD';
}

const UserDetails: React.FC<ownProps> = ({ idUser, editing = false }) => {
  const [tabNavigation, setTabNavigation] = useState(0);
  const [userResponse, setUserResponse] = useState<UserByOrg>({
    role: 'STANDARD',
  } as UserByOrg);
  const [previousEmailValue, setPreviousEmailValue] = useState('');
  const [previousRoleValue, setPreviousRoleValue] = useState('');
  const [previousOrgId, setPreviousOrgId] = useState<number>();
  const [previousOrgUnitId, setPreviousOrgUnitId] = useState<number>();
  const [valuesEditing, setValuesEditing] = useState<UserFieldsValidation>(
    {} as UserFieldsValidation,
  );

  const {
    user,
    access: { token },
  } = useSelector((state: iStore) => state.auth);

  const formikRef =
    useRef<FormikProps<{} | UserFieldsValidation | UserByOrg>>(null);
  const options = useMemo(
    () => [
      `${Translator('Dados Pessoais')}`,
      `${Translator('Endereço')}`,
      `${Translator('Vínculo')}`,
    ],
    [],
  );

  const initialValuesUsers: UserFieldsValidation = {
    name: '',
    email: '',
    birthdate: new Date(),
    phone: '',
    zipcode: '',
    city: '',
    province: '',
    country: '',
    neighborhood: '',
    street: '',
    complement: '',
    organization: -1,
    sector: -1,
  };

  const { push, replace } = useHistory();
  const { search } = useLocation();

  const params = useMemo(
    () => ({
      org: new URLSearchParams(search).get('org'),
      unit: new URLSearchParams(search).get('unit'),
    }),
    [search],
  );

  const handleDisabledButton = useCallback(
    (errors: FormikErrors<UserFieldsValidation>): boolean => {
      if (editing) return false;

      switch (tabNavigation) {
        case 0:
          return (
            Boolean(errors?.name) ||
            Boolean(errors.email) ||
            Boolean(errors.birthdate) ||
            Boolean(errors.phone)
          );
        case 1:
          return (
            Boolean(errors?.zipcode) ||
            Boolean(errors?.province) ||
            Boolean(errors?.city) ||
            Boolean(errors?.neighborhood) ||
            Boolean(errors?.number) ||
            Boolean(errors?.complement) ||
            Boolean(errors?.street)
          );
        case 2:
          return Boolean(errors?.organization) || Boolean(errors?.sector);
        default:
          return false;
      }
    },
    [editing, tabNavigation],
  );

  const RenderSubPagesTab = useMemo(() => {
    const stepIndex: Record<number, JSX.Element> = {
      0: (
        <GeneralData
          user={userResponse}
          onChangeUserInfo={setUserResponse}
          editing={editing}
        />
      ),
      1: (
        <Address
          user={userResponse}
          onChangeUserInfo={setUserResponse}
          editing={editing}
        />
      ),
      2: (
        <Bond
          user={userResponse}
          onChangeUserInfo={setUserResponse}
          editing={editing}
        />
      ),
    };

    return (
      <>
        <HeaderMenu
          options={options}
          setState={editing ? setTabNavigation : undefined}
          active={tabNavigation}
        />
        {stepIndex[tabNavigation] || <div />}
      </>
    );
  }, [options, tabNavigation, userResponse, editing]);

  // TODO: Solução temporária. Refatorar
  const updateError = (currentFormValues: any) => {
    if (tabNavigation === 0) {
      if (currentFormValues.name === '') {
        return true;
      }
      if (currentFormValues.email === '') {
        return true;
      }

      // if (currentFormValues.birthdate === '') {
      //   return true;
      // }

      if (currentFormValues.phone === '') {
        return true;
      }
    }

    if (tabNavigation === 1) {
      // if (currentFormValues.zipcode === '') {
      //   return true;
      // }
      // if (currentFormValues.province === '') {
      //   return true;
      // }
      // if (currentFormValues.city === '') {
      //   return true;
      // }
      // if (currentFormValues.neighborhood === '') {
      //   return true;
      // }
      // if (currentFormValues.street === '') {
      //   return true;
      // }
      // if (currentFormValues.number === '') {
      //   return true;
      // }
    }

    if (tabNavigation === 2) {
      if (currentFormValues.organization === -1) {
        return true;
      }
      if (currentFormValues.sector === -1) {
        return true;
      }
    }

    return false;
  };

  // eslint-disable-next-line consistent-return
  const handleUpdateUserInfo = useCallback(async () => {
    if (editing && idUser) {
      if (!userResponse.orgId || !previousOrgId)
        return toast.error(
          `${Translator('Você não pode editar o usuário sem uma organização')}`,
        );

      if (!user?.administrator)
        return toast.error(
          `${Translator(
            'Não foi possível buscar detalhes de administração do usuário',
          )}`,
        );

      const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

      // Update user data
      makeRemoteUpdateUser()
        .update({
          id: idUser,
          body: {
            orgLink: {
              org: userResponse.orgId,
              orgUnit: userResponse.orgUnitId || undefined,
            },
            fullName: userResponse.fullName,
            phone: `+${userResponse.phone}`,
            email:
              userResponse?.email !== previousEmailValue
                ? userResponse.email
                : undefined,
            birthDate: userResponse.birthDate,
            notifyOptions: {
              whatsapp: userResponse?.notifyOptions?.whatsapp ?? false,
              email: userResponse?.notifyOptions?.email ?? false,
              sms: userResponse?.notifyOptions?.sms ?? false,
            },
            address: userResponse?.address?.zipcode
              ? {
                  ...userResponse?.address,
                  type: 'HOUSE',
                  number: String(userResponse?.address.number),
                  country: userResponse?.address.country ?? 'BRA',
                  complement: userResponse?.address.complement ?? '',
                }
              : undefined,
          },
        })
        .then(res => {
          UpdateListOrgUser(role, user?.org?.id, user?.orgUnit?.id);

          if (
            userResponse.orgId !== previousOrgId ||
            userResponse.orgUnitId !== previousOrgUnitId
          ) {
            replace(
              `/admin/users/${idUser}?org=${userResponse.orgId}${
                userResponse.orgUnitId ? `&unit=${userResponse.orgUnitId}` : ''
              }`,
            );
          }

          toast.success(
            `${Translator('Informações do usuário atualizadas com sucesso')}`,
          );
        })
        .catch(err => {
          // toast.error(
          //   `${Translator(
          //     'Não foi possível atualizar as informações do usuário',
          //   )}`,
          // );
        });
    } else {
      makeRemoteCreateUser()
        .create({
          body: {
            address: userResponse?.address?.zipcode?.length
              ? {
                  city: userResponse?.address?.city,
                  complement: userResponse?.address?.complement,
                  neighborhood: userResponse?.address?.neighborhood,
                  number: userResponse?.address?.number,
                  province: userResponse?.address?.province,
                  street: userResponse?.address?.street,
                  zipcode: userResponse?.address?.zipcode,
                  country: userResponse?.address?.country ?? 'BRA',
                  type: 'HOUSE',
                }
              : undefined,
            fullName: userResponse.fullName,
            email: userResponse.email,
            birthDate: userResponse.birthDate || undefined,
            auth: 'LOCAL',
            avatar: userResponse.avatar,
            phone: `+${userResponse.phone}`,
            login: userResponse.email,
            org: userResponse.orgId,
            orgUnit: userResponse.orgUnitId,
            notifyOptions: {
              email: true,
              sms: true,
              whatsapp: userResponse?.notifyOptions?.whatsapp ?? false,
            },
            orgAdmin: userResponse.role === 'ORG_ADMIN' || undefined,
            unitAdmin: userResponse.role === 'UNIT_ADMIN' || undefined,
            sysAdmin: userResponse.role === 'SYSADMIN' || undefined,
            timezone: 0,
          },
        })
        .then(res => {
          toast.success(`${Translator('Usuário criado com sucesso')}`);
          history.goBack();
          // push('/admin/users');
        })
        .catch(err => {
          const errorMessage =
            err.name === 'conflict'
              ? `${Translator('E-mail já cadastrado')}`
              : `${Translator('Não foi possível criar usuário')}`;

          makeReduxActiveMessage().active({
            active: 'error',
            title: `${Translator('Criação de usuário')}`,
            content: errorMessage,
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    }
  }, [
    editing,
    idUser,
    userResponse,
    previousOrgId,
    user?.administrator,
    user?.org?.id,
    user?.orgUnit?.id,
    previousEmailValue,
  ]);

  const handleClick = useCallback(() => {
    if (editing) {
      handleUpdateUserInfo();
    } else if (tabNavigation < 2) {
      setTabNavigation(tabNavigation + 1);
    } else {
      handleUpdateUserInfo();
    }
  }, [editing, handleUpdateUserInfo, tabNavigation]);

  const getNameButton = useCallback(() => {
    if (editing) return `${Translator('Salvar')}`;
    if (tabNavigation < 2) return `${Translator('Próximo')}`;

    return `${Translator('Salvar')}`;
  }, [editing, tabNavigation]);

  useEffect(() => {
    if (!idUser) return;

    makeRemoteGetUser()
      .get({ id: idUser })
      .then(response => {
        const isOrgAdmin = response.administrator.org.includes(
          Number(params?.org),
        );

        const isUnitAdmin = response.administrator.orgUnit.includes(
          Number(params?.unit),
        );

        const userRole = response.sysAdmin
          ? 'SYSADMIN'
          : isOrgAdmin
          ? 'ORG_ADMIN'
          : isUnitAdmin
          ? 'UNIT_ADMIN'
          : 'STANDARD';

        setUserResponse({
          ...response,
          phone: response.phone,
          orgId: Number(params?.org) ?? undefined,
          orgUnitId: Number(params?.unit) ?? undefined,
          role: userRole,
          birthDate: editing
            ? convertDateToUTC(new Date(response?.birthDate))
            : new Date(),
        });

        setValuesEditing({
          name: response.fullName,
          email: response.email,
          birthdate: response.birthDate,
          phone: response.phone,
          zipcode: response?.address?.zipcode,
          city: response?.address?.city,
          province: response?.address?.province,
          neighborhood: response?.address?.neighborhood,
          street: response?.address?.street,
          number: response?.address?.number,
          complement: response?.address?.complement,
          organization: Number(params?.org) ?? -1,
          sector: Number(params?.unit) ?? -1,
        });

        setPreviousEmailValue(response.email);
        setPreviousRoleValue(userRole);
        setPreviousOrgId(Number(params?.org) ?? undefined);
        setPreviousOrgUnitId(Number(params?.unit) ?? undefined);
      })
      .catch(e => {
        console.log('Não foi possível carregar dados do usuário');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUser, token]);

  useEffect(() => {
    if (!params.org || !formikRef.current || idUser) return;

    setUserResponse({
      ...userResponse,
      orgId: Number(params.org),
      orgUnitId: Number(params.unit) || undefined,
      role: params.unit ? 'UNIT_ADMIN' : 'ORG_ADMIN',
    });

    formikRef.current?.setFieldValue('organization', Number(params.org));
    formikRef.current?.setFieldValue(
      'sector',
      Number(params.unit) || undefined,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, tabNavigation]);

  return (
    <Container>
      <Body>
        <Left />
        <Formik
          validationSchema={createUserSchema}
          initialValues={editing ? valuesEditing : initialValuesUsers}
          onSubmit={() => {}}
          validateOnChange
          enableReinitialize={editing}
          innerRef={formikRef}
        >
          {({ values }) => {
            console.log('values', values);
            return (
              <>
                <Right>
                  {RenderSubPagesTab}
                  <ContentButton>
                    {!editing && (
                      <Button
                        size="small"
                        variant="secundary"
                        onClick={() => setTabNavigation(tabNavigation - 1)}
                        disabled={!tabNavigation}
                      >
                        {`${Translator('Voltar')}`}
                      </Button>
                    )}
                    <Button
                      size="small"
                      onClick={handleClick}
                      // disabled={handleDisabledButton(errors)}
                      disabled={updateError(values)}
                    >
                      {getNameButton()}
                    </Button>
                  </ContentButton>
                </Right>
              </>
            );
          }}
        </Formik>
      </Body>
    </Container>
  );
};

export default UserDetails;
