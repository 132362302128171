import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import { ThemeContext } from 'App';

interface PropsAvatarImage {
  src?: string;
  name?: string;
  size: 'orange' | 'mini' | 'small' | 'medium' | 'large' | 'root';
}

const AvatarImage: React.FC<PropsAvatarImage> = ({ src, name, size }) => {
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles((themeMUI: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        '& > *': {
          margin: `${themeMUI.spacing(1)} 0`,
        },
      },
      mini: {
        width: '44px',
        height: '44px',
      },
      small: {
        width: themeMUI.spacing(8),
        height: themeMUI.spacing(8),
        backgroundColor: `${theme.colors.primary.main}`,
        fontSize: '32px',
      },
      medium: {
        width: themeMUI.spacing(10),
        height: themeMUI.spacing(10),
      },
      large: {
        width: themeMUI.spacing(15),
        height: themeMUI.spacing(15),
      },
      orange: {
        color: themeMUI.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      },
    }),
  );

  const classes = useStyles();

  const stringAvatar = (value?: string) => {
    const split = value?.split(' ').filter(el => el !== '');
    const first = value?.split(' ')[0][0];
    let second = '';

    if (split && split?.length > 1) {
      second = split[split.length - 1] ? split[split.length - 1][0] : '';
    }

    return {
      children: `${first}${second}`.toUpperCase(),
    };
  };
  const fallbackAvatar = (
    <Avatar src="/broken-image.jpg" className={classes[size]} />
  );

  const initials = (
    <Avatar
      {...stringAvatar(name)}
      src="/broken-image.jpg"
      className={classes[size]}
    />
  );
  if (!name && !src) {
    return <div className={classes[size]}>{fallbackAvatar}</div>;
  }
  if (src) {
    return (
      <div className={classes.root}>
        <Avatar alt={name} src={src} className={classes[size]} />
      </div>
    );
  }
  return <div className={classes.root}>{initials}</div>;
};

export default AvatarImage;
