import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UserVerification as UsecaseRemoteUserVerification } from 'domain/usecases/user/remote';

import { RemoteUserVerification } from 'data/repository/user';

/**
 * send request via API.
 */
export const makeRemoteUserVerification = (): UsecaseRemoteUserVerification =>
  new RemoteUserVerification(
    makeApiUrl('/users/availability/'),
    makeHttpClient(),
  );
