import { ListConferenceFilteredByACL } from 'domain/usecases/conference/redux/ListConferenceFilteredByACL';
import {
  iActionListFilteredByACL,
  iActionListFilteredByACLFailed,
  iActionListFilteredByACLSuccess,
  ConferenceTypes,
} from 'domain/interfaces/redux/conference/listFilteredByACL';

export const listFilteredByACLRequest = (
  payload: ListConferenceFilteredByACL.Params,
): iActionListFilteredByACL => ({
  type: ConferenceTypes.LIST_FILTERED_BY_ACL,
  payload,
});

export const listFilteredByACLSuccess = (
  params: ListConferenceFilteredByACL.Model,
): iActionListFilteredByACLSuccess => ({
  type: ConferenceTypes.LIST_FILTERED_BY_ACL_SUCCESS,
  payload: params,
});

export const listFilteredByACLFailed = (): iActionListFilteredByACLFailed => ({
  type: ConferenceTypes.LIST_FILTERED_BY_ACL_FAILED,
});
