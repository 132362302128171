import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteByOrgUnitAdmin as UsecaseRemoteDeleteByOrgUnitAdmin } from 'domain/usecases/admin/remote';
// import { DeleteByOrgUnitAdmin as UsecaseReduxDeleteByOrgUnitAdmin } from 'domain/usecases/admin/redux';

import { RemoteDeleteByOrgUnitAdmin } from 'data/repository/admin';
// import { ReduxDeleteByOrgUnitAdmin } from 'data/store/reducer/admin/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteByOrgUnitAdmin =
  (): UsecaseRemoteDeleteByOrgUnitAdmin =>
    new RemoteDeleteByOrgUnitAdmin(
      makeApiUrl('/orgs/{orgId}/units/{unitId}/admins/{userId}/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteByOrgUnitAdmin =
  (): UsecaseReduxDeleteByOrgUnitAdmin =>
    new ReduxDeleteByOrgUnitAdmin(); */
