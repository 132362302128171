import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  /* max-width: 1920px; */
  margin: 0px auto;
  height: 52px;
  padding: 0px 24px;
  color: #656a6e;
  display: flex;
  margin-top: 19px;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
`;

export const List = styled.div`
  width: 100%;
  margin-top: 14px;
  padding: 0px 24px;
`;
