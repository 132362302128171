import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUnit } from 'domain/interfaces/redux/orgUnit/list';
import { ListOrgUnit } from 'domain/usecases/orgUnit/remote/ListOrgUnit';
import { makeRemoteListOrgUnit } from 'main/factories/usecases/orgUnit/ListOrgUnitFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUnits/actions/list';

export function* onList(action: iActionListOrgUnit) {
  const remoteListOrgUnit = makeRemoteListOrgUnit();

  try {
    const response: ListOrgUnit.Model = yield call(
      remoteListOrgUnit.list,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
