import styled from 'styled-components';
import iconStop from 'presentation/base/icons/stop.svg';
import iconCancelOutline from 'presentation/base/icons/cancelOutline.svg';
import iconDetailsOutline from 'presentation/base/icons/detailsOutline.svg';
import iconPerson from 'presentation/base/icons/person.svg';
import iconPersonOutline from 'presentation/base/icons/personOutline.svg';
import iconWait from 'presentation/base/icons/wait.svg';
import iconWaitOutline from 'presentation/base/icons/waitOutline.svg';
import iconStartOutline from 'presentation/base/icons/startOutline.svg';
import iconNewDocument from 'presentation/base/icons/newDocument.svg';
import iconNewDocumentOutline from 'presentation/base/icons/newDocumentOutline.svg';
import iconShareEmail from 'presentation/base/icons/shareEmail.svg';
import iconShareEmailOutline from 'presentation/base/icons/shareEmailOutline.svg';
import iconDownload from 'presentation/base/icons/download.svg';
import iconDownloadOutline from 'presentation/base/icons/downloadOutline.svg';
import iconHome from 'presentation/base/icons/home.svg';
import iconHomeOutline from 'presentation/base/icons/homeOutline.svg';
import iconEdit from 'presentation/base/icons/editIcon.svg';
import iconEditFill from 'presentation/base/icons/editIconFill.svg';
import IconDashboard from 'presentation/base/icons/icon_dashboard.svg';
import IconCopyLink from 'presentation/base/icons/iconCopyLink.svg';
import IconCopyLinkHover from 'presentation/base/icons/iconCopyLinkHover.svg';
import IconSendWhats from 'presentation/base/icons/sendWhats.svg';
import IconHoverWhats from 'presentation/base/icons/hoverWhats.svg';
import IconDetailsOrgUnit from 'presentation/base/icons/iconDetailsList.svg';
import IconRectangleList from 'presentation/base/icons/iconRectangle.svg';
import IconDeleteFromList from 'presentation/base/icons/iconTrash.svg';
import IconViewDetails from 'presentation/base/icons/icon_view.svg';
import NewMoreIcon from 'presentation/base/icons/newMoreIcon.svg';
import DownloadIcon from 'presentation/base/icons/icon_download.svg';

interface IDetailsProps {
  isView?: boolean;
}

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const Container = styled.div``;
export const Cancel = styled.div`
  background-image: url(${iconCancelOutline});
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`;

export const Details = styled.div<IDetailsProps>`
  margin-right: 9px;
  background-image: url(${props =>
    props.isView ? IconViewDetails : iconDetailsOutline});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`;

export const Delete = styled.div`
  margin: 0px 3px;
  background-image: url(${IconDeleteFromList});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconDeleteFromList});
  }
`;

export const EditIcon = styled.div`
  margin: 0px 3px;
  background-image: url(${iconEdit});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconEditFill});
  }
`;

export const Stop = styled.div`
  background-image: url(${iconStop});
  background-size: 27px;
  background-repeat: no-repeat;
  margin: 0px 3px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const NewDocument = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconNewDocumentOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconNewDocument});
  }
`;
export const Person = styled.div`
  margin: 0px 3px;
  background-image: url(${iconPersonOutline});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconPerson});
  }
`;

export const Wait = styled.div`
  margin: 0px 3px;
  background-image: url(${iconWaitOutline});
  width: 26px;
  background-repeat: no-repeat;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${iconWait});
  }
`;

export const ShareEmail = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconShareEmailOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconShareEmail});
  }
`;

export const Download = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconDownloadOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconDownload});
  }
`;
export const Home = styled.div`
  margin: 0px 3px;
  background-repeat: no-repeat;
  background-image: url(${iconHomeOutline});
  width: 27px;
  height: 27px;
  cursor: pointer;
  :hover {
    background-image: url(${iconHome});
  }
`;

export const Start = styled.div`
  margin-right: 19px;
  background-image: url(${iconStartOutline});
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`;

export const Dashboard = styled.div`
  margin: 0px 3px;
  background-image: url(${IconDashboard});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const CopyLink = styled.div`
  margin: 0px 3px;
  background-image: url(${IconCopyLink});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconCopyLinkHover});
  }
`;

export const SendWhats = styled.div`
  margin: 0px 3px;
  background-image: url(${IconSendWhats});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconHoverWhats});
  }
`;

export const DetailsOrg = styled.div`
  margin: 0px 3px;
  background-image: url(${IconDetailsOrgUnit});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const RectangleIcon = styled.div`
  margin: 0px 3px;
  background-image: url(${IconRectangleList});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const IconDownload = styled.div`
  margin: 3px 3px;
  background-image: url(${DownloadIcon});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`;
