import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 32px 0px 32px 56px;
  justify-content: space-between;
  align-items: center;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface TitleProps {
  response: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE'
}

export const Title = styled.div<TitleProps>`
  margin-top: 40px;
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  color: #353a40;

  span {
    color: ${({ response }) => {
      switch (response) {
        case 'ACCEPTED':
          return '#1ca345';
        case 'DECLINED':
          return '#DE3F44';
        case 'TENTATIVE':
          return '#005BC8';
        default:
          return '#353a40';
      }
    }};
  }

  .info {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #6c757e;
  }
`;

export const Form = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 24px;
  align-items: flex-end;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 32px;
`;

export const Info = styled.div`
  padding: 32px 0px;

  .main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .response {
      font-weight: 400;
      font-size: 14px;
      color: #353a40;

      span {
        font-weight: bold;
      }
    }

    .invite {
      color: #353a40;
    }

    .confirmed {
      color: #1ca345;
    }

    .recused {
      color: #de3f44;
    }

    .maybe {
      color: #005bc8;
    }

    .waiting {
      color: #353a40;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #6c757e;
    }

    .link {
      font-weight: 400;
      font-size: 14px;
      color: #353a40;
    }
  }

  .general {
    font-size: 14px;
    line-height: 26px;
    color: #353a40;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #6c757e;
      margin-bottom: 4px;
    }

    strong {
    }

    .info {
      display: flex;
      flex-direction: column;
    }
  }
`;
