/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useMemo, useState } from 'react';
import { OrgUserInfo } from 'domain/interfaces/models';
import { currencyConvert } from 'utils/formatCurrency';
import { iColumnsConfig } from './interface';
import Translator from '../i18n/Translator';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    overflowY: 'auto',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

interface ownProps {
  dataList: any[];
  visualization:
    | 'orgUnit'
    | 'service'
    | 'user'
    | 'day'
    | 'serviceAndDay'
    | 'orgUnitAndDay'
    | 'serviceAndOrgUnit'
    | 'serviceAndUser'
    | 'detailed';
  currency?: string;
}

type iColumns =
  | 'fullname'
  | 'short'
  | 'total'
  | 'name'
  | 'unitPrice'
  | 'amount'
  | 'login'
  | 'day'
  | 'time';

const BillingTable: React.FC<ownProps> = ({
  dataList,
  visualization = 'orgUnit',
  currency,
}) => {
  const classes = useStyles();

  const [columns, setColumns] = useState<iColumnsConfig[]>();

  const RenderComponents: React.FC<{
    id: iColumns;
    item: any;
  }> = ({ id, item }) => {
    switch (id) {
      case 'fullname':
        return (
          <div>{item?.fullname || item?.short || item?.orgUnit?.short}</div>
        );
      case 'short':
        return (
          <div>
            {item?.short || item?.orgUnit?.short || item?.user?.orgUnit?.short}
          </div>
        );
      case 'total':
        return (
          <div>{currencyConvert(item?.total || item?.service?.total)}</div>
        );
      case 'name':
        return <div>{item?.name || item?.service?.name}</div>;
      case 'unitPrice':
        return <div>{currencyConvert(item?.unitPrice)}</div>;
      case 'amount':
        let currencyValue = currencyConvert(
          item?.amount || item?.service?.amount,
        );

        if (currencyValue)
          currencyValue += ` ${item?.unit || item?.service?.unit}`;

        return <div>{currencyValue}</div>;
      case 'login':
        return <div>{item?.login || item?.user?.name}</div>;
      case 'day':
        return (
          <div>
            {item?.date ||
              new Date(item?.timestamp).toLocaleDateString('pt-PT')}
          </div>
        );
      case 'time':
        return (
          <div>
            {item?.time ||
              new Date(item?.timestamp).toLocaleTimeString('pt-PT')}
          </div>
        );
      default:
        return <div />;
    }
  };

  const columnsByOrgUnit: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'fullname',
        label: Translator('Setor'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 400,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByService: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'name',
        label: Translator('Serviço'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'unitPrice',
        label: Translator('Unitário'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'amount',
        label: Translator('Consumo'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByUser: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'login',
        label: Translator('Usuário'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 400,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByDay: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'day',
        label: Translator('Data'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByServiceAndDay: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'name',
        label: Translator('Serviço'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'day',
        label: Translator('Data'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'amount',
        label: Translator('Consumo'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByOrgUnitAndDay: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'fullname',
        label: Translator('Setor'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'day',
        label: Translator('Data'),
        minWidth: 100,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByServiceAndOrgUnit: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'fullname',
        label: Translator('Setor'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'name',
        label: Translator('Serviço'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'amount',
        label: Translator('Consumo'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsByServiceAndUser: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'login',
        label: Translator('Usuário'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 400,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'name',
        label: Translator('Serviço'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'amount',
        label: Translator('Consumo'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  const columnsDetailed: iColumnsConfig[] = useMemo(
    () => [
      {
        id: 'day',
        label: Translator('Data'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'time',
        label: Translator('Hora'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'login',
        label: Translator('Usuário'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'short',
        label: Translator('Sigla'),
        minWidth: 50,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'name',
        label: Translator('Serviço'),
        minWidth: 200,
        align: 'left',
        alignTitle: 'left',
      },
      {
        id: 'amount',
        label: Translator('Consumo'),
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
      {
        id: 'total',
        label: `${Translator('Valor')} (${currency})`,
        minWidth: 50,
        align: 'right',
        alignTitle: 'right',
      },
    ],
    [currency],
  );

  useEffect(() => {
    switch (visualization) {
      case 'orgUnit':
        setColumns(columnsByOrgUnit);
        break;
      case 'service':
        setColumns(columnsByService);
        break;
      case 'user':
        setColumns(columnsByUser);
        break;
      case 'day':
        setColumns(columnsByDay);
        break;
      case 'serviceAndDay':
        setColumns(columnsByServiceAndDay);
        break;
      case 'orgUnitAndDay':
        setColumns(columnsByOrgUnitAndDay);
        break;
      case 'serviceAndOrgUnit':
        setColumns(columnsByServiceAndOrgUnit);
        break;
      case 'serviceAndUser':
        setColumns(columnsByServiceAndUser);
        break;
      case 'detailed':
        setColumns(columnsDetailed);
        break;
      default:
        setColumns(columnsByOrgUnit);
        break;
    }
  }, [
    columnsByDay,
    columnsByOrgUnit,
    columnsByOrgUnitAndDay,
    columnsByService,
    columnsByServiceAndDay,
    columnsByServiceAndOrgUnit,
    columnsByServiceAndUser,
    columnsByUser,
    columnsDetailed,
    visualization,
  ]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns?.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px', backgroundColor: '#f5f5f5' }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.map((item: OrgUserInfo) => (
            <TableRow>
              {columns?.map((columnProps: any) => {
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} item={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingTable;
