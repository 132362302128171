import styled from 'styled-components';

export const Container = styled.div``;
export const Signed = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.blue};
  text {
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;
export const NotSigned = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.colors.feedback.error};
  text {
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;
