import styled from 'styled-components';

interface successProps {
  hasContent: boolean;
}

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`;

export const Body = styled.div`
  position: fixed;
  background: white;

  display: flex;
  flex-direction: column;

  max-width: 408px;
  max-height: 604px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div<successProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  gap: 12px;
  padding: 24px;

  p {
    color: #222529;
    font-size: 16px;
    font-weight: 600;
  }

  span {
    color: #636d77;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: space-between;

  background-color: #f8f8f8;
  border-top: 1px solid #dedede;
  padding: 12px 24px;
`;
