/* eslint-disable new-cap */
/* eslint-disable no-lonely-if */
/* eslint-disable dot-notation */
import { subDays } from 'date-fns';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteDeleteConference } from 'main/factories/usecases/conference/DeleteConferenceFactory';
import { makeReduxListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import { makeRemoteUpdateConference } from 'main/factories/usecases/conference/UpdateConferenceFactory';
import { Button } from 'presentation/components/UI';
import Radio from 'presentation/components/UI/radio';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RRule } from 'rrule';
import { closeModal } from 'utils/closeModal';
import { makeRemoteGetConference } from 'main/factories/usecases/conference/GetConferenceFactory';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import Translator from '../i18n/Translator';
import {
  Actions,
  Container,
  ContainerModal,
  ContainerOptions,
  Content,
  Label,
  Options,
  Subtitle,
  Title,
} from './styles';

interface iOptions {
  ONLYTHIS: boolean;
  FROMTHIS: boolean;
  ALL: boolean;
}

interface iModalRecurringConference {
  message: iMessage;
}

interface iConferenceData {
  rrule?: string;
  scheduled?: string;
}

const RecurringConferenceModal: React.FC<iModalRecurringConference> = ({
  message,
}) => {
  const [options, setOptions] = useState<iOptions>({
    ONLYTHIS: true,
    FROMTHIS: false,
    ALL: false,
  });

  const [conferenceData, setConferenceData] = useState<iConferenceData>({});
  const [isDelete, setIsDelete] = useState(false);

  const { active, actionCancel, actionOk, conferenceId, type } = message;

  const msgName = MessageOptions.recurringConferenceModal;

  function verifyOptions(opt: iOptions) {
    if (opt.ONLYTHIS && !opt.FROMTHIS && !opt.ALL) {
      return 'ONLYTHIS';
    }
    if (opt.FROMTHIS && !opt.ONLYTHIS && !opt.ALL) {
      return 'FROMTHIS';
    }
    if (opt.ALL && !opt.ONLYTHIS && !opt.FROMTHIS) {
      return 'ALL';
    }
    return '';
  }

  const selectedOption = verifyOptions(options);

  const handleCloseSideSheet = useCallback(() => {
    makeReduxUpdateControllerConference().updateController({
      showDetails: false,
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    closeModal();
    setOptions({
      ONLYTHIS: true,
      FROMTHIS: false,
      ALL: false,
    });
  }, []);

  const handleDeleteOnlyThisConference = useCallback(() => {
    if (Number(conferenceId)) {
      makeRemoteDeleteConference()
        .delete({
          conferenceId: Number(conferenceId),
        })
        .then(() => {
          setIsDelete(true);
          toast.success(Translator('Reunião excluida com sucesso'));
        })
        .catch(() => {
          setIsDelete(false);
          toast.error(Translator('Não foi possível remover a reunião.'));
        })
        .finally(() => {
          handleCloseSideSheet();
          handleCloseModal();
        });
    }
  }, [conferenceId, handleCloseModal, handleCloseSideSheet]);

  const handleDeleteAllConference = useCallback(() => {
    if (conferenceId) {
      makeRemoteUpdateConference()
        .update({
          conferenceId: Number(conferenceId),
          body: {
            recurrence: null,
          },
        })
        .then(() => {
          setIsDelete(true);

          toast.success(Translator('Reunião excluida com sucesso'));
        })
        .catch(() => {
          setIsDelete(false);

          toast.error(Translator('Não foi possível remover a reunião.'));
        })
        .finally(() => {
          handleCloseModal();
          handleCloseSideSheet();
        });
    }
  }, [conferenceId]);

  const handleDeleteFromThisConference = useCallback(() => {
    if (conferenceId) {
      const reccurence = RRule.fromString(conferenceData?.rrule ?? '');

      const rrule = new RRule({
        ...reccurence?.options,
        until: subDays(new Date(conferenceData?.scheduled ?? ''), 1),
      });

      console.log('rrule', rrule);

      makeRemoteUpdateConference()
        .update({
          conferenceId: Number(conferenceId),
          body: {
            recurrence: {
              effect: 'FROMTHIS',
              scheduled: conferenceData?.scheduled,
              rrule: rrule.toString(),
            },
          },
        })
        .then(() => {
          setIsDelete(true);

          toast.success(Translator('Reunião excluida com sucesso'));
        })
        .catch(() => {
          setIsDelete(false);

          toast.error(Translator('Não foi possível remover a reunião.'));
        })
        .finally(() => {
          handleCloseModal();
          handleCloseSideSheet();
        });
    }
  }, [conferenceId, conferenceData, handleCloseModal, handleCloseSideSheet]);

  useEffect(() => {
    if (conferenceId) {
      makeRemoteGetConference()
        .get({
          conferenceId: Number(conferenceId),
        })
        .then((response: iConferenceData) => {
          setConferenceData({
            rrule: response?.rrule,
            scheduled: response?.scheduled,
          });
        })
        .catch(() => {
          toast.error(Translator('Erro ao buscar reunião'));
        });
    }
  }, [conferenceId]);

  useEffect(() => {
    if (isDelete) {
      makeReduxListFilteredConference().list({
        dataControl: {
          limit: 9999,
        },
      });
    }
    setIsDelete(false);
  }, [isDelete]);

  useEffect(() => {
    return () => {
      setOptions({
        ONLYTHIS: true,
        FROMTHIS: false,
        ALL: false,
      });
    };
  }, []);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Title>
                {type === 'EDIT'
                  ? `${Translator('Editar reunião recorrente')}`
                  : `${Translator('Excluir reunião recorrente')}`}
              </Title>
              <Subtitle>
                {type === 'EDIT'
                  ? `${Translator(
                      'Quais das reuniões associadas a este evento você deseja editar?',
                    )}`
                  : `${Translator(
                      'Quais das reuniões associadas a este evento você deseja excluir?',
                    )}`}
              </Subtitle>
              <ContainerOptions>
                <Options>
                  <Radio
                    checked={options['ONLYTHIS']}
                    onClick={() => {
                      if (!options['FROMTHIS'] || !options['ALL']) {
                        setOptions({
                          ONLYTHIS: !options['ONLYTHIS'] || false,
                          FROMTHIS: false,
                          ALL: false,
                        });
                      }
                    }}
                  />
                  <Label>{Translator('Apenas esta reunião')}</Label>
                </Options>
                <Options>
                  <Radio
                    checked={options['FROMTHIS']}
                    onClick={() => {
                      if (!options['ONLYTHIS'] || !options['ALL']) {
                        setOptions({
                          ONLYTHIS: false,
                          FROMTHIS: !options['FROMTHIS'],
                          ALL: false,
                        });
                      }
                    }}
                  />
                  <Label>{Translator('Esta e todas as seguintes')}</Label>
                </Options>
                <Options>
                  <Radio
                    checked={options['ALL']}
                    onClick={() => {
                      if (!options['ONLYTHIS'] || !options['FROMTHIS']) {
                        setOptions({
                          ONLYTHIS: false,
                          FROMTHIS: false,
                          ALL: !options['ALL'],
                        });
                      }
                    }}
                  />
                  <Label>{Translator('Todas as reuniões')}</Label>
                </Options>
              </ContainerOptions>
            </Content>
            <Actions>
              <Button
                variant="secundary"
                title="Cancelar"
                size="small"
                onClick={handleCloseModal}
              >
                {Translator('Cancelar')}
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={() => {
                  if (type === 'EDIT') {
                    actionOk?.()(selectedOption);
                    closeModal();
                  } else {
                    if (selectedOption === 'ONLYTHIS' && type === 'DELETE') {
                      handleDeleteOnlyThisConference();
                    }
                    if (selectedOption === 'FROMTHIS' && type === 'DELETE') {
                      handleDeleteFromThisConference();
                    }
                    if (selectedOption === 'ALL' && type === 'DELETE') {
                      handleDeleteAllConference();
                    }
                  }
                }}
                disabled={!selectedOption}
              >
                {Translator('OK')}
              </Button>
            </Actions>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default RecurringConferenceModal;
