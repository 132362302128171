import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const Left = styled.div`
  width: 7%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary.main};
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 93%;
  height: 100%;
  padding: 0 160px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #000000;

  margin: 26px 0 26px 0;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;

  gap: 42px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  gap: 20px;
`;

export const ForgotPasswordText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  height: 100%;
`;
