import { takeLatest } from 'redux-saga/effects';
import { OrgUnitTypes } from 'domain/interfaces/redux/orgUnit/types';

import { onList, onListSuccess, onListFailed } from './List';
import {
  onListAclFiltered,
  onListAclFilteredSuccess,
  onListAclFilteredFailed,
} from './ListAclFiltered';
import {
  onListAclFilteredV2,
  onListAclFilteredV2Success,
  onListAclFilteredV2Failed,
} from './ListAclFilteredV2';
import {
  onListByOrgId,
  onListByOrgIdSuccess,
  onListByOrgIdFailed,
} from './ListByOrgId';
import {
  onListByUnitId,
  onListByUnitIdSuccess,
  onListByUnitIdFailed,
} from './ListByOrgUnitId';

const tasks = [
  takeLatest(OrgUnitTypes.LIST, onList),
  takeLatest(OrgUnitTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(OrgUnitTypes.LIST_FAILED, onListFailed),

  takeLatest(OrgUnitTypes.LIST_ACL_FILTERED, onListAclFiltered),
  takeLatest(OrgUnitTypes.LIST_ACL_FILTERED_SUCCESS, onListAclFilteredSuccess),
  takeLatest(OrgUnitTypes.LIST_ACL_FILTERED_FAILED, onListAclFilteredFailed),

  takeLatest(OrgUnitTypes.LIST_ACL_FILTERED_V2, onListAclFilteredV2),
  takeLatest(
    OrgUnitTypes.LIST_ACL_FILTERED_V2_SUCCESS,
    onListAclFilteredV2Success,
  ),
  takeLatest(
    OrgUnitTypes.LIST_ACL_FILTERED_V2_FAILED,
    onListAclFilteredV2Failed,
  ),

  takeLatest(OrgUnitTypes.LIST_BY_ORGID, onListByOrgId),
  takeLatest(OrgUnitTypes.LIST_BY_ORGID_SUCCESS, onListByOrgIdSuccess),
  takeLatest(OrgUnitTypes.LIST_BY_ORGID_FAILED, onListByOrgIdFailed),

  takeLatest(OrgUnitTypes.LIST_BY_ORGUNITID, onListByUnitId),
  takeLatest(OrgUnitTypes.LIST_BY_ORGUNITID_SUCCESS, onListByUnitIdSuccess),
  takeLatest(OrgUnitTypes.LIST_BY_ORGUNITID_FAILED, onListByUnitIdFailed),
];

export default tasks;
