import React from 'react';
import { ButtonPageStyle } from './styles';

interface ButtonPageProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  active?: boolean;
}

export const ButtonPage: React.FC<ButtonPageProps> = ({
  children,
  active,
  ...rest
}) => {
  return (
    <ButtonPageStyle active={active} type="button" {...rest}>
      {children}
    </ButtonPageStyle>
  );
};
