/* eslint-disable no-plusplus */

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
const minutes = ['00', '15', '30', '45'];

export const times: string[] = [];

for (let i = 0; i < hours.length; i++) {
  for (let j = 0; j < minutes.length; j++) {
    times.push(`${hours[i]}:${minutes[j]}`);
  }
}
