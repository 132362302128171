import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import Translator from 'presentation/components/i18n/Translator';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { OrgInfos } from 'domain/usecases/org/remote';
import { Input, PhoneInput } from 'presentation/components/UI';
import { initialValuesOrg } from '..';

import { Container, InputFields, InputPhoneFields } from './styles';

interface ownProps {
  org: OrgInfos;
  onChangeOrgInfo: Function;
}

const Contact: React.FC<ownProps> = ({ org, onChangeOrgInfo }) => {
  const { setFieldValue, errors, touched, handleBlur } =
    useFormikContext<initialValuesOrg>();

  const { resources } = useSelector((store: iStore) => store.auth.permissions);
  const { user } = useSelector((store: iStore) => store.auth);

  const disableUpdate = useMemo(() => {
    if (user?.administrator?.system) return false;
    if (!resources) return true;

    // Go to resource ORG and check if user have UPDATE permission
    const haveUpdate = resources.find(
      res =>
        res.name === 'ORG' &&
        res.actions.find(item => item.name.includes('UPDATE')),
    );

    return !haveUpdate;
  }, [resources, user?.administrator?.system]);

  return (
    <Container>
      <InputFields>
        <Input
          label={`${Translator('Nome da pessoa de contato')}`}
          defaultValue={org?.contactName}
          name="contactName"
          onBlur={handleBlur}
          value={org?.contactName}
          message={touched.contactName ? errors.contactName : ''}
          error={Boolean(touched.contactName && errors?.contactName)}
          onChange={e => {
            onChangeOrgInfo({ ...org, contactName: e.target.value });
            setFieldValue('contactName', e.target.name);
          }}
          autoFocus
          disabled={disableUpdate}
        />
        <Input
          label={`${Translator('E-mail')}`}
          className="email"
          defaultValue={org?.contactEmail}
          name="contactEmail"
          onBlur={handleBlur}
          message={touched.contactEmail ? errors.contactEmail : ''}
          error={Boolean(touched.contactEmail && errors?.contactEmail)}
          value={org?.contactEmail}
          onChange={e => {
            onChangeOrgInfo({ ...org, contactEmail: e.target.value });
            setFieldValue('contactEmail', e.target.value);
          }}
          disabled={disableUpdate}
        />
        <InputPhoneFields>
          <PhoneInput
            label={`${Translator('Telefone 1')}`}
            name="contactPhone1"
            preferredCountries={['br', 'pt']}
            message={touched.contactPhone1 ? errors.contactPhone1 : ''}
            error={Boolean(touched.contactPhone1 && errors?.contactPhone1)}
            value={org?.contactPhone1}
            onChange={e => {
              onChangeOrgInfo({
                ...org,
                contactPhone1: e.replace(/[^A-Z0-9]/gi, ''),
              });
              setFieldValue('contactPhone1', e);
            }}
            disabled={disableUpdate}
          />
          <PhoneInput
            label={`${Translator('Telefone 2')}`}
            preferredCountries={['br', 'pt']}
            name="contactPhone2"
            message={touched.contactPhone2 ? errors.contactPhone2 : ''}
            error={Boolean(touched.contactPhone2 && errors?.contactPhone2)}
            value={org?.contactPhone2}
            onChange={e => {
              onChangeOrgInfo({
                ...org,
                contactPhone2: e.replace(/[^A-Z0-9]/gi, ''),
              });
              setFieldValue('contactPhone2', e);
            }}
            disabled={disableUpdate}
          />
        </InputPhoneFields>
      </InputFields>
    </Container>
  );
};

export default Contact;
