import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .line {
    display: flex;
    width: 100%;
    gap: 32px;
    margin-bottom: 16px;

    @media (max-width: 1440px) {
      flex-wrap: wrap;
    }
  }

  .line:last-child {
    margin-bottom: 0;
  }

  .between {
    display: flex;
    justify-content: space-between;

    .label {
      display: flex;
      align-items: center;
      gap: 8px;

      .t {
        color: black;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .title {
    justify-content: start;
  }
`;

interface iCard {
  width: string;
  height?: string;
  breakpoint?: string;
}

export const Card = styled.div<iCard>`
  height: ${({ height }) => height || '160px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${({ width }) => width};
  background: #ffffff;
  border: 1px solid rgba(105, 127, 150, 0.25);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 24px 24px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #436385;

  & .title-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .info {
    height: 50px;
    display: flex;
    gap: 30px;
  }

  ${({ breakpoint }) =>
    breakpoint &&
    css`
      @media (max-width: ${breakpoint}) {
        max-width: 100%;
      }
    `}
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #697f96;
  }
  .value {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #233242;
  }
`;

export const Separator = styled.div`
  width: 2px;
  height: 100%;
  background: rgba(105, 127, 150, 0.3);
`;
