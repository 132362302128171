import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListGroupsAudit as UsecaseRemoteListGroupsAudit } from 'domain/usecases/audit/remote';
import { ListGroupsAudit as UsecaseReduxListGroupsAudit } from 'domain/usecases/audit/redux';

import { RemoteListGroupsAudit } from 'data/repository/audit';
import { ReduxListGroupsAudit } from 'data/store/reducer/audit/usecases';

/**
 * send request via API.
 */
export const makeRemoteListGroupsAudit = (): UsecaseRemoteListGroupsAudit =>
  new RemoteListGroupsAudit(makeApiUrl('/audit/groups'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListGroupsAudit = (): UsecaseReduxListGroupsAudit =>
  new ReduxListGroupsAudit();
