import { ListOrgUnitAclFilteredV2 } from 'domain/usecases/orgUnit/redux/ListOrgUnitAclFilteredV2';
import {
  iActionListOrgUnitAclFilteredV2,
  iActionListOrgUnitAclFilteredV2Failed,
  iActionListOrgUnitAclFilteredV2Success,
  OrgUnitTypes,
} from 'domain/interfaces/redux/orgUnit/listAclFilteredV2';

export const listAclFilteredV2Request = (
  payload: ListOrgUnitAclFilteredV2.Params,
): iActionListOrgUnitAclFilteredV2 => ({
  type: OrgUnitTypes.LIST_ACL_FILTERED_V2,
  payload,
});

export const listAclFilteredV2Success = (
  params: ListOrgUnitAclFilteredV2.Model,
): iActionListOrgUnitAclFilteredV2Success => ({
  type: OrgUnitTypes.LIST_ACL_FILTERED_V2_SUCCESS,
  payload: params,
});

export const listAclFilteredV2Failed =
  (): iActionListOrgUnitAclFilteredV2Failed => ({
    type: OrgUnitTypes.LIST_ACL_FILTERED_V2_FAILED,
  });
