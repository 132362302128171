import { toast } from 'react-toastify';
import { ListAdmin } from 'domain/usecases/admin/remote';
import { makeReduxListOrgUnitAclFilteredV2 } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';
import { makeReduxListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import { makeRemoteListAdmin } from '../main/factories/usecases/admin/ListAdminFactory';

export const UpdateListOrgUser = (
  role: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD',
  orgId?: number,
  unitId?: number,
): void => {
  makeReduxListOrgUser().list({
    query: {
      disablePagination: true,
      org:
        role === 'ORG_ADMIN' || role === 'UNIT_ADMIN' || role === 'STANDARD'
          ? orgId || undefined
          : undefined,
      orgUnit: role === 'UNIT_ADMIN' ? unitId || undefined : undefined,
    },
  });
};

export const UpdateListAdmin = (
  id: number,
  orgId: number,
  onSuccess?: (data: ListAdmin.Model) => void,
): void => {
  makeRemoteListAdmin()
    .list({
      query: {
        org: orgId,
        orgUnit: id,
        disablePagination: true,
      },
    })
    .then(res => {
      onSuccess?.(res);
    })
    .catch(e => {
      // toast.error('Não foi possível listar usuários do setor.');
    });
};

export const UpdateListOrgUnit = (): void => {
  makeReduxListOrgUnitAclFilteredV2().list({
    limit: 9999,
  });
};
