import { Dispatch } from 'redux';
import store from 'data/store';
import { active } from 'data/store/reducer/message/actions/active';

import { iActionActive } from 'domain/interfaces/redux/message/active';

import { ActiveMessage } from 'domain/usecases/message/redux';

export class ReduxActiveMessage implements ActiveMessage {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  active = (params: ActiveMessage.Params): iActionActive =>
    this.send(active(params));
}
