import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { MatchOrg as UsecaseRemoteMatchOrg } from 'domain/usecases/org/remote';
// import { MatchOrg as UsecaseReduxMatchOrg } from 'domain/usecases/org/redux';

import { RemoteMatchOrg } from 'data/repository/org';
// import { ReduxMatchOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteMatchOrg = (): UsecaseRemoteMatchOrg =>
  new RemoteMatchOrg(makeApiUrl('/orgs/match'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxMatchOrg =
  (): UsecaseReduxMatchOrg =>
    new ReduxMatchOrg(); */
