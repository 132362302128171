import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  width: 530px;
  border-radius: 16px;
`;

export const Header = styled.div`
  width: 100%;
  padding: 16px 24px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #dedede;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  gap: 16px;
  overflow-y: auto;
  max-height: 500px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
`;

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const TitleAndSubtitle = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const ContentNote = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Note = styled.div`
  display: flex;
  gap: 16px;
  height: 120px;
  border-radius: 6px;
  flex-direction: column;
`;
