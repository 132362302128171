import { iStore } from 'domain/interfaces/models';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import {
  Button,
  Input,
  KeyboardDate,
  Select,
} from 'presentation/components/UI';
import { translator } from 'presentation/components/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import { getAppointmentsByDate } from 'utils/getAppointmentByDate';
import { makeReduxListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import { subYears } from 'date-fns';
import { FILTERED_HOURS } from './constants';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  Options,
  Typography,
} from './styles/StyledFilterAppointment';
import Translator from '../i18n/Translator';

export interface iFilterAppointment {
  conference: {
    timestamp?: {
      begin?: string;
      end?: string;
    };
    search?: string;
    moderator?: string;
    participants?: string;
    ownedBy?: {
      orgId?: number;
      orgUnitId?: number;
      userId?: number;
    };
    recorded?: boolean;
    transcribed?: boolean;
  };
}

interface ownProps {
  user?: iStore['user']['records'];
  role?: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD';
  search?: string;
  setSearch?: Function;
  isClosed: boolean;
  setIsClosed: Function;
  filterAppointment: iFilterAppointment;
  setFilterAppointment: (data: iFilterAppointment) => void;
}

const FilterAppointment: React.FC<ownProps> = ({
  user,
  role,
  search,
  setSearch,
  isClosed,
  setIsClosed,
  filterAppointment,
  setFilterAppointment,
}) => {
  const { records: orgs } = useSelector((store: iStore) => store.org);
  const { records: orgUnits } = useSelector((store: iStore) => store.orgUnits);
  const { user: userRedux } = useSelector((store: iStore) => store.auth);

  const [created, setCreated] = useState(
    filterAppointment?.conference?.timestamp?.begin === undefined
      ? new Date()
      : new Date(filterAppointment?.conference?.timestamp?.begin),
  );
  const [endAt, setEndAt] = useState(
    filterAppointment?.conference?.timestamp?.end === undefined
      ? new Date()
      : new Date(filterAppointment?.conference?.timestamp?.end),
  );

  const [createdByMe, setCreatedByMe] = useState<boolean | undefined>();

  const [start, setStart] = useState<number>(1);
  const [end, setEnd] = useState<number>(1440);

  const filteredUnits = useMemo(
    () =>
      orgUnits?.filter(
        unit => unit?.org?.id === filterAppointment?.conference?.ownedBy?.orgId,
      ),
    [filterAppointment?.conference?.ownedBy?.orgId, orgUnits],
  );

  const handleFilter = useCallback(() => {
    const today = new Date();
    if (endAt.getDate() < today.getDate()) {
      endAt.setHours(23);
      endAt.setMinutes(59);
      endAt.setSeconds(59);
      endAt.setMilliseconds(999);
    }

    makeReduxListFilteredConference().list({
      conference: {
        timestamp: {
          begin: created.toISOString(),
          end: endAt.toISOString(),
        },
        search: filterAppointment?.conference?.search?.length
          ? filterAppointment?.conference?.search?.trim()
          : undefined,
        moderator: filterAppointment?.conference?.moderator?.length
          ? filterAppointment?.conference?.moderator?.trim()
          : undefined,
        participants: filterAppointment?.conference?.participants?.length
          ? filterAppointment?.conference?.participants?.trim()
          : undefined,
        ownedBy:
          filterAppointment?.conference?.ownedBy?.orgId ||
          filterAppointment?.conference?.ownedBy?.orgUnitId ||
          createdByMe
            ? {
                orgId:
                  filterAppointment?.conference?.ownedBy?.orgId ?? undefined,
                orgUnitId: filterAppointment?.conference?.ownedBy?.orgUnitId,
                userId: createdByMe ? userRedux?.id : undefined,
              }
            : undefined,
        recorded: filterAppointment?.conference?.recorded ?? undefined,
        transcribed: filterAppointment?.conference?.transcribed ?? undefined,
      },
      dataControl: {
        limit: 9999,
        paging: false,
      },
    });

    setFilterAppointment?.({
      ...filterAppointment,
      conference: {
        ...filterAppointment?.conference,
        timestamp: {
          begin: created.toISOString(),
          end: endAt.toISOString(),
        },
      },
    });

    setIsClosed(false);
  }, [
    created,
    endAt,
    filterAppointment,
    createdByMe,
    userRedux?.id,
    setFilterAppointment,
    setIsClosed,
  ]);

  const resetFilter = () => {
    setCreatedByMe(undefined);
    const now = new Date();
    const previous = subYears(new Date(), 5);

    setCreated(previous);
    setEndAt(now);

    setStart(1);
    setEnd(1440);

    setFilterAppointment?.({
      conference: {
        ownedBy: {
          orgId: undefined,
          orgUnitId: undefined,
          userId: undefined,
        },
        timestamp: {
          begin: new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate(),
          ).toISOString(),
          end: new Date().toISOString(),
        },
        search: undefined,
        moderator: undefined,
        participants: undefined,
        recorded: undefined,
        transcribed: undefined,
      },
    });

    makeReduxListFilteredConference().list({
      dataControl: {
        paging: false,
        limit: 9999,
      },
    });
  };

  console.log('isClosed: ', isClosed);

  return (
    <>
      <Container isClosed={isClosed}>
        <ContainerForm
          onSubmit={e => {
            e.preventDefault();
            handleFilter();
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '12px',
              padding: '24px',
              borderBottom: '1px solid #DEDEDE',
            }}
          >
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Geral
            </Typography>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Contém texto:
              </Typography>

              <Input
                id="input_title"
                placeholder="Título, descrição, transcrição..."
                className="hover-input"
                value={filterAppointment?.conference?.search ?? ''}
                onChange={e =>
                  setFilterAppointment?.({
                    ...filterAppointment,
                    conference: {
                      ...filterAppointment?.conference,
                      search: e.target.value,
                    },
                  })
                }
                width="295px"
              />
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Criadas por mim
              </Typography>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={createdByMe === true}
                    onChange={() => {
                      setCreatedByMe(true);
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={createdByMe === false}
                    onChange={() => setCreatedByMe(false)}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                {Translator('Reuniões gravadas')}
              </Typography>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={filterAppointment?.conference?.recorded === true}
                    onChange={() => {
                      setFilterAppointment?.({
                        ...filterAppointment,
                        conference: {
                          ...filterAppointment?.conference,
                          recorded: true,
                        },
                      });
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={filterAppointment?.conference?.recorded === false}
                    onChange={() => {
                      setFilterAppointment?.({
                        ...filterAppointment,
                        conference: {
                          ...filterAppointment?.conference,
                          recorded: false,
                        },
                      });
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                {Translator('Reuniões transcritas')}
              </Typography>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={
                      filterAppointment?.conference?.transcribed === true
                    }
                    onChange={() => {
                      setFilterAppointment?.({
                        ...filterAppointment,
                        conference: {
                          ...filterAppointment?.conference,
                          transcribed: true,
                        },
                      });
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={
                      filterAppointment?.conference?.transcribed === false
                    }
                    onChange={() =>
                      setFilterAppointment?.({
                        ...filterAppointment,
                        conference: {
                          ...filterAppointment?.conference,
                          transcribed: false,
                        },
                      })
                    }
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '12px',
              padding: '24px',
              borderBottom: '1px solid #DEDEDE',
            }}
          >
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Data e duração
            </Typography>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                A partir de
              </Typography>

              <div
                style={{
                  width: '140px',
                }}
              >
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={created}
                  setState={setCreated}
                  placeholder="Data"
                  width="140px"
                />
              </div>

              <Typography size="14px" color="#222529" line="16px">
                a
              </Typography>

              <div
                style={{
                  width: '140px',
                }}
              >
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={endAt}
                  setState={setEndAt}
                  placeholder={`${translator('Fim')}`}
                  width="140px"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Entre
              </Typography>

              <Select
                id="select_status"
                value={start}
                onChange={e => setStart(e.target.value as unknown as number)}
                placeholder={`${translator('Qualquer')}`}
                width="140px"
              >
                {FILTERED_HOURS.map(hour => (
                  <Options id={`state_${hour.value}`} value={hour.value}>
                    {hour.label}
                  </Options>
                ))}
              </Select>

              <Typography size="14px" color="#222529" line="16px">
                e
              </Typography>

              <Select
                id="select_status"
                value={end}
                onChange={e => setEnd(e.target.value as unknown as number)}
                placeholder={`${translator('Qualquer')}`}
                width="140px"
              >
                {FILTERED_HOURS.map(hour => {
                  if (start && hour.value <= start) return null;

                  return (
                    <Options id={`state_${hour.value}`} value={hour.value}>
                      {hour.label}
                    </Options>
                  );
                })}
              </Select>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '12px',
              padding: '24px',
              borderBottom: '1px solid #DEDEDE',
            }}
          >
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Administração
            </Typography>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Organização
              </Typography>

              <Select
                id="select_status"
                value={
                  filterAppointment?.conference?.ownedBy?.orgId ?? 'DEFAULT'
                }
                onChange={e =>
                  setFilterAppointment?.({
                    ...filterAppointment,
                    conference: {
                      ...filterAppointment?.conference,
                      ownedBy: {
                        ...filterAppointment?.conference?.ownedBy,
                        orgId: Number(e.target.value),
                      },
                    },
                  })
                }
                placeholder={`${translator('Qualquer')}`}
                width="307px"
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todas')}
                </Options>
                {orgs?.map(org => (
                  <Options id="state_1" value={org.id}>
                    {org.name}
                  </Options>
                ))}
              </Select>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Setor
              </Typography>

              <Select
                id="select_status"
                value={filterAppointment?.conference?.ownedBy?.orgUnitId}
                onChange={e =>
                  setFilterAppointment?.({
                    ...filterAppointment,
                    conference: {
                      ...filterAppointment?.conference,
                      ownedBy: {
                        ...filterAppointment?.conference?.ownedBy,
                        orgUnitId: Number(e.target.value),
                      },
                    },
                  })
                }
                placeholder={`${translator('Qualquer')}`}
                width="307px"
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todos')}
                </Options>
                {filteredUnits?.map(unit => (
                  <Options id="state_1" value={unit.id}>
                    {unit.name}
                  </Options>
                ))}
              </Select>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '12px',
              padding: '24px',
              borderBottom: '1px solid #DEDEDE',
            }}
          >
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Usuários
            </Typography>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Participante:
              </Typography>

              <Input
                id="input_professional"
                placeholder="Pesquise pelo nome, e-mail ou CPF..."
                className="hover-input"
                value={filterAppointment?.conference?.participants ?? ''}
                onChange={e =>
                  setFilterAppointment?.({
                    ...filterAppointment,
                    conference: {
                      ...filterAppointment?.conference,
                      participants: e.target.value,
                    },
                  })
                }
                width="302px"
              />
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
            >
              <Typography size="14px" color="#222529" line="16px">
                Moderador:
              </Typography>

              <Input
                id="input_professional"
                placeholder="Pesquise pelo nome, e-mail ou CPF..."
                className="hover-input"
                value={filterAppointment?.conference?.moderator ?? ''}
                onChange={e =>
                  setFilterAppointment?.({
                    ...filterAppointment,
                    conference: {
                      ...filterAppointment?.conference,
                      moderator: e.target.value,
                    },
                  })
                }
                width="302px"
              />
            </div>
          </div>
        </ContainerForm>

        <ContainerButtons>
          <Button
            rounded
            size="fit-content"
            variant="secundary"
            onClick={() => resetFilter()}
          >
            Restaurar padrões
          </Button>
          <Button
            id="btn_search"
            rounded
            variant="primary"
            size="fit-content"
            onClick={() => handleFilter()}
            autoFocus
          >
            Aplicar filtros
          </Button>
        </ContainerButtons>
      </Container>
    </>
  );
};

export default FilterAppointment;
