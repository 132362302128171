import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { ListAdmin } from 'domain/usecases/admin/remote';
import { makeRemoteDeleteByOrgUnitAdmin } from 'main/factories/usecases/admin/DeleteByOrgUnitAdminFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { ActionsList } from 'presentation/components/actionsList';
import { translator } from 'presentation/components/i18n';
import Translator from 'presentation/components/i18n/Translator';
import { closeModal } from 'utils/closeModal';
import { formattedDate } from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUnit } from 'utils/updateListUsers';
import { iColumnsConfig } from './interface';

interface ownProps {
  dataList: ListAdmin.Model;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

type iColumns = 'name' | 'login' | 'lastEntry' | 'actions';

const SectorOrganizers: React.FC<ownProps> = ({ dataList }) => {
  const { user } = useSelector((store: iStore) => store.auth);

  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'name',
      label: Translator('Nome'),
      minWidth: 250,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'login',
      label: Translator('Login'),
      minWidth: 250,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'lastEntry',
      label: Translator('Último acesso'),
      minWidth: 250,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 50,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const RenderComponents: React.FC<{
    id: iColumns;
    orgUnit: ListAdmin.Model['records'][0];
  }> = ({ id, orgUnit }) => {
    switch (id) {
      case 'name':
        return <div>{orgUnit.user.fullName}</div>;
      case 'login':
        return <div>{orgUnit.user.login}</div>;
      case 'lastEntry':
        return (
          <div>
            {orgUnit?.lastAccess
              ? formattedDate(new Date(orgUnit?.lastAccess))
              : '--'}
          </div>
        );
      case 'actions':
        return (
          <ActionsList
            actions={{
              deleteOrgUnitUser: () => {
                const orgId = orgUnit?.orgUnit?.org?.id ?? orgUnit?.org?.id;

                if (orgId && orgUnit?.orgUnit?.id)
                  makeReduxActiveMessage().active({
                    active: 'confirm',
                    title: Translator('Desvinculo de usuário do setor'),
                    content: translator(
                      'Deseja realmente desvincular esse usuário do setor?',
                    ),
                    actionOk: () => {
                      makeRemoteDeleteByOrgUnitAdmin()
                        .deleteByOrgUnit({
                          orgId: Number(orgId),
                          unitId: Number(orgUnit?.orgUnit?.id),
                          userId: orgUnit?.user?.id,
                        })
                        .then(res => {
                          toast.success(
                            Translator(
                              'Usuário desassociado do setor com sucesso!',
                            ),
                          );

                          closeModal();

                          if (!user?.administrator) return;

                          const role = getRole(
                            user.administrator,
                            user.org.id,
                            user.orgUnit.id,
                          );

                          // Refetch orgUnits after delete user
                          UpdateListOrgUnit();
                        })
                        .catch(e => {
                          makeReduxActiveMessage().active({
                            active: 'error',
                            title: Translator('Desvinculo de usuário do setor'),
                            content: Translator(
                              'Não foi possível desvincular o usuário do setor.',
                            ),
                            actionOk: () => closeModal(),
                            actionCancel: () => closeModal(),
                          });
                        });
                    },
                    actionCancel: () => closeModal(),
                  });
              },
            }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columns.map(item => {
                return (
                  <TableCell
                    align={item.alignTitle}
                    style={{ padding: '13px 16px' }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {item.label}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList?.records?.map((item: ListAdmin.Model['records'][0]) => (
              <TableRow>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        fontSize: columnProps.fontSize,
                      }}
                    >
                      <RenderComponents id={columnProps.id} orgUnit={item} />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SectorOrganizers;
