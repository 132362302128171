import styled from 'styled-components';
import { IconBlockChain } from 'presentation/base/icons';
import DownloadIcon from '@mui/icons-material/Download';
import { withAccess } from 'presentation/hooks/access';

export const Container = styled.div`
  width: 100%;
`;

interface TextProps {
  bold?: boolean;
  lighter?: boolean;
  color?: string;
}

export const HeaderText = styled.p<TextProps>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ color }) => color ?? '#000000'};
  text-align: left;
`;

export const HeaderTitle = styled.p`
  font-size: 36px;
  font-weight: 700;
  color: #000000;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const HeaderSubtitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #000000;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

export const Records = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const SpacedRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface StatusProps {
  status: 'scheduled' | 'in progress' | 'not recorded' | 'finished';
}

export const Status = styled.div<StatusProps>`
  min-width: ${({ status }) => (status === 'in progress' ? 'none' : '104px')};
  height: 30px;
  padding: 6px 12px;
  border-radius: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  color: #ffffff;

  background: ${({ status, theme }) => {
    switch (status) {
      case 'scheduled':
        return '#45B4F2';
      case 'not recorded':
        return `${theme.colors.primary.main}`;
      case 'in progress':
        return '#45F275';
      case 'finished':
        return '#FF5B5B';
      default:
        return '#45B4F2';
    }
  }};
`;

export const CenteredDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #c4c4c4;
`;

export const ContentDivider = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0px;
  padding-left: 20px;
`;

export const DownloadText = styled.p<TextProps>`
  font-size: 18px;
  font-weight: ${({ lighter }) => (lighter ? 400 : 300)};
  color: ${({ lighter }) => (lighter ? '#c4c4c4' : '#000000')};
`;

export const RecordingsContainer = styled.div`
  display: flex;
  height: 8rem;
  flex-direction: column;
  margin-top: 16px;
  overflow: auto;
  gap: 8px;
`;

export const RecordingsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px 0 0;
`;

export const RecordingsText = styled(HeaderText)`
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RecordingsDownloadIcon = withAccess(styled(DownloadIcon)`
  cursor: pointer;
`);

export const MoreOptionsContainer = withAccess(styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    border-color: #bfc4ca;
  }
`);

export const Drop = withAccess(styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 15px;
  color: black;
  background: white;
  cursor: pointer;
  border: none;

  span {
    margin-left: 15px;
  }

  :hover {
    background: rgba(226, 6, 19, 0.1);
  }
`);

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  // max-width: 389px;
  max-height: 120px;
  padding: 20px;
  border-radius: 3px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 11px;
`;

export const User = styled.div`
  display: flex;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 6px;

  .icon {
    border: 1px solid red;
    border-radius: 50%;
  }

  .img {
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0px;
`;

export const BlockChainIcon = withAccess(styled(IconBlockChain)`
  cursor: pointer;
`);

export const RegistryItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RegistryText = styled.p`
  color: #000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const LoadingCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
