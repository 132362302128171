import styled from 'styled-components';

interface iContainer {
  width?: string;
  height?: string;
  color?: string;
  isCircular?: boolean;
}

export const Container = styled.div<iContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.onClick && 'pointer'};

  img {
    display: flex;
    width: ${props => props.width || '26px'};
    height: ${props => props.height || '26px'};
    border: ${props =>
      props.isCircular
        ? `1px solid ${props.color ?? 'rgba(166, 166, 166, 0.5)'}`
        : 'none'};
    border-radius: ${props => (props.isCircular ? '50%' : 'none')};
    object-fit: contain;
  }

  svg {
    width: ${props => props.width || '26px'};
    height: ${props => props.height || '26px'};
  }

  path {
    fill: ${props => props.color};
  }

  rect {
    stroke: ${props => props.color};
  }

  circle {
    fill: ${props => props.color};
  }

  line {
    fill: ${props => props.color};
    stroke: ${props => props.color};
  }

  polygon {
    fill: ${props => props.color};
  }
`;
