import React from 'react';
import { Modal as ModalMaterial } from '@mui/material';

import { CloseButton } from 'presentation/base/icons';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { withAccess } from 'presentation/hooks/access';
import { Close, Container, Header } from './styles/StyledModal';

interface ownProps {
  children: any;
  open?: boolean;
  title?: string;
  handleClose?: () => void;
}

const Modal: React.FC<ownProps> = ({
  children,
  open,
  title,
  handleClose,
}): JSX.Element => {
  const { visible } = useSelector((store: iStore) => store.modal);

  return (
    <Container>
      <ModalMaterial open={visible} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Header>
            {title}
            <Close onClick={handleClose}>
              <CloseButton />
            </Close>
          </Header>
          {children}
        </div>
      </ModalMaterial>
    </Container>
  );
};

export default withAccess(Modal);
