import { iOrgUser } from 'domain/interfaces/models/OrgUser';
import { OrgUserTypes } from 'domain/interfaces/redux/orgUser/types';
import { OrgUserActions } from './actions';

export const initialState: iOrgUser = {
  loading: false,
  pageSize: undefined,
  totalItems: undefined,
  records: [],
};

const reducer = (state = initialState, action: OrgUserActions): iOrgUser => {
  switch (action.type) {
    case OrgUserTypes.LIST:
      return { ...state, loading: true };
    case OrgUserTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OrgUserTypes.LIST_SUCCESS: {
      return {
        ...state,
        pageSize: action.payload.metadata.limit,
        totalItems: action.payload.metadata.total,
        records: action.payload.records,
      };
    }

    case OrgUserTypes.LIST_BY_ORGID:
      return { ...state, loading: true };
    case OrgUserTypes.LIST_BY_ORGID_FAILED:
      return { ...state, loading: false };
    case OrgUserTypes.LIST_BY_ORGID_SUCCESS: {
      return {
        ...state,
        pageSize: action.payload.metadata.limit,
        totalItems: action.payload.metadata.total,
        records: action.payload.records,
      };
    }

    case OrgUserTypes.LIST_BY_ORGUNITID:
      return { ...state, loading: true };
    case OrgUserTypes.LIST_BY_ORGUNITID_FAILED:
      return { ...state, loading: false };
    case OrgUserTypes.LIST_BY_ORGUNITID_SUCCESS: {
      return {
        ...state,
        pageSize: action.payload.metadata.limit,
        totalItems: action.payload.metadata.total,
        records: action.payload.records,
      };
    }
    default:
      return state;
  }
};

export default reducer;
