import { ListOrgAclFiltered } from 'domain/usecases/org/redux/ListOrgAclFiltered';
import {
  iActionListAclFiltered,
  iActionListAclFilteredFailed,
  iActionListAclFilteredSuccess,
  OrgTypes,
} from 'domain/interfaces/redux/org/listAclFiltered';

export const listAclFilteredRequest = (
  payload: ListOrgAclFiltered.Params,
): iActionListAclFiltered => ({
  type: OrgTypes.LIST_ACL_FILTERED,
  payload,
});

export const listAclFilteredSuccess = (
  params: ListOrgAclFiltered.Model,
): iActionListAclFilteredSuccess => ({
  type: OrgTypes.LIST_ACL_FILTERED_SUCCESS,
  payload: params,
});

export const listAclFilteredFailed = (): iActionListAclFilteredFailed => ({
  type: OrgTypes.LIST_ACL_FILTERED_FAILED,
});
