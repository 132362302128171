import { call, put, select } from 'redux-saga/effects';
import { iActionListGroups } from 'domain/interfaces/redux/audit/listGroups';
import { ListGroupsAudit } from 'domain/usecases/audit/remote/ListGroupsAudit';
import { makeRemoteListGroupsAudit } from 'main/factories/usecases/audit/ListGroupsAuditFactory';
import {
  listGroupsSuccess,
  listGroupsFailed,
} from 'data/store/reducer/audit/actions/listGroups';

export function* onListGroups(action: iActionListGroups) {
  const remoteListGroupsAudit = makeRemoteListGroupsAudit();

  try {
    const response: ListGroupsAudit.Model = yield call(
      remoteListGroupsAudit.listGroups,
      action.payload,
    );
    yield put(
      listGroupsSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listGroupsFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListGroupsSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListGroupsFailed() {}
