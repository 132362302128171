import { Dispatch } from 'redux';
import store from 'data/store';
import { setupRequest } from 'data/store/reducer/conferenceWaitingRoom/actions/setup';

import { iActionSetup } from 'domain/interfaces/redux/conferenceWaitingRoom/setup';

import { SetupConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

export class ReduxSetupConferenceWaitingRoom
  implements SetupConferenceWaitingRoom
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setup = (params: SetupConferenceWaitingRoom.Params): iActionSetup =>
    this.send(setupRequest(params));
}
