import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DownloadRegistry as UsecaseRemoteDownloadRegistry } from 'domain/usecases/registry/remote';

import { RemoteDownloadRegistry } from 'data/repository/registry';

/**
 * send request via API.
 */
export const makeRemoteDownloadRegistry = (): UsecaseRemoteDownloadRegistry =>
  new RemoteDownloadRegistry(
    makeApiUrl('/conferences/{conferenceId}/registry/{dltId}/download'),
    makeHttpClient(),
  );
