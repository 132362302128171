import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  MessageOptions,
  iMessageOptions,
} from 'domain/interfaces/redux/message';
import ModalsTeste from 'presentation/pages/Modals';

interface iStateParams {
  id?: string;
}

export const MakeModalTestFactory: React.FC = () => {
  const [active, setActive] = useState<iMessageOptions>('none');

  const params = useParams<iStateParams>();

  useEffect(() => {
    if (params?.id) {
      const id = Number(params?.id);

      setActive(Object.values(MessageOptions)[id]);
    }
  }, [params?.id]);

  return <ModalsTeste active={active} />;
};
