import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';

import {
  Available,
  Canceled,
  Container,
  Finished,
  Progress,
  Scheduled,
  Confirmed,
} from './styles/StyledSchedule';

interface ScheduleProps {
  status?: 'ENDED' | 'CANCELED' | 'INPROGRESS' | 'SCHEDULED';
}

const ScheduleStatus: React.FC<ScheduleProps> = ({ status }) => {
  function renderStatus() {
    switch (status) {
      case 'ENDED':
        return <Finished>Finalizado</Finished>;
      case 'CANCELED':
        return <Canceled>Cancelado</Canceled>;
      case 'INPROGRESS':
        return <Progress>Andamento</Progress>;
      case 'SCHEDULED':
        return <Scheduled>Agendado</Scheduled>;
      default:
        return <Skeleton animation="wave" variant="text" width="100px" />;
    }
  }
  return <Container>{renderStatus()}</Container>;
};
export default ScheduleStatus;
