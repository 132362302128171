/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Translator from 'presentation/components/i18n/Translator';
import React, { useMemo, useState } from 'react';

import { TableSortLabel } from '@material-ui/core';
import { iStore } from 'domain/interfaces/models';
import { OrgInfos } from 'domain/usecases/org/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteDeleteOrg } from 'main/factories/usecases/org/DeleteOrgFactory';
import { History } from 'main/routes';
import { ActionsList } from 'presentation/components/actionsList';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { closeModal } from 'utils/closeModal';
import { getRole } from 'utils/getRole';
import { OrgUnitInfo } from 'domain/interfaces/models/OrgUnit';
import { makeRemoteGetFilteredOrgs } from 'main/factories/usecases/org/GetFilteredOrgs';
import { IconSort } from 'presentation/base/icons';
import { iColumnsConfig, iListOrgs } from './interface';

type iOrder =
  | 'participant'
  | 'professional'
  | 'specialty'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'status';

interface ownProps {
  dataList: iListOrgs;
  haveUpdated?: () => void;
  setAllOrderBy: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

type iColumns =
  | 'name'
  | 'logo'
  | 'organizer'
  | 'e-mail'
  | 'sectors'
  | 'actions';

interface IOrderBy {
  order: number;
  attribute: string;
  direction: string;
  active?: boolean;
}

const Org: React.FC<ownProps> = ({ dataList, haveUpdated, setAllOrderBy }) => {
  const url = window.location.pathname;
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'name',
      label: `${Translator('Nome')}`,
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'logo',
      label: `${Translator('Sigla')}`,
      minWidth: 50,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'organizer',
      label: Translator('Organizador'),
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    // {
    //   id: 'e-mail',
    //   label: `${Translator('E-mail')}`,
    //   minWidth: 100,
    //   align: 'left',
    //   alignTitle: 'left',
    // },
    {
      id: 'sectors',
      label: `${Translator('Setores')}`,
      minWidth: 100,
      align: 'center',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: `${Translator('Ações')}`,
      minWidth: 20,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const { auth } = useSelector((store: iStore) => store);

  const orderByFunction = async (type: string) => {
    setAllOrderBy(type);
  };

  const role = useMemo(
    () =>
      auth.user?.administrator
        ? getRole(
            auth.user.administrator,
            auth.user.org.id,
            auth.user.orgUnit.id,
          )
        : 'STANDARD',
    [auth.user?.administrator, auth.user?.org.id, auth.user?.orgUnit.id],
  );

  const RenderComponents: React.FC<{
    id: iColumns;
    org: OrgInfos;
    orgUnitId?: number;
  }> = ({ id, org, orgUnitId }) => {
    switch (id) {
      case 'name':
        return <div>{org.name}</div>;
      case 'logo':
        return <b>{org.shortname}</b>;
      case 'organizer':
        return <div>{org.name}</div>;
      case 'e-mail':
        return <div>{org.contactEmail}</div>;
      case 'sectors':
        return <div>{org.orgUnitsCount}</div>;
      case 'actions':
        return (
          <ActionsList
            enablers={{
              isViewDetailsOrg: role === 'STANDARD',
            }}
            actions={{
              detailsOrg: org.permission.read
                ? () => {
                    History.getHistory().push(`/admin/orgs/${org?.id}`, {
                      from: url.replaceAll('/', ''),
                    });
                  }
                : undefined,
              deleteOrg: org.permission.delete
                ? () =>
                    makeReduxActiveMessage().active({
                      active: 'confirm',
                      content: `${Translator(
                        'Deseja realmente excluir a organização',
                      )}`,
                      title: `${Translator('Remoção de Organização')}`,
                      actionOk: () => {
                        makeRemoteDeleteOrg()
                          .delete({
                            id: org?.id,
                          })
                          .then(() => {
                            toast.success(
                              `${Translator(
                                'Organização removida com sucesso',
                              )}`,
                            );

                            haveUpdated?.();
                            closeModal();
                          })
                          .catch(() => {
                            makeReduxActiveMessage().active({
                              active: 'error',
                              title: `${Translator('Remoção de Organização')}`,
                              content: `${Translator(
                                'Não é possível deletar organização com usuários ativos',
                              )}`,
                              actionOk: () => closeModal(),
                              actionCancel: () => closeModal(),
                            });
                          });
                      },
                      actionCancel: () => closeModal(),
                    })
                : undefined,
            }}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  {item.id === 'name' || item.id === 'logo' ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div
                          style={{ paddingLeft: 3 }}
                          onClick={() => orderByFunction(item.id)}
                        >
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div>{item.label}</div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.records?.map((item: any) => (
            <TableRow>
              {columns.map((columnProps: any) => {
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} org={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Org;
