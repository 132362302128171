import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f8f8f8;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;
