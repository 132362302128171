import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUnitAclFilteredV2 } from 'domain/interfaces/redux/orgUnit/listAclFilteredV2';
import { ListOrgUnitAclFilteredV2 } from 'domain/usecases/orgUnit/remote/ListOrgUnitAclFilteredV2';
import { makeRemoteListOrgUnitAclFilteredV2 } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';
import {
  listAclFilteredV2Success,
  listAclFilteredV2Failed,
} from 'data/store/reducer/orgUnits/actions/listAclFilteredV2';

export function* onListAclFilteredV2(action: iActionListOrgUnitAclFilteredV2) {
  const remoteListOrgUnitAclFilteredV2 = makeRemoteListOrgUnitAclFilteredV2();

  try {
    const response: ListOrgUnitAclFilteredV2.Model = yield call(
      remoteListOrgUnitAclFilteredV2.list,
      action.payload,
    );

    yield put(
      listAclFilteredV2Success({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listAclFilteredV2Failed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredV2Success() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredV2Failed() {}
