import { Dispatch } from 'redux';
import store from 'data/store';
import { listLogsRequest } from 'data/store/reducer/audit/actions/listLogs';

import { iActionListLogs } from 'domain/interfaces/redux/audit/listLogs';

import { ListLogsAudit } from 'domain/usecases/audit/redux';

export class ReduxListLogsAudit implements ListLogsAudit {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listLogs = (params: ListLogsAudit.Params): iActionListLogs =>
    this.send(listLogsRequest(params));
}
