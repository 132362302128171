import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUnitByOrgId } from 'domain/interfaces/redux/orgUnit/listByOrgId';
import { ListByOrgIdOrgUnit } from 'domain/usecases/orgUnit/remote/ListByOrgIdOrgUnit';
import { makeRemoteListByOrgIdOrgUnit } from 'main/factories/usecases/orgUnit/ListByOrgIdOrgUnitFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUnits/actions/listByOrgId';

export function* onListByOrgId(action: iActionListOrgUnitByOrgId) {
  const remoteListByOrgIdOrgUnit = makeRemoteListByOrgIdOrgUnit();

  try {
    const response: ListByOrgIdOrgUnit.Model = yield call(
      remoteListByOrgIdOrgUnit.listByOrgId,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByOrgIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByOrgIdFailed() {}
