import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  max-width: 1920px;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 60px 35px;
  }
`;

export const Left = styled.div`
  height: 100%;

  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
  }
`;

export const Right = styled.div`
  height: 100%;
  /* min-height: calc(100vh - 213px); */
  width: 100%;

  .title {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 21px;
  }

  .summary {
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .graph {
    width: 100%;
  }

  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .title {
      font-size: 18px;
      margin: 0px;
    }

    .buttons {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    .label {
      margin: 0px 10px 0px 22px;
    }
  }

  @media (max-width: 1024px) {
    box-shadow: none;
    margin: 0;
    padding: 31px 0 0 0;
    width: 100%;
  }
`;
