/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import { iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import { makeReduxListLogsAudit } from 'main/factories/usecases/audit/ListLogsAuditFactory';
import { ListLogsAudit } from 'domain/usecases/audit/remote';
import { makeReduxListActionsAudit } from 'main/factories/usecases/audit/ListActionsAuditFactory';
import { format, subYears } from 'date-fns';
import { getRole } from 'utils/getRole';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  Form,
  Fields,
  CheckboxField,
  Message,
  KeyboardContent,
  Options,
  Typography,
} from './styles/StyledFilterAudit';
import { Button, Input, KeyboardDate, Select } from '../UI';
import { translator } from '../i18n';
import { ChildComponentRef } from '../header/BackButton';

interface iFilterAuditProps {
  isClosed: boolean;
  setIsClosed: (closed: boolean) => void;
  filterAuditt?: ListLogsAudit.Params['body'];
  setFilterAuditt?: (data: ListLogsAudit.Params['body']) => void;
}

const AuditFilter: React.ForwardRefRenderFunction<any, iFilterAuditProps> = (
  { isClosed, setIsClosed, filterAuditt, setFilterAuditt },
  ref,
) => {
  const { records: orgs } = useSelector((store: iStore) => store.org);
  const { records: orgUnits } = useSelector((store: iStore) => store.orgUnits);
  const { records: users } = useSelector((store: iStore) => store.orgUsers);
  const { groups, actions } = useSelector((store: iStore) => store.audit);
  const { user } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const [begin, setBegin] = useState(
    filterAuditt?.log?.timestamp?.begin === undefined
      ? new Date()
      : new Date(filterAuditt?.log?.timestamp?.begin),
  );
  const [end, setEnd] = useState(
    filterAuditt?.log?.timestamp?.end === undefined
      ? new Date()
      : new Date(filterAuditt?.log?.timestamp?.end),
  );

  useImperativeHandle(ref, () => ({
    getState: () => {
      return filterAuditt;
    },
  }));

  const handleFilter = useCallback(() => {
    const filterBegin = format(begin, 'yyyy-MM-dd');
    const filterEnd = format(end, 'yyyy-MM-dd');

    const filterBeginTime = filterAuditt?.log?.timestamp?.begin
      ? format(
          new Date(filterAuditt?.log?.timestamp?.begin.replace(' ', 'T')),
          'HH:mm:ss',
        )
      : '00:00:00';

    const filterEndTime = filterAuditt?.log?.timestamp?.end
      ? format(
          new Date(filterAuditt?.log?.timestamp?.end.replace(' ', 'T')),
          'HH:mm:ss',
        )
      : '23:59:59';

    const defaultOrgFilter = role === 'ORG_ADMIN' ? user?.org.id : undefined;
    const defaultUnitFilter =
      role === 'UNIT_ADMIN' ? user?.orgUnit.id : undefined;

    makeReduxListLogsAudit().listLogs({
      body: {
        log: {
          timestamp: {
            begin: `${filterBegin}T${filterBeginTime}`,
            end: `${filterEnd}T${filterEndTime}`,
          },
        },
        user: {
          org: filterAuditt?.user?.org ?? defaultOrgFilter,
          orgUnit: filterAuditt?.user?.orgUnit ?? defaultUnitFilter,
          id: filterAuditt?.user?.id,
        },
        action: {
          changeData: filterAuditt?.action?.changeData,
          groupShort:
            filterAuditt?.action?.groupShort !== 'DEFAULT'
              ? filterAuditt?.action?.groupShort
              : undefined,
          name:
            filterAuditt?.action?.name !== 'DEFAULT'
              ? filterAuditt?.action?.name
              : undefined,
        },
        dataControl: {
          limit: 15,
          paging: true,
        },
      },
    });

    setFilterAuditt?.({
      ...filterAuditt,
      log: {
        ...filterAuditt?.log,
        timestamp: {
          begin: filterAuditt?.log?.timestamp?.begin,
          end: filterAuditt?.log?.timestamp?.end,
        },
      },
    });

    setIsClosed(false);
  }, [filterAuditt, begin, end, setFilterAuditt, setIsClosed]);

  const resetFilter = () => {
    const now = new Date();
    const previous = subYears(new Date(), 5);

    setFilterAuditt?.({
      log: {
        timestamp: {
          begin: format(previous, 'yyyy-MM-dd HH:mm:ss'),
          end: format(now, 'yyyy-MM-dd HH:mm:ss'),
        },
      },
      user: {
        org: undefined,
        orgUnit: undefined,
        id: undefined,
      },
      action: {
        changeData: true,
        groupShort: undefined,
      },
    });

    setBegin(previous);
    setEnd(now);

    makeReduxListLogsAudit().listLogs({
      body: {
        log: {
          timestamp: {
            begin: format(previous, 'yyyy-MM-dd HH:mm:ss'),
            end: format(now, 'yyyy-MM-dd HH:mm:ss'),
          },
        },
        user:
          role === 'SYSADMIN'
            ? undefined
            : {
                org: role === 'ORG_ADMIN' ? user?.org.id : undefined,
                orgUnit: role === 'UNIT_ADMIN' ? user?.orgUnit.id : undefined,
              },
        action: {
          changeData: true,
        },
        dataControl: {
          limit: 15,
        },
      },
    });
  };

  const filteredUnits = useMemo(
    () => orgUnits?.filter(unit => unit?.org?.id === filterAuditt?.user?.org),
    [orgUnits, filterAuditt?.user?.org],
  );

  const filteredOrgUsers = useMemo(
    () =>
      users?.filter(
        orgUser =>
          orgUser?.orgUnit?.org?.id === filterAuditt?.user?.org &&
          orgUser?.orgUnit?.id === filterAuditt?.user?.orgUnit,
      ),
    [users, filterAuditt?.user?.org, filterAuditt?.user?.orgUnit],
  );

  useEffect(() => {
    makeReduxListActionsAudit().listActions({
      body: {
        dataControl: {
          limit: 9999,
          paging: false,
        },
      },
    });
  }, []);

  return (
    <>
      <Container isOpen={isClosed}>
        <ContainerForm
          onSubmit={e => {
            e.preventDefault();
            handleFilter();
          }}
        >
          <Form>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Data e hora da ocorrência do evento
            </Typography>
            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                A partir de
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={begin}
                  setState={setBegin}
                  placeholder="Data"
                  width="140px"
                />
              </KeyboardContent>

              <KeyboardContent>
                <Input
                  mask="99:99"
                  value={filterAuditt?.log?.timestamp?.begin?.slice(11, 16)}
                  onChange={e => {
                    setFilterAuditt?.({
                      ...filterAuditt,
                      log: {
                        ...filterAuditt?.log,
                        timestamp: {
                          ...filterAuditt?.log?.timestamp,
                          begin: `${begin.toISOString().split('T')[0]}T${
                            e.target.value
                          }:00.000Z`,
                        },
                      },
                    });
                  }}
                />
              </KeyboardContent>
            </Fields>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Até
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={end}
                  setState={setEnd}
                  placeholder="Data"
                  width="140px"
                />
              </KeyboardContent>

              <KeyboardContent>
                <Input
                  mask="99:99"
                  value={filterAuditt?.log?.timestamp?.end?.slice(11, 16)}
                  onChange={e => {
                    setFilterAuditt?.({
                      ...filterAuditt,
                      log: {
                        ...filterAuditt?.log,
                        timestamp: {
                          ...filterAuditt?.log?.timestamp,
                          end: `${end.toISOString().split('T')[0]}T${
                            e.target.value
                          }:00.000Z`,
                        },
                      },
                    });
                  }}
                />
              </KeyboardContent>
            </Fields>
          </Form>

          <Form>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Seleção do tipo de evento
            </Typography>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Grupo:
              </Typography>

              <Select
                id="select_status"
                placeholder={`${translator('Qualquer')}`}
                width="307px"
                value={filterAuditt?.action?.groupShort}
                onChange={e =>
                  setFilterAuditt?.({
                    ...filterAuditt,
                    action: {
                      ...filterAuditt?.action,
                      groupShort: e.target.value,
                    },
                  })
                }
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todos')}
                </Options>
                {groups?.records?.map(group => (
                  <Options
                    key={group?.id}
                    id={`state_${group?.id}`}
                    value={group?.short}
                  >
                    {group?.short}
                  </Options>
                ))}
              </Select>
            </Fields>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Ação:
              </Typography>

              <Select
                id="select_status"
                placeholder={`${translator('Qualquer')}`}
                width="307px"
                value={filterAuditt?.action?.name}
                onChange={e =>
                  setFilterAuditt?.({
                    ...filterAuditt,
                    action: {
                      ...filterAuditt?.action,
                      name: e.target.value,
                    },
                  })
                }
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todos')}
                </Options>

                {actions?.records?.map(action => {
                  if (
                    filterAuditt?.action?.groupShort &&
                    filterAuditt?.action?.groupShort !== 'DEFAULT' &&
                    filterAuditt?.action?.groupShort !== action.groupName
                  )
                    return null;

                  return (
                    <Options
                      key={action?.id}
                      id={`state_${action?.id}`}
                      value={action?.short}
                    >
                      {action?.descr}
                    </Options>
                  );
                })}
              </Select>
            </Fields>

            <CheckboxField>
              <Message>
                <input
                  type="checkbox"
                  checked={filterAuditt?.action?.changeData}
                  onChange={e => {
                    setFilterAuditt?.({
                      ...filterAuditt,
                      action: {
                        ...filterAuditt?.action,
                        changeData: e.target.checked,
                      },
                    });
                  }}
                />
                <Typography size="14px" color="#222529" line="16px">
                  Apenas eventos que modificaram dados
                </Typography>
              </Message>
            </CheckboxField>
          </Form>

          <Form>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Seleção de usuários
            </Typography>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Organização:
              </Typography>

              <Select
                id="select_status"
                placeholder={`${translator('Qualquer')}`}
                width="307px"
                value={filterAuditt?.user?.org}
                onChange={e => {
                  setFilterAuditt?.({
                    ...filterAuditt,
                    user: {
                      ...filterAuditt?.user,
                      org: Number(e.target.value),
                    },
                  });
                }}
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todas')}
                </Options>
                {orgs?.map(org => (
                  <Options key={org.id} id={`state_${org.id}`} value={org.id}>
                    {org?.name}
                  </Options>
                ))}
              </Select>
            </Fields>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Setor:
              </Typography>

              <Select
                id="select_status"
                placeholder={`${translator('Qualquer')}`}
                width="307px"
                value={filterAuditt?.user?.orgUnit}
                onChange={e => {
                  setFilterAuditt?.({
                    ...filterAuditt,
                    user: {
                      ...filterAuditt?.user,
                      orgUnit: Number(e.target.value),
                    },
                  });
                }}
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todos')}
                </Options>
                {filteredUnits?.map(unit => (
                  <Options
                    key={unit.id}
                    id={`state_${unit.id}`}
                    value={unit.id}
                  >
                    {unit?.name}
                  </Options>
                ))}
              </Select>
            </Fields>

            <Fields>
              <Typography size="14px" color="#222529" line="16px">
                Usuário:
              </Typography>

              <Select
                id="select_status"
                placeholder={`${translator('Qualquer')}`}
                width="307px"
                value={filterAuditt?.user?.id}
                onChange={e => {
                  setFilterAuditt?.({
                    ...filterAuditt,
                    user: {
                      ...filterAuditt?.user,
                      id: Number(e.target.value),
                    },
                  });
                }}
              >
                <Options id="state_0" value="DEFAULT">
                  {translator('Todos')}
                </Options>
                {filteredOrgUsers?.map(orgUser => (
                  <Options
                    key={orgUser.user?.id}
                    id={`state_${orgUser.user?.id}`}
                    value={orgUser.user?.id}
                  >
                    {orgUser.user?.firstName} {orgUser.user?.lastName}
                  </Options>
                ))}
              </Select>
            </Fields>
          </Form>
        </ContainerForm>

        <ContainerButtons>
          <Button
            rounded
            size="fit-content"
            variant="secundary"
            onClick={() => resetFilter()}
          >
            Restaurar padrões
          </Button>
          <Button
            id="btn_search"
            rounded
            variant="primary"
            size="fit-content"
            onClick={() => handleFilter()}
            autoFocus
          >
            Aplicar filtros
          </Button>
        </ContainerButtons>
      </Container>
    </>
  );
};

export default forwardRef<ChildComponentRef, iFilterAuditProps>(AuditFilter);
