import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgUnit as UsecaseRemoteListOrgUnit } from 'domain/usecases/orgUnit/remote';
import { ListOrgUnit as UsecaseReduxListOrgUnit } from 'domain/usecases/orgUnit/redux';

import { RemoteListOrgUnit } from 'data/repository/orgUnit';
import { ReduxListOrgUnit } from 'data/store/reducer/orgUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgUnit = (): UsecaseRemoteListOrgUnit =>
  new RemoteListOrgUnit(makeApiUrl('/orgs/units/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUnit = (): UsecaseReduxListOrgUnit =>
  new ReduxListOrgUnit();
