/* eslint-disable @typescript-eslint/no-empty-function */
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from 'App';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteCreatePasswordRecovery } from 'main/factories/usecases/password/CreatePasswordRecoveryFactory';
import { makeRemoteUpdatePasswordValidate } from 'main/factories/usecases/password/UpdatePasswordValidateFactory';
import { makeRemoteUserConfirmationPassword } from 'main/factories/usecases/user/UserConfirmation';

import { IconLogoV4H, IconLoginV4H } from 'presentation/base/icons';
import { Button, Input } from 'presentation/components/UI';
import { closeModal } from 'utils/closeModal';
import { passwordResetSchema } from 'validation/profile/ResetPasswordValidation';
import Translator from 'presentation/components/i18n/Translator';

import {
  Container,
  Left,
  Right,
  Content,
  Form,
  Title,
  ButtonsContainer,
  ImageContainer,
} from './styles/StyledRecoveryPassword';

const RecoveryPassword: React.FC = () => {
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const { theme } = useContext(ThemeContext);
  const query = new URLSearchParams(useLocation().search);
  const tokenResult = query.get('token');

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const { push } = useHistory();

  const handleValidatePassword = () => {
    if (tokenResult && password) {
      if (window.location.pathname.includes('pwd/reset')) {
        makeRemoteUpdatePasswordValidate()
          .update({
            body: {
              token: tokenResult,
              password,
            },
          })
          .then(() => {
            toast.success(Translator('Senha alterada com sucesso!'));
            push('/login');
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Mudança de senha'),
              content: Translator('Não foi possível alterar senha'),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      } else {
        makeRemoteUserConfirmationPassword()
          .post({
            body: {
              token: tokenResult,
              password,
            },
          })
          .then(() => {
            toast.success(Translator('Conta confirmada com sucesso!'));
            push('/');
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Confirmação de conta'),
              content: Translator('Não foi possível confirmar conta'),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      }
    }
  };

  return (
    <Formik
      validationSchema={passwordResetSchema}
      initialValues={initialValues}
      onSubmit={() => {}}
      validateOnChange
      isInitialValid
    >
      {({ values, errors, touched, handleBlur, setFieldValue }) => (
        <Container>
          <Left />
          <Right>
            <Content>
              {theme.images.logo.main && (
                <img src={theme.images.logo.main} alt="Logo" />
              )}
              <Title>Definir nova senha</Title>
              <Form>
                <Input
                  name="password"
                  label={Translator('Nova senha')}
                  type="password"
                  placeholder={Translator('Digite sua senha')}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setFieldValue('password', e.target.value);
                  }}
                  autoFocus
                  onBlur={handleBlur}
                  message={touched.password ? errors.password : ''}
                  error={Boolean(touched.password && errors?.password)}
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  label={Translator('Confirmar nova senha')}
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    setFieldValue('confirmPassword', e.target.value);
                  }}
                  placeholder={Translator('Confime sua senha')}
                  onBlur={handleBlur}
                  message={
                    touched.confirmPassword ? errors.confirmPassword : ''
                  }
                  error={Boolean(
                    touched.confirmPassword && errors?.confirmPassword,
                  )}
                />
                <ButtonsContainer>
                  <Button
                    size="small"
                    onClick={handleValidatePassword}
                    disabled={
                      Boolean(errors?.password || errors?.confirmPassword) ||
                      password !== confirmPassword
                    }
                  >
                    Salvar
                  </Button>
                </ButtonsContainer>
              </Form>
            </Content>
            <ImageContainer>
              {theme.images.background.main && (
                <img src={theme.images.background.main} alt="Logo" />
              )}
            </ImageContainer>
          </Right>
        </Container>
      )}
    </Formik>
  );
};

export default RecoveryPassword;
