import React, { cloneElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { GetDashboardOrg } from 'domain/usecases/dashboard/remote';
import {
  IconArrowUpDown,
  IconCalendarDashboard,
  IconCamDashboard,
  IconStorageDashboard,
  IconUser,
  IconUserRanking,
} from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import SkeletonTable from './skeleton';

import {
  CellContent,
  HeaderLabelStyled,
  InfoCard,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
} from './styles';

interface DashboardData {
  id: number;
  org: {
    name: string;
    subInfo: string;
  };
  users: { icon: JSX.Element; value: number };
  schedules: { icon: JSX.Element; value: number };
  recordings: { icon: JSX.Element; value: number };
  storage: { icon: JSX.Element; value: number };
}

const objToRenderLabelColumn: Record<string, any> = {
  org: {
    name: Translator('Organização'),
  },
  users: {
    name: Translator('Usuários'),
    icon: <IconUser />,
  },
  schedules: {
    name: Translator('Agendamentos'),
    icon: <IconCalendarDashboard />,
  },
  recordings: {
    name: Translator('Gravações'),
    icon: <IconCamDashboard />,
  },
  storage: {
    name: Translator('Armazenamento'),
    icon: <IconStorageDashboard />,
  },
};

type TableProps = {
  activePage: number;
  itemsPerPage: number;
};

const Table: React.FC<TableProps> = ({ activePage, itemsPerPage }) => {
  const { loadingNextPage, response } = useSelector(
    (store: iStore) => store.dashboardOrg,
  );

  const data: Array<Record<string, any>> = useMemo(() => {
    const orgs = response?.orgs;

    return orgs?.ranking?.records
      ?.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
      ?.map((record, idx) => ({
        id: idx + (activePage - 1) * itemsPerPage + 1,
        org: {
          shortname: record.shortname,
          name: record.name,
        },
        users: { icon: <IconUserRanking />, value: record.users },
        totalConferences: {
          icon: <IconCalendarDashboard />,
          value: record.totalConferences,
        },
        recordedConferences: {
          icon: <IconCamDashboard />,
          value: record.recordedConferences,
        },
        storage: { icon: <IconStorageDashboard />, value: record.storage },
      }));
  }, [response, activePage, itemsPerPage]);

  return loadingNextPage || data?.length === 0 ? (
    <SkeletonTable />
  ) : (
    <StyledTableContainer>
      <StyledTable stickyHeader>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell />
            {Object.keys(objToRenderLabelColumn)?.map(key => (
              <StyledTableCell key={key}>
                <HeaderLabelStyled>
                  <p>{objToRenderLabelColumn[key].name}</p>
                  <IconArrowUpDown />
                </HeaderLabelStyled>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {data?.map((row, idx) => (
            <StyledTableRow key={`${row.org.name}-${idx + 1}`}>
              <StyledTableCell style={{ paddingLeft: '1.6rem' }}>
                <InfoCard>
                  <CellContent>
                    <p>{`${row.id}º`}</p>
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  <h2 className="info">{row.org.shortname}</h2>
                  <p className="subInfo">{row.org.name}</p>
                </InfoCard>
              </StyledTableCell>
              {Object.keys(row).map(key => {
                return key !== 'org' && key !== 'id' ? (
                  <StyledTableCell key={key}>
                    <InfoCard>
                      <CellContent>
                        {cloneElement(row?.[key]?.icon)}
                        <p>{row[key]?.value}</p>
                      </CellContent>
                    </InfoCard>
                  </StyledTableCell>
                ) : null;
              })}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default Table;
