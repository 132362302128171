import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUnitByOrgUnitId } from 'domain/interfaces/redux/orgUnit/listByOrgUnitId';
import { ListOrgUnitUsers } from 'domain/usecases/orgUnit/remote/ListOrgUnitUsers';
import { makeRemoteListOrgUnitUsers } from 'main/factories/usecases/orgUnit/ListOrgUnitUsers';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUnits/actions/listByOrgUnitId';

export function* onListByUnitId(action: iActionListOrgUnitByOrgUnitId) {
  const remoteListOrgUserByOrgUnitId = makeRemoteListOrgUnitUsers();

  try {
    const response: ListOrgUnitUsers.Model = yield call(
      remoteListOrgUserByOrgUnitId.list,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByUnitIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByUnitIdFailed() {}
