import styled from 'styled-components';

export const Container = styled.div`
  width: 450px;
  background-color: white;
  padding-bottom: 15px;
  border-radius: 0px 0px 8px 8px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
`;

export const Itens = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  color: #000000;

  strong {
    color: black;
  }
`;

export const Orgs = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  padding: 0px 20px;
  overflow: auto;
`;

interface iTitle {
  selected: boolean;
}

export const Title = styled.div<iTitle>`
  /* color: ${props => (props.selected ? '#004ba5' : '#7a7a7a')}; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  /* primary */

  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  max-width: 80%;
  width: 100%;
  height: 1px;
  background: rgba(230, 230, 230, 1);
  margin-left: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
  margin-top: 60px;

  button {
    margin-left: 16px;
  }
`;
