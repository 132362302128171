import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetPublicTopicById as UsecaseRemoteGetPublicTopicById } from 'domain/usecases/sacTopic/remote';

import { RemoteGetPublicTopicById } from 'data/repository/sacTopic';

/**
 * send request via API.
 */
export const makeRemoteGetPublicTopicById =
  (): UsecaseRemoteGetPublicTopicById =>
    new RemoteGetPublicTopicById(makeApiUrl('/sac/topics'), makeHttpClient());
