import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';
import { Forbidden, NotFound, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetPublicTopicById implements GetPublicTopicById {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetPublicTopicById.Model>;

  constructor(url: string, httClient: HttpClient<GetPublicTopicById.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getById = async (
    params: GetPublicTopicById.Params,
  ): Promise<GetPublicTopicById.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.topic}`,
      method: 'get',
      headers: params?.token
        ? {
            Authorization: `APIKEY ${params?.token}`,
          }
        : undefined,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
