import { RefreshAuth } from 'domain/usecases/auth/redux/RefreshAuth';
import {
  iActionRefresh,
  iActionRefreshFailed,
  iActionRefreshSuccess,
  AuthTypes,
} from 'domain/interfaces/redux/auth/refresh';

export const refreshRequest = (
  payload: RefreshAuth.Params,
): iActionRefresh => ({
  type: AuthTypes.REFRESH,
  payload,
});

export const refreshSuccess = (
  params: RefreshAuth.Model,
): iActionRefreshSuccess => ({
  type: AuthTypes.REFRESH_SUCCESS,
  payload: params,
});

export const refreshFailed = (): iActionRefreshFailed => ({
  type: AuthTypes.REFRESH_FAILED,
});
