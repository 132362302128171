/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';

import { Container } from './styles/StyledIcon';

interface ownProps {
  src: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  fallback?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  popover?: string;
  color?: string;
  width?: string;
  height?: string;
  onClick?: () => any;
  isCircular?: boolean;
  className?: string;
}

const Icon: React.FC<ownProps> = props => {
  const Image = props.src;

  const [error, setError] = useState(false);

  return (
    <Container
      width={props.width}
      height={props.height}
      color={props.color}
      onClick={props.onClick}
      className={props.className}
    >
      {error && props.fallback ? (
        <props.fallback />
      ) : (
        <>
          {typeof props.src === 'string' ? (
            <img
              src={props.src}
              alt={props.className}
              onError={() => setError(true)}
            />
          ) : (
            <Image />
          )}
        </>
      )}
    </Container>
  );
};

export default Icon;
