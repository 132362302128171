import { ListGroupsAudit } from 'domain/usecases/audit/redux/ListGroupsAudit';
import {
  iActionListGroups,
  iActionListGroupsFailed,
  iActionListGroupsSuccess,
  AuditTypes,
} from 'domain/interfaces/redux/audit/listGroups';

export const listGroupsRequest = (
  payload: ListGroupsAudit.Params,
): iActionListGroups => ({
  type: AuditTypes.LISTGROUPS,
  payload,
});

export const listGroupsSuccess = (
  params: ListGroupsAudit.Model,
): iActionListGroupsSuccess => ({
  type: AuditTypes.LISTGROUPS_SUCCESS,
  payload: params,
});

export const listGroupsFailed = (): iActionListGroupsFailed => ({
  type: AuditTypes.LISTGROUPS_FAILED,
});
