import React, { FormEvent, useState } from 'react';

import { useHistory } from 'react-router';
import Input from 'presentation/components/UI/input/index';
import { Button } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';
import {
  IconOfferOne,
  IconOfferThree,
  IconOfferTwo,
  IconWiseAgenda,
} from 'presentation/base/icons';
import { toast } from 'react-toastify';
import { makeRemoteCreateFreemiumOrg } from 'main/factories/usecases/org/CreateOrgFreemiumFactory';
import {
  Container,
  Left,
  Right,
  Form,
  Body,
  ButtonsContainer,
  Title,
  Offerings,
  Offer,
  WiseServices,
} from './styles/styledFreemium';

const FreemiumRegister: React.FC = (): JSX.Element => {
  const { push } = useHistory();

  const [form, setForm] = useState({
    name: '',
    cpf: '',
    orgName: '',
    email: '',
    phone: '',
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    makeRemoteCreateFreemiumOrg()
      .create({
        org: {
          name: form.name,
          type: 'F',
          reg: form.cpf.replace(/\D/g, ''),
          skin: 'WISECARE',
        },
        admin: {
          fullName: form.name,
          email: form.email.toLocaleLowerCase().trim(),
          phone: `+55${form.phone.replace(/\D/g, '')}`,
        },
      })
      .then(res => {
        console.log('res: ', res);

        toast.success('Cadastro realizado com sucesso!');
        push('/');
      })
      .catch(err => {
        console.log('err: ', err);

        toast.error('Erro ao realizar cadastro!');
      });
  };

  return (
    <Container>
      <Body>
        <Left>
          <Offerings>
            <IconWiseAgenda />
            <span className="text">
              Utilize a nossa plataforma por um período gratuito de 14 dias.
            </span>
            <WiseServices>O que o WiseAgenda pode oferecer</WiseServices>
            <Offer>
              <Icon src={IconOfferOne} width="64px" height="64px" />
              <span>
                União de todos os seus compromissos em uma única plataforma.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferTwo} width="64px" height="64px" />
              <span>
                Agendamento de videoconferências seguras e de fácil acesso.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferThree} width="64px" height="64px" />
              <span>
                Registro e preservação dos vídeos com tecnologia blockchain.
              </span>
            </Offer>
          </Offerings>
        </Left>
        <Right>
          <Form id="loginAuthentication" onSubmit={onSubmit}>
            <Title>Cadastro</Title>
            <Input
              id="name"
              type="text"
              name="name"
              label="Nome Completo"
              placeholder="Digite seu nome completo"
              value={form.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
              required
            />

            <Input
              id="cpf"
              type="text"
              name="cpf"
              mask="999.999.999-99"
              label="CPF"
              placeholder="Digite seu cpf"
              value={form.cpf}
              onChange={e => setForm({ ...form, cpf: e.target.value })}
              required
            />

            <Input
              id="email"
              type="text"
              name="email"
              label="E-mail"
              placeholder="Digite o seu email"
              value={form.email}
              onChange={e =>
                setForm({
                  ...form,
                  email: e.target.value,
                })
              }
              required
            />

            <Input
              id="orgName"
              type="text"
              name="orgName"
              label="Nome da empresa ou organização"
              placeholder="Digite o nome da empresa ou organização"
              value={form.orgName}
              onChange={e => setForm({ ...form, orgName: e.target.value })}
              required
            />

            <Input
              id="phone"
              name="phone"
              placeholder="(DDD) 99999-9999"
              mask="(99) 99999-9999"
              label="Telefone"
              value={form.phone}
              onChange={e => {
                const phone = e.target.value.replace(/[^\d]+/g, '').trim();
                setForm({ ...form, phone });
              }}
              required
            />

            <ButtonsContainer>
              <Button id="signupSubmit" type="submit" rounded height="48px">
                Criar minha conta
              </Button>
            </ButtonsContainer>
          </Form>
        </Right>
      </Body>
    </Container>
  );
};

export default FreemiumRegister;
