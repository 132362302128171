import { DownloadRegistry } from 'domain/usecases/registry/remote';
import { BadRequestError, Forbidden, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDownloadRegistry implements DownloadRegistry {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadRegistry.Model>;

  constructor(url: string, httClient: HttpClient<DownloadRegistry.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (params: DownloadRegistry.Params): Promise<string> => {
    const httpResponse = await this.httpClient.request({
      url: this.url
        .replace('{conferenceId}', `${params.conferenceId}`)
        .replace('{dltId}', `${params.dltId}`),
      method: 'get',
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
