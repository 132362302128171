import Pagination from 'presentation/components/Pagination';
import { Header, HeaderBack } from 'presentation/components/header';
import { ChildComponentRef } from 'presentation/components/header/BackButton';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxListLogsAudit } from 'main/factories/usecases/audit/ListLogsAuditFactory';
import Auditoria from 'presentation/components/list/Auditoria';
import { iListAudit } from 'presentation/components/list/interface';
import { ListLogsAudit } from 'domain/usecases/audit/remote';
import _ from 'lodash';
import { format, subYears } from 'date-fns';
import { getRole } from 'utils/getRole';
import { Container, ContentAudit, List } from './styles/StyledAudit';

const now = new Date();
const previous = subYears(now, 5);

const defaultFilterAudit: ListLogsAudit.Params['body'] = {
  log: {
    timestamp: {
      begin: format(previous, 'yyyy-MM-dd HH:mm:ss'),
      end: format(now, 'yyyy-MM-dd HH:mm:ss'),
    },
  },
  user: {
    id: undefined,
    org: undefined,
    orgUnit: undefined,
  },
  action: {
    changeData: true,
    groupShort: undefined,
  },
};

const Audit: React.FC = () => {
  const filterRef = useRef<ChildComponentRef>(null);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [isFilterActive, setIsFilterActive] = useState(false);
  const itemsPerPage = 15;

  const { logs } = useSelector((store: iStore) => store.audit);
  const { user } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const [filterAudit, setFilterAudit] =
    useState<ListLogsAudit.Params['body']>(defaultFilterAudit);

  const handlePageUpdate = useCallback(
    (currentPage: number) => {
      const equalToDefaultFilter = _.isEqual(filterAudit, defaultFilterAudit);

      makeReduxListLogsAudit().listLogs({
        body: {
          action: equalToDefaultFilter
            ? {
                changeData: true,
              }
            : filterAudit?.action,
          log: equalToDefaultFilter ? undefined : filterAudit?.log,
          user: equalToDefaultFilter
            ? {
                org: role === 'ORG_ADMIN' ? user?.org.id : undefined,
                orgUnit: role === 'UNIT_ADMIN' ? user?.orgUnit.id : undefined,
              }
            : filterAudit?.user,
          dataControl: {
            limit: itemsPerPage,
            paging: true,
            offset: (currentPage - 1) * itemsPerPage,
          },
        },
      });
      setPage(currentPage);
      setIsFilterActive(!equalToDefaultFilter);
    },
    [filterAudit, role, user?.org.id, user?.orgUnit.id],
  );

  const handleFilterAudit = useCallback(() => {
    makeReduxListLogsAudit().listLogs({
      body: {
        action: {
          groupShort: search?.length ? search : undefined,
        },
        dataControl: {
          limit: itemsPerPage,
          paging: true,
        },
      },
    });
    setIsFilterActive(!!search?.length);
  }, [search]);

  return (
    <Container>
      <Header title="teste" />
      <HeaderBack
        title="Auditoria de ações realizadas pelos usuários"
        hasFilter
        ref={filterRef}
        filterAudit={filterAudit}
        updateFilterAudit={setFilterAudit}
        search={search}
        setSearch={setSearch}
        onSearch={handleFilterAudit}
        isFilterActive={isFilterActive}
      />
      <div style={{ padding: '0 24px' }}>
        <ContentAudit>
          <Pagination
            currentPage={page}
            pageSize={Math.ceil(
              (logs?.metadata.total ? logs?.metadata.total : 0) / itemsPerPage,
            )}
            setPage={handlePageUpdate}
            pageDynamicSize
          />
        </ContentAudit>
        <List>
          <Auditoria dataList={logs?.records} />
        </List>
      </div>
    </Container>
  );
};

export default Audit;
