import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { GetOrgUnit } from 'domain/usecases/orgUnit/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteCreateOrgUnit } from 'main/factories/usecases/orgUnit/CreateOrgUnitFactory';
import { makeReduxListOrgUnitAclFilteredV2 } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';
import { makeRemoteUpdateOrgUnit } from 'main/factories/usecases/orgUnit/UpdateOrgUnitFactory';
import Translator from 'presentation/components/i18n/Translator';
import Button from 'presentation/components/UI/buttons/Button';
import Input from 'presentation/components/UI/input';
import { initialValuesOrgUnit } from 'presentation/pages/CreateOrgUnit';
import { closeModal } from 'utils/closeModal';
import { Container, ContentButton, ContentInput, Fields } from './styles';

interface ownProps {
  units: GetOrgUnit.Model;
  editing?: boolean;
  // TODO: Remove after everything is on Redux
  haveUpdated?: () => void;
}

interface unitProps {
  shortname?: string;
  name?: string;
}

interface iParams {
  id: string;
}

export const OrgUnitName: React.FC<ownProps> = ({
  units,
  editing = false,
  haveUpdated,
}) => {
  const [unit, setUnit] = useState<unitProps>({} as unitProps);
  const [previousName, setPreviousName] = useState('');
  const [previousShortnameValue, setPreviousShortnameValue] = useState('');

  const { user } = useSelector((store: iStore) => store.auth);
  const { resources } = useSelector((store: iStore) => store.auth.permissions);
  const { records: orgUnitRecords } = useSelector(
    (store: iStore) => store.orgUnits,
  );

  const formikContext = useFormikContext<initialValuesOrgUnit>();
  const params = useParams<iParams>();
  const { push } = useHistory();

  // eslint-disable-next-line consistent-return
  const updateOrgUnit = () => {
    if (editing && !units?.org)
      return toast.error(
        Translator('Não foi possível buscar dados da organização do setor'),
      );

    if (editing && units?.org) {
      if (
        unit.name === previousName &&
        unit.shortname === previousShortnameValue
      )
        // eslint-disable-next-line consistent-return
        return toast.error(
          Translator('Altere algum campo para atualizar o setor.'),
        );

      makeRemoteUpdateOrgUnit()
        .update({
          orgId: units.org.id,
          unitId: units.id,
          body: {
            name: previousName !== unit?.name ? unit?.name : undefined,
            shortname:
              previousShortnameValue !== unit?.shortname
                ? unit?.shortname
                : undefined,
          },
        })
        .then(res => {
          toast.success(Translator('Setor atualizado com sucesso!'));

          haveUpdated?.();
          setPreviousName(unit.name ?? previousName);
          setPreviousShortnameValue(unit.shortname ?? previousShortnameValue);

          makeReduxListOrgUnitAclFilteredV2().list({
            limit: 9999,
          });
        })
        .catch(e => {
          makeReduxActiveMessage().active({
            active: 'error',
            title: Translator('Atualizar setor'),
            content: Translator('Não foi possível atualizar o setor.'),
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    } else if (params?.id && unit.name && unit.shortname) {
      makeRemoteCreateOrgUnit()
        .create({
          orgId: Number(params?.id),
          name: unit?.name,
          shortname: unit?.shortname,
          settings: {
            notification: {
              sms: {
                enabled: true,
                limit: 1500,
              },
              email: {
                enabled: true,
                limit: 1500,
              },
              whatsapp: {
                enabled: true,
                limit: 1500,
              },
            },
          },
        })
        .then(res => {
          toast.success(Translator('Setor criado com sucesso!'));

          push(`/admin/orgs/${params?.id}`);
        })
        .catch(err => {
          const errorMessage =
            err.name === 'conflict'
              ? 'Já existe um setor com este nome.'
              : 'Não foi possível criar o setor.';

          makeReduxActiveMessage().active({
            active: 'error',
            title: Translator('Criação de setor'),
            content: Translator(errorMessage),
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    }
  };

  const disableUpdate = useMemo(() => {
    if (user?.administrator?.system) return false;
    if (!resources) return true;

    // Go to resource ORGUNIT and check if user have UPDATE permission
    const haveUpdate = resources.find(
      res =>
        res.name === 'ORGUNIT' &&
        res.actions.find(item => item.name.includes('UPDATE')),
    );

    return !haveUpdate;
  }, [resources, user?.administrator?.system]);

  const actualOrgUnit = useMemo(
    () => orgUnitRecords?.find(item => item.id === units?.id),
    [orgUnitRecords, units?.id],
  );

  useEffect(() => {
    if (editing) {
      setPreviousName(units.name);
      setPreviousShortnameValue(units.shortname);

      setUnit({
        name: units?.name,
        shortname: units?.shortname,
      });
    }
  }, [editing, units]);

  return (
    <Container>
      <Fields>
        <ContentInput>
          <Input
            name="name"
            label={Translator('Nome do setor')}
            defaultValue={unit?.name}
            value={unit?.name}
            onChange={e => {
              setUnit({ ...unit, name: e.target.value });
              formikContext?.setFieldValue('name', e.target.value);
            }}
            onBlur={formikContext?.handleBlur}
            message={
              formikContext?.touched.name ? formikContext?.errors.name : ''
            }
            error={Boolean(
              formikContext?.touched.name && formikContext?.errors?.name,
            )}
            disabled={disableUpdate}
            autoFocus
            required
          />
          <Input
            name="shortname"
            label={Translator('Sigla')}
            defaultValue={unit?.shortname}
            value={unit?.shortname}
            onChange={e => {
              setUnit({ ...unit, shortname: e.target.value });
              formikContext?.setFieldValue('shortname', e.target.value);
            }}
            onBlur={formikContext?.handleBlur}
            message={
              formikContext?.touched.shortname
                ? formikContext?.errors.shortname
                : ''
            }
            error={Boolean(
              formikContext?.touched.shortname &&
                formikContext?.errors?.shortname,
            )}
            disabled={disableUpdate}
            required
          />
        </ContentInput>
      </Fields>
      <ContentButton>
        <Button
          size="small"
          onClick={() => updateOrgUnit()}
          permissionsV2={actualOrgUnit?.permission?.update}
          disabled={Boolean(
            formikContext?.errors?.name || formikContext?.errors?.shortname,
          )}
        >
          {Translator('Salvar')}
        </Button>
      </ContentButton>
    </Container>
  );
};

export default OrgUnitName;
