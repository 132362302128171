import { Dispatch } from 'redux';
import store from 'data/store';

import { listMyConferenceRequest } from 'data/store/reducer/myConference/actions/listMyConferences';

import { iActionListMyConference } from 'domain/interfaces/redux/myConferences/myConference';

import { ListMyConferences } from 'domain/usecases/myConferences/redux';

export class ReduxListMyConferences implements ListMyConferences {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListMyConferences.Params): iActionListMyConference =>
    this.send(listMyConferenceRequest(params));
}
