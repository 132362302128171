import { GuestAuth } from 'domain/usecases/auth/redux/GuestAuth';
import {
  iActionGuest,
  iActionGuestFailed,
  iActionGuestSuccess,
  AuthTypes,
} from 'domain/interfaces/redux/auth/guest';

export const guestRequest = (payload: GuestAuth.Params): iActionGuest => ({
  type: AuthTypes.GUEST,
  payload,
});

export const guestSuccess = (params: GuestAuth.Model): iActionGuestSuccess => ({
  type: AuthTypes.GUEST_SUCCESS,
  payload: params,
});

export const guestFailed = (): iActionGuestFailed => ({
  type: AuthTypes.GUEST_FAILED,
});
