import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { RequestConferenceWaitingRoom as UsecaseRemoteRequestConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/remote';
// import { RequestConferenceWaitingRoom as UsecaseReduxRequestConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

import { RemoteRequestConferenceWaitingRoom } from 'data/repository/conferenceWaitingRoom';
// import { ReduxRequestConferenceWaitingRoom } from 'data/store/reducer/conferenceWaitingRoom/usecases';

/**
 * send request via API.
 */
export const makeRemoteRequestConferenceWaitingRoom =
  (): UsecaseRemoteRequestConferenceWaitingRoom =>
    new RemoteRequestConferenceWaitingRoom(
      makeApiUrl('/conferences/{conferenceShort}/waitingRoom/request'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRequestConferenceWaitingRoom =
  (): UsecaseReduxRequestConferenceWaitingRoom =>
    new ReduxRequestConferenceWaitingRoom(); */
