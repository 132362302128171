import React, { HTMLAttributes } from 'react';

import { Container } from './styles';

interface Chips extends HTMLAttributes<HTMLButtonElement> {
  week: string;
  active?: boolean;
}

const Chips: React.FC<Chips> = ({ week, active, ...rest }) => {
  return (
    <Container type="button" active={active} {...rest}>
      {week}
    </Container>
  );
};

export default Chips;
