import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import { Body, Container, Content, Header } from './styles/StyledInfoMessage';

const InfoModal: React.FC<ownProps> = ({ message }): JSX.Element => {
  const msgName = MessageOptions.infoMarkdown;

  const { active, actionOk, actionCancel, content, title } = message;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{title}</span>
            </Header>
            <Content hasContent={!!content}>
              <>{content && <ReactMarkdown>{content}</ReactMarkdown>}</>
            </Content>
            <Button
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              autoFocus
              onClick={actionOk}
            >
              Fechar
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default InfoModal;
