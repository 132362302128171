import { LogoutAuth } from 'domain/usecases/auth/redux/LogoutAuth';
import {
  iActionLogout,
  iActionLogoutSuccess,
  iActionLogoutFailed,
  AuthTypes,
} from 'domain/interfaces/redux/auth/logout';

export const logoutRequest = (payload: LogoutAuth.Params): iActionLogout => ({
  type: AuthTypes.LOGOUT,
  payload,
});

export const logoutSuccess = (
  params: LogoutAuth.Model,
): iActionLogoutSuccess => ({
  type: AuthTypes.LOGOUT_SUCCESS,
  payload: params,
});

export const logoutFailed = (): iActionLogoutFailed => ({
  type: AuthTypes.LOGOUT_FAILED,
});
