import { Dispatch } from 'redux';
import store from 'data/store';
import { listActionsRequest } from 'data/store/reducer/audit/actions/listActions';

import { iActionListActions } from 'domain/interfaces/redux/audit/listActions';

import { ListActionsAudit } from 'domain/usecases/audit/redux';

export class ReduxListActionsAudit implements ListActionsAudit {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listActions = (params: ListActionsAudit.Params): iActionListActions =>
    this.send(listActionsRequest(params));
}
