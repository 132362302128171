import { ListOrgAclFilteredV2 } from 'domain/usecases/org/redux/ListOrgAclFilteredV2';
import {
  iActionListAclFilteredV2,
  iActionListAclFilteredV2Failed,
  iActionListAclFilteredV2Success,
  OrgTypes,
} from 'domain/interfaces/redux/org/listAclFilteredV2';

export const listAclFilteredV2Request = (
  payload: ListOrgAclFilteredV2.Params,
): iActionListAclFilteredV2 => ({
  type: OrgTypes.LIST_ACL_FILTERED_V2,
  payload,
});

export const listAclFilteredV2Success = (
  params: ListOrgAclFilteredV2.Model,
): iActionListAclFilteredV2Success => ({
  type: OrgTypes.LIST_ACL_FILTERED_V2_SUCCESS,
  payload: params,
});

export const listAclFilteredV2Failed = (): iActionListAclFilteredV2Failed => ({
  type: OrgTypes.LIST_ACL_FILTERED_V2_FAILED,
});
