/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/default-props-match-prop-types */
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Input,
  Select,
  Button,
  KeyboardDate,
} from 'presentation/components/UI';
import { translator } from 'presentation/components/i18n';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import {
  makeRemoteGetFilteredOrgs,
  makeReduxGetFilteredOrgs,
} from 'main/factories/usecases/org/GetFilteredOrgs';
import { subYears } from 'date-fns';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  Options,
  Typography,
  GeneralData,
  GeneralDataForm,
  Idenfitication,
  ContainerInput,
  RegisterDate,
  KeyboardDateContainer,
  KeyboardContent,
  Permissions,
  RadioPermissions,
  ContainerRadioButtons,
  ContentRadioButtons,
} from './styles/StyledFilterOrganization';
import { ChildComponentRef } from '../header/BackButton';

export interface iFilterOrganization {
  organization?: {
    id?: number;
    search?: string;
    registered?: {
      begin?: string;
      end?: string;
    };
    conference?: {
      userLimit?: number;
    };
    recording?: {
      enabled?: boolean;
      limit?: {
        min?: number;
        max?: number;
      };
    };
    linkedUsers?: {
      min?: number;
      max?: number;
    };
  };
  dataControl?: {
    limit?: number;
    offset?: number;
    paging?: boolean;
  };
  orderBy?: {
    order?: number;
    attribute?: string;
    direction?: string;
  }[];
}

interface iFilterOrganizationProps {
  isClosed: boolean;
  setIsClosed: (closed: boolean) => void;
  filterOrganization?: iFilterOrganization;
  setFilterOrganization?: (data: iFilterOrganization) => void;
}

const FilterOrganization: React.ForwardRefRenderFunction<
  any,
  iFilterOrganizationProps
> = (
  { isClosed, setIsClosed, filterOrganization, setFilterOrganization },
  ref,
) => {
  const [options, setOptions] = useState<string>(
    filterOrganization?.organization?.linkedUsers?.max === undefined
      ? 'acima'
      : 'abaixo',
  );

  const [created, setCreated] = useState(
    filterOrganization?.organization?.registered?.begin === undefined
      ? new Date()
      : new Date(filterOrganization?.organization?.registered?.begin),
  );
  const [endAt, setEndAt] = useState(
    filterOrganization?.organization?.registered?.end === undefined
      ? new Date()
      : new Date(filterOrganization?.organization?.registered?.end),
  );

  const organization = filterOrganization?.organization;

  useImperativeHandle(ref, () => ({
    getState: () => filterOrganization,
  }));

  const handleFilter = useCallback(() => {
    makeReduxGetFilteredOrgs().list({
      organization: {
        registered: {
          begin: created.toISOString(),
          end: endAt.toISOString(),
        },
        linkedUsers: {
          min: organization?.linkedUsers?.min ?? undefined,
          max: organization?.linkedUsers?.max ?? undefined,
        },
        search: organization?.search?.length
          ? organization?.search?.trim()
          : undefined,
        recording: {
          enabled: organization?.recording?.enabled ?? undefined,
        },
      },
      dataControl: {
        limit: 9999,
      },
    });

    setFilterOrganization?.({
      ...filterOrganization,
      organization: {
        ...organization,
        registered: {
          begin: created.toISOString(),
          end: endAt.toISOString(),
        },
      },
    });

    setIsClosed(false);
  }, [
    created,
    endAt,
    filterOrganization,
    organization,
    setFilterOrganization,
    setIsClosed,
  ]);

  const resetFilter = () => {
    const now = new Date();
    const previous = subYears(now, 5);

    setCreated(previous);
    setEndAt(now);

    setFilterOrganization?.({
      organization: {
        registered: {
          begin: subYears(
            new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
            5,
          ).toISOString(),
          end: new Date().toISOString(),
        },
        search: undefined,
        linkedUsers: {
          min: 0,
          max: undefined,
        },
        recording: {
          enabled: undefined,
        },
      },
    });

    setOptions('acima');

    makeReduxListOrgAclFilteredV2().list({
      limit: 9999,
      offset: 0,
    });
  };

  return (
    <>
      <Container isOpen={isClosed}>
        <ContainerForm
          onSubmit={e => {
            e.preventDefault();
            handleFilter();
          }}
        >
          <GeneralData>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Dados gerais
            </Typography>

            <GeneralDataForm>
              <Idenfitication>
                <Typography size="14px" color="#222529" line="16px">
                  Identificação:
                </Typography>
                <Input
                  placeholder="Pesquise pelo nome, sigla ou e-mail"
                  value={filterOrganization?.organization?.search}
                  onChange={e =>
                    setFilterOrganization?.({
                      ...filterOrganization,
                      organization: {
                        ...organization,
                        search: e.target.value,
                      },
                    })
                  }
                />
              </Idenfitication>
              <ContainerInput>
                <Typography size="14px" color="#222529" line="16px">
                  Usuários:
                </Typography>
                <Select
                  width="137px"
                  onChange={e => {
                    const changingToMax =
                      options.includes('acima') && e.target.value === 'abaixo';
                    const changingToMin =
                      options.includes('abaixo') && e.target.value === 'acima';

                    let newMax = changingToMax
                      ? organization?.linkedUsers?.min
                      : organization?.linkedUsers?.max;

                    let newMin = changingToMin
                      ? organization?.linkedUsers?.max
                      : organization?.linkedUsers?.min;

                    if (changingToMin) {
                      newMax = undefined;
                    }

                    if (changingToMax) {
                      newMin = 0;
                    }

                    setFilterOrganization?.({
                      ...filterOrganization,
                      organization: {
                        ...organization,
                        linkedUsers: {
                          min: newMin,
                          max: newMax,
                        },
                      },
                    });

                    setOptions(e.target.value);
                  }}
                  value={options}
                >
                  <Options value="acima">Acima de</Options>
                  <Options value="abaixo">Abaixo de</Options>
                </Select>
                <Input
                  type="number"
                  width="92px"
                  min={0}
                  value={
                    options.includes('acima')
                      ? organization?.linkedUsers?.min
                      : organization?.linkedUsers?.max
                  }
                  onChange={e => {
                    if (options.includes('acima')) {
                      setFilterOrganization?.({
                        ...filterOrganization,
                        organization: {
                          ...organization,
                          linkedUsers: {
                            min: Number(e.target.value),
                          },
                        },
                      });
                    } else {
                      setFilterOrganization?.({
                        ...filterOrganization,
                        organization: {
                          ...organization,
                          linkedUsers: {
                            max: Number(e.target.value),
                          },
                        },
                      });
                    }
                  }}
                />
              </ContainerInput>
            </GeneralDataForm>
          </GeneralData>

          <RegisterDate>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Data de cadastro
            </Typography>

            <KeyboardDateContainer>
              <Typography size="14px" color="#222529" line="16px">
                A partir de
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={created}
                  setState={setCreated}
                  placeholder="Data"
                  width="140px"
                />
              </KeyboardContent>

              <Typography size="14px" color="#222529" line="16px">
                a
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={endAt}
                  setState={setEndAt}
                  placeholder={`${translator('Fim')}`}
                  width="140px"
                  minDate={created}
                />
              </KeyboardContent>
            </KeyboardDateContainer>
          </RegisterDate>

          <Permissions>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Permissões
            </Typography>

            <RadioPermissions>
              <Typography size="14px" color="#222529" line="16px">
                As reuniões podem ser gravadas?
              </Typography>

              <ContainerRadioButtons>
                <ContentRadioButtons>
                  <input
                    type="radio"
                    checked={organization?.recording?.enabled === true}
                    onChange={() => {
                      setFilterOrganization?.({
                        ...filterOrganization,
                        organization: {
                          ...organization,
                          recording: {
                            enabled: true,
                          },
                        },
                      });
                    }}
                    width="10px"
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </ContentRadioButtons>

                <ContentRadioButtons>
                  <input
                    type="radio"
                    checked={organization?.recording?.enabled === false}
                    onChange={() => {
                      setFilterOrganization?.({
                        ...filterOrganization,
                        organization: {
                          ...organization,
                          recording: {
                            enabled: false,
                          },
                        },
                      });
                    }}
                    width="10px"
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </ContentRadioButtons>
              </ContainerRadioButtons>
            </RadioPermissions>
          </Permissions>
        </ContainerForm>

        <ContainerButtons>
          <Button
            rounded
            size="fit-content"
            variant="secundary"
            onClick={() => resetFilter()}
          >
            Restaurar padrões
          </Button>
          <Button
            id="btn_search"
            rounded
            variant="primary"
            size="fit-content"
            onClick={() => handleFilter()}
            autoFocus
          >
            Aplicar filtros
          </Button>
        </ContainerButtons>
      </Container>
    </>
  );
};

export default forwardRef<ChildComponentRef, iFilterOrganizationProps>(
  FilterOrganization,
);
