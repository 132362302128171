import { makeRemoteUserVerification } from 'main/factories/usecases/user/UserVerificationFactory';
import * as yup from 'yup';

export enum MessageError {
  REQUIRED_FIELD = 'Campo obrigatório',
  INVALID_EMAIL = 'E-mail inválido',
  INVALID_PHONE = 'Telefone inválido',
}

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const createUserSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'O nome deve conter no mínimo 3 caracteres')
    .required('O nome é obrigatório'),
  email: yup
    .string()
    .email('O e-mail não está em formato válido')
    .required('O e-mail é obrigatório')
    .test('checkDuplicate', 'O e-mail já está cadastrado', value => {
      if (!value?.length || !isValidEmail(value)) return true;

      return new Promise((resolve, reject) => {
        makeRemoteUserVerification()
          .post({
            body: {
              email: value,
            },
          })
          .then(res => {
            resolve(res.email);
          })
          .catch(() => {
            resolve(true);
          });
      });
    }),

  birthdate: yup.string().required('A data de nascimento é obrigatória'),

  phone: yup.string().required('O telefone é obrigatório'),

  zipcode: yup
    .string()
    .test('checkZipcode', 'Código Postal inválido, existe(m) digitos faltando', value => {
      const zipcode = value?.replace(/[^0-9]/g, '');

      return !!zipcode?.length && ( zipcode?.length >= 4 || zipcode?.length === 0);
    })
    .optional(),

  province: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .min(2, 'O estado deve conter no mínimo 2 caracteres.')
          .required('O estado é obrigatório');
      }
      return yup
        .string()
        .min(2, 'O estado deve conter no mínimo 2 caracteres.')
        .optional();
    }
    return yup
      .string()
      .min(2, 'O estado deve conter no mínimo 2 caracteres.')
      .optional();
  }),

  city: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .max(128, 'A cidade deve conter no máximo 128 caracteres.')
          .required('A cidade é obrigatória');
      }
      return yup
        .string()
        .max(128, 'A cidade deve conter no máximo 128 caracteres.')
        .optional();
    }
    return yup
      .string()
      .max(128, 'A cidade deve conter no máximo 128 caracteres.')
      .optional();
  }),

  country: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .max(3, 'O país deve conter no máximo 3 caracteres.')
          .required('O país é obrigatório');
      }
      return yup
        .string()
        .max(3, 'O país deve conter no máximo 3 caracteres.')
        .optional();
    }
    return yup
      .string()
      .max(3, 'O país deve conter no máximo 3 caracteres.')
      .optional();
  }),

  neighborhood: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .max(128, 'O bairro deve conter no máximo 128 caracteres.')
          .required('O bairro é obrigatório');
      }
      return yup
        .string()
        .max(128, 'O bairro deve conter no máximo 128 caracteres.')
        .optional();
    }
    return yup
      .string()
      .max(128, 'O bairro deve conter no máximo 128 caracteres.')
      .optional();
  }),

  street: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .max(256, 'A rua deve conter no máximo 256 caracteres.')
          .required('A rua é obrigatória');
      }
      return yup
        .string()
        .max(256, 'A rua deve conter no máximo 256 caracteres.')
        .optional();
    }
    return yup
      .string()
      .max(256, 'A rua deve conter no máximo 256 caracteres.')
      .optional();
  }),

  number: yup.string().when('zipcode', (val: any, schema) => {
    if (val) {
      if (val.length > 0) {
        return yup
          .string()
          .max(20, 'O número deve conter no máximo 20 caracteres.')
          .required('O número é obrigatório');
      }
      return yup
        .string()
        .max(20, 'O número deve conter no máximo 20 caracteres.')
        .optional();
    }
    return yup
      .string()
      .max(20, 'O número deve conter no máximo 20 caracteres.')
      .optional();
  }),

  complement: yup
    .string()
    .max(1024, 'O complemento deve conter no máximo 1024 caracteres.')
    .optional(),

  organization: yup
    .number()
    .test('is-default', MessageError.REQUIRED_FIELD, item => item !== -1)
    .required(MessageError.REQUIRED_FIELD),

  sector: yup
    .number()
    .test('is-default', MessageError.REQUIRED_FIELD, item => item !== -1)
    .required(MessageError.REQUIRED_FIELD),
});
