import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateConferenceNote as UsecaseRemoteUpdateConferenceNote } from 'domain/usecases/conferenceNote/remote';

import { RemoteUpdateConferenceNote } from 'data/repository/conferenceNote';

/**
 * send request via API.
 */
export const makeRemoteUpdateConferenceNote =
  (): UsecaseRemoteUpdateConferenceNote =>
    new RemoteUpdateConferenceNote(
      makeApiUrl('/conferences'),
      makeHttpClient(),
    );
