import { Dispatch } from 'redux';
import store from 'data/store';

import { listOrgsFilteredRequest } from 'data/store/reducer/org/actions/listOrgFiltered';

import { iActionListOrgsFiltered } from 'domain/interfaces/redux/org/listOrgsFiltered';

import { ListFilteredOrgs } from 'domain/usecases/org/redux';

export class ReduxListFilteredOrgs implements ListFilteredOrgs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListFilteredOrgs.Params): iActionListOrgsFiltered =>
    this.send(listOrgsFilteredRequest(params));
}
