/* eslint-disable no-restricted-globals */
/* eslint-disable no-var */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  Typography,
  IconButton,
} from '@wisecare-tech/design-system-web';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { chooseSubjectSchema } from 'validation/sac/ChooseSubjectModalValidator';
import { iMessage } from 'domain/interfaces/models';
import { closeModal } from 'utils/closeModal';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { History } from 'main/routes';
import { GetPublicTopics } from 'domain/usecases/sacTopic/remote';
import { toast } from 'react-toastify';
import { makeRemoteGetPublicTopics } from 'main/factories/usecases/sacTopic/GetPublicTopicsFactory';
import { Select } from '../UI';
import {
  Container,
  Title,
  Information,
  InformationContainer,
  Subtitle,
  Subject,
  Footer,
  ContainerModal,
  Label,
  ErrorMessage,
} from './styles/StyledChooseSubjectModal';

interface propsModalChooseSubject {
  message: iMessage;
}

type ChooseSubjectForm = {
  topic: string;
};

export const ChooseSubjectModal: React.FC<propsModalChooseSubject> = ({
  message,
}) => {
  const { active, actionCancel } = message;

  const [publicTopics, setPublicTopics] = useState<GetPublicTopics.Model>();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      token: searchParams.get('token'),
    };
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<ChooseSubjectForm>({
    resolver: yupResolver(chooseSubjectSchema) as any,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmit = (form: ChooseSubjectForm) => {
    History.push(`/sac/attendant/${form?.topic}?token=${params.token}`);
    closeModal();
  };

  const msgName = MessageOptions.chooseSubject;
  const topicId = window.location.href[window.location.href.length - 1];

  const castTopicId = Number(topicId);

  const getTopics = async () => {
    try {
      const response = await makeRemoteGetPublicTopics().list({
        token: params.token ?? undefined,
      });
      setPublicTopics(response);
    } catch {
      toast.error('Erro ao carregar os tópicos públicos.');
    }
  };

  useEffect(() => {
    if (!params.token) return;
    getTopics();
  }, []);

  useEffect(() => {
    if (isNaN(castTopicId)) return;
    setValue('topic', topicId);
  }, [castTopicId]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <InformationContainer>
              <Information>
                <Title>Seja bem-vindo ao suporte AtendeFácil.</Title>
                <Subtitle>
                  Você será atendido por uma videoconferência. Selecione o
                  assunto que você deseja tratar:
                </Subtitle>
              </Information>
              <IconButton
                icon="close"
                variant="primary"
                onClick={actionCancel}
                size="md"
              />
            </InformationContainer>
            <Subject onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="topic"
                render={({ field }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Label>
                      <Typography variant="b3_14medium">Assunto</Typography>
                    </Label>
                    <Select {...field} error={Boolean(errors?.topic)}>
                      <option value="">Selecione</option>
                      {publicTopics?.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </Select>
                    {errors?.topic && (
                      <ErrorMessage>
                        <Icon name="info" color="red-7" />
                        {errors?.topic?.message}
                      </ErrorMessage>
                    )}
                  </div>
                )}
              />
            </Subject>
            <Footer>
              <Button
                variant="primary"
                type="submit"
                size="md"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                <Typography variant="t4_16medium">
                  Continuar solicitação
                </Typography>
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
