import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetConference as UsecaseRemoteGetConference } from 'domain/usecases/conference/remote';
// import { GetConference as UsecaseReduxGetConference } from 'domain/usecases/conference/redux';

import { RemoteGetConference } from 'data/repository/conference';
// import { ReduxGetConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetConference = (): UsecaseRemoteGetConference =>
  new RemoteGetConference(
    makeApiUrl('/conferences/{conferenceId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetConference =
  (): UsecaseReduxGetConference =>
    new ReduxGetConference(); */
