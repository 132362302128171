/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeResendUserEmail } from 'main/factories/usecases/user/ResendUserEmail';
import { closeModal } from 'utils/closeModal';
import { formattedDate } from 'utils/formattedDate';
import { Button } from '../UI';
import { Container, FieldsetContainer } from './styles';
import Translator from '../i18n/Translator';

interface CardConfirmUserProps {
  user: iStore['user']['records'];
}

const CardConfirmUser: React.FC<CardConfirmUserProps> = ({
  user: selectedUser,
}) => {
  const user = useMemo(() => {
    return selectedUser[0];
  }, [selectedUser]);

  // console.log('user', user?.created);

  const lastLogin = useMemo(() => {
    return formattedDate(new Date(user?.orgUsers[0]?.lastAccess));
  }, [user]);

  const dueDate = useMemo(() => {
    return formattedDate(new Date(user?.emailVerificationDueDate));
  }, [user]);

  const emailVerified = useMemo(() => {
    return formattedDate(new Date(user?.emailVerified));
  }, [user]);

  // eslint-disable-next-line consistent-return
  const handleResendConfirmation = () => {
    if (!user.email)
      return toast.error(Translator('Não foi possível enviar e-mail'));

    makeReduxActiveMessage().active({
      active: 'advConfig',
      title: Translator('Reenviar confirmação'),
      userName: user?.fullName,
      userMail: user?.email,
      content: Translator(
        'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.',
      ),
      inputContent: false,
      actionOk: () => {
        makeResendUserEmail()
          .post({
            email: user.email,
          })
          .then(() => {
            toast.success(Translator('E-mail reenviado com sucesso!'));
            closeModal();
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Confirmação de conta'),
              content: Translator(
                'Não foi possível reenviar e-mail de confirmação',
              ),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      },
      actionCancel: () => closeModal(),
    });
  };

  return (
    <Container id="form">
      <FieldsetContainer>
        <legend>{Translator('Confirmação do cadastro de usuário')}</legend>
        <div className="field">
          <b>{Translator('Cadastrado em')}:</b>
          <span>{formattedDate(new Date(user?.created))}</span>
        </div>
        <div className="field">
          <b>{Translator('Prazo para confirmação')}:</b>
          <span>
            {user?.emailVerificationDueDate ? (
              dueDate
            ) : (
              <div style={{ color: '#c4c4c4' }}>
                {Translator('sem prazo para confirmação')}
              </div>
            )}
          </span>
        </div>
        <div className="field">
          <b>{Translator('E-mail confirmado em')}:</b>
          <span>
            {user?.emailVerified ? (
              emailVerified
            ) : (
              <div style={{ color: '#c4c4c4' }}>
                {Translator('não confirmado')}
              </div>
            )}
          </span>
        </div>
        <div className="field">
          <b>{Translator('Último login em')}:</b>
          <span>
            {user?.orgUsers[0]?.lastAccess ? (
              lastLogin
            ) : (
              <div style={{ color: '#c4c4c4' }}>
                {Translator('não fez login')}
              </div>
            )}
          </span>
        </div>
        {!user?.emailVerified ? (
          <div className="field">
            <b>{Translator('Confirmação de e-mail')}:</b>
            <Button size="medium" onClick={handleResendConfirmation}>
              {Translator('Reenviar confirmação')}
            </Button>
          </div>
        ) : null}
      </FieldsetContainer>
    </Container>
  );
};

export default CardConfirmUser;
