import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { ConfirmConference } from 'domain/usecases/conference/remote';
import ConfirmMeet from 'presentation/pages/ConfirmMeet';
import { History } from 'main/routes';
import { closeModal } from 'utils/closeModal';
import { CircularProgress } from '@mui/material';
import { makeRemoteConfirmConference } from '../../usecases/conference/ConfirmConferenceFactory';
import { makeReduxActiveMessage } from '../../usecases/message/Update';

export const MakeConfirmMeetFactory: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<ConfirmConference.Model>();

  const params = useMemo(
    () => ({
      id: new URLSearchParams(search).get('id') as string,
      confirm: new URLSearchParams(search).get('confirm') as
        | 'ACCEPTED'
        | 'DECLINED'
        | 'TENTATIVE',
    }),
    [search],
  );

  useEffect(() => {
    if (params?.id && params?.confirm)
      makeRemoteConfirmConference()
        .confirm({
          confirmCode: params.id,
          confirmation: params.confirm,
        })
        .then(res => {
          toast.success('Confirmação enviada com sucesso!');
          setLoading(false);
          setData(res);
        })
        // eslint-disable-next-line consistent-return
        .catch(err => {
          console.log('...err', err.data);
          switch (err?.data?.internalCode) {
            case 'CONF_001':
              makeReduxActiveMessage().active({
                active: 'error',
                title: 'Erro ao confirmar participação',
                content: 'Membro da conferência não encontrado',
                actionOk: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
                actionCancel: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
              });
              break;
            case 'CONF_002':
              makeReduxActiveMessage().active({
                active: 'error',
                title: 'Erro ao confirmar participação',
                content: 'Conferência iniciada!',
                actionOk: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
                actionCancel: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
              });
              break;
            case 'CONF_003':
              makeReduxActiveMessage().active({
                active: 'error',
                title: 'Erro ao confirmar participação',
                content: 'Conferência cancelada ou finalizada!',
                actionOk: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
                actionCancel: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
              });
              break;
            default:
              makeReduxActiveMessage().active({
                active: 'error',
                title: 'Confirmar partipação',
                content: 'Erro ao enviar confirmação',
                actionOk: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
                actionCancel: () => {
                  closeModal();
                  History.getHistory().push('/');
                },
              });
          }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <ConfirmMeet
          confirmCode={params.id}
          response={params.confirm}
          data={data}
        />
      )}
    </>
  );
};
