import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  BadGateway,
  BadRequestError,
  Forbidden,
  NotFound,
  UnexpectedError,
} from 'domain/errors';
import { GetBillingReportPDF } from 'domain/usecases/billing/remote';
import 'infra/global/variables';

export class RemoteGetBillingReportPDF implements GetBillingReportPDF {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetBillingReportPDF.Model>;

  constructor(url: string, httClient: HttpClient<GetBillingReportPDF.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: GetBillingReportPDF.Params,
  ): Promise<GetBillingReportPDF.Model> => {
    let query = '';

    Object.entries(params.query).forEach(([key, value]) => {
      if (value) query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{orgId}', `${params.orgId}`)}?${query}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="report.pdf"',
      },
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
      case HttpStatusCode.notModified:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
