import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListConferenceFiles as UsecaseRemoteListConferenceFiles } from 'domain/usecases/conferenceFiles/remote';

import { RemoteListConferenceFiles } from 'data/repository/conferenceFiles';

/**
 * send request via API.
 */
export const makeRemoteListConferenceFiles =
  (): UsecaseRemoteListConferenceFiles =>
    new RemoteListConferenceFiles(makeApiUrl('/conferences'), makeHttpClient());
