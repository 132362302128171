import { withAccess } from 'presentation/hooks/access';
import React, { useEffect, useState } from 'react';
import Uploader from '../../uploader';

import { AccessDiv, Container, Img, Initials } from './styles/StyledAvatar';

interface AvatarProps {
  src?: string;
  size?: string;
  fullName?: string;
  showUploadButton?: boolean;
  haveUpdated?: () => void;
  isCircular?: boolean;
  hasBorder?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  size,
  fullName,
  showUploadButton,
  haveUpdated,
  isCircular = true,
  hasBorder = false,
}) => {
  const [error, setError] = useState(false);
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    if (!fullName) return;

    const formatName = fullName.toUpperCase().split(' ');

    if (formatName.length > 1) {
      setInitials(`${formatName[0][0] ?? ''}${formatName[1][0] ?? ''}`);
    }

    if (formatName.length === 1) {
      setInitials(`${formatName[0][0] ?? ''}`);
    }
  }, [fullName]);

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <Container isCircular={isCircular} hasBorder={hasBorder}>
      {error ? (
        <Initials size={size} isCircular={isCircular}>
          {initials}
        </Initials>
      ) : (
        <Img
          src={src}
          size={size}
          isCircular={isCircular}
          onError={e => setError(true)}
        />
      )}
      {showUploadButton && (
        <AccessDiv
          // TODO: Utilizar permissionsV2 para renderização condicional
          resourcers={isCircular ? ['USER'] : ['ORG']}
          actions={
            isCircular
              ? ['UPDATE_UNIT', 'UPDATE_ORG', 'UPDATE_ANY', 'UPDATE_OWN']
              : ['UPDATE_ANY', 'UPDATE_OWN']
          }
        >
          <div id="uploader">
            <Uploader
              setUpdate={setError}
              haveUpdated={() => {
                haveUpdated?.();

                // Wait half second to update profile image
                setTimeout(() => {
                  setError(false);
                }, 500);
              }}
            />
          </div>
        </AccessDiv>
      )}
    </Container>
  );
};

export default withAccess(Avatar);
