import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListByOrgIdOrgUnit as UsecaseRemoteListByOrgIdOrgUnit } from 'domain/usecases/orgUnit/remote';
import { ListOrgUnitByOrgId as UsecaseReduxListOrgUnitByOrgId } from 'domain/usecases/orgUnit/redux';

import { RemoteListByOrgIdOrgUnit } from 'data/repository/orgUnit';
import { ReduxListOrgUnitByOrgId } from 'data/store/reducer/orgUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteListByOrgIdOrgUnit =
  (): UsecaseRemoteListByOrgIdOrgUnit =>
    new RemoteListByOrgIdOrgUnit(
      makeApiUrl('/orgs/{orgId}/units/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUnitByOrgId = (): UsecaseReduxListOrgUnitByOrgId =>
  new ReduxListOrgUnitByOrgId();
