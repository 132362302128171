import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { makeRemoteCreateConferenceNote } from 'main/factories/usecases/conferenceNote/CreateConferenceNoteFactory';
import { toast } from 'react-toastify';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import { ListSacRequestById } from 'domain/usecases/sacRequest/remote';
import { makeRemoteUpdateConferenceNote } from 'main/factories/usecases/conferenceNote/UpdateConferenceNoteFactory';
import {
  Typography,
  IconButton,
  Button,
} from '@wisecare-tech/design-system-web';
import { Editor } from './Editor';
import { Container, Header, Content, Anottation } from './StyledNotes';

interface iNotes {
  onClose: () => void;
}

export const Notes: React.FC<iNotes> = ({ onClose }) => {
  const [note, setNote] = useState('');
  const [requestInfo, setRequestInfo] = useState<ListSacRequestById.Model>();

  const requestId = new URLSearchParams(window.location.search).get('sr');

  const saveNote = useCallback(() => {
    if (requestInfo?.conference?.notes?.length) {
      const { conference } = requestInfo;
      const lastNote = conference?.notes[conference?.notes.length - 1]?.note;

      if (note === lastNote) return;

      if (!note) return;

      makeRemoteUpdateConferenceNote()
        .update({
          short: requestInfo?.conference?.short,
          note: Number(
            requestInfo?.conference?.notes[conference?.notes?.length - 1].id,
          ),
          body: {
            note,
          },
        })
        .then(() => {
          toast.success('Anotação atualizada com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao atualizar anotação');
        });
    } else {
      makeRemoteCreateConferenceNote()
        .create({
          short: requestInfo?.conference?.short ?? '',
          body: {
            note,
          },
        })
        .then(() => {
          toast.success('Anotação salva com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao salvar anotação');
        });
    }
  }, [note, requestInfo]);

  const debouncedSaveNote = _.debounce(saveNote, 5000);

  useEffect(() => {
    if (!requestId) return;

    makeRemoteListSacRequestById()
      .listById({
        request: requestId,
      })
      .then(response => {
        const { conference } = response;

        setRequestInfo(response);

        if (conference?.notes?.length) {
          setNote(conference?.notes[conference?.notes.length - 1]?.note);
        }
      })
      .catch(() => {
        toast.error('Erro ao carregar detalhes da solicitação');
      });
  }, [requestId]);

  useEffect(() => {
    debouncedSaveNote();

    return () => {
      debouncedSaveNote.cancel();
    };
  }, [debouncedSaveNote]);

  return (
    <Container>
      <Header>
        <div />
        <Typography variant="t3_16semibold">Anotações</Typography>

        <IconButton
          icon="close"
          variant="primary"
          size="sm"
          onClick={onClose}
        />
      </Header>

      <Content>
        {requestInfo?.isTransfer && (
          <Anottation>
            <Button
              onClick={() =>
                makeReduxActiveMessage().active({
                  active: 'notesHistory',
                  actionOk: () => closeModal(),
                  actionCancel: () => closeModal(),
                  data: {
                    notes: requestInfo?.conference?.notes,
                  },
                })
              }
              variant="text"
            >
              Ver anotações anteriores
            </Button>
          </Anottation>
        )}

        <Editor value={note} onChange={setNote} />
      </Content>
    </Container>
  );
};
