import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ResendUserEmail as UsecaseRemoteResendUserEmail } from 'domain/usecases/user/remote';
// import { ResendUserEmail as UsecaseReduxResendUserEmail } from 'domain/usecases/user/redux';

import { RemoteResendUserEmail } from 'data/repository/user';
// import { Redux } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeResendUserEmail = (): UsecaseRemoteResendUserEmail =>
  new RemoteResendUserEmail(
    makeApiUrl('/users/email/resend/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
// export const makeReduxUpdateUser = (): UsecaseReduxResendUserEmail =>
//   new ReduxResendUserEmail();
