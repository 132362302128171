import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { ListAdmin } from 'domain/usecases/admin/remote';
import { ListOrgUser } from 'domain/usecases/orgUser/remote';
import { makeRemoteListAdmin } from 'main/factories/usecases/admin/ListAdminFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import {
  IconHealth,
  IconPlusBlack,
  IconSharedLink,
} from 'presentation/base/icons';
import { Icon } from 'presentation/components/icon';
import { ListAdms } from 'presentation/components/list';
import Pagination from 'presentation/components/Pagination';
import { Button } from 'presentation/components/UI';
import Popover from 'presentation/components/UI/popover/Popover';
import { closeModal } from 'utils/closeModal';
import { getOrgUserRole } from 'utils/getRole';
import Translator from 'presentation/components/i18n/Translator';

import {
  Container,
  Content,
  Drop,
  List,
  RowContainer,
} from './styles/StyledSettings';

interface iParams {
  id: string;
}

const Administrators: React.FC = (): JSX.Element => {
  const url = window.location.pathname;
  const [responseOrgUsers, setResponseOrgUsers] = useState<ListAdmin.Model>(
    {} as ListAdmin.Model,
  );
  const [page, setPage] = useState<number>(1);

  const params = useParams<iParams>();
  const { push } = useHistory();

  const itemsPerPage = 15;

  const handleClick = useCallback(() => {
    makeReduxActiveMessage().active({
      active: 'selectBondAdmin',
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  const newAdminOptions = useMemo(
    () => (
      <div>
        <RowContainer
          data-testid="btn-new-admin"
          onClick={() =>
            push(`/admin/user/create?org=${params?.id}`, {
              from: url.replaceAll('/', ''),
            })
          }
        >
          <Icon src={IconPlusBlack} width="25px" height="25px" />
          <Drop>{Translator('Adicionar novo usuário')}</Drop>
        </RowContainer>
        <RowContainer data-testid="btn-add-existing" onClick={handleClick}>
          <Icon src={IconSharedLink} width="25px" height="25px" />
          <Drop>{Translator('Vincular usuário existente')}</Drop>
        </RowContainer>
      </div>
    ),
    [handleClick, params?.id, push, url],
  );
  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;

    const obj = {
      ...responseOrgUsers,
      records: responseOrgUsers.records?.slice(start, start + itemsPerPage),
    };

    return obj;
  }, [responseOrgUsers, page]);

  const { active } = useSelector((store: iStore) => store.message);

  useEffect(() => {
    if (params?.id)
      makeRemoteListAdmin()
        .list({
          query: {
            org: Number(params?.id),
            disablePagination: true,
          },
        })
        .then(response => {
          setResponseOrgUsers(response);
        })
        .catch(e => {
          // toast.error('Não foi possível listar usuários do setor.');
        });
  }, [params?.id, active]);

  return (
    <Container>
      <Content>
        <div>
          <Pagination
            currentPage={page}
            pageSize={Math.ceil(
              responseOrgUsers.records?.length / itemsPerPage,
            )}
            setPage={setPage}
          />
        </div>
        <div>
          <Popover content={newAdminOptions} originX="center" originY="bottom">
            <Button icon={IconHealth} rounded variant="primary" size="medium">
              {Translator('Novo Administrador')}
            </Button>
          </Popover>
        </div>
      </Content>
      <List>
        <ListAdms dataList={displayData} />
      </List>
    </Container>
  );
};

export default Administrators;
