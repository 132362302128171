import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateFreemium as UsecaseRemoteCreateFreemiumOrg } from 'domain/usecases/org/remote';

import { RemoteCreateFreemium } from 'data/repository/org';

/**
 * send request via API.
 */
export const makeRemoteCreateFreemiumOrg = (): UsecaseRemoteCreateFreemiumOrg =>
  new RemoteCreateFreemium(makeApiUrl('/orgs/freemium'), makeHttpClient());
