import styled, { css } from 'styled-components';

interface ContainerProps {
  width: string;
  gridArea?: string;
}

interface InputContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  grid-area: ${props => props.gridArea};
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: lighter;
  padding-left: 16px;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #444a51;
  margin-bottom: 4px;
`;

export const InputContainer = styled.span<InputContainerProps>`
  .react-tel-input .form-control {
    border-radius: 8px;
    /* border-color: ${props => (props.focus ? '#106bef' : '#bfc4ca')}; */
    border: 1px solid #b4b7b9;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #106bef;
    outline: 0;
    box-shadow: none;
  }

  .react-tel-input .selected-flag:focus {
    color: #495057;
    background-color: #fff;
    border-color: #106bef;
    outline: 0;
    box-shadow: none;
  }
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;
