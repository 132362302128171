import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUnitAclFiltered } from 'domain/interfaces/redux/orgUnit/listAclFiltered';
import { ListOrgUnitAclFiltered } from 'domain/usecases/orgUnit/remote/ListOrgUnitAclFiltered';
import { makeRemoteListOrgUnitAclFiltered } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactory';
import {
  listAclFilteredSuccess,
  listAclFilteredFailed,
} from 'data/store/reducer/orgUnits/actions/listAclFiltered';

export function* onListAclFiltered(action: iActionListOrgUnitAclFiltered) {
  const remoteListOrgUnitAclFiltered = makeRemoteListOrgUnitAclFiltered();

  try {
    const response: ListOrgUnitAclFiltered.Model = yield call(
      remoteListOrgUnitAclFiltered.list,
      action.payload,
    );

    yield put(
      listAclFilteredSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listAclFilteredFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredFailed() {}
