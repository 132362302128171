import { ListActionsAudit } from 'domain/usecases/audit/redux/ListActionsAudit';
import {
  iActionListActions,
  iActionListActionsFailed,
  iActionListActionsSuccess,
  AuditTypes,
} from 'domain/interfaces/redux/audit/listActions';

export const listActionsRequest = (
  payload: ListActionsAudit.Params,
): iActionListActions => ({
  type: AuditTypes.LISTACTIONS,
  payload,
});

export const listActionsSuccess = (
  params: ListActionsAudit.Model,
): iActionListActionsSuccess => ({
  type: AuditTypes.LISTACTIONS_SUCCESS,
  payload: params,
});

export const listActionsFailed = (): iActionListActionsFailed => ({
  type: AuditTypes.LISTACTIONS_FAILED,
});
