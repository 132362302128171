/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent */
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { ThemeContext } from 'App';
import { iStore } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteCreatePasswordRecovery } from 'main/factories/usecases/password/CreatePasswordRecoveryFactory';
import { makeRemoteGetUser } from 'main/factories/usecases/user/GetUserFactory';
import { makeResendUserEmail } from 'main/factories/usecases/user/ResendUserEmail';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUserFactory';
import CardConfirmUser from 'presentation/components/cardConfirmUser';
import Translator from 'presentation/components/i18n/Translator';
import { Button } from 'presentation/components/UI';
import { closeModal } from 'utils/closeModal';
import { formattedDate } from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import {
  AdvancedSettingsFields,
  ChangePasswordDiv,
  Container,
} from './styles/StyledAdvancedSettings';

interface ownProps {
  userId: number;
  orgId?: number;
}

const AdvancedSettings: React.FC<ownProps> = ({ userId, orgId }) => {
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles({
    switch: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: `${theme.colors.primary.main}`,
        '&:hover': {
          backgroundColor: `${theme.colors.primary.main}40`,
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: `${theme.colors.primary.main}40`,
      },
    },
  });

  const [userEmail, setUserEmail] = useState<string>();
  const [userLastLogin, setUserLastLogin] = useState<Date>();
  const [userFullname, setUserFullname] = useState<string>();
  const [enableLogin, setEnableLogin] = useState(true);
  const [isGeneralAdmin, setIsGeneralAdmin] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>([] as any);

  const classes = useStyles();
  const { search } = useLocation();

  const user = useSelector((store: iStore) => store.auth.user);
  const { resources } = useSelector((store: iStore) => store.auth.permissions);

  const params = useMemo(
    () => ({
      org: new URLSearchParams(search).get('org'),
      unit: new URLSearchParams(search).get('unit'),
    }),
    [search],
  );

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const disableUpdate = useMemo(() => {
    if (user?.administrator?.system) return false;
    if (!resources) return true;

    // Go to resource USER and check if user have UPDATE permission
    const haveUpdate = resources.find(
      res =>
        res.name === 'USER' &&
        res.actions.find(
          item =>
            item.name === 'UPDATE_UNIT' ||
            item.name === 'UPDATE_ORG' ||
            item.name === 'UPDATE_ANY',
        ),
    );

    return !haveUpdate;
  }, [resources, user?.administrator?.system]);

  const handleEnableLogin = () => {
    const updateEnableLogin = !enableLogin;

    setEnableLogin(updateEnableLogin);

    makeRemoteUpdateUser()
      .update({
        id: Number(userId),
        body: {
          active: updateEnableLogin,
        },
      })
      .then(() => {
        if (updateEnableLogin) {
          toast.success(Translator('Login habilitado com sucesso!'));
        } else {
          toast.success(Translator('Login desabilitado com sucesso!'));
        }
      })
      .catch(err => {
        // toast.error('Não foi possível buscar gravações');
      });
  };

  // eslint-disable-next-line consistent-return
  const handlePasswordRecovery = () => {
    if (!userEmail)
      return toast.error(Translator('Não foi possível enviar e-mail'));

    makeReduxActiveMessage().active({
      active: 'advConfig',
      title: Translator('Redefinir senha'),
      userName: userFullname,
      userMail: userEmail,
      content: Translator(
        'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.',
      ),
      inputContent: false,
      actionOk: () => {
        makeRemoteCreatePasswordRecovery()
          .create({
            body: {
              email: userEmail,
            },
          })
          .then(() => {
            toast.success(Translator('E-mail enviado com sucesso!'));
            closeModal();
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Recuperação de senha'),
              content: Translator(
                'Não foi possível enviar e-mail de recuperação',
              ),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      },
      actionCancel: () => closeModal(),
    });
  };

  const handleIsGeneralAdmin = () => {
    const updateUserRole = !isGeneralAdmin;

    if (updateUserRole) {
      makeReduxActiveMessage().active({
        active: 'advConfig',
        title: Translator('Administrador Geral'),
        userName: userFullname,
        userMail: userEmail,
        content: Translator(
          'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento e na segurança e requer que o usuário tenha experiência e seja treinado para assumir esta função. ',
        ),
        inputContent: true,
        actionOk: () => {
          if (userId && params.org && params.unit)
            setIsGeneralAdmin(updateUserRole);
          makeRemoteUpdateUser()
            .update({
              id: Number(userId),
              body: {
                orgLink: {
                  org: Number(params.org),
                  orgUnit: Number(params.unit),
                  sysAdmin: updateUserRole,
                },
              },
            })
            .then(() => {
              toast.success(Translator('Usuário promovido com sucesso!'));
              closeModal();
            })
            .catch(err => {
              // toast.error('Não foi possível buscar gravações');
            });
        },
        actionCancel: () => closeModal(),
      });
    } else if (!updateUserRole) {
      makeReduxActiveMessage().active({
        active: 'advConfig',
        title: Translator('Administrador Geral'),
        userName: userFullname,
        userMail: userEmail,
        content: Translator(
          'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.',
        ),
        inputContent: true,
        actionOk: () => {
          if (userId && params.org && params.unit)
            setIsGeneralAdmin(updateUserRole);

          makeRemoteUpdateUser()
            .update({
              id: Number(userId),
              body: {
                orgLink: {
                  org: Number(params.org),
                  orgUnit: Number(params.unit),
                  sysAdmin: updateUserRole,
                },
              },
            })
            .then(() => {
              toast.success(
                Translator('Usuário não é mais administrador do sistema!'),
              );
              closeModal();
            })
            .catch(err => {
              // toast.error('Não foi possível buscar gravações');
            });
        },
        actionCancel: () => closeModal(),
      });
    }
  };

  // eslint-disable-next-line consistent-return

  useEffect(() => {
    makeRemoteGetUser()
      .get({ id: userId })
      .then(res => {
        console.log('res: ', res);

        setUserEmail(res.email);
        setSelectedUser([res]);
        if (res.orgUsers?.[0].lastAccess)
          setUserLastLogin(new Date(res.orgUsers?.[0].lastAccess));
        setUserFullname(res.fullName);
        setEnableLogin(res.active);
        setIsGeneralAdmin(res.sysAdmin);
      })
      .catch(() => {
        // toast.error(Translator('Não foi possível buscar usuário.'));
      });
  }, [userId]);

  // console.log('ultimo login: ', selectedUser);

  return (
    <Container>
      <div
        style={{
          width: '100%',
          alignItems: 'left',
          marginTop: '20px',
        }}
      >
        <AdvancedSettingsFields>
          <span>{`${Translator('Login habilitado')}:`}</span>
          <FormControlLabel
            data-testid="form-switch-user-active"
            control={
              <Switch
                data-testid="switch-user-active"
                className={classes.switch}
                inputProps={{
                  'aria-label': `${
                    enableLogin
                      ? Translator('Ativado')
                      : Translator('Desativado')
                  }`,
                }}
                checked={enableLogin}
                value={enableLogin}
                onChange={handleEnableLogin}
                disabled={disableUpdate}
                defaultChecked
              />
            }
            label={`${enableLogin ? Translator('Sim') : Translator('Nao')}`}
          />
        </AdvancedSettingsFields>
        <AdvancedSettingsFields>
          <span>{`${Translator('Administrador Geral')}:`}</span>
          {role === 'SYSADMIN' ? (
            <FormControlLabel
              control={
                <Switch
                  data-testid="switch-user-active"
                  className={classes.switch}
                  inputProps={{
                    'aria-label': `${
                      isGeneralAdmin
                        ? Translator('Ativado')
                        : Translator('Desativado')
                    }`,
                  }}
                  checked={isGeneralAdmin}
                  value={isGeneralAdmin}
                  onChange={handleIsGeneralAdmin}
                  disabled={!user?.administrator.system}
                  defaultChecked
                />
              }
              label={`${
                isGeneralAdmin ? Translator('Sim') : Translator('Nao')
              }`}
            />
          ) : (
            <FormControlLabel
              disabled
              control={
                <Switch
                  data-testid="switch-user-active"
                  className={classes.switch}
                  inputProps={{
                    'aria-label': `${
                      isGeneralAdmin
                        ? Translator('Ativado')
                        : Translator('Desativado')
                    }`,
                  }}
                  checked={isGeneralAdmin}
                  value={isGeneralAdmin}
                  defaultChecked
                />
              }
              label={`${
                isGeneralAdmin ? Translator('Sim') : Translator('Nao')
              }`}
            />
          )}
        </AdvancedSettingsFields>
        <ChangePasswordDiv>
          <span>{`${Translator('Redefinição de senha')}:`}</span>
          <Button
            data-testid="btn-reset-password"
            size="medium"
            onClick={handlePasswordRecovery}
            disabled={disableUpdate}
          >
            {Translator('Redefinir senha')}
          </Button>
        </ChangePasswordDiv>
        <CardConfirmUser user={selectedUser} />
      </div>
    </Container>
  );
};

export default AdvancedSettings;
