import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { JoinConference as UsecaseRemoteJoinConference } from 'domain/usecases/conference/remote';
// import { JoinConference as UsecaseReduxJoinConference } from 'domain/usecases/conference/redux';

import { RemoteJoinConference } from 'data/repository/conference';
// import { ReduxJoinConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinConference = (): UsecaseRemoteJoinConference =>
  new RemoteJoinConference(
    makeApiUrl('/conferences/{conferenceShort}/join/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinConference =
  (): UsecaseReduxJoinConference =>
    new ReduxJoinConference(); */
