import { ListOrgUnitByOrgId } from 'domain/usecases/orgUnit/redux/ListOrgUnitByOrgId';
import {
  iActionListOrgUnitByOrgId,
  iActionListOrgUnitByOrgIdFailed,
  iActionListOrgUnitByOrgIdSuccess,
  OrgUnitTypes,
} from 'domain/interfaces/redux/orgUnit/listByOrgId';

export const listRequest = (
  payload: ListOrgUnitByOrgId.Params,
): iActionListOrgUnitByOrgId => ({
  type: OrgUnitTypes.LIST_BY_ORGID,
  payload,
});

export const listSuccess = (
  params: ListOrgUnitByOrgId.Model,
): iActionListOrgUnitByOrgIdSuccess => ({
  type: OrgUnitTypes.LIST_BY_ORGID_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUnitByOrgIdFailed => ({
  type: OrgUnitTypes.LIST_BY_ORGID_FAILED,
});
