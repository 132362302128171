/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as dateFns from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconFullScreen } from 'presentation/base/icons';
import Select from 'presentation/components/UI/select';

import { iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import { getRole } from 'utils/getRole';
import Translator from '../i18n/Translator';
import {
  Container,
  Content,
  FullScreen,
  Left,
  Right,
} from './styles/StylePageHeader';

// import { IconBack } from 'presentation/base/icons';

interface ownProps {
  org: string;
  unit: string;
  period: string;
  dateFrom: string;
  disableSelect?: boolean;
  setOrg: (orgs: string) => void;
  setUnit: (unit: string) => void;
  setPeriod: (period: string) => void;
  setDateFrom: (dateFrom: string) => void;
}

interface listOrgsProps {
  [key: string]: {
    name: string;
    id: number;
    units: { name: string; id: number }[];
  };
}

const PageHeader: React.FC<ownProps> = ({
  org,
  setOrg,
  unit,
  setUnit,
  period,
  setPeriod,
  dateFrom,
  disableSelect,
  setDateFrom,
}): JSX.Element => {
  const { records } = useSelector((store: iStore) => store.orgUnits);
  const userRedux = useSelector((store: iStore) => store.auth.user);

  const [listOrgs, setListOrgs] = useState<listOrgsProps>({});
  const [isFullscreen, setIsFullscreen] = useState(false);

  const role = useMemo(
    () =>
      userRedux?.administrator
        ? getRole(
            userRedux.administrator,
            userRedux.org.id,
            userRedux.orgUnit.id,
          )
        : 'STANDARD',
    [userRedux?.administrator, userRedux?.org.id, userRedux?.orgUnit.id],
  );

  const requestFullscreen = useCallback(() => {
    try {
      const anyDocument = document as any;

      if (document.body.requestFullscreen && !document.fullscreenElement) {
        document.body.requestFullscreen();
      } else if (
        anyDocument.body.mozRequestFullScreen &&
        !anyDocument.mozFullScreenElement
      ) {
        anyDocument.body.mozRequestFullScreen();
      } else if (
        anyDocument.body.webkitRequestFullscreen &&
        !anyDocument.webkitFullscreenElement
      ) {
        anyDocument.body.webkitRequestFullscreen(); /// work on ipad
      } else if (
        anyDocument.body.msRequestFullscreen &&
        !anyDocument.msFullscreenElement
      ) {
        anyDocument.body.msRequestFullscreen();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    const anyDocument = document as any;

    try {
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else if (
        anyDocument.mozCancelFullScreen &&
        anyDocument.mozFullScreenElement
      ) {
        anyDocument.mozCancelFullScreen();
      } else if (
        anyDocument.webkitExitFullscreen &&
        anyDocument.webkitFullscreenElement
      ) {
        anyDocument.webkitExitFullscreen();
      } else if (
        anyDocument.msExitFullscreen &&
        anyDocument.msFullscreenElement
      ) {
        anyDocument.msExitFullscreen();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleFullscreen = useCallback(() => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }

    setIsFullscreen(!isFullscreen);
  }, [exitFullscreen, isFullscreen, requestFullscreen]);

  useEffect(() => {
    const auxlistOrgs: listOrgsProps = {};

    records?.forEach(el => {
      if (el.org === undefined) return;

      if (auxlistOrgs[`${el.org.id}`]) {
        auxlistOrgs[`${el.org.id}`].units.push({
          name: el.name,
          id: el.id,
        });
      } else {
        auxlistOrgs[`${el.org.id}`] = {
          name: el.org.name,
          id: el.org.id,
          units: [{ name: el.name, id: el.id }],
        };
      }
    });

    setListOrgs(auxlistOrgs);
  }, [records]);

  useEffect(() => {
    if (role !== 'SYSADMIN' && Object.values(listOrgs).length) {
      setOrg(Object.values(listOrgs)[0].id.toString());
    }
  }, [listOrgs, role, setOrg]);

  return (
    <Container>
      <Content>
        <Left>
          <div className="t">
            <span>{Translator('Painel de reuniões')}</span>
          </div>
          <span className="title">{`${Translator('Organização')}:`}</span>
          <Select
            width="300px"
            height="40px"
            value={org}
            disabled={disableSelect}
            onChange={e => {
              setOrg(e.target.value);
              setUnit('All');
            }}
          >
            {role === 'SYSADMIN' && (
              <option value="All">{Translator('Todas as organizações')}</option>
            )}
            {Object.values(listOrgs).map(el => {
              return <option value={el.id}>{el.name}</option>;
            })}
          </Select>
          <span className="title">{`${Translator('Setor')}:`}</span>
          <Select
            width="170px"
            height="40px"
            value={unit}
            disabled={disableSelect}
            onChange={e => {
              setUnit(e.target.value);
            }}
          >
            <option value="All">{Translator('Todos os setores')}</option>
            {listOrgs[org]?.units.map(el => {
              return <option value={el.id}>{el.name}</option>;
            })}
          </Select>
          <span className="title">{`${Translator('Período')}:`}</span>
          <Select
            width="170px"
            height="40px"
            value={period}
            onChange={e => {
              switch (e.target.value) {
                case 'All':
                  setPeriod('All');
                  setDateFrom('');
                  break;
                case 'lastYear':
                  setPeriod('lastYear');
                  setDateFrom(
                    dateFns.format(
                      dateFns.subYears(new Date(), 1),
                      'yyyy-MM-dd',
                    ),
                  );
                  break;
                case 'lastMonth':
                  setPeriod('lastMonth');
                  setDateFrom(
                    dateFns.format(
                      dateFns.subMonths(new Date(), 1),
                      'yyyy-MM-dd',
                    ),
                  );
                  break;

                case 'today':
                  setPeriod('today');
                  setDateFrom(
                    `${dateFns.format(new Date(), 'yyyy-MM-dd')}T00:00`,
                  );
                  break;
                default:
                  break;
              }
            }}
          >
            <option value="All">{Translator('Geral')}</option>
            <option value="lastYear">{Translator('Últimos 12 meses')}</option>
            <option value="lastMonth">{Translator('Últimos 30 dias')}</option>
            <option value="today">{Translator('Hoje')}</option>
          </Select>
        </Left>
        <Right>
          <FullScreen id="fullscreen_button" onClick={handleFullscreen}>
            <IconFullScreen />
          </FullScreen>
        </Right>
      </Content>
    </Container>
  );
};

export default PageHeader;
