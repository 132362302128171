import * as yup from 'yup';
import { validateCpf } from 'utils/validCPF';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const availableAttendantSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'O nome deve conter no mínimo 3 caracteres')
    .max(255, 'O nome deve conter no máximo 255 caracteres')
    .required('O nome é obrigatório'),
  email: yup
    .string()
    .email('E-mail inválido')
    .required('O e-mail é obrigatório')
    .test('checkEmail', 'O e-mail é inválido', value => {
      if (!isValidEmail(value as string)) return false;
      return true;
    }),
  phone: yup
    .string()
    .min(11, 'Telefone inválido')
    .max(11, 'O telefone deve conter no máximo 16 caracteres')
    .required('O telefone é obrigatório'),
  docValue: yup
    .string()
    .min(11, 'CPF inválido')
    .max(11, 'CPF inválido')
    .required('O CPF é obrigatório')
    .test('checkCPF', 'CPF inválido', (value: string | undefined): boolean => {
      if (!value) return true;
      return validateCpf(value) as boolean;
    }),
  descr: yup
    .string()
    .required('A descrição é obrigatória')
    .max(1024, 'A descrição deve conter no máximo 1024 caracteres'),
});
