import { SetupConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux/SetupConferenceWaitingRoom';
import {
  iActionSetup,
  ConferenceWaitingRoomTypes,
} from 'domain/interfaces/redux/conferenceWaitingRoom/setup';

export const setupRequest = (
  payload: SetupConferenceWaitingRoom.Params,
): iActionSetup => ({
  type: ConferenceWaitingRoomTypes.SETUP,
  payload,
});
