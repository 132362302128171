import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';

export const addedMaskToPhones = (
  phones: GetPublicTopicById.Model['contactPhones'] = [],
): string => {
  return phones
    ?.map(phone => {
      const phoneFormatted = phone.replace('+55', '');

      return `(${phoneFormatted.slice(0, 2)}) ${phoneFormatted.slice(
        2,
        7,
      )}-${phoneFormatted.slice(7, 11)}`;
    })
    .join(' | ');
};
