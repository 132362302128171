import { call, put, select } from 'redux-saga/effects';
import { iActionListFilteredConference } from 'domain/interfaces/redux/conference/listFiltered';
import { ListFilteredConference } from 'domain/usecases/conference/remote/ListFilteredConference';
import { makeRemoteListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import {
  listFilteredConferenceSuccess,
  listFilteredConferenceFailed,
} from 'data/store/reducer/conference/actions/listFiltered';

export function* onListFilteredConference(
  action: iActionListFilteredConference,
) {
  const remoteListFilteredConference = makeRemoteListFilteredConference();

  try {
    const response: ListFilteredConference.Model = yield call(
      remoteListFilteredConference.list,
      action.payload,
    );
    yield put(
      listFilteredConferenceSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFilteredConferenceFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredConferenceSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredConferenceFailed() {}
