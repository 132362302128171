import React from 'react';

import { Container } from './styles/StyledOrgs';
import OrgDetails from '../AdmForms';

interface ownProps {
  orgId?: number;
  editing?: boolean;
  // TODO: Remove after everything is on Redux
  haveUpdated?: () => void;
}
const OrgPage: React.FC<ownProps> = ({
  orgId,
  editing,
  haveUpdated,
}): JSX.Element => {
  return (
    <Container>
      <OrgDetails idOrg={orgId} editing={editing} haveUpdated={haveUpdated} />
    </Container>
  );
};

export default OrgPage;
