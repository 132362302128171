import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteConferenceFiles as UsecaseRemoteDeleteConferenceFiles } from 'domain/usecases/conferenceFiles/remote';

import { RemoteDeleteConferenceFiles } from 'data/repository/conferenceFiles';

/**
 * send request via API.
 */
export const makeRemoteDeleteConferenceFiles =
  (): UsecaseRemoteDeleteConferenceFiles =>
    new RemoteDeleteConferenceFiles(
      makeApiUrl('/conferences'),
      makeHttpClient(),
    );
