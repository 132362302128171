import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  min-height: 18.3rem;

  h2 {
    margin-top: 30px;
  }
`;

export const ContentFields = styled.div`
  width: 100%;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

export const Fields = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 20px;
`;

export const ContentInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  margin-top: 30px;
`;

export const ContentButton = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  gap: 1rem;

  padding-top: 40px;
`;
