import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteOrgUnit as UsecaseRemoteDeleteOrgUnit } from 'domain/usecases/orgUnit/remote';
// import { DeleteOrgUnit as UsecaseReduxDeleteOrgUnit } from 'domain/usecases/orgUnit/redux';

import { RemoteDeleteOrgUnit } from 'data/repository/orgUnit';
// import { ReduxDeleteOrgUnit } from 'data/store/reducer/orgUnit/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteOrgUnit = (): UsecaseRemoteDeleteOrgUnit =>
  new RemoteDeleteOrgUnit(
    makeApiUrl('/orgs/{orgId}/units/{unitId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteOrgUnit =
  (): UsecaseReduxDeleteOrgUnit =>
    new ReduxDeleteOrgUnit(); */
