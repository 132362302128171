import { ListOrgUserByOrgId } from 'domain/usecases/orgUser/redux/ListOrgUserByOrgId';
import {
  iActionListOrgUserByOrgId,
  iActionListOrgUserByOrgIdFailed,
  iActionListOrgUserByOrgIdSuccess,
  OrgUserTypes,
} from 'domain/interfaces/redux/orgUser/listByOrgId';

export const listRequest = (
  payload: ListOrgUserByOrgId.Params,
): iActionListOrgUserByOrgId => ({
  type: OrgUserTypes.LIST_BY_ORGID,
  payload,
});

export const listSuccess = (
  params: ListOrgUserByOrgId.Model,
): iActionListOrgUserByOrgIdSuccess => ({
  type: OrgUserTypes.LIST_BY_ORGID_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUserByOrgIdFailed => ({
  type: OrgUserTypes.LIST_BY_ORGID_FAILED,
});
