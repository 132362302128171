import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateByOrgAdmin as UsecaseRemoteCreateByOrgAdmin } from 'domain/usecases/admin/remote';
// import { CreateByOrgAdmin as UsecaseReduxCreateByOrgAdmin } from 'domain/usecases/admin/redux';

import { RemoteCreateByOrgAdmin } from 'data/repository/admin';
// import { ReduxCreateByOrgAdmin } from 'data/store/reducer/admin/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateByOrgAdmin = (): UsecaseRemoteCreateByOrgAdmin =>
  new RemoteCreateByOrgAdmin(
    makeApiUrl('/orgs/{orgId}/admins/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateByOrgAdmin =
  (): UsecaseReduxCreateByOrgAdmin =>
    new ReduxCreateByOrgAdmin(); */
