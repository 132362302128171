import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 24px;
`;

export const Subject = styled.div`
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #ffffff;
`;

export const SubjectAndHour = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  color: #656a6e;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const ContainerForm = styled.div`
  width: 798px;
  border: 1px solid #dedede;
  background: #ffffff;
  border-radius: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 24px;
`;

export const InlineForms = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
`;

export const FormButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
`;

export const NoAttenddantContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  padding: 53px 0;
`;

export const EmptyAttendant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const IconNoAttendant = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  border: 1px solid #dedede;
  padding: 24px;
  margin-bottom: 16px;
`;

export const NotAttendantInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PhonesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContactTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: #656a6e;
  margin-bottom: 8px;
`;

export const PhoneNumber = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #656a6e;
  display: flex;
  width: 45%;
  align-items: center;
  flex-wrap: wrap;
`;

export const Email = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #656a6e;
`;

export const EmailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
`;
