import styled from 'styled-components';

interface ownProps {
  disabledToolbar?: boolean;
}

export const EditorContainer = styled.div<ownProps>`
  display: flex;
  flex-direction: column;
  height: 75vh;
  border-radius: ${({ disabledToolbar }) =>
    disabledToolbar ? '6px' : '4px 4px 0 0'};
  border: 1px solid #b4b7b9;
  opacity: ${({ disabledToolbar }) => (disabledToolbar ? 0.5 : 1)};

  @media (min-width: 1024px) and (max-width: 1366px) {
    height: 70vh;
  }
`;

export const Container = styled.div`
  width: 410px;
  height: 100%;
  border: 1px solid #dedede;
  background-color: #ffffff;

  .ql-toolbar {
    padding: 0 !important;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #b4b7b9 !important;
    border-radius: 4px 4px 0 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ql-formats {
    margin-right: 0 !important;
    border-right: 1px solid #eaecf0 !important;
    padding: 10px 0 !important;
    :last-child {
      border-right: none !important;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22.5px 24px 22.5px 0;
  border-bottom: 1px solid #dedede;
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const Anottation = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;
