import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateByOrgUnitAdmin as UsecaseRemoteCreateByOrgUnitAdmin } from 'domain/usecases/admin/remote';
// import { CreateByOrgUnitAdmin as UsecaseReduxCreateByOrgUnitAdmin } from 'domain/usecases/admin/redux';

import { RemoteCreateByOrgUnitAdmin } from 'data/repository/admin';
// import { ReduxCreateByOrgUnitAdmin } from 'data/store/reducer/admin/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateByOrgUnitAdmin =
  (): UsecaseRemoteCreateByOrgUnitAdmin =>
    new RemoteCreateByOrgUnitAdmin(
      makeApiUrl('/orgs/{orgId}/units/{unitId}/admins/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateByOrgUnitAdmin =
  (): UsecaseReduxCreateByOrgUnitAdmin =>
    new ReduxCreateByOrgUnitAdmin(); */
