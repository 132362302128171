import { Dispatch } from 'redux';
import store from 'data/store';
import { listAclFilteredV2Request } from 'data/store/reducer/orgUnits/actions/listAclFilteredV2';

import { iActionListOrgUnitAclFilteredV2 } from 'domain/interfaces/redux/orgUnit/listAclFilteredV2';

import { ListOrgUnitAclFilteredV2 } from 'domain/usecases/orgUnit/redux';

export class ReduxListOrgUnitAclFilteredV2 implements ListOrgUnitAclFilteredV2 {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (
    params: ListOrgUnitAclFilteredV2.Params,
  ): iActionListOrgUnitAclFilteredV2 =>
    this.send(listAclFilteredV2Request(params));
}
