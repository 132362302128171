import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBillingDetailed as UsecaseRemoteGetBillingDetailed } from 'domain/usecases/billing/remote';

import { RemoteGetBillingDetailed } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBillingDetailed =
  (): UsecaseRemoteGetBillingDetailed =>
    new RemoteGetBillingDetailed(
      makeApiUrl('/orgs/billing/detailed/'),
      makeHttpClient(),
    );
