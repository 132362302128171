import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateSacRequest as UsecaseRemoteCreateSacRequest } from 'domain/usecases/sacRequest/remote';
// import { CreateSacRequest as UsecaseReduxCreateSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteCreateSacRequest } from 'data/repository/sacRequest';
// import { ReduxCreateSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateSacRequest = (): UsecaseRemoteCreateSacRequest =>
  new RemoteCreateSacRequest(makeApiUrl('/sac/requests'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateSacRequest =
  (): UsecaseReduxCreateSacRequest =>
    new ReduxCreateSacRequest(); */
