import { iSacRequest } from 'domain/interfaces/models/SacRequest';
import { SacRequestTypes } from 'domain/interfaces/redux/sacRequest/types';
import { SacRequestActions } from './actions';

export const initialState: iSacRequest = {
  loading: false,
  records: [],
  metadata: undefined,
};

const reducer = (
  state = initialState,
  action: SacRequestActions,
): iSacRequest => {
  switch (action.type) {
    case SacRequestTypes.LIST:
      return { ...state, loading: true };
    case SacRequestTypes.LIST_FAILED:
      return { ...state, loading: false };
    case SacRequestTypes.LIST_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
