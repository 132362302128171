import styled from 'styled-components';
import { IconCloseButtonModal } from 'presentation/base/icons';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 530px;
  max-width: 530px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #dedede;
`;

export const InformationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #222529;
`;

export const Subtitle = styled.span`
  width: 80%;
  font-size: 16px;
  font-weight: 400;
  color: #656a6e;
`;

export const Subject = styled.form`
  display: flex;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
`;

export const Footer = styled.div`
  padding: 16px 24px 16px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  ::after {
    content: '*';
    color: #ff0000;
  }
`;

export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #ff0000;
  gap: 8px;
`;
