import React, { useEffect } from 'react';

import { MyConferences } from 'presentation/pages';
import { iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import { makeReduxListMyConferences } from '../../usecases/myConferences/ListMyConferencesFactory';

export const MakeMyConferencesFactory: React.FC = (): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    if (user?.id) {
      makeReduxListMyConferences().list({
        owner: user?.id,
        limit: 15,
      });
    }
  }, [user]);

  return <MyConferences />;
};
