import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ConfirmConference as UsecaseRemoteConfirmConference } from 'domain/usecases/conference/remote';
// import { ConfirmConference as UsecaseReduxConfirmConference } from 'domain/usecases/conference/redux';

import { RemoteConfirmConference } from 'data/repository/conference';
// import { ReduxConfirmConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteConfirmConference = (): UsecaseRemoteConfirmConference =>
  new RemoteConfirmConference(
    makeApiUrl('/conferences/{confirmCode}/confirm/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxConfirmConference =
  (): UsecaseReduxConfirmConference =>
    new ReduxConfirmConference(); */
