import { call, put, select } from 'redux-saga/effects';
import { iActionListAclFilteredV2 } from 'domain/interfaces/redux/org/listAclFilteredV2';
import { ListOrgAclFilteredV2 } from 'domain/usecases/org/remote/ListOrgAclFilteredV2';
import { makeRemoteListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import {
  listAclFilteredV2Success,
  listAclFilteredV2Failed,
} from 'data/store/reducer/org/actions/listAclFilteredV2';

export function* onListAclFilteredV2(action: iActionListAclFilteredV2) {
  const remoteListOrgAclFilteredV2 = makeRemoteListOrgAclFilteredV2();

  try {
    const response: ListOrgAclFilteredV2.Model = yield call(
      remoteListOrgAclFilteredV2.list,
      action.payload,
    );
    yield put(
      listAclFilteredV2Success({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listAclFilteredV2Failed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredV2Success() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListAclFilteredV2Failed() {}
