import React, { useState, useMemo } from 'react';
import HeaderMenu from 'presentation/components/MenuNavigation/MenuNavigation';
import { Container, Content } from './styles/StyledSettings';
import Parameters from './Parameters';
import Admistrators from './Administrators';

const SettingsPage: React.FC = (): JSX.Element => {
  const [tabNavigation, setTabNavigation] = useState(0);

  const RenderSubPagesTab = useMemo(() => {
    const options = ['Parâmetros', 'Administradores'];

    const stepIndex: Record<number, JSX.Element> = {
      0: <Parameters />,
      1: <Admistrators />,
    };

    return (
      <div style={{ width: '100%' }}>
        <HeaderMenu
          options={options}
          setState={setTabNavigation}
          active={tabNavigation}
        />
        {stepIndex[tabNavigation] || <div />}
      </div>
    );
  }, [tabNavigation]);

  return (
    <Container>
      <Content>{RenderSubPagesTab}</Content>
    </Container>
  );
};

export default SettingsPage;
