import { Skeleton } from '@mui/material';
import React, { ReactNode } from 'react';
import { pxToRem } from 'utils/pixelToRem';
import { Counter as CounterStyle } from './styles';

interface CounterProps {
  loading?: boolean;
  children?: ReactNode;
}

export const Counter: React.FC<CounterProps> = ({ loading, children }) => {
  // const { loading, result } = useSelector(
  //   (store: iStore) => store.dashboardOrg,
  // );
  return (
    <div>
      {loading ? (
        <Skeleton variant="text" width={pxToRem(100)} height={pxToRem(60)} />
      ) : (
        <CounterStyle>{children}</CounterStyle>
      )}
    </div>
  );
};
