import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetConferenceHistoryDashboard as UsecaseRemoteGetConferenceHistoryDashboard } from 'domain/usecases/dashboard/remote';
// import { GetConferenceHistoryDashboard as UsecaseReduxGetConferenceHistoryDashboard } from 'domain/usecases/dashboard/redux';

import { RemoteGetConferenceHistoryDashboard } from 'data/repository/dashboard';
// import { ReduxGetConferenceHistoryDashboard } from 'data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetConferenceHistoryDashboard =
  (): UsecaseRemoteGetConferenceHistoryDashboard =>
    new RemoteGetConferenceHistoryDashboard(
      makeApiUrl('/dashboard/conference/history'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetConferenceHistoryDashboard =
  (): UsecaseReduxGetConferenceHistoryDashboard =>
    new ReduxGetConferenceHistoryDashboard(); */
