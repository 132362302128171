import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UserConfirmation as UsecaseRemoteUserConfirmation } from 'domain/usecases/user/remote';
// import { ResendUserEmail as UsecaseReduxResendUserEmail } from 'domain/usecases/user/redux';

import { RemoteUserConfirmation } from 'data/repository/user';
// import { Redux } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteUserConfirmationPassword =
  (): UsecaseRemoteUserConfirmation =>
    new RemoteUserConfirmation(
      makeApiUrl('/users/confirmation/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
// export const makeReduxUserConfirmation = (): UsecaseReduxResendUserEmail =>
//   new ReduxUserConfirmation();
