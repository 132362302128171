import { takeLatest } from 'redux-saga/effects';
import { AuthTypes } from 'domain/interfaces/redux/auth/types';
import { RefreshAuth } from 'domain/usecases/auth/remote';
import { onLogin, onLoginSuccess, onLoginFailed } from './Login';
import { onRefresh, onRefreshSuccess, onRefreshFailed } from './Refresh';
import { onLogout, onLogoutSuccess, onLogoutFailed } from './Logout';
import { onGuest, onGuestSuccess, onGuestFailed } from './Guest';

const tasks = [
  takeLatest(AuthTypes.LOGIN, onLogin),
  takeLatest(AuthTypes.LOGIN_SUCCESS, onLoginSuccess),
  takeLatest(AuthTypes.LOGIN_FAILED, onLoginFailed),
  takeLatest(AuthTypes.REFRESH, onRefresh),
  takeLatest(AuthTypes.REFRESH_SUCCESS, onRefreshSuccess),
  takeLatest(AuthTypes.REFRESH_FAILED, onRefreshFailed),
  takeLatest(AuthTypes.LOGOUT, onLogout),
  takeLatest(AuthTypes.LOGOUT_SUCCESS, onLogoutSuccess),
  takeLatest(AuthTypes.LOGOUT_FAILED, onLogoutFailed),
  takeLatest(AuthTypes.GUEST, onGuest),
  takeLatest(AuthTypes.GUEST_SUCCESS, onGuestSuccess),
  takeLatest(AuthTypes.GUEST_FAILED, onGuestFailed),
];

export default tasks;
