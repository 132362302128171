import { ListOrgUnitByOrgUnitId } from 'domain/usecases/orgUnit/redux/ListOrgUnitByOrgUnitId';
import {
  iActionListOrgUnitByOrgUnitId,
  iActionListOrgUnitByOrgUnitIdFailed,
  iActionListOrgUnitByOrgUnitIdSuccess,
  OrgUnitTypes,
} from 'domain/interfaces/redux/orgUnit/listByOrgUnitId';

export const listRequest = (
  payload: ListOrgUnitByOrgUnitId.Params,
): iActionListOrgUnitByOrgUnitId => ({
  type: OrgUnitTypes.LIST_BY_ORGUNITID,
  payload,
});

export const listSuccess = (
  params: ListOrgUnitByOrgUnitId.Model,
): iActionListOrgUnitByOrgUnitIdSuccess => ({
  type: OrgUnitTypes.LIST_BY_ORGUNITID_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUnitByOrgUnitIdFailed => ({
  type: OrgUnitTypes.LIST_BY_ORGUNITID_FAILED,
});
