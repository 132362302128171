import { Dispatch } from 'redux';
import store from 'data/store';
import { updateControllerRequest } from 'data/store/reducer/conference/actions/updateController';

import { iActionUpdateController } from 'domain/interfaces/redux/conference/updateController';

import { UpdateControllerConference } from 'domain/usecases/conference/redux';

export class ReduxUpdateControllerConference
  implements UpdateControllerConference
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  updateController = (
    params: UpdateControllerConference.Params,
  ): iActionUpdateController => this.send(updateControllerRequest(params));
}
