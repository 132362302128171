/* eslint-disable @typescript-eslint/no-empty-function */
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { OrgInfos } from 'domain/usecases/org/remote';
import { Header, HeaderBack } from 'presentation/components/header';
import { OrgNavigator } from 'presentation/components/OrgNavigator';
import { createOrgUnitSchema } from 'validation/orgunit/CreateOrgUnitValidation';
import SectorDetails from '../Admin/Sectors/SectorDetails';
import { Body, Container, Left, Right } from './styles/StyledCreateOrg';

interface iParams {
  id: string;
}

export interface initialValuesOrgUnit {
  name: string;
  shortname: string;
}

const CreateUserPage: React.FC = () => {
  const [navigation, setNavigation] = useState<number>(2);
  const [selectedOrg, setSelectedOrg] = useState<OrgInfos>({} as OrgInfos);
  const responseOrgs = useSelector((store: iStore) => store.org);

  const params = useParams<iParams>();
  const { pathname } = useLocation();

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 2:
        return <SectorDetails editing={false} />;
      default:
        return <div />;
    }
  }, [navigation]);

  const { push, goBack } = useHistory();

  const initialValuesOrgUnit = {
    name: '',
    shortname: '',
  };

  useEffect(() => {
    if (Number(params?.id)) {
      const foundOrg = responseOrgs?.records?.find(
        item => item.id === Number(params?.id),
      );

      if (foundOrg) setSelectedOrg(foundOrg);
    }
  }, [params, pathname, responseOrgs?.records]);

  return (
    <Formik
      validationSchema={createOrgUnitSchema}
      initialValues={initialValuesOrgUnit}
      onSubmit={() => {}}
      validateOnChange
      isInitialValid
    >
      {() => (
        <Container>
          <Header title="teste" />
          <HeaderBack
            onBack={() => goBack()}
            backDescription="Voltar a organização"
            title="Criação de setor"
          />
          <Body>
            <Left>
              <OrgNavigator
                active={navigation}
                nav={setNavigation}
                creating={2}
                org={selectedOrg}
              />
            </Left>
            <Right>{RenderSubPages}</Right>
          </Body>
        </Container>
      )}
    </Formik>
  );
};

export default CreateUserPage;
