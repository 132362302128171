import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { iMessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { Button } from 'presentation/components/UI';
import { closeModal } from 'utils/closeModal';

interface ModalsProps {
  active: iMessageOptions;
}

interface ToastProps {
  type: 'dark' | 'error' | 'info' | 'success' | 'warn';
}

const ModalsTeste: React.FC<ModalsProps> = ({ active }) => {
  const handleClick = useCallback((type: ToastProps) => {
    toast[type.type]('teste');
  }, []);

  useEffect(() => {
    makeReduxActiveMessage().active({
      active,
      // actionCancel: () => closeModal(),
      // actionOk: () => closeModal(),
      content: 'teste',
      userId: -1,
      userMail: 'teste',
      userName: 'teste',
      inputContent: 'teste',
      title: 'teste',
    });
  }, [active]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'dark' });
        }}
      >
        Toast dark
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'error' });
        }}
      >
        Toast error
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'info' });
        }}
      >
        Toast info
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'success' });
        }}
      >
        Toast success
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'warn' });
        }}
      >
        Toast warn
      </Button>
    </div>
  );
};

export default ModalsTeste;
