import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteConference as UsecaseRemoteDeleteConference } from 'domain/usecases/conference/remote';
// import { DeleteConference as UsecaseReduxDeleteConference } from 'domain/usecases/conference/redux';

import { RemoteDeleteConference } from 'data/repository/conference';
// import { ReduxDeleteConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteConference = (): UsecaseRemoteDeleteConference =>
  new RemoteDeleteConference(
    makeApiUrl('/conferences/{conferenceId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteConference =
  (): UsecaseReduxDeleteConference =>
    new ReduxDeleteConference(); */
