import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetOrg as UsecaseRemoteGetOrg } from 'domain/usecases/org/remote';
// import { GetOrg as UsecaseReduxGetOrg } from 'domain/usecases/org/redux';

import { RemoteGetOrg } from 'data/repository/org';
// import { ReduxGetOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetOrg = (): UsecaseRemoteGetOrg =>
  new RemoteGetOrg(makeApiUrl('/orgs/{orgId}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetOrg =
  (): UsecaseReduxGetOrg =>
    new ReduxGetOrg(); */
