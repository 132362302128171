import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBilling as UsecaseRemoteGetBilling } from 'domain/usecases/billing/remote';

import { RemoteGetBilling } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBilling = (): UsecaseRemoteGetBilling =>
  new RemoteGetBilling(makeApiUrl('/orgs/{orgId}/billing/'), makeHttpClient());
