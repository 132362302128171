import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { QueueSacRequest as UsecaseRemoteQueueSacRequest } from 'domain/usecases/sacRequest/remote';
// import { QueueSacRequest as UsecaseReduxQueueSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteQueueSacRequest } from 'data/repository/sacRequest';
// import { ReduxQueueSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteQueueSacRequest = (): UsecaseRemoteQueueSacRequest =>
  new RemoteQueueSacRequest(
    makeApiUrl('/sac/requests/{request}/QUEUE'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxQueueSacRequest =
  (): UsecaseReduxQueueSacRequest =>
    new ReduxQueueSacRequest(); */
