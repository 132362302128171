import styled from 'styled-components';

export const Container = styled.div`
  .icon {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 24px;
    margin-left: 8px;
  }

  .css-1laqsz7-MuiInputAdornment-root {
    margin: 0;
  }

  margin-bottom: 20px;
`;

export const Info = styled.div`
  margin-left: 10px;

  span {
    font-size: 16px;
    font-weight: 400;
  }
`;
