import { ListFilteredOrgs } from 'domain/usecases/org/redux/ListFilteredOrg';
import {
  iActionListOrgsFiltered,
  iActionListOrgsFilteredFailed,
  iActionListOrgsFilteredSuccess,
  OrgTypes,
} from 'domain/interfaces/redux/org/listOrgsFiltered';

export const listOrgsFilteredRequest = (
  payload: ListFilteredOrgs.Params,
): iActionListOrgsFiltered => ({
  type: OrgTypes.LIST_FILTERED_ORGS,
  payload,
});

export const listOrgsFilteredSuccess = (
  params: ListFilteredOrgs.Model,
): iActionListOrgsFilteredSuccess => ({
  type: OrgTypes.LIST_FILTERED_ORGS_SUCCESS,
  payload: params,
});

export const listOrgsFilteredFailed = (): iActionListOrgsFilteredFailed => ({
  type: OrgTypes.LIST_FILTERED_ORGS_FAILED,
});
