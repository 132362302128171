import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  max-width: 233px;

  .MuiSvgIcon-root {
    font-size: 28px;
    color: black;
  }

  .MuiPickersCalendarHeader-iconButton {
    padding: 0px;
    margin: 10px 0px 10px 5px;
  }

  .MuiPickersCalendarHeader-daysHeader {
    span {
      color: ${({ theme }) => theme.colors.primary.main};
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .MuiPickersDay-current {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .MuiPickersDay-daySelected {
    color: ${({ theme }) => theme.colors.background.default};
    background-color: ${({ theme }) => theme.colors.primary.main};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.main};
      opacity: 0.8;
    }
  }
`;
