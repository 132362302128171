import { call, put, select } from 'redux-saga/effects';
import { iActionList } from 'domain/interfaces/redux/conference/list';
import { ListConference } from 'domain/usecases/conference/remote/ListConference';
import { makeRemoteListConference } from 'main/factories/usecases/conference/ListConferenceFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/conference/actions/list';

export function* onList(action: iActionList) {
  const remoteListConference = makeRemoteListConference();

  try {
    const response: ListConference.Model = yield call(
      remoteListConference.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
