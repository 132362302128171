import { ListUser } from 'domain/usecases/user/redux/ListUser';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  UserTypes,
} from 'domain/interfaces/redux/user/list';

export const listRequest = (payload: ListUser.Params): iActionList => ({
  type: UserTypes.LIST,
  payload,
});

export const listSuccess = (params: ListUser.Model): iActionListSuccess => ({
  type: UserTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: UserTypes.LIST_FAILED,
});
