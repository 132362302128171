import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateConferenceNote as UsecaseRemoteCreateConferenceNote } from 'domain/usecases/conferenceNote/remote';

import { RemoteCreateConferenceNote } from 'data/repository/conferenceNote';

/**
 * send request via API.
 */
export const makeRemoteCreateConferenceNote =
  (): UsecaseRemoteCreateConferenceNote =>
    new RemoteCreateConferenceNote(
      makeApiUrl('/conferences'),
      makeHttpClient(),
    );
