/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Select,
  Button,
  KeyboardDate,
} from 'presentation/components/UI';
import { translator } from 'presentation/components/i18n';
import { iStore } from 'domain/interfaces/models';
import { makeReduxListUser } from 'main/factories/usecases/user/ListUserFactory';
import { makeReduxListFilteredUser } from 'main/factories/usecases/user/GetFilteredUsers';
import { makeReduxListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import { subYears } from 'date-fns';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  Options,
  Typography,
  GeneralData,
  GeneralDataForm,
  Idenfitication,
  ContainerInput,
  RegisterDate,
  KeyboardDateContainer,
  KeyboardContent,
  Permissions,
  ContainerCheckButtons,
  ContentCheckButtons,
} from './styles/StyledFilterUsers';
import { ChildComponentRef } from '../header/BackButton';

export interface iFilterUsers {
  user?: {
    id?: number;
    org?: number;
    unit?: number;
    search?: string;
    registered?: {
      begin?: string;
      end?: string;
    };
    role?: {
      orgAdmin?: boolean;
      orgUnitAdmin?: boolean;
      systemAdmin?: boolean;
    };
  };
  dataControl?: {
    limit?: number;
    offset?: number;
    paging?: boolean;
  };
  orderBy?: {
    order?: number;
    attribute?: string;
    direction?: string;
  }[];
}

interface iFilterUsersProps {
  setIsClosed: (closed: boolean) => void;
  isClosed: boolean;
  filterUsers?: iFilterUsers;
  setFilterUsers?: (data: iFilterUsers) => void;
}

const FilterUsers: React.ForwardRefRenderFunction<any, iFilterUsersProps> = (
  { isClosed, setIsClosed, filterUsers, setFilterUsers },
  ref,
) => {
  const [created, setCreated] = useState(
    filterUsers?.user?.registered?.begin === undefined
      ? new Date()
      : new Date(filterUsers?.user?.registered?.begin),
  );
  const [endAt, setEndAt] = useState(
    filterUsers?.user?.registered?.end === undefined
      ? new Date()
      : new Date(filterUsers?.user?.registered?.end),
  );

  const { records: orgs } = useSelector((store: iStore) => store.org);
  const { records: orgUnits } = useSelector((store: iStore) => store.orgUnits);

  const user = filterUsers?.user;

  const filteredUnits = useMemo(
    () => orgUnits?.filter(unit => unit?.org?.id === user?.org),
    [orgUnits, user?.org],
  );

  useImperativeHandle(ref, () => ({
    getState: () => filterUsers,
  }));

  const handleFilter = () => {
    makeReduxListFilteredUser().list({
      user: {
        org: user?.org && user?.org !== 0 ? user?.org : undefined,
        unit: user?.unit && user?.unit !== 0 ? user?.unit : undefined,
        registered: {
          begin: created?.toISOString() ?? undefined,
          end: endAt?.toISOString() ?? undefined,
        },
        search: user?.search?.length ? user?.search?.trim() : undefined,
        role:
          !user?.role?.orgAdmin &&
          !user?.role?.orgUnitAdmin &&
          !user?.role?.systemAdmin
            ? undefined
            : {
                orgAdmin: user?.role?.orgAdmin ?? undefined,
                orgUnitAdmin: user?.role?.orgUnitAdmin ?? undefined,
                systemAdmin: user?.role?.systemAdmin ?? undefined,
              },
      },
      dataControl: {
        limit: 9999,
      },
    });

    setFilterUsers?.({
      ...filterUsers,
      user: {
        ...user,
        registered: {
          begin: created.toISOString(),
          end: endAt.toISOString(),
        },
      },
    });

    setIsClosed(false);
  };

  const resetFilter = () => {
    const now = new Date();
    const previous = subYears(new Date(), 5);

    setCreated(previous);
    setEndAt(now);

    setFilterUsers?.({
      user: {
        org: undefined,
        unit: undefined,
        registered: {
          begin: subYears(
            new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
            5,
          ).toISOString(),
          end: new Date().toISOString(),
        },
        role: {
          orgAdmin: undefined,
          orgUnitAdmin: undefined,
          systemAdmin: undefined,
        },
        search: undefined,
      },
    });

    makeReduxListFilteredUser().list({
      dataControl: {
        limit: 9999,
      },
    });
  };

  return (
    <>
      <Container isOpen={isClosed}>
        <ContainerForm
          onSubmit={e => {
            e.preventDefault();
            handleFilter();
          }}
        >
          <GeneralData>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Dados gerais
            </Typography>

            <GeneralDataForm>
              <Idenfitication>
                <Typography size="14px" color="#222529" line="16px">
                  Identificação:
                </Typography>
                <Input
                  placeholder="Pesquise pelo nome, e-mail ou CPF..."
                  value={user?.search}
                  onChange={e => {
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        search: e.target.value,
                      },
                    });
                  }}
                />
              </Idenfitication>
              <Idenfitication>
                <Typography size="14px" color="#222529" line="16px">
                  Organização:
                </Typography>
                <Select
                  id="select_status"
                  value={user?.org}
                  onChange={e =>
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        org: Number(e.target.value),
                      },
                    })
                  }
                  placeholder={`${translator('Qualquer')}`}
                  width="307px"
                >
                  <Options id="state_0" value={0}>
                    {translator('Todas')}
                  </Options>
                  {orgs?.map(org => (
                    <Options id="state_1" value={org.id}>
                      {org.name}
                    </Options>
                  ))}
                </Select>
              </Idenfitication>
              <ContainerInput>
                <Typography size="14px" color="#222529" line="16px">
                  Setor:
                </Typography>
                <Select
                  id="select_status"
                  value={user?.unit}
                  onChange={e =>
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        unit: Number(e.target.value),
                      },
                    })
                  }
                  placeholder={`${translator('Qualquer')}`}
                  width="307px"
                >
                  <Options id="state_0" value={0}>
                    {translator('Todos')}
                  </Options>
                  {filteredUnits?.map(unit => (
                    <Options id="state_1" value={unit.id}>
                      {unit.name}
                    </Options>
                  ))}
                </Select>
              </ContainerInput>
            </GeneralDataForm>
          </GeneralData>

          <RegisterDate>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Data de cadastro
            </Typography>

            <KeyboardDateContainer>
              <Typography size="14px" color="#222529" line="16px">
                A partir de
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={created}
                  setState={setCreated}
                  placeholder="Data"
                  width="140px"
                />
              </KeyboardContent>

              <Typography size="14px" color="#222529" line="16px">
                a
              </Typography>

              <KeyboardContent>
                <KeyboardDate
                  id="input_date"
                  defaultValue="dd/MM/yyyy"
                  state={endAt}
                  setState={setEndAt}
                  placeholder={`${translator('Fim')}`}
                  width="140px"
                />
              </KeyboardContent>
            </KeyboardDateContainer>
          </RegisterDate>

          <Permissions>
            <Typography weight="bold" size="14px" color="#222529" line="16px">
              Papéis e permissões
            </Typography>
            <ContainerCheckButtons>
              <ContentCheckButtons>
                <input
                  type="checkbox"
                  checked={user?.role?.systemAdmin === true}
                  onChange={e => {
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        role: {
                          systemAdmin: e.target.checked,
                          orgAdmin:
                            filterUsers?.user?.role?.orgAdmin ?? undefined,
                          orgUnitAdmin:
                            filterUsers?.user?.role?.orgUnitAdmin ?? undefined,
                        },
                      },
                    });
                  }}
                />
                <Typography size="14px" color="#222529" line="16px">
                  Administradores do sistema
                </Typography>
              </ContentCheckButtons>
              <ContentCheckButtons>
                <input
                  type="checkbox"
                  checked={user?.role?.orgAdmin === true}
                  onChange={e => {
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        role: {
                          systemAdmin:
                            filterUsers?.user?.role?.systemAdmin ?? undefined,
                          orgAdmin: e.target.checked,
                          orgUnitAdmin:
                            filterUsers?.user?.role?.orgUnitAdmin ?? undefined,
                        },
                      },
                    });
                  }}
                />
                <Typography size="14px" color="#222529" line="16px">
                  Responsáveis por uma organização
                </Typography>
              </ContentCheckButtons>
              <ContentCheckButtons>
                <input
                  type="checkbox"
                  checked={user?.role?.orgUnitAdmin === true}
                  onChange={e => {
                    setFilterUsers?.({
                      ...filterUsers,
                      user: {
                        ...filterUsers?.user,
                        role: {
                          systemAdmin:
                            filterUsers?.user?.role?.systemAdmin ?? undefined,
                          orgAdmin:
                            filterUsers?.user?.role?.orgAdmin ?? undefined,
                          orgUnitAdmin: e.target.checked,
                        },
                      },
                    });
                  }}
                />
                <Typography size="14px" color="#222529" line="16px">
                  Responsáveis por um setor
                </Typography>
              </ContentCheckButtons>
            </ContainerCheckButtons>
          </Permissions>
        </ContainerForm>

        <ContainerButtons>
          <Button
            rounded
            size="fit-content"
            variant="secundary"
            onClick={() => resetFilter()}
          >
            Restaurar padrões
          </Button>
          <Button
            id="btn_search"
            rounded
            variant="primary"
            size="fit-content"
            onClick={() => handleFilter()}
            autoFocus
          >
            Aplicar filtros
          </Button>
        </ContainerButtons>
      </Container>
    </>
  );
};

export default forwardRef<ChildComponentRef, iFilterUsersProps>(FilterUsers);
