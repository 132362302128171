import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrg as UsecaseRemoteListOrg } from 'domain/usecases/org/remote';
import { ListOrg as UsecaseReduxListOrg } from 'domain/usecases/org/redux';

import { RemoteListOrg } from 'data/repository/org';
import { ReduxListOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrg = (): UsecaseRemoteListOrg =>
  new RemoteListOrg(makeApiUrl('/orgs/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListOrg = (): UsecaseReduxListOrg => new ReduxListOrg();
