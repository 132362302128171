import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DownloadConference as UsecaseRemoteDownloadConference } from 'domain/usecases/conference/remote';

import { RemoteDownloadConference } from 'data/repository/conference';

/**
 * send request via API.
 */
export const makeRemoteDownloadConference =
  (): UsecaseRemoteDownloadConference =>
    new RemoteDownloadConference(
      makeApiUrl('/conferences/{conferenceId}/recordings/{recordingId}'),
      makeHttpClient(),
    );
