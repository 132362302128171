import { iUser } from 'domain/interfaces/models/User';
import { UserTypes } from 'domain/interfaces/redux/user/types';
import { UserActions } from './actions';

export const initialState: iUser = {
  loading: false,
  pageSize: undefined,
  totalItems: undefined,
  records: [],
};

const reducer = (state = initialState, action: UserActions): iUser => {
  switch (action.type) {
    case UserTypes.LIST:
      return { ...state, loading: true };
    case UserTypes.LIST_FAILED:
      return { ...state, loading: false };
    case UserTypes.LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case UserTypes.LIST_USER_FILTERED:
      return { ...state, loading: true };
    case UserTypes.LIST_USER_FILTERED_FAILED:
      return { ...state, loading: false };
    case UserTypes.LIST_USER_FILTERED_SUCCESS: {
      console.log('action.payload user', action.payload);
      return {
        ...state,
        ...(action.payload as any),
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
