import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { DownloadConferenceFiles as UsecaseRemoteDownloadConferenceFiles } from 'domain/usecases/conferenceFiles/remote';

import { RemoteDownloadConferenceFiles } from 'data/repository/conferenceFiles';

/**
 * send request via API.
 */
export const makeRemoteDownloadConferenceFiles =
  (): UsecaseRemoteDownloadConferenceFiles =>
    new RemoteDownloadConferenceFiles(
      makeApiUrl('/conferences'),
      makeHttpClient(),
    );
