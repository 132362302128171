import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  font-size: 14px;
`;
export const Finished = styled.div`
  border-radius: 12px;
  padding: 6px 27px;
  background-color: #ff5b5b;
  color: white;
`;

export const Confirmed = styled.div`
  border-radius: 4px;
  padding: 4px;
  background-color: #48c1f9;
  color: #fff;
`;

export const Available = styled.div`
  border-radius: 4px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.feedback.successBack};
  color: ${({ theme }) => theme.colors.feedback.success};
`;

export const Canceled = styled.div`
  border-radius: 12px;
  padding: 6px 27px;
  background-color: ${({ theme }) => theme.colors.feedback.cancelBack};
  color: ${({ theme }) => theme.colors.feedback.cancel};
`;

export const Progress = styled.div`
  border-radius: 12px;
  padding: 6px 27px;
  background-color: #45f275;
  color: white;
`;

export const Scheduled = styled.div`
  border-radius: 12px;
  padding: 6px 27px;
  background-color: #45b4f2;
  /* display: flex; */
  /* justify-content: center; */
  color: white;
`;
