import React, { useMemo, useState } from 'react';
import { History } from 'main/routes';
import Translator from 'presentation/components/i18n/Translator';
import { HeaderBack, Header } from 'presentation/components/header';
import { OrgNavigator } from 'presentation/components/OrgNavigator';
import OrgPage from '../Admin/Orgs';

import {
  Body,
  Container,
  Content,
  Left,
  ListOrgs,
  Right,
} from './styles/StyledCreateOrg';

const CreateUserPage: React.FC = () => {
  const [navigation, setNavigation] = useState<number>(1);

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return <OrgPage editing={false} />;
      default:
        return <div />;
    }
  }, [navigation]);

  return (
    <Container>
      <Header title="teste" />
      <HeaderBack
        onBack={() => History.getHistory().goBack()}
        title={`${Translator('Criação de organização')}`}
      />
      <Body>
        <Left>
          <OrgNavigator active={navigation} nav={setNavigation} creating={1} />
        </Left>
        <Right>{RenderSubPages}</Right>
      </Body>
    </Container>
  );
};

export default CreateUserPage;
