import React from 'react';

const ImageConfirmPage = () => {
  return (
    <svg
      width="362"
      height="500"
      viewBox="0 0 362 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          d="M460.058 234.16L237.174 46.3727C229.166 39.6377 219.862 34.6157 209.836 31.617C199.811 28.6183 189.278 27.7064 178.886 28.9376C168.495 30.1689 158.466 33.5172 149.419 38.7757C140.372 44.0343 132.5 51.0915 126.287 59.5116L47.2084 166.75C40.4461 175.929 35.8195 186.503 33.6665 197.699C31.5134 208.895 31.8884 220.43 34.7639 231.462C37.6394 242.495 42.9428 252.746 50.2869 261.466C57.6309 270.187 66.8301 277.157 77.2125 281.867L351.414 406.28C364.623 412.273 378.951 415.401 393.455 415.458C407.959 415.516 422.312 412.501 435.567 406.614C448.823 400.726 460.682 392.098 470.363 381.297C480.045 370.497 487.33 357.769 491.739 343.951C497.921 324.57 498.211 303.79 492.571 284.245C486.931 264.699 475.616 247.268 460.058 234.16Z"
          fill="#EBEBEB"
        />
        <path
          opacity="0.9"
          d="M460.058 234.16L237.174 46.3727C229.166 39.6377 219.862 34.6157 209.836 31.617C199.811 28.6183 189.278 27.7064 178.886 28.9376C168.495 30.1689 158.466 33.5172 149.419 38.7757C140.372 44.0343 132.5 51.0915 126.287 59.5116L47.2084 166.75C40.4461 175.929 35.8195 186.503 33.6665 197.699C31.5134 208.895 31.8884 220.43 34.7639 231.462C37.6394 242.495 42.9428 252.746 50.2869 261.466C57.6309 270.187 66.8301 277.157 77.2125 281.867L351.414 406.28C364.623 412.273 378.951 415.401 393.455 415.458C407.959 415.516 422.312 412.501 435.567 406.614C448.823 400.726 460.682 392.098 470.363 381.297C480.045 370.497 487.33 357.769 491.739 343.951C497.921 324.57 498.211 303.79 492.571 284.245C486.931 264.699 475.616 247.268 460.058 234.16Z"
          fill="#EBEBEB"
        />
        <path
          d="M250 474.541C388.071 474.541 500 468.006 500 459.945C500 451.884 388.071 445.349 250 445.349C111.929 445.349 0 451.884 0 459.945C0 468.006 111.929 474.541 250 474.541Z"
          fill="#EBEBEB"
        />
        <path
          d="M357.291 171.147L348.407 171.998L334.804 168.8L329.865 176.936L333.037 178.561L337.473 177.194L343.597 178.406L349.116 177.542L359.599 177.8L357.291 171.147Z"
          fill="#FFC3BD"
        />
        <path
          d="M353.693 170.812L355.808 178.406C355.808 178.406 371.126 180.482 377.147 177.852C383.169 175.221 378.617 130.763 372.828 131.253C367.039 131.743 370.249 156.332 370.249 164.055C370.223 169.961 353.693 170.812 353.693 170.812Z"
          fill="#E50411"
        />
        <path
          opacity="0.7"
          d="M353.693 170.812L355.808 178.406C355.808 178.406 371.126 180.482 377.147 177.852C383.169 175.221 378.617 130.763 372.828 131.253C367.039 131.743 370.249 156.332 370.249 164.055C370.223 169.961 353.693 170.812 353.693 170.812Z"
          fill="white"
        />
        <path
          d="M387.219 87.1697C359.729 81.8832 367.775 104.28 367.775 104.28C367.775 104.28 367.981 100.412 371.565 100.412C374.051 100.214 376.408 99.2291 378.296 97.6008C377.027 99.1309 375.357 100.278 373.474 100.915C375.901 100.936 378.295 100.343 380.432 99.1906C382.569 98.0387 384.38 96.3652 385.697 94.3258C385.825 94.9551 385.907 95.5929 385.942 96.2341C385.942 99.3802 388.521 101.082 389.81 105.376C391.1 109.67 396.193 105.376 396.193 105.376C403.31 87.0536 387.219 87.1697 387.219 87.1697Z"
          fill="#263238"
        />
        <path
          d="M387.232 172.629L378.348 173.48L364.745 168.916L358.453 175.698L361.612 177.31H367.414L373.552 179.876L379.058 179.025L389.54 179.282L387.232 172.629Z"
          fill="#FFC3BD"
        />
        <path
          d="M394.179 182.004H276.342C277.102 184.075 278.167 186.022 279.501 187.78H394.179C394.945 187.78 395.68 187.476 396.222 186.934C396.763 186.393 397.068 185.658 397.068 184.892C397.068 184.126 396.763 183.391 396.222 182.85C395.68 182.308 394.945 182.004 394.179 182.004Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M394.179 182.004H276.342C277.102 184.075 278.167 186.022 279.501 187.78H394.179C394.945 187.78 395.68 187.476 396.222 186.934C396.763 186.393 397.068 185.658 397.068 184.892C397.068 184.126 396.763 183.391 396.222 182.85C395.68 182.308 394.945 182.004 394.179 182.004Z"
          fill="white"
        />
        <path
          d="M297.231 196.639H391.473V187.781H279.502C281.566 190.532 284.243 192.766 287.321 194.303C290.398 195.841 293.791 196.64 297.231 196.639Z"
          fill="#263238"
        />
        <path
          opacity="0.4"
          d="M297.231 196.639H391.473V187.781H279.502C281.566 190.532 284.243 192.766 287.321 194.303C290.398 195.841 293.791 196.64 297.231 196.639Z"
          fill="white"
        />
        <path
          d="M376.012 178.25H307.352C306.881 178.289 306.442 178.504 306.122 178.851C305.803 179.199 305.625 179.654 305.625 180.126C305.625 180.599 305.803 181.054 306.122 181.401C306.442 181.749 306.881 181.963 307.352 182.002H376.012C376.271 182.024 376.532 181.991 376.779 181.907C377.025 181.822 377.251 181.688 377.442 181.512C377.634 181.335 377.787 181.121 377.891 180.883C377.996 180.644 378.05 180.387 378.05 180.126C378.05 179.866 377.996 179.609 377.891 179.37C377.787 179.132 377.634 178.918 377.442 178.741C377.251 178.565 377.025 178.43 376.779 178.346C376.532 178.261 376.271 178.229 376.012 178.25Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M376.012 178.25H307.352C306.881 178.289 306.442 178.504 306.122 178.851C305.803 179.199 305.625 179.654 305.625 180.126C305.625 180.599 305.803 181.054 306.122 181.401C306.442 181.749 306.881 181.963 307.352 182.002H376.012C376.271 182.024 376.532 181.991 376.779 181.907C377.025 181.822 377.251 181.688 377.442 181.512C377.634 181.335 377.787 181.121 377.891 180.883C377.996 180.644 378.05 180.387 378.05 180.126C378.05 179.866 377.996 179.609 377.891 179.37C377.787 179.132 377.634 178.918 377.442 178.741C377.251 178.565 377.025 178.43 376.779 178.346C376.532 178.261 376.271 178.229 376.012 178.25Z"
          fill="white"
        />
        <path
          d="M308.372 180.855H331.839C331.981 180.855 332.121 180.821 332.248 180.755C332.374 180.69 332.483 180.595 332.565 180.479C332.647 180.363 332.7 180.229 332.72 180.088C332.739 179.947 332.724 179.803 332.677 179.669L319.783 142.908C319.613 142.412 319.291 141.982 318.863 141.679C318.435 141.376 317.922 141.215 317.398 141.219H293.931C293.789 141.217 293.649 141.25 293.522 141.313C293.396 141.377 293.287 141.471 293.204 141.586C293.122 141.702 293.069 141.835 293.049 141.976C293.03 142.116 293.045 142.259 293.093 142.393L305.987 179.166C306.167 179.656 306.491 180.08 306.917 180.381C307.343 180.683 307.85 180.848 308.372 180.855Z"
          fill="#263238"
        />
        <path
          opacity="0.5"
          d="M308.372 180.855H331.839C331.981 180.855 332.121 180.821 332.248 180.755C332.374 180.69 332.483 180.595 332.565 180.479C332.647 180.363 332.7 180.229 332.72 180.088C332.739 179.947 332.724 179.803 332.677 179.669L319.783 142.908C319.613 142.412 319.291 141.982 318.863 141.679C318.435 141.376 317.922 141.215 317.398 141.219H293.931C293.789 141.217 293.649 141.25 293.522 141.313C293.396 141.377 293.287 141.471 293.204 141.586C293.122 141.702 293.069 141.835 293.049 141.976C293.03 142.116 293.045 142.259 293.093 142.393L305.987 179.166C306.167 179.656 306.491 180.08 306.917 180.381C307.343 180.683 307.85 180.848 308.372 180.855Z"
          fill="white"
        />
        <path
          d="M307.237 180.855H330.716C330.858 180.852 330.996 180.816 331.121 180.75C331.246 180.684 331.353 180.589 331.435 180.474C331.516 180.358 331.57 180.225 331.591 180.086C331.611 179.946 331.599 179.803 331.555 179.669L318.661 142.908C318.49 142.412 318.168 141.982 317.74 141.679C317.312 141.376 316.8 141.215 316.275 141.219H292.808C292.668 141.22 292.529 141.254 292.404 141.319C292.279 141.384 292.171 141.477 292.09 141.592C292.008 141.706 291.954 141.839 291.934 141.978C291.913 142.117 291.925 142.259 291.97 142.393L304.864 179.166C305.043 179.654 305.366 180.076 305.789 180.378C306.212 180.679 306.717 180.845 307.237 180.855Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M307.237 180.855H330.716C330.858 180.852 330.996 180.816 331.121 180.75C331.246 180.684 331.353 180.589 331.435 180.474C331.516 180.358 331.57 180.225 331.591 180.086C331.611 179.946 331.599 179.803 331.555 179.669L318.661 142.908C318.49 142.412 318.168 141.982 317.74 141.679C317.312 141.376 316.8 141.215 316.275 141.219H292.808C292.668 141.22 292.529 141.254 292.404 141.319C292.279 141.384 292.171 141.477 292.09 141.592C292.008 141.706 291.954 141.839 291.934 141.978C291.913 142.117 291.925 142.259 291.97 142.393L304.864 179.166C305.043 179.654 305.366 180.076 305.789 180.378C306.212 180.679 306.717 180.845 307.237 180.855Z"
          fill="white"
        />
        <path
          d="M309.868 161.038C310.164 162.444 311.157 163.617 312.047 163.617C312.937 163.617 313.427 162.47 313.13 161.038C312.834 159.607 311.841 158.46 310.951 158.46C310.061 158.46 309.571 159.62 309.868 161.038Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M334.867 182.002C334.397 181.963 333.958 181.749 333.638 181.401C333.318 181.054 333.141 180.599 333.141 180.126C333.141 179.654 333.318 179.199 333.638 178.851C333.958 178.504 334.397 178.289 334.867 178.25H376.025C376.284 178.229 376.545 178.261 376.792 178.346C377.038 178.43 377.264 178.565 377.455 178.741C377.647 178.918 377.8 179.132 377.904 179.37C378.009 179.609 378.063 179.866 378.063 180.126C378.063 180.387 378.009 180.644 377.904 180.883C377.8 181.121 377.647 181.335 377.455 181.512C377.264 181.688 377.038 181.822 376.792 181.907C376.545 181.991 376.284 182.024 376.025 182.002H334.867Z"
          fill="white"
        />
        <path
          d="M293.157 120.163H288.451V130.414H293.157V120.163Z"
          fill="#E50411"
        />
        <path
          d="M349.53 120.163H344.824V130.414H349.53V120.163Z"
          fill="#E50411"
        />
        <path
          d="M321.266 100.347H320.711C321.82 83.8818 319.422 78.5309 317.127 76.9449C316.776 76.6749 316.366 76.494 315.93 76.4176C315.494 76.3412 315.047 76.3718 314.625 76.5065L314.406 76.0037C314.912 75.8246 315.454 75.7757 315.984 75.8613C316.513 75.9469 317.013 76.1644 317.436 76.4936C319.809 78.1312 322.388 83.624 321.266 100.347Z"
          fill="#E50411"
        />
        <path
          d="M318.108 98.6833C317.567 84.6805 319.991 79.4456 322.131 77.5373C322.462 77.1901 322.863 76.9174 323.308 76.7373C323.753 76.5572 324.23 76.4739 324.71 76.4929L324.632 77.0473C324.234 77.0427 323.84 77.1231 323.475 77.2832C323.11 77.4433 322.784 77.6794 322.518 77.9757C320.493 79.8324 318.185 84.9255 318.714 98.6059L318.108 98.6833Z"
          fill="#E50411"
        />
        <path
          d="M320.493 98.6824H319.951C320.518 90.2756 319.474 85.2727 316.947 84.2154C313.633 82.8229 308.695 88.3544 308.643 88.4189L308.23 88.0449C308.437 87.8128 313.478 82.1653 317.153 83.6997C319.964 84.8343 321.099 89.8888 320.493 98.6824Z"
          fill="#E50411"
        />
        <path
          d="M319.098 99.2256C319.098 94.068 320.168 90.8574 322.295 89.7485C324.681 88.4591 327.453 90.3417 327.569 90.4319L327.247 90.8832C327.247 90.8832 324.668 89.1296 322.553 90.2385C320.439 91.3474 319.652 94.2743 319.652 99.2642L319.098 99.2256Z"
          fill="#E50411"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M321.264 100.347H320.709C321.818 83.8818 319.42 78.5309 317.125 76.9449C316.775 76.6749 316.364 76.494 315.928 76.4176C315.493 76.3412 315.045 76.3718 314.623 76.5065L314.404 76.0037C314.91 75.8246 315.452 75.7757 315.982 75.8613C316.511 75.9469 317.011 76.1644 317.434 76.4936C319.807 78.1312 322.386 83.624 321.264 100.347Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M318.104 98.6833C317.563 84.6805 319.987 79.4456 322.127 77.5373C322.458 77.1901 322.859 76.9174 323.304 76.7373C323.749 76.5572 324.227 76.4739 324.706 76.4929L324.629 77.0473C324.23 77.0427 323.836 77.1231 323.471 77.2832C323.106 77.4433 322.78 77.6794 322.514 77.9757C320.49 79.8324 318.182 84.9255 318.71 98.6059L318.104 98.6833Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M320.491 98.6849H319.949C320.516 90.278 319.472 85.2752 316.945 84.2179C313.631 82.8253 308.693 88.3568 308.641 88.4213L308.229 88.0474C308.435 87.8153 313.476 82.1678 317.151 83.7021C319.962 84.8368 321.097 89.8912 320.491 98.6849Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M319.098 99.2256C319.098 94.068 320.168 90.8574 322.295 89.7485C324.681 88.4591 327.453 90.3417 327.569 90.4319L327.247 90.8832C327.247 90.8832 324.668 89.1296 322.553 90.2385C320.439 91.3474 319.652 94.2743 319.652 99.2642L319.098 99.2256Z"
            fill="black"
          />
        </g>
        <path
          d="M306.605 97.7424L308.978 111.307C309.24 112.805 310.022 114.162 311.188 115.139C312.353 116.116 313.827 116.649 315.348 116.645H324.786C326.307 116.649 327.78 116.116 328.946 115.139C330.111 114.162 330.894 112.805 331.155 111.307L333.58 97.7424H306.605Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M306.605 97.7424L308.978 111.307C309.24 112.805 310.022 114.162 311.188 115.139C312.353 116.116 313.827 116.649 315.348 116.645H324.786C326.307 116.649 327.78 116.116 328.946 115.139C330.111 114.162 330.894 112.805 331.155 111.307L333.58 97.7424H306.605Z"
          fill="white"
        />
        <path
          d="M315.604 76.1049C315.604 76.1049 321.213 66.6923 311.349 66.5634C309.933 66.5531 308.522 66.7441 307.159 67.1307C308.448 68.0075 310.511 69.6063 309.441 70.0061C308.371 70.4058 304.283 69.6192 302.401 69.1937C299.191 71.1773 296.365 73.7238 294.059 76.7109C296.051 78.5855 298.18 80.3094 300.428 81.8684C301.408 80.8885 302.891 79.6636 303.742 80.1407C304.593 80.6177 305.508 83.261 306.063 85.1306C307.911 86.0252 309.885 86.6339 311.917 86.9358C311.917 85.4401 311.917 83.0676 312.613 82.6163C313.309 82.165 315.952 84.718 317.422 86.2266C324.952 82.9386 315.604 76.1049 315.604 76.1049Z"
          fill="#E50411"
        />
        <path
          opacity="0.1"
          d="M315.604 76.1049C315.604 76.1049 321.213 66.6923 311.349 66.5634C309.933 66.5531 308.522 66.7441 307.159 67.1307C308.448 68.0075 310.511 69.6063 309.441 70.0061C308.371 70.4058 304.283 69.6192 302.401 69.1937C299.191 71.1773 296.365 73.7238 294.059 76.7109C296.051 78.5855 298.18 80.3094 300.428 81.8684C301.408 80.8885 302.891 79.6636 303.742 80.1407C304.593 80.6177 305.508 83.261 306.063 85.1306C307.911 86.0252 309.885 86.6339 311.917 86.9358C311.917 85.4401 311.917 83.0676 312.613 82.6163C313.309 82.165 315.952 84.718 317.422 86.2266C324.952 82.9386 315.604 76.1049 315.604 76.1049Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M315.604 76.1049C315.604 76.1049 321.213 66.6923 311.349 66.5634C309.933 66.5531 308.522 66.7441 307.159 67.1307C308.448 68.0075 310.511 69.6063 309.441 70.0061C308.371 70.4058 304.283 69.6192 302.401 69.1937C299.191 71.1773 296.365 73.7238 294.059 76.7109C304.799 72.1464 315.604 76.1049 315.604 76.1049Z"
          fill="white"
        />
        <path
          d="M327.276 82.4747C328.307 82.1781 329.596 84.254 330.267 85.4918C332.433 84.5257 334.431 83.2198 336.185 81.6237C335.708 80.5664 335.167 79.1222 335.515 78.5678C335.863 78.0134 337.733 78.4518 339.074 78.8644C340.29 77.5893 341.423 76.2369 342.465 74.8157C341.442 73.9906 340.36 73.2407 339.228 72.5721C337.294 72.7913 334.561 72.959 333.89 72.2885C333.22 71.618 334.703 70.9991 335.773 70.8057C333.975 70.0107 332.074 69.4767 330.125 69.2197C329.867 70.0965 329.3 71.4504 328.256 71.4504C327.211 71.4504 326.115 70.277 325.393 69.4131C317.321 71.1538 323.73 76.9303 323.73 76.9303C323.73 76.9303 316.78 85.1824 325.019 86.2784C325.47 84.8987 326.296 82.7583 327.276 82.4747Z"
          fill="#E50411"
        />
        <path
          opacity="0.2"
          d="M327.276 82.4747C328.307 82.1781 329.596 84.254 330.267 85.4918C332.433 84.5257 334.431 83.2198 336.185 81.6237C335.708 80.5664 335.167 79.1222 335.515 78.5678C335.863 78.0134 337.733 78.4518 339.074 78.8644C340.29 77.5893 341.423 76.2369 342.465 74.8157C341.442 73.9906 340.36 73.2407 339.228 72.5721C337.294 72.7913 334.561 72.959 333.89 72.2885C333.22 71.618 334.703 70.9991 335.773 70.8057C333.975 70.0107 332.074 69.4767 330.125 69.2197C329.867 70.0965 329.3 71.4504 328.256 71.4504C327.211 71.4504 326.115 70.277 325.393 69.4131C317.321 71.1538 323.73 76.9303 323.73 76.9303C323.73 76.9303 316.78 85.1824 325.019 86.2784C325.47 84.8987 326.296 82.7583 327.276 82.4747Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M333.887 72.3288C333.178 71.6196 334.7 71.0394 335.77 70.846C333.973 70.051 332.071 69.517 330.122 69.26C329.865 70.1368 329.297 71.4907 328.253 71.4907C327.208 71.4907 326.112 70.3173 325.39 69.4534C317.319 71.1941 323.727 76.9706 323.727 76.9706C323.727 76.9706 334.21 73.1024 342.462 74.856C341.439 74.0309 340.357 73.281 339.226 72.6124C337.291 72.8316 334.558 72.9992 333.887 72.3288Z"
          fill="white"
        />
        <path
          d="M339.085 89.7215C337.963 90.3275 335.809 91.3074 334.946 90.4435C334.082 89.5796 335.397 85.9307 336.054 84.4221C335.055 83.1736 333.785 82.1684 332.341 81.4823C323.573 77.6141 326.552 90.1986 326.552 90.1986C326.552 90.1986 315.527 92.8676 322.619 99.7014C323.14 100.167 323.692 100.598 324.269 100.991C324.824 99.7014 325.907 97.5223 326.99 97.8188C328.073 98.1154 327.661 100.913 327.3 102.654C329.164 103.419 331.125 103.923 333.128 104.15C333.385 102.86 333.875 101.3 334.662 101.339C335.448 101.378 336.596 103.157 337.241 104.382C338.901 104.37 340.557 104.219 342.192 103.931C341.833 99.0712 340.786 94.2873 339.085 89.7215Z"
          fill="#E50411"
        />
        <path
          opacity="0.2"
          d="M326.552 90.1975C326.552 90.1975 315.527 92.8666 322.619 99.7003C323.14 100.166 323.692 100.596 324.269 100.99C324.824 99.7003 325.907 97.5212 326.99 97.8178C328.073 98.1144 327.661 100.912 327.3 102.653C329.164 103.418 331.125 103.922 333.128 104.149C333.385 102.859 333.875 101.299 334.662 101.338C335.448 101.377 336.596 103.156 337.241 104.381C338.901 104.369 340.557 104.218 342.192 103.93C340.655 100.698 338.475 97.8135 335.786 95.4525C333.097 93.0915 329.955 91.3034 326.552 90.1975Z"
          fill="white"
        />
        <path
          d="M309.352 87.5163C309.352 87.5163 303.421 77.704 299.037 86.5493C298.357 87.9536 297.896 89.4538 297.67 90.9977C299.488 90.1982 303.382 88.7283 303.434 90.714C303.485 92.6997 299.32 96.9675 297.915 98.2698C298.485 101.143 299.449 103.924 300.777 106.535C302.575 105.826 304.331 105.016 306.038 104.111C306.206 102.525 306.657 99.3787 307.83 98.6824C309.004 97.9862 309.906 100.513 310.203 101.674C312.063 100.475 313.792 99.083 315.36 97.522C314.226 96.2326 312.666 94.1696 313.13 93.1123C313.594 92.055 316.83 92.4547 318.61 92.8286C318.934 92.0582 319.151 91.2469 319.254 90.4174C320.402 80.8888 309.352 87.5163 309.352 87.5163Z"
          fill="#E50411"
        />
        <path
          opacity="0.2"
          d="M300.803 106.536C302.6 105.827 304.357 105.017 306.063 104.112C306.231 102.526 306.682 99.3797 307.856 98.6835C309.029 97.9872 309.932 100.514 310.228 101.675C312.088 100.476 313.817 99.084 315.386 97.523C314.251 96.2336 312.691 94.1706 313.155 93.1133C313.619 92.056 316.856 92.4557 318.635 92.8296C318.959 92.0592 319.176 91.2479 319.28 90.4185C320.376 80.8899 309.326 87.5173 309.326 87.5173C309.326 87.5173 302.814 93.6162 300.803 106.536Z"
          fill="white"
        />
        <path
          d="M355.344 115.472H282.648V121.261H355.344V115.472Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M355.344 115.472H282.648V121.261H355.344V115.472Z"
          fill="white"
        />
        <path
          d="M432.733 26H297.231C289.889 26.0102 282.85 28.9315 277.658 34.1233C272.467 39.3151 269.545 46.3538 269.535 53.6961V174.396C269.542 181.739 272.462 188.78 277.655 193.973C282.847 199.165 289.888 202.085 297.231 202.092H432.733C440.077 202.085 447.117 199.165 452.31 193.973C457.502 188.78 460.423 181.739 460.429 174.396V53.6961C460.419 46.3538 457.498 39.3151 452.306 34.1233C447.114 28.9315 440.076 26.0102 432.733 26ZM454.975 174.396C454.975 180.295 452.632 185.952 448.461 190.123C444.29 194.295 438.632 196.638 432.733 196.638H297.231C291.332 196.638 285.675 194.295 281.504 190.123C277.333 185.952 274.989 180.295 274.989 174.396V53.6961C274.989 47.7972 277.333 42.1398 281.504 37.9687C285.675 33.7975 291.332 31.4541 297.231 31.4541H432.733C438.632 31.4541 444.29 33.7975 448.461 37.9687C452.632 42.1398 454.975 47.7972 454.975 53.6961V174.396Z"
          fill="#E50411"
        />
        <path
          opacity="0.4"
          d="M432.733 26H297.231C289.889 26.0102 282.85 28.9315 277.658 34.1233C272.467 39.3151 269.545 46.3538 269.535 53.6961V174.396C269.542 181.739 272.462 188.78 277.655 193.973C282.847 199.165 289.888 202.085 297.231 202.092H432.733C440.077 202.085 447.117 199.165 452.31 193.973C457.502 188.78 460.423 181.739 460.429 174.396V53.6961C460.419 46.3538 457.498 39.3151 452.306 34.1233C447.114 28.9315 440.076 26.0102 432.733 26ZM454.975 174.396C454.975 180.295 452.632 185.952 448.461 190.123C444.29 194.295 438.632 196.638 432.733 196.638H297.231C291.332 196.638 285.675 194.295 281.504 190.123C277.333 185.952 274.989 180.295 274.989 174.396V53.6961C274.989 47.7972 277.333 42.1398 281.504 37.9687C285.675 33.7975 291.332 31.4541 297.231 31.4541H432.733C438.632 31.4541 444.29 33.7975 448.461 37.9687C452.632 42.1398 454.975 47.7972 454.975 53.6961V174.396Z"
          fill="white"
        />
        <path
          d="M364.434 68.7563H381.415C383.231 68.7563 384.703 67.2843 384.703 65.4684V62.477C384.703 60.6611 383.231 59.1891 381.415 59.1891H364.434C362.618 59.1891 361.146 60.6611 361.146 62.477V65.4684C361.146 67.2843 362.618 68.7563 364.434 68.7563Z"
          fill="#E50411"
        />
        <path
          d="M347.338 56.9202H364.319C366.135 56.9202 367.607 55.4481 367.607 53.6322V50.6408C367.607 48.825 366.135 47.3529 364.319 47.3529H347.338C345.522 47.3529 344.05 48.825 344.05 50.6408V53.6322C344.05 55.4481 345.522 56.9202 347.338 56.9202Z"
          fill="#E50411"
        />
        <path
          opacity="0.8"
          d="M364.434 68.7563H381.415C383.231 68.7563 384.703 67.2843 384.703 65.4684V62.477C384.703 60.6611 383.231 59.1891 381.415 59.1891H364.434C362.618 59.1891 361.146 60.6611 361.146 62.477V65.4684C361.146 67.2843 362.618 68.7563 364.434 68.7563Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M347.338 56.9202H364.319C366.135 56.9202 367.607 55.4481 367.607 53.6322V50.6408C367.607 48.825 366.135 47.3529 364.319 47.3529H347.338C345.522 47.3529 344.05 48.825 344.05 50.6408V53.6322C344.05 55.4481 345.522 56.9202 347.338 56.9202Z"
          fill="white"
        />
        <path
          d="M224.058 80.5552H146.875V167.731H224.058V80.5552Z"
          fill="#263238"
        />
        <path
          d="M230.236 167.73H140.688V174.757H230.236V167.73Z"
          fill="#263238"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M230.236 167.729H140.688V174.757H230.236V167.729Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.6"
          d="M224.058 80.5552H146.875V167.731H224.058V80.5552Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M218.618 86.0076H152.33V162.288H218.618V86.0076Z"
          fill="white"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M152.33 86.0066V162.274H152.794L171.026 86.0066H152.33Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M209.181 86.0066H205.764L187.545 162.274H190.962L209.181 86.0066Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M201.173 86.0066H186.873L168.654 162.274H182.954L201.173 86.0066Z"
            fill="white"
          />
        </g>
        <path
          d="M102.275 150.853C99.6962 151.033 96.3567 148.932 94.745 146.198L93.4556 144.071C89.7292 137.985 81.5545 133.627 75.9199 134.039L74.5144 134.142L70.5431 134.426L69.1377 134.529C63.503 134.942 62.2265 139.983 65.566 146.134C66.0431 146.985 66.2752 147.423 66.7265 148.274C68.1577 151.02 67.0875 153.432 64.4701 153.625C59.9572 153.947 58.0231 158.125 59.8025 163.051C65.5274 183.358 67.1004 200.443 64.96 219.036C69.056 222.842 74.4414 224.957 80.033 224.954H125.806C127.573 201.745 124.233 182.404 115.001 159.053C112.667 154.128 106.801 150.518 102.275 150.853Z"
          fill="#E50411"
        />
        <path
          opacity="0.1"
          d="M102.275 150.853C99.6962 151.033 96.3567 148.932 94.745 146.198L93.4556 144.071C89.7292 137.985 81.5545 133.627 75.9199 134.039L74.5144 134.142L70.5431 134.426L69.1377 134.529C63.503 134.942 62.2265 139.983 65.566 146.134C66.0431 146.985 66.2752 147.423 66.7265 148.274C68.1577 151.02 67.0875 153.432 64.4701 153.625C59.9572 153.947 58.0231 158.125 59.8025 163.051C65.5274 183.358 67.1004 200.443 64.96 219.036C69.056 222.842 74.4414 224.957 80.033 224.954H125.806C127.573 201.745 124.233 182.404 115.001 159.053C112.667 154.128 106.801 150.518 102.275 150.853Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M102.277 150.853C99.6982 151.033 96.3587 148.932 94.747 146.198L93.4576 144.071C89.7312 137.985 81.5565 133.627 75.9219 134.039L74.5164 134.142C68.8689 134.542 67.6569 139.584 71.0867 145.747C71.5637 146.598 71.7958 147.036 72.26 147.887C73.7299 150.621 72.6855 153.045 70.068 153.238C65.5552 153.561 63.6984 157.725 65.5036 162.651C71.9505 184.467 73.4849 202.622 70.6612 222.93C73.5689 224.268 76.7311 224.963 79.9319 224.967H125.705C127.472 201.758 124.132 182.417 114.9 159.066C112.669 154.128 106.803 150.518 102.277 150.853Z"
          fill="black"
        />
        <path
          d="M133.246 173.855C133.246 173.855 131.248 200.274 137.875 200.004C144.503 199.733 157.551 199.823 157.551 199.823L168.331 195.014C168.61 194.885 168.922 194.846 169.224 194.902C169.526 194.957 169.804 195.104 170.02 195.323L174.907 200.158C175.115 200.374 175.255 200.647 175.309 200.942C175.363 201.237 175.329 201.541 175.21 201.817C175.092 202.092 174.894 202.327 174.643 202.49C174.392 202.654 174.098 202.74 173.798 202.737L170.806 202.66C166.783 204.811 162.201 205.688 157.667 205.174C159.215 204.71 130.397 210.061 127.444 205.973C123.408 200.403 121.629 177.065 121.629 177.065L133.246 173.855Z"
          fill="#B55B52"
        />
        <path
          opacity="0.1"
          d="M133.246 173.855C133.246 173.855 131.248 200.274 137.875 200.004C144.503 199.733 157.551 199.823 157.551 199.823L168.331 195.014C168.61 194.885 168.922 194.846 169.224 194.902C169.526 194.957 169.804 195.104 170.02 195.323L174.907 200.158C175.115 200.374 175.255 200.647 175.309 200.942C175.363 201.237 175.329 201.541 175.21 201.817C175.092 202.092 174.894 202.327 174.643 202.49C174.392 202.654 174.098 202.74 173.798 202.737L170.806 202.66C166.783 204.811 162.201 205.688 157.667 205.174C159.215 204.71 130.397 210.061 127.444 205.973C123.408 200.403 121.629 177.065 121.629 177.065L133.246 173.855Z"
          fill="black"
        />
        <path
          d="M127.007 157.402C131.494 157.273 136.561 163.101 134.331 178.587L121.978 180.933C121.978 180.933 117.968 157.724 127.007 157.402Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M127.007 157.402C131.494 157.273 136.561 163.101 134.331 178.587L121.978 180.933C121.978 180.933 117.968 157.724 127.007 157.402Z"
          fill="black"
        />
        <path
          d="M81.4015 157.724C74.6451 166.427 75.754 212.213 76.2439 224.63C77.5212 224.855 78.8152 224.971 80.1121 224.978H119.361C122.714 180.972 136.961 167.949 131.739 159.645C126.659 151.677 89.241 147.563 81.4015 157.724Z"
          fill="#263238"
        />
        <path
          d="M113.996 163.101C113.996 163.101 123.783 157.015 119.811 153.715C114.685 152.509 109.448 151.84 104.184 151.716C102.585 152.773 103.746 155.713 113.996 163.101Z"
          fill="#B55B52"
        />
        <path
          d="M119.54 147.912L110.901 138.525C110.076 145.72 106.246 151.625 105.473 151.857L106.414 155.158C116.884 166.93 119.308 155.622 119.308 155.622C116.69 153.495 119.54 147.912 119.54 147.912Z"
          fill="#B55B52"
        />
        <path
          opacity="0.2"
          d="M114.294 142.252L110.271 141.839C110.271 141.839 110.439 153.908 118.704 154.952C117.17 152.515 119.478 147.873 119.478 147.873L114.294 142.252Z"
          fill="#263238"
        />
        <path
          d="M112.011 128.21C110.141 136.732 108.607 141.619 111.56 147.254C112.499 149.099 113.926 150.653 115.685 151.745C117.445 152.838 119.469 153.428 121.54 153.452C123.611 153.476 125.649 152.932 127.433 151.881C129.217 150.829 130.679 149.309 131.661 147.486C135.529 140.549 137.979 127.771 130.836 121.827C129.318 120.498 127.476 119.592 125.496 119.202C123.516 118.811 121.468 118.95 119.558 119.604C117.649 120.258 115.946 121.404 114.621 122.926C113.296 124.448 112.396 126.293 112.011 128.274V128.21Z"
          fill="#B55B52"
        />
        <path
          d="M123.887 134.605C123.694 135.276 123.887 135.895 124.351 136.049C124.815 136.204 125.305 135.753 125.499 135.082C125.692 134.412 125.499 133.728 125.035 133.625C124.57 133.522 124.08 133.922 123.887 134.605Z"
          fill="#263238"
        />
        <path
          d="M131.506 137.093C131.3 137.763 131.506 138.382 131.957 138.537C132.409 138.692 132.911 138.241 133.118 137.557C133.324 136.874 133.118 136.268 132.641 136.1C132.164 135.933 131.699 136.371 131.506 137.093Z"
          fill="#263238"
        />
        <path
          d="M122.248 131.357C122.18 131.345 122.117 131.314 122.067 131.266C122.03 131.229 122 131.185 121.98 131.136C121.96 131.088 121.949 131.036 121.949 130.983C121.949 130.93 121.96 130.878 121.98 130.829C122 130.781 122.03 130.736 122.067 130.699C122.585 130.288 123.196 130.012 123.846 129.895C124.496 129.778 125.166 129.824 125.794 130.029C125.845 130.046 125.893 130.073 125.934 130.11C125.975 130.146 126.008 130.19 126.031 130.24C126.053 130.29 126.066 130.343 126.067 130.398C126.069 130.452 126.059 130.507 126.039 130.557C125.998 130.657 125.92 130.738 125.822 130.781C125.723 130.825 125.611 130.828 125.51 130.789C125.022 130.635 124.503 130.6 123.998 130.687C123.493 130.775 123.017 130.982 122.609 131.292C122.559 131.333 122.5 131.361 122.437 131.372C122.374 131.383 122.308 131.378 122.248 131.357Z"
          fill="#263238"
        />
        <path
          d="M135.53 135.47C135.469 135.44 135.415 135.397 135.372 135.343C135.33 135.29 135.3 135.227 135.285 135.161C135.22 134.625 135.029 134.113 134.729 133.665C134.429 133.217 134.027 132.846 133.558 132.582C133.469 132.517 133.409 132.421 133.389 132.312C133.37 132.204 133.393 132.093 133.455 132.002C133.503 131.927 133.575 131.871 133.659 131.841C133.742 131.81 133.834 131.808 133.919 131.834C134.502 132.152 135.004 132.602 135.382 133.148C135.76 133.695 136.005 134.322 136.098 134.98C136.116 135.088 136.092 135.198 136.029 135.288C135.966 135.377 135.87 135.438 135.763 135.457C135.688 135.481 135.608 135.485 135.53 135.47Z"
          fill="#263238"
        />
        <path
          d="M123.293 145.346C123.67 145.463 124.059 145.54 124.453 145.578C124.482 145.583 124.511 145.583 124.54 145.577C124.569 145.571 124.596 145.559 124.62 145.542C124.644 145.525 124.665 145.504 124.68 145.479C124.696 145.454 124.706 145.426 124.711 145.397C124.714 145.367 124.712 145.336 124.704 145.306C124.695 145.277 124.681 145.249 124.662 145.225C124.642 145.201 124.619 145.181 124.592 145.167C124.565 145.152 124.535 145.143 124.505 145.14C123.515 145.029 122.565 144.691 121.729 144.152C120.892 143.613 120.192 142.887 119.682 142.032C119.67 142.004 119.652 141.979 119.629 141.958C119.607 141.937 119.581 141.92 119.552 141.909C119.523 141.899 119.492 141.894 119.462 141.895C119.431 141.896 119.401 141.903 119.373 141.916C119.317 141.946 119.275 141.996 119.254 142.055C119.232 142.115 119.233 142.18 119.257 142.238C119.688 143 120.267 143.667 120.96 144.2C121.653 144.734 122.446 145.124 123.293 145.346Z"
          fill="#263238"
        />
        <path
          d="M128.85 136.616C129.067 138.964 129.587 141.274 130.397 143.488C129.836 143.67 129.243 143.729 128.658 143.66C128.072 143.591 127.509 143.396 127.006 143.089L128.85 136.616Z"
          fill="#A02724"
        />
        <path
          d="M112.177 132.969C112.177 132.969 120.455 133.046 118.083 128.288C118.083 128.288 129.571 130.493 127.611 126.161C127.611 126.161 135.348 132.272 133.375 126.496C133.375 126.496 138.674 131.009 138.842 128.107C139.009 125.206 136.108 123.363 136.108 123.363C136.108 123.363 142.555 118.115 133.194 118.128C133.194 118.128 135.412 110.237 124.71 116.542C124.71 116.542 126.141 108.367 118.16 116.542C118.16 116.542 107.355 116.619 109.908 120.191C109.908 120.191 103.1 122.408 107.329 124.794C107.329 124.794 102.172 129.474 105.924 131.395C105.924 131.395 101.024 138.126 108.18 137.288C108.18 137.288 105.601 143.516 108.773 142.239C111.945 140.963 112.177 132.969 112.177 132.969Z"
          fill="#263238"
        />
        <path
          d="M106.854 134.257C106.907 136.186 107.68 138.025 109.02 139.414C110.799 141.245 112.888 139.865 113.314 137.467C113.726 135.288 113.314 131.729 111.031 130.827C108.749 129.924 106.828 131.845 106.854 134.257Z"
          fill="#B55B52"
        />
        <path
          opacity="0.3"
          d="M120.83 211.054C121.217 208.475 121.642 205.896 122.119 203.588L118.006 202.763L95.042 189.315L86.8673 170.412L83.4633 163.102L80.2656 164.804C80.2656 164.804 80.2656 202.634 96.2412 209.507C103.023 212.408 111.559 211.492 120.83 211.054Z"
          fill="black"
        />
        <path
          d="M94.6037 172.462C94.6037 172.462 99.2842 195.813 113.945 198.443C128.605 201.074 130.707 201.022 130.707 201.022L142.53 197.154C142.877 197.042 143.249 197.039 143.597 197.145C143.945 197.251 144.252 197.461 144.477 197.747L147.469 201.615C147.667 201.869 147.792 202.173 147.83 202.492C147.868 202.812 147.818 203.136 147.685 203.43C147.552 203.723 147.342 203.975 147.076 204.157C146.811 204.34 146.501 204.446 146.179 204.465L141.125 204.8C139.74 205.904 138.143 206.71 136.432 207.17C134.722 207.629 132.935 207.731 131.184 207.469C131.184 207.469 107.523 210.048 99.2842 205.316C91.045 200.584 84.5078 181.178 84.5078 181.178L94.6037 172.462Z"
          fill="#B55B52"
        />
        <path
          d="M84.1469 187.613C84.1469 187.613 75.869 161.103 83.6698 156.887C91.4706 152.67 96.5637 171.263 98.5623 178.303L84.1469 187.613Z"
          fill="#263238"
        />
        <path
          d="M161.936 204.851L133.389 206.217V210.266H161.936H180.323V204.851H161.936Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M161.936 210.266H133.389V206.217L161.936 204.851V210.266Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M180.322 204.851H161.936V210.266H180.322V204.851Z"
          fill="white"
        />
        <path
          d="M224.702 196.302H164.385L170.471 146.712H230.788L224.702 196.302Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M224.702 196.302H164.385L170.471 146.712H230.788L224.702 196.302Z"
          fill="white"
        />
        <path
          d="M173.952 146.712L167.854 196.302H224.703L230.789 146.712H173.952Z"
          fill="#E50411"
        />
        <path
          opacity="0.6"
          d="M173.952 146.712L167.854 196.302H224.703L230.789 146.712H173.952Z"
          fill="white"
        />
        <path
          d="M205.064 210.125H192.235L190.945 189.662H201.338L205.064 210.125Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M205.064 210.125H192.235L190.945 189.662H201.338L205.064 210.125Z"
          fill="white"
        />
        <path
          d="M207.361 210.125H194.532L193.242 189.662H203.622L207.361 210.125Z"
          fill="#E50411"
        />
        <path
          opacity="0.5"
          d="M207.361 210.125H194.532L193.242 189.662H203.622L207.361 210.125Z"
          fill="white"
        />
        <path
          d="M198.463 206.553H196.387H178.658V210.266H196.387H198.463H216.192V206.553H198.463Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M198.463 206.553H196.387H178.658V210.266H196.387H198.463H216.192V206.553H198.463Z"
          fill="white"
        />
        <path
          d="M216.194 206.553H196.389V210.266H216.194V206.553Z"
          fill="#E50411"
        />
        <path
          opacity="0.6"
          d="M216.194 206.553H196.389V210.266H216.194V206.553Z"
          fill="white"
        />
        <path
          d="M121.732 216.057V224.915H215.497C218.936 224.913 222.328 224.111 225.405 222.574C228.481 221.037 231.159 218.806 233.226 216.057H121.732Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M121.732 216.057V224.915H215.497C218.936 224.913 222.328 224.111 225.405 222.574C228.481 221.037 231.159 218.806 233.226 216.057H121.732Z"
          fill="white"
        />
        <path
          d="M236.371 210.268H116.148V216.057H233.225C234.56 214.297 235.62 212.345 236.371 210.268Z"
          fill="#263238"
        />
        <path
          opacity="0.5"
          d="M236.371 210.268H116.148V216.057H233.225C234.56 214.297 235.62 212.345 236.371 210.268Z"
          fill="white"
        />
        <path
          d="M215.497 54.2764H79.9949C72.6516 54.2832 65.6109 57.2034 60.4184 62.3959C55.2258 67.5885 52.3057 74.6291 52.2988 81.9725V202.672C52.3057 210.015 55.2262 217.054 60.4192 222.244C65.6121 227.435 72.6528 230.352 79.9949 230.356H215.497C222.837 230.349 229.874 227.43 235.064 222.24C240.255 217.05 243.173 210.012 243.18 202.672V81.9725C243.177 74.6303 240.26 67.5896 235.069 62.3967C229.879 57.2038 222.839 54.2832 215.497 54.2764ZM237.739 202.672C237.736 208.57 235.391 214.226 231.221 218.396C227.05 222.567 221.395 224.911 215.497 224.914H79.9949C74.0948 224.914 68.4361 222.571 64.2629 218.401C60.0897 214.23 57.7435 208.573 57.7401 202.672V81.9725C57.7435 76.0724 60.0897 70.4151 64.2629 66.2442C68.4361 62.0734 74.0948 59.7305 79.9949 59.7305H215.497C221.397 59.7339 227.054 62.0801 231.225 66.2534C235.396 70.4266 237.739 76.0853 237.739 81.9854V202.672Z"
          fill="#E50411"
        />
        <path
          opacity="0.4"
          d="M215.497 54.2764H79.9949C72.6516 54.2832 65.6109 57.2034 60.4184 62.3959C55.2258 67.5885 52.3057 74.6291 52.2988 81.9725V202.672C52.3057 210.015 55.2262 217.054 60.4192 222.244C65.6121 227.435 72.6528 230.352 79.9949 230.356H215.497C222.837 230.349 229.874 227.43 235.064 222.24C240.255 217.05 243.173 210.012 243.18 202.672V81.9725C243.177 74.6303 240.26 67.5896 235.069 62.3967C229.879 57.2038 222.839 54.2832 215.497 54.2764ZM237.739 202.672C237.736 208.57 235.391 214.226 231.221 218.396C227.05 222.567 221.395 224.911 215.497 224.914H79.9949C74.0948 224.914 68.4361 222.571 64.2629 218.401C60.0897 214.23 57.7435 208.573 57.7401 202.672V81.9725C57.7435 76.0724 60.0897 70.4151 64.2629 66.2442C68.4361 62.0734 74.0948 59.7305 79.9949 59.7305H215.497C221.397 59.7339 227.054 62.0801 231.225 66.2534C235.396 70.4266 237.739 76.0853 237.739 81.9854V202.672Z"
          fill="white"
        />
        <path
          d="M281.752 119.574C295.594 105.732 295.594 83.2891 281.752 69.4468C267.909 55.6046 245.466 55.6046 231.624 69.4468C217.782 83.2891 217.782 105.732 231.624 119.574C245.466 133.416 267.909 133.416 281.752 119.574Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M281.752 119.574C295.594 105.732 295.594 83.2891 281.752 69.4468C267.909 55.6046 245.466 55.6046 231.624 69.4468C217.782 83.2891 217.782 105.732 231.624 119.574C245.466 133.416 267.909 133.416 281.752 119.574Z"
          fill="black"
        />
        <path
          d="M291.18 100.189C295.677 81.1364 283.877 62.046 264.825 57.5492C245.772 53.0524 226.682 64.8521 222.185 83.9046C217.688 102.957 229.488 122.047 248.54 126.544C267.593 131.041 286.683 119.241 291.18 100.189Z"
          fill="#E50411"
        />
        <path
          opacity="0.9"
          d="M285.909 98.9447C289.718 82.8032 279.721 66.6294 263.58 62.8197C247.438 59.0099 231.265 69.0068 227.455 85.1484C223.645 101.29 233.642 117.464 249.784 121.273C265.925 125.083 282.099 115.086 285.909 98.9447Z"
          fill="white"
        />
        <path
          d="M255.453 70.9217C255.453 77.5621 255.234 84.254 255.453 90.8944C255.53 93.0218 256.51 93.615 258.032 95.1622L265.059 102.189C265.302 102.317 265.58 102.364 265.852 102.322C266.124 102.281 266.375 102.154 266.569 101.959C266.764 101.765 266.891 101.513 266.932 101.242C266.974 100.97 266.927 100.692 266.8 100.449L260.262 93.9244C259.285 93.1085 258.383 92.2066 257.567 91.2296C258.393 92.519 257.877 91.062 257.877 89.9402V70.9217C257.792 70.6637 257.628 70.4391 257.408 70.2799C257.188 70.1206 256.924 70.0349 256.652 70.0349C256.38 70.0349 256.116 70.1206 255.896 70.2799C255.676 70.4391 255.512 70.6637 255.427 70.9217H255.453Z"
          fill="#263238"
        />
        <path
          d="M312.858 455.226C312.066 455.229 311.301 454.936 310.714 454.404C310.127 453.872 309.761 453.139 309.687 452.351L308.668 441.726V353.506H317.036V441.726L316.018 452.351C315.944 453.137 315.579 453.868 314.995 454.4C314.41 454.932 313.649 455.226 312.858 455.226Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M312.858 455.226C312.066 455.229 311.301 454.936 310.714 454.404C310.127 453.872 309.761 453.139 309.687 452.351L308.668 441.726V353.506H317.036V441.726L316.018 452.351C315.944 453.137 315.579 453.868 314.995 454.4C314.41 454.932 313.649 455.226 312.858 455.226Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M317.036 363.474H308.668V373.75H317.036V363.474Z"
          fill="black"
        />
        <path
          d="M320.002 443.196V439.882C319.999 439.261 319.751 438.666 319.311 438.226C318.872 437.787 318.277 437.539 317.656 437.535H308.05C307.428 437.539 306.833 437.787 306.394 438.226C305.955 438.666 305.706 439.261 305.703 439.882V443.196L282.094 449.643C280.702 450.026 279.474 450.856 278.599 452.004C277.723 453.152 277.248 454.555 277.246 455.999H348.459C348.457 454.555 347.982 453.152 347.107 452.004C346.231 450.856 345.003 450.026 343.611 449.643L320.002 443.196Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M320.002 443.196V439.882C319.999 439.261 319.751 438.666 319.311 438.226C318.872 437.787 318.277 437.539 317.656 437.535H308.05C307.428 437.539 306.833 437.787 306.394 438.226C305.955 438.666 305.706 439.261 305.703 439.882V443.196L282.094 449.643C280.702 450.026 279.474 450.856 278.599 452.004C277.723 453.152 277.248 454.555 277.246 455.999H348.459C348.457 454.555 347.982 453.152 347.107 452.004C346.231 450.856 345.003 450.026 343.611 449.643L320.002 443.196Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M320.002 443.119V439.883C320.009 439.775 320.009 439.668 320.002 439.56H305.819C305.813 439.668 305.813 439.775 305.819 439.883V443.119H320.002Z"
          fill="black"
        />
        <path
          d="M350.754 267.323C335.023 267.878 321.42 281.236 319.809 296.979L313.942 352.849C313.787 354.014 313.887 355.198 314.233 356.322C314.579 357.445 315.164 358.48 315.948 359.356C316.732 360.231 317.696 360.927 318.774 361.395C319.852 361.863 321.019 362.093 322.194 362.068H369.644C372.168 362.012 374.587 361.05 376.46 359.357C378.333 357.665 379.534 355.354 379.843 352.849L385.787 296.296C387.502 279.972 375.369 266.807 358.851 267.388L350.754 267.323Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M350.754 267.323C335.023 267.878 321.42 281.236 319.809 296.979L313.942 352.849C313.787 354.014 313.887 355.198 314.233 356.322C314.579 357.445 315.164 358.48 315.948 359.356C316.732 360.231 317.696 360.927 318.774 361.395C319.852 361.863 321.019 362.093 322.194 362.068H369.644C372.168 362.012 374.587 361.05 376.46 359.357C378.333 357.665 379.534 355.354 379.843 352.849L385.787 296.296C387.502 279.972 375.369 266.807 358.851 267.388L350.754 267.323Z"
          fill="white"
        />
        <path
          d="M377.689 296.27C379.404 279.946 367.258 266.782 350.754 267.362C335.023 267.916 321.42 281.274 319.809 297.018L313.942 352.887C313.787 354.052 313.887 355.237 314.233 356.36C314.579 357.483 315.164 358.518 315.948 359.394C316.732 360.27 317.696 360.966 318.774 361.434C319.852 361.902 321.019 362.131 322.194 362.106H361.585C364.109 362.051 366.528 361.089 368.401 359.396C370.274 357.703 371.475 355.393 371.784 352.887L377.689 296.27Z"
          fill="#263238"
        />
        <path
          d="M246.79 353.055C256.22 350.451 265.931 349.001 275.711 348.736C285.768 348.465 323.418 348.736 332.302 348.736C343.194 348.728 354.038 350.194 364.537 353.094C371.164 354.925 369.114 365.304 363.131 365.42C362.603 365.42 248.93 365.42 248.505 365.42C242.29 365.691 240.007 354.925 246.79 353.055Z"
          fill="#263238"
        />
        <path
          d="M361.508 365.162C342.391 359.888 322.202 359.888 303.085 365.162C295.865 367.161 292.77 355.053 300.017 353.055C321.146 347.226 343.46 347.226 364.589 353.055C371.797 355.053 368.741 367.161 361.508 365.162Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M361.51 365.161C342.393 359.887 322.204 359.887 303.087 365.161C295.867 367.16 292.772 355.052 300.019 353.054C321.148 347.225 343.462 347.225 364.591 353.054C371.799 355.052 368.743 367.16 361.51 365.161Z"
            fill="white"
          />
        </g>
        <path
          d="M232.464 394.25L224.419 417.73L217.662 415.796L223.129 391.039L232.464 394.25Z"
          fill="#B97964"
        />
        <path
          d="M217.792 413.992L226.096 416.506C226.272 416.551 226.427 416.654 226.535 416.8C226.643 416.946 226.698 417.124 226.689 417.306L226.38 425.558C226.38 425.733 226.344 425.906 226.276 426.066C226.207 426.227 226.106 426.372 225.979 426.493C225.853 426.614 225.703 426.707 225.539 426.768C225.375 426.829 225.2 426.856 225.026 426.847C224.932 426.86 224.836 426.86 224.742 426.847C221.299 425.88 218.295 424.887 213.963 423.778C208.883 422.489 210.017 423.391 204.022 421.922C200.411 421.019 200.824 416.764 202.397 416.957C209.617 417.899 212.17 415.99 215.523 414.185C216.232 413.851 217.037 413.783 217.792 413.992Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M228.081 407.054L220.061 404.99L223.129 391.039L232.464 394.25L228.081 407.054Z"
          fill="black"
        />
        <path
          d="M233.91 400.529L231.667 404.862L218.799 400.529V395.54L233.91 400.529Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M233.91 400.529L231.667 404.862L218.799 400.529V395.54L233.91 400.529Z"
          fill="white"
        />
        <path
          d="M284.249 423.03L286.931 443.815L279.375 444.008L273.908 423.971L284.249 423.03Z"
          fill="#B97964"
        />
        <path
          d="M279.27 442.861H287.896C288.077 442.86 288.252 442.921 288.393 443.033C288.534 443.146 288.632 443.304 288.67 443.48L290.449 451.552C290.495 451.723 290.505 451.902 290.478 452.077C290.452 452.252 290.389 452.42 290.295 452.57C290.201 452.72 290.076 452.849 289.93 452.949C289.783 453.049 289.618 453.117 289.444 453.15H289.173C285.588 453.086 282.416 452.88 277.968 452.867C272.72 452.867 274.1 453.486 267.872 453.563C264.159 453.563 263.488 449.373 265.061 449.166C272.282 448.277 274.319 446.097 277.117 443.519C277.73 443.044 278.496 442.81 279.27 442.861Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M277.08 435.588L273.908 423.971L284.249 423.03L285.796 435.047L277.08 435.588Z"
          fill="black"
        />
        <path
          d="M310.28 298.269H298.07C296.057 303.744 294.756 309.455 294.202 315.263C293.557 323.953 310.268 317.519 310.268 317.519L311.738 305.618L310.28 298.269Z"
          fill="#E50411"
        />
        <path
          opacity="0.1"
          d="M310.28 298.269H298.07C296.057 303.744 294.756 309.455 294.202 315.263C293.557 323.953 310.268 317.519 310.268 317.519L311.738 305.618L310.28 298.269Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M303.189 298.269C306.58 316.075 300.043 315.16 300.043 315.16L303.744 317.738L310.461 316.088L311.751 305.618L310.281 298.269H303.189Z"
          fill="black"
        />
        <path
          d="M334.638 297.173C334.638 297.173 347.068 317.971 344.953 335.339C342.839 352.707 326.747 361.901 265.011 332.309C265.011 332.309 252.349 345.422 231.332 401.601L220.617 398.377C220.617 398.377 232.725 333.444 243.555 314.967C254.386 296.49 302.519 318.19 306.168 314.541C309.418 311.305 306.594 298.462 306.594 298.462L334.638 297.173Z"
          fill="#E50411"
        />
        <path
          opacity="0.2"
          d="M257.918 338.137L254.539 335.558C254.539 335.558 242.638 342.005 248.557 360.353C251.72 353.626 255.227 347.068 259.065 340.702L257.918 338.137Z"
          fill="black"
        />
        <path
          d="M309.494 349.381C298.276 346.699 283.706 341.309 264.958 332.309C264.958 332.309 250.478 332.502 249.485 343.269C248.492 354.035 273.984 428.562 273.984 428.562L285.717 427.543C285.717 427.543 278.806 353.249 277.194 349.381H309.494Z"
          fill="#E50411"
        />
        <path
          opacity="0.1"
          d="M309.494 349.381C298.276 346.699 283.706 341.309 264.958 332.309C264.958 332.309 250.478 332.502 249.485 343.269C248.492 354.035 273.984 428.562 273.984 428.562L285.717 427.543C285.717 427.543 278.806 353.249 277.194 349.381H309.494Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M264.958 332.309C263.283 332.372 261.62 332.61 259.994 333.018C267.864 339.74 276.69 345.254 286.182 349.381H309.494C298.276 346.699 283.745 341.309 264.958 332.309Z"
          fill="black"
        />
        <path
          d="M287.097 425.609L287.419 430.431L273.752 431.501L271.805 426.911L287.097 425.609Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M287.097 425.609L287.419 430.431L273.752 431.501L271.805 426.911L287.097 425.609Z"
          fill="white"
        />
        <path
          d="M280.984 276.349L267.187 284.085L257.955 283.634C257.548 283.617 257.143 283.706 256.781 283.894C256.419 284.082 256.113 284.361 255.892 284.704L253.249 288.83C253.068 289.115 252.952 289.435 252.907 289.769C252.863 290.103 252.891 290.442 252.991 290.764L253.056 290.958C253.224 291.525 253.605 292.005 254.119 292.299C254.633 292.592 255.241 292.675 255.815 292.531L259.838 291.564L267.845 290.029L287.431 281.506L280.984 276.349Z"
          fill="#B97964"
        />
        <path
          d="M278.277 276.84C279.84 277.413 281.222 278.392 282.281 279.677C283.339 280.963 284.036 282.507 284.299 284.151C284.299 284.151 294.356 280.656 296.11 275.035C297.863 269.413 302.557 241.665 294.975 241.614C287.393 241.562 291.507 274.828 278.277 276.84Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M278.277 276.84C279.84 277.413 281.222 278.392 282.281 279.677C283.339 280.963 284.036 282.507 284.299 284.151C284.299 284.151 294.356 280.656 296.11 275.035C297.863 269.413 302.557 241.665 294.975 241.614C287.393 241.562 291.507 274.828 278.277 276.84Z"
          fill="white"
        />
        <path
          d="M275.711 277.263C278.252 279.766 280.253 282.764 281.591 286.07L286.903 283.491C286.903 283.491 284.762 277.444 280.456 274.994L275.711 277.263Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M275.711 277.263C278.252 279.766 280.253 282.764 281.591 286.07L286.903 283.491C286.903 283.491 284.762 277.444 280.456 274.994L275.711 277.263Z"
          fill="white"
        />
        <path
          d="M297.065 299.558C310.166 300.519 323.331 300.134 336.353 298.41C333.607 287.669 333.168 276.468 335.064 265.544C338.274 247.492 355.217 236.842 313.144 237.577C307.045 237.68 288.994 236.868 291.07 249.723C293.313 263.455 297.065 299.558 297.065 299.558Z"
          fill="#263238"
        />
        <path
          opacity="0.5"
          d="M297.065 299.558C310.166 300.519 323.331 300.134 336.353 298.41C333.607 287.669 333.168 276.468 335.064 265.544C338.274 247.492 355.217 236.842 313.144 237.577C307.045 237.68 288.994 236.868 291.07 249.723C293.313 263.455 297.065 299.558 297.065 299.558Z"
          fill="white"
        />
        <path
          d="M322.143 278.54L302.505 284.342L294.098 282.872C293.664 282.799 293.217 282.846 292.807 283.01C292.398 283.174 292.042 283.448 291.777 283.801L288.954 287.669C288.748 287.946 288.605 288.266 288.536 288.604C288.467 288.943 288.473 289.293 288.554 289.629V289.745C288.69 290.337 289.048 290.854 289.554 291.19C290.061 291.526 290.676 291.655 291.275 291.55L295.297 290.867L303.382 289.887L328.216 284.084L322.143 278.54Z"
          fill="#B97964"
        />
        <path
          d="M319.242 278.217C320.772 278.903 322.094 279.978 323.076 281.336C324.058 282.694 324.666 284.287 324.838 285.954C324.838 285.954 335.153 283.671 337.332 278.217C339.511 272.763 346.216 242.114 338.622 241.495C331.027 240.876 332.587 277.121 319.242 278.217Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M319.242 278.217C320.772 278.903 322.094 279.978 323.076 281.336C324.058 282.694 324.666 284.287 324.838 285.954C324.838 285.954 335.153 283.671 337.332 278.217C339.511 272.763 346.216 242.114 338.622 241.495C331.027 240.876 332.587 277.121 319.242 278.217Z"
          fill="white"
        />
        <path
          d="M316.314 278.722C318.882 281.192 320.873 284.2 322.143 287.529L327.558 285.982C327.558 285.982 325.34 279.535 321.188 276.75L316.314 278.722Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M316.314 278.722C318.882 281.192 320.873 284.2 322.143 287.529L327.558 285.982C327.558 285.982 325.34 279.535 321.188 276.75L316.314 278.722Z"
          fill="white"
        />
        <path
          d="M321.265 200.068C321.265 200.068 315.386 188.322 324.398 189.856C333.411 191.391 328.073 214.767 336.364 217.965C336.364 217.965 326.784 220.35 321.265 200.068Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M319.578 195.117C319.944 196.824 320.511 198.482 321.267 200.056C322.298 204.331 324.042 208.402 326.425 212.099C327.779 207.831 328.32 200.855 324.31 196.858C322.776 195.324 321.164 195.736 319.578 195.117Z"
          fill="black"
        />
        <path
          d="M318.107 197.566C318.107 197.566 321.047 194.239 323.587 198.507C326.128 202.775 321.937 203.794 321.937 203.794L318.107 197.566Z"
          fill="#E50411"
        />
        <path
          d="M320.223 219.86C320.223 219.86 321.512 224.67 324.993 221.369C328.475 218.068 322.208 221.523 320.223 219.86Z"
          fill="#263238"
        />
        <path
          d="M313.168 237.204C313.168 237.204 304.735 234.303 301.15 241.459L304.477 242.748L302.633 245.017L309.506 250.007L321.239 245.984L320.594 244.321L326.242 242.787C326.242 242.787 327.751 234.535 313.168 237.204Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M313.168 237.204C313.168 237.204 304.735 234.303 301.15 241.459L304.477 242.748L302.633 245.017L309.506 250.007L321.239 245.984L320.594 244.321L326.242 242.787C326.242 242.787 327.751 234.535 313.168 237.204Z"
          fill="white"
        />
        <path
          d="M313.415 237.86C313.415 237.86 299.374 234.778 309.237 248.768C309.237 248.768 337.32 237.576 313.415 237.86Z"
          fill="#B97964"
        />
        <path
          d="M318.996 221.756L309.441 231.13C310.292 235.218 309.106 237.332 307.791 238.428V241.458C309.41 241.902 311.095 242.055 312.768 241.91C315.859 241.873 318.938 241.501 321.949 240.801C317.784 237.474 318.377 227.352 318.996 221.756Z"
          fill="#B97964"
        />
        <path
          opacity="0.1"
          d="M309.532 235.475C309.815 234.037 309.784 232.554 309.441 231.129L317.964 222.761L318.802 223.728C318.802 223.728 317.088 234.392 309.532 235.475Z"
          fill="black"
        />
        <path
          d="M319.783 209.301C320.492 218.494 320.982 222.363 317.024 227.675C311.08 235.682 299.63 234.522 296.2 225.728C293.106 217.811 292.474 204.105 300.894 199.244C303.774 197.564 307.202 197.096 310.427 197.942C313.652 198.788 316.41 200.879 318.094 203.757C319.088 205.445 319.667 207.345 319.783 209.301Z"
          fill="#B97964"
        />
        <path
          d="M301.021 218.636C300.596 220.438 299.928 222.173 299.035 223.794C299.45 224.01 299.906 224.135 300.374 224.159C300.841 224.184 301.308 224.108 301.743 223.936L301.021 218.636Z"
          fill="#A24E3F"
        />
        <path
          d="M307.983 212.357C308.039 212.33 308.092 212.296 308.138 212.254C308.204 212.185 308.241 212.092 308.241 211.996C308.241 211.9 308.204 211.808 308.138 211.738C307.619 211.277 306.996 210.948 306.322 210.782C305.648 210.616 304.943 210.617 304.27 210.784C304.18 210.819 304.107 210.886 304.064 210.972C304.021 211.057 304.012 211.156 304.038 211.248C304.067 211.339 304.131 211.414 304.216 211.457C304.3 211.5 304.398 211.509 304.489 211.48C305.04 211.357 305.613 211.367 306.16 211.508C306.707 211.649 307.212 211.918 307.635 212.293C307.683 212.332 307.74 212.359 307.801 212.371C307.862 212.382 307.924 212.377 307.983 212.357Z"
          fill="#2E353A"
        />
        <path
          d="M294.397 213.619C294.459 213.605 294.515 213.569 294.551 213.516C294.911 213.078 295.37 212.732 295.89 212.508C296.411 212.283 296.977 212.187 297.543 212.227C297.64 212.242 297.74 212.217 297.819 212.16C297.899 212.102 297.952 212.015 297.968 211.917C297.976 211.869 297.973 211.819 297.96 211.772C297.948 211.725 297.926 211.68 297.896 211.642C297.866 211.603 297.828 211.571 297.785 211.548C297.742 211.524 297.695 211.509 297.646 211.505C296.963 211.442 296.276 211.543 295.641 211.801C295.006 212.06 294.442 212.466 293.997 212.988C293.963 213.021 293.935 213.061 293.917 213.106C293.898 213.15 293.889 213.197 293.889 213.245C293.889 213.293 293.898 213.341 293.917 213.385C293.935 213.43 293.963 213.47 293.997 213.503C294.047 213.552 294.11 213.586 294.179 213.599C294.248 213.613 294.319 213.607 294.384 213.581L294.397 213.619Z"
          fill="#2E353A"
        />
        <path
          d="M306.102 216.676C306.205 217.424 305.883 218.069 305.393 218.146C304.903 218.224 304.438 217.669 304.348 216.857C304.258 216.045 304.554 215.464 305.032 215.4C305.509 215.335 305.986 215.928 306.102 216.676Z"
          fill="#2E353A"
        />
        <path
          d="M297.709 217.837C297.812 218.585 297.503 219.229 297.013 219.307C296.523 219.384 296.059 218.83 295.955 218.082C295.852 217.334 296.162 216.689 296.639 216.625C297.116 216.56 297.606 217.037 297.709 217.837Z"
          fill="#2E353A"
        />
        <path
          d="M296.858 216.625L295.014 216.367C295.014 216.367 296.187 217.631 296.858 216.625Z"
          fill="#2E353A"
        />
        <path
          d="M305.214 215.464L303.357 215.206C303.357 215.206 304.505 216.47 305.214 215.464Z"
          fill="#2E353A"
        />
        <path
          d="M306.682 224.206C306.828 223.945 306.953 223.673 307.056 223.393C307.074 223.35 307.074 223.302 307.057 223.259C307.041 223.216 307.008 223.181 306.966 223.161C306.92 223.148 306.87 223.153 306.828 223.174C306.785 223.196 306.751 223.233 306.734 223.277C306.482 223.995 306.063 224.643 305.511 225.168C304.96 225.692 304.292 226.078 303.562 226.294C303.518 226.309 303.48 226.339 303.456 226.38C303.433 226.42 303.424 226.468 303.433 226.514C303.442 226.559 303.469 226.6 303.508 226.626C303.546 226.652 303.593 226.663 303.639 226.655C304.284 226.47 304.885 226.158 305.408 225.737C305.93 225.317 306.364 224.796 306.682 224.206Z"
          fill="#263238"
        />
        <path
          d="M320.22 219.862C320.22 219.862 315.191 217.115 314.869 209.546C314.547 201.978 303.032 200.417 295.928 204.866C295.928 204.866 293.903 198.174 301.33 197.581C301.33 197.581 312.187 189.638 321.071 198.522C328.923 206.362 320.22 219.862 320.22 219.862Z"
          fill="#263238"
        />
        <path
          d="M322.943 216.251C322.666 218.011 321.707 219.591 320.274 220.648C318.352 222.028 316.586 220.506 316.405 218.276C316.276 216.264 317.115 213.118 319.371 212.628C319.764 212.553 320.168 212.557 320.559 212.638C320.951 212.72 321.322 212.877 321.653 213.102C321.983 213.328 322.266 213.616 322.485 213.95C322.704 214.285 322.855 214.659 322.93 215.052C323.011 215.447 323.016 215.854 322.943 216.251Z"
          fill="#B97964"
        />
        <path
          d="M296.549 203.68C296.549 203.68 294.293 208.734 292.397 203.68C290.502 198.625 294.667 204.814 296.549 203.68Z"
          fill="#263238"
        />
        <path
          d="M302.35 290.531H236.475C235.962 290.531 235.47 290.735 235.107 291.097C234.745 291.46 234.541 291.952 234.541 292.465C234.541 292.978 234.745 293.47 235.107 293.833C235.47 294.195 235.962 294.399 236.475 294.399H302.35C302.863 294.399 303.355 294.195 303.718 293.833C304.08 293.47 304.284 292.978 304.284 292.465C304.284 291.952 304.08 291.46 303.718 291.097C303.355 290.735 302.863 290.531 302.35 290.531Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M302.35 290.531H236.475C235.962 290.531 235.47 290.735 235.107 291.097C234.745 291.46 234.541 291.952 234.541 292.465C234.541 292.978 234.745 293.47 235.107 293.833C235.47 294.195 235.962 294.399 236.475 294.399H302.35C302.863 294.399 303.355 294.195 303.718 293.833C304.08 293.47 304.284 292.978 304.284 292.465C304.284 291.952 304.08 291.46 303.718 291.097C303.355 290.735 302.863 290.531 302.35 290.531Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M264.866 294.399C264.353 294.399 263.861 294.195 263.498 293.833C263.135 293.47 262.932 292.978 262.932 292.465C262.932 291.952 263.135 291.46 263.498 291.097C263.861 290.735 264.353 290.531 264.866 290.531H302.348C302.861 290.531 303.353 290.735 303.716 291.097C304.079 291.46 304.282 291.952 304.282 292.465C304.282 292.978 304.079 293.47 303.716 293.833C303.353 294.195 302.861 294.399 302.348 294.399H264.866Z"
          fill="white"
        />
        <path
          d="M237.533 293.239H261.748C261.903 293.244 262.057 293.209 262.195 293.138C262.333 293.067 262.45 292.963 262.537 292.834C262.623 292.705 262.675 292.556 262.688 292.401C262.701 292.247 262.675 292.091 262.612 291.95L249.331 254.003C249.155 253.491 248.823 253.048 248.381 252.735C247.938 252.423 247.409 252.257 246.868 252.262H222.692C222.546 252.26 222.401 252.293 222.27 252.359C222.139 252.425 222.026 252.522 221.941 252.641C221.856 252.76 221.801 252.898 221.782 253.044C221.762 253.189 221.778 253.337 221.828 253.474L235.109 291.421C235.269 291.941 235.59 292.397 236.026 292.724C236.461 293.05 236.989 293.231 237.533 293.239Z"
          fill="#E50411"
        />
        <path
          d="M236.359 293.239H260.587C260.741 293.241 260.893 293.204 261.029 293.132C261.165 293.06 261.281 292.955 261.367 292.828C261.452 292.7 261.505 292.553 261.52 292.399C261.534 292.246 261.511 292.092 261.451 291.95L248.17 254.003C247.994 253.491 247.662 253.048 247.22 252.735C246.778 252.423 246.249 252.257 245.707 252.262H221.48C221.334 252.263 221.191 252.298 221.062 252.364C220.932 252.431 220.821 252.528 220.736 252.646C220.652 252.765 220.597 252.902 220.576 253.046C220.555 253.19 220.569 253.337 220.616 253.474L233.896 291.421C234.059 291.947 234.386 292.408 234.829 292.735C235.272 293.062 235.808 293.239 236.359 293.239Z"
          fill="#E50411"
        />
        <path
          opacity="0.5"
          d="M236.359 293.239H260.587C260.741 293.241 260.893 293.204 261.029 293.132C261.165 293.06 261.281 292.955 261.367 292.828C261.452 292.7 261.505 292.553 261.52 292.399C261.534 292.246 261.511 292.092 261.451 291.95L248.17 254.003C247.994 253.491 247.662 253.048 247.22 252.735C246.778 252.423 246.249 252.257 245.707 252.262H221.48C221.334 252.263 221.191 252.298 221.062 252.364C220.932 252.431 220.821 252.528 220.736 252.646C220.652 252.765 220.597 252.902 220.576 253.046C220.555 253.19 220.569 253.337 220.616 253.474L233.896 291.421C234.059 291.947 234.386 292.408 234.829 292.735C235.272 293.062 235.808 293.239 236.359 293.239Z"
          fill="white"
        />
        <path
          d="M239.068 272.777C239.377 274.234 240.357 275.355 241.311 275.355C242.265 275.355 242.755 274.182 242.446 272.777C242.137 271.371 241.157 270.198 240.19 270.198C239.223 270.198 238.758 271.32 239.068 272.777Z"
          fill="white"
        />
        <path
          d="M189.425 271.732C189.425 271.732 181.147 264.06 185.557 260.734C189.966 257.407 180.399 252.288 183.816 249.8C187.233 247.311 182.14 240.864 185.105 239.794C188.071 238.724 184.383 234.959 186.537 233.141C189.373 230.768 188.793 242.914 191.617 243.121C194.441 243.327 188.832 250.019 192.339 250.857C195.846 251.695 191.05 259.29 194.35 259.432C197.651 259.573 194.608 267.825 189.425 271.732Z"
          fill="#E50411"
        />
        <path
          opacity="0.5"
          d="M189.425 271.732C189.425 271.732 181.147 264.06 185.557 260.734C189.966 257.407 180.399 252.288 183.816 249.8C187.233 247.311 182.14 240.864 185.105 239.794C188.071 238.724 184.383 234.959 186.537 233.141C189.373 230.768 188.793 242.914 191.617 243.121C194.441 243.327 188.832 250.019 192.339 250.857C195.846 251.695 191.05 259.29 194.35 259.432C197.651 259.573 194.608 267.825 189.425 271.732Z"
          fill="white"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M186.665 236.262C187.525 241.291 188.217 246.341 188.741 251.413C189.425 257.628 190.856 265.48 188.909 271.592C188.703 272.236 189.721 272.52 189.94 271.875C191.952 265.583 190.508 257.795 189.812 251.413C189.244 246.255 188.522 241.098 187.697 235.94C187.594 235.257 186.562 235.553 186.665 236.224V236.262Z"
            fill="black"
          />
        </g>
        <path
          d="M186.666 270.39C186.666 270.39 178.13 266.277 180.799 262.538C183.468 258.799 174.571 257.032 176.712 254.183C178.852 251.333 173.153 247.426 175.28 245.827C177.408 244.228 172.856 240.386 174.803 240.141C177.911 239.754 179.02 247.426 181.328 246.898C183.636 246.369 180.799 253.112 183.816 252.945C186.833 252.777 184.886 260.024 187.555 259.392C190.225 258.76 189.863 266.032 186.666 270.39Z"
          fill="#E50411"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M176.582 243.765C180.669 252.146 184.808 261.237 186.149 270.533C186.252 271.216 187.271 270.92 187.181 270.249C185.814 260.888 181.623 251.682 177.497 243.172C177.201 242.553 176.272 243.095 176.582 243.714V243.765Z"
            fill="black"
          />
        </g>
        <path
          d="M193.834 270.391C193.834 270.391 202.37 266.278 199.701 262.539C197.032 258.8 205.928 257.033 203.788 254.183C201.648 251.334 207.347 247.427 205.219 245.828C203.092 244.229 206.999 242.785 205.709 240.142C204.42 237.499 201.48 247.427 199.172 246.898C196.864 246.37 199.701 253.113 196.683 252.946C193.666 252.778 195.613 260.024 192.944 259.393C190.275 258.761 190.636 266.033 193.834 270.391Z"
          fill="#E50411"
        />
        <path
          opacity="0.2"
          d="M193.834 270.391C193.834 270.391 202.37 266.278 199.701 262.539C197.032 258.8 205.928 257.033 203.788 254.183C201.648 251.334 207.347 247.427 205.219 245.828C203.092 244.229 206.999 242.785 205.709 240.142C204.42 237.499 201.48 247.427 199.172 246.898C196.864 246.37 199.701 253.113 196.683 252.946C193.666 252.778 195.613 260.024 192.944 259.393C190.275 258.761 190.636 266.033 193.834 270.391Z"
          fill="white"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M203.841 243.765C199.753 252.146 195.614 261.237 194.274 270.533C194.17 271.216 193.152 270.92 193.242 270.249C194.596 260.888 198.799 251.682 202.925 243.172C203.222 242.553 204.215 243.095 203.841 243.714V243.765Z"
            fill="black"
          />
        </g>
        <path
          d="M198.348 294.646H183.172L180.322 269.09H201.185L198.348 294.646Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M198.348 294.646H183.172L180.322 269.09H201.185L198.348 294.646Z"
          fill="white"
        />
        <path
          d="M236.978 455.226C236.187 455.227 235.424 454.932 234.837 454.401C234.251 453.869 233.883 453.138 233.807 452.351L232.801 441.726V296.102H241.156V441.726L240.15 452.351C240.073 453.138 239.706 453.869 239.12 454.401C238.533 454.932 237.77 455.227 236.978 455.226Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M236.978 455.226C236.187 455.227 235.424 454.932 234.837 454.401C234.251 453.869 233.883 453.138 233.807 452.351L232.801 441.726V296.102H241.156V441.726L240.15 452.351C240.073 453.138 239.706 453.869 239.12 454.401C238.533 454.932 237.77 455.227 236.978 455.226Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M241.169 297.52H232.801V307.797H241.169V297.52Z"
          fill="black"
        />
        <path
          d="M244.124 443.196V439.882C244.126 439.573 244.066 439.266 243.949 438.98C243.832 438.694 243.66 438.433 243.441 438.214C243.223 437.995 242.964 437.821 242.679 437.702C242.393 437.583 242.087 437.522 241.777 437.522H232.184C231.562 437.522 230.965 437.77 230.525 438.21C230.085 438.65 229.838 439.247 229.838 439.869V443.183L206.229 449.63C204.837 450.013 203.609 450.843 202.733 451.991C201.858 453.139 201.383 454.542 201.381 455.986H272.581C272.579 454.542 272.104 453.139 271.229 451.991C270.353 450.843 269.125 450.013 267.733 449.63L244.124 443.196Z"
          fill="#E50411"
        />
        <path
          opacity="0.1"
          d="M244.124 443.118V439.882C244.13 439.775 244.13 439.667 244.124 439.56H229.94C229.934 439.667 229.934 439.775 229.94 439.882V443.118H244.124Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M244.124 443.196V439.882C244.126 439.573 244.066 439.266 243.949 438.98C243.832 438.694 243.66 438.433 243.441 438.214C243.223 437.995 242.964 437.821 242.679 437.702C242.393 437.583 242.087 437.522 241.777 437.522H232.184C231.562 437.522 230.965 437.77 230.525 438.21C230.085 438.65 229.838 439.247 229.838 439.869V443.183L206.229 449.63C204.837 450.013 203.609 450.843 202.733 451.991C201.858 453.139 201.383 454.542 201.381 455.986H272.581C272.579 454.542 272.104 453.139 271.229 451.991C270.353 450.843 269.125 450.013 267.733 449.63L244.124 443.196Z"
          fill="black"
        />
        <path
          d="M327.613 299.313H146.338V304.2H327.613V299.313Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M327.613 299.313H146.338V304.2H327.613V299.313Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M327.613 299.313H237.227V304.2H327.613V299.313Z"
          fill="black"
        />
        <path
          d="M338.546 294.413H135.402V299.299H338.546V294.413Z"
          fill="#E50411"
        />
        <path
          opacity="0.3"
          d="M338.545 294.413H226.291V299.299H338.545V294.413Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ImageConfirmPage;
