import { call, put, select } from 'redux-saga/effects';
import { iActionListFilteredUser } from 'domain/interfaces/redux/user/listUserFiltered';
import { GetFilteredUsers } from 'domain/usecases/user/remote/GetFilteredUser';
import { makeRemoteGetFilteredUsers } from 'main/factories/usecases/user/GetFilteredUsers';
import {
  listFilteredUserSuccess,
  listFilteredUserFailed,
} from 'data/store/reducer/user/actions/listFilteredUser';

export function* onListFilteredUser(action: iActionListFilteredUser) {
  const remoteGetFilteredUsers = makeRemoteGetFilteredUsers();

  try {
    const response: GetFilteredUsers.Model = yield call(
      remoteGetFilteredUsers.get,
      action.payload,
    );
    yield put(
      listFilteredUserSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFilteredUserFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredUserSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredUserFailed() {}
