import { Dispatch } from 'redux';
import store from 'data/store';
import { listRequest } from 'data/store/reducer/orgUsers/actions/list';

import { iActionListOrgUser } from 'domain/interfaces/redux/orgUser/list';

import { ListOrgUser } from 'domain/usecases/orgUser/redux';

export class ReduxListOrgUser implements ListOrgUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListOrgUser.Params): iActionListOrgUser =>
    this.send(listRequest(params));
}
