import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetFilteredUsers as UsecaseRemoteGetFilteredUsers } from 'domain/usecases/user/remote';
import { ListFilteredUser as UsecaseReduxListFilteredUser } from 'domain/usecases/user/redux';

import { RemoteGetFilteredUsers } from 'data/repository/user';
import { ReduxListFilteredUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetFilteredUsers = (): UsecaseRemoteGetFilteredUsers =>
  new RemoteGetFilteredUsers(makeApiUrl('/users/filtered/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListFilteredUser = (): UsecaseReduxListFilteredUser =>
  new ReduxListFilteredUser();
