import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  max-width: 1920px;
  padding: 0px 72px 20px 72px;
  margin: 0px auto;
  overflow: auto;

  .title {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .t {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-weight: 800;
      font-size: 24px;
      color: #233242;

      .f {
        font-size: 20px;
        color: #697f96;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 60px 35px;
    overflow: auto;
  }
`;

export const Left = styled.div`
  height: 100%;

  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  height: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  padding: 20px;
  overflow: auto;

  .title {
    display: flex;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 21px;
  }

  .summary {
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .graph {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    .title {
      font-size: 18px;
      margin: 0px;
    }

    .buttons {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    .label {
      margin: 0px 10px 0px 22px;
    }
  }

  @media (max-width: 1024px) {
    box-shadow: none;
    margin: 0;
    padding: 31px 0 0 0;
    width: 100%;
  }
`;

export const Cards = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const CardOne = styled.div`
  width: 180px;
  height: 119px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  font-weight: 500;
  font-size: 18px;

  .blue {
    color: #45b4f2;
  }

  .green {
    color: #45f275;
  }

  .red {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .purple {
    color: #471eed;
  }

  .title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }
  }

  .value {
    font-weight: bold;
    font-size: 48px;
    text-align: center;
  }

  @media (max-width: 1024px) {
    width: 25%;
    margin: 0px 3px;
    padding-left: 10px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const CardTwo = styled.div`
  width: 213px;
  height: 99px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 18px;
  color: #494949;

  .value {
    font-weight: bold;
    font-size: 42px;
    color: #471eed;
    text-align: center;
  }

  @media (max-width: 1024px) {
    margin: 0px 5px 20px 5px;
    width: 33%;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
  height: 318px;
  margin-left: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: auto;

  .header {
    height: 84px;
    background: rgba(71, 30, 237, 0.1);
    font-weight: bold;
    font-size: 36px;
    display: flex;
    align-items: center;
    padding: 21px;
    border-radius: 8px 8px 0px 0px;
  }

  .body {
    padding: 0px 19px;
    display: flex;
  }

  .colTitle {
    width: calc(100% - 220px);
    padding: 69px 0px 13px 0px;

    div {
      border-bottom: 1px solid #a6a6a6;
      padding: 10px 0px 7px 0px;
      color: #471eed;

      &:last-child {
        border: none;
      }
    }
  }

  .colValue {
    width: 90px;
    padding: 27px 0px 0px 0px;
    text-align: end;

    .title {
      font-weight: normal;
      font-size: 18px;
      color: #471eed;
    }

    .value {
      border-bottom: 1px solid #a6a6a6;
      padding: 10px 0px 7px 0px;
      color: #494949;

      &:last-child {
        border: none;
      }
    }

    &:last-child {
      width: 130px;
    }
  }

  @media (max-width: 1024px) {
    margin: 0;
    overflow: auto;
  }
`;

export const Cardss = styled.div``;

export const TimeFilterContainer = styled.div`
  display: flex;
  gap: 12px;
`;

interface TimeFilterProps {
  active: boolean;
}

export const TimeFilter = styled.button<TimeFilterProps>`
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid #697f96;
  border-radius: 6px;
  background: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: #233242;
      color: #fff;
    `}
`;
