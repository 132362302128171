import { makeHttpClient } from 'main/factories/http';
import { GetCitiesByUf as UsecaseRemoteGetCitiesByUf } from 'domain/usecases/externalServices/remote';

import { RemoteGetCitiesByUf } from 'data/repository/externalServices/RemoteGetCitiesByUf';

/**
 * send request via API.
 */
export const makeRemoteGetCitiesByUf = (): UsecaseRemoteGetCitiesByUf =>
  new RemoteGetCitiesByUf(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
    makeHttpClient(),
  );
