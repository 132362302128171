import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: 0;

    a {
      text-decoration: none;
    }

    
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /** SCROLLBAR **/
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => `${theme.colors.background.default}`};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => `${theme.colors.text.lightGray}`};
    border-radius: 10px;

  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => `${theme.colors.secondary.main}`};
  }

  .dzu-previewStatusContainer {
    display: none !important;
  }

  .dzu-previewFileName {
    display: none !important;
  }

  .MuiPaper-root {
    box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px 0px 0px 8px;
  }

  #assistaAsideDescription {
    top: 120px !important;
  }
`;
