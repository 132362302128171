import styled from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

interface iContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<iContainerProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 449px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #dedede;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 24px;
  justify-content: flex-end;
  border-bottom: 1px solid #dedede;
`;

export const Fields = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const CheckboxField = styled(Fields)`
  padding-left: 10px;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const KeyboardContent = styled.div`
  width: 140px;
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
`;

interface iTypographyProps {
  weight?: string;
  size?: string;
  line?: string;
  color?: string;
}

export const Typography = styled.p<iTypographyProps>`
  font-style: normal;
  font-weight: ${({ weight }) => weight || 'normal'};
  font-size: ${({ size }) => size || '16px'};
  line-height: ${({ line }) => line || '19px'};
  color: ${({ color }) => color || '#000000'};
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #f8f8f8;
  border-top: 1px solid #dedede;
`;

export const Options = styled.option`
  width: 100%;
  min-width: 211px;
  background-color: #ffffff;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 16px;
`;
