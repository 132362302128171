/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-indent */
import { Formik } from 'formik';
import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import {
  makeReduxUpdateUser,
  makeRemoteUpdateUser,
} from 'main/factories/usecases/user/UpdateUserFactory';
import Translator from 'presentation/components/i18n/Translator';
import { Button, Input } from 'presentation/components/UI';
import { closeModal } from 'utils/closeModal';
import { passwordRecoverSchema } from 'validation/profile/RecoverPasswordValidation';
import {
  Container,
  ContentFields,
  ContentButton,
  Fields,
} from './styles/StyledChangePasswordForm';

interface ownProps {
  userId: number;
}

const ChangePassword: React.FC<ownProps> = ({ userId }) => {
  const [currentPassword, setActualPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');

  const initialValues = {
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const onSubmit = (values: typeof initialValues) => {
    makeRemoteUpdateUser()
      .update({
        id: Number(userId),
        body: {
          password: {
            currentPassword: values.password,
            newPassword: values.newPassword,
            confirmation: values.confirmNewPassword,
          },
        },
      })
      .then(() => {
        toast.success(Translator('Senha alterada com sucesso!'));
      })
      .catch(() => {
        // TODO: Adicionar mensagem específica para senhas que não batem
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Mudança de senha'),
          content: Translator('Não foi possível alterar a senha.'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      });
  };

  return (
    <Formik
      validationSchema={passwordRecoverSchema}
      initialValues={initialValues}
      onSubmit={() => {}}
      validateOnChange
      isInitialValid
    >
      {({ values, errors, touched, handleBlur, setFieldValue }) => (
        <Container
          onSubmit={e => {
            e.preventDefault();
            onSubmit(values);
          }}
        >
          <h2>Alterar senha</h2>
          <ContentFields>
            <Fields>
              <Input
                name="password"
                label={Translator('Senha Atual')}
                placeholder={Translator('Digite a senha atual')}
                value={currentPassword}
                onChange={e => {
                  setActualPassword(e.target.value);
                  setFieldValue('password', e.target.value);
                }}
                type="password"
                autoFocus
                onBlur={handleBlur}
                message={touched.password ? errors.password : ''}
                error={Boolean(touched.password && errors?.password)}
              />
              <Input
                name="newPassword"
                label={Translator('Nova Senha')}
                placeholder={Translator('Digite a nova senha')}
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                  setFieldValue('newPassword', e.target.value);
                }}
                type="password"
                onBlur={handleBlur}
                message={touched.newPassword ? errors.newPassword : ''}
                error={Boolean(touched.newPassword && errors?.newPassword)}
              />
              <Input
                name="confirmNewPassword"
                label={Translator('Confirmar Nova Senha')}
                placeholder={Translator('Digite a confirmação da nova senha')}
                value={confirmation}
                onChange={e => {
                  setConfirmation(e.target.value);
                  setFieldValue('confirmNewPassword', e.target.value);
                }}
                type="password"
                onBlur={handleBlur}
                message={
                  touched.confirmNewPassword ? errors.confirmNewPassword : ''
                }
                error={Boolean(
                  touched.confirmNewPassword && errors?.confirmNewPassword,
                )}
              />
            </Fields>
          </ContentFields>
          <ContentButton>
            <Button
              size="small"
              type="submit"
              disabled={
                Boolean(errors?.password) ||
                Boolean(errors?.newPassword) ||
                Boolean(errors?.confirmNewPassword) ||
                values.password === '' ||
                values.newPassword === '' ||
                values.confirmNewPassword === ''
              }
            >
              Salvar
            </Button>
          </ContentButton>
        </Container>
      )}
    </Formik>
  );
};

export default ChangePassword;
