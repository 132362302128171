import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Fields = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
`;

export const ContentInput = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 2.9rem;

  margin-top: 30px;
`;

export const ContentButton = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  margin-top: 50px;
`;
