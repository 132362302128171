import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListByOrgUnitOrgUser as UsecaseRemoteListByOrgUnitOrgUser } from 'domain/usecases/orgUser/remote';
import { ListOrgUserByOrgUnitId as UsecaseReduxListOrgUserByOrgUnitId } from 'domain/usecases/orgUser/redux';

import { RemoteListByOrgUnitOrgUser } from 'data/repository/orgUser';
import { ReduxListOrgUserByOrgUnitId } from 'data/store/reducer/orgUsers/usecases';

/**
 * send request via API.
 */
export const makeRemoteListByOrgUnitOrgUser =
  (): UsecaseRemoteListByOrgUnitOrgUser =>
    new RemoteListByOrgUnitOrgUser(
      makeApiUrl('/orgs/{orgId}/units/{unitId}/users/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListByOrgUnitOrgUser =
  (): UsecaseReduxListOrgUserByOrgUnitId => new ReduxListOrgUserByOrgUnitId();
