import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgUnitUsers as UsecaseRemoteListOrgUnitUsers } from 'domain/usecases/orgUnit/remote';
import { ListOrgUnitByOrgUnitId as UsecaseReduxListOrgUnitUsers } from 'domain/usecases/orgUnit/redux';

import { RemoteListOrgUnitUsers } from 'data/repository/orgUnit';
import { ReduxListOrgUnitByOrgUnitId } from 'data/store/reducer/orgUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgUnitUsers = (): UsecaseRemoteListOrgUnitUsers =>
  new RemoteListOrgUnitUsers(
    makeApiUrl('/orgs/{orgId}/units/{unitId}/units/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUnitUsers = (): UsecaseReduxListOrgUnitUsers =>
  new ReduxListOrgUnitByOrgUnitId();
