import { createIntl, createIntlCache } from 'react-intl';
import { getLocale } from 'utils/getLocale';
import { defaultTranslations, psiTranslations } from 'infra/i18n/locales';
import { useContext } from 'react';
import { ThemeContext } from 'App';

const cache = createIntlCache();

const Translator = (data: string): string => {
  try {
    const theme = useContext(ThemeContext);

    const intl = createIntl(
      {
        locale: String(getLocale()),
        messages:
          theme.theme.systemName === 'V4HPsi' ||
          theme.theme.systemName === 'WisePsi'
            ? psiTranslations[getLocale()]
            : defaultTranslations[getLocale()],
      },
      cache,
    );

    return intl.formatMessage({ id: data });
  } catch (error) {
    // console.warn('>>> Failed to translate', error);

    return data;
  }
};

export default Translator;
