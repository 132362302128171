import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListConferenceFilteredByACL as UsecaseRemoteListConferenceFilteredByACL } from 'domain/usecases/conference/remote';
import { ListConferenceFilteredByACL as UsecaseReduxListConferenceFilteredByACL } from 'domain/usecases/conference/redux';

import { RemoteListConferenceFilteredByACL } from 'data/repository/conference';
import { ReduxListConferenceFilteredByACL } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListConferenceFilteredByACL =
  (): UsecaseRemoteListConferenceFilteredByACL =>
    new RemoteListConferenceFilteredByACL(
      makeApiUrl('/conferences/acl-filtered'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListConferenceFilteredByACL =
  (): UsecaseReduxListConferenceFilteredByACL =>
    new ReduxListConferenceFilteredByACL();
