import { DownloadConferenceTranscription } from 'domain/usecases/conference/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDownloadConferenceTranscription
  implements DownloadConferenceTranscription
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadConferenceTranscription.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadConferenceTranscription.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: DownloadConferenceTranscription.Params,
  ): Promise<DownloadConferenceTranscription.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{conferenceId}', `${params.conferenceId}`),
      method: 'get',
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
      case HttpStatusCode.notModified:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
