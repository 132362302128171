import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { AfterConference as UsecaseRemoteAfterConference } from 'domain/usecases/conference/remote';
// import { AfterConference as UsecaseReduxAfterConference } from 'domain/usecases/conference/redux';

import { RemoteAfterConference } from 'data/repository/conference';
// import { ReduxAfterConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteAfterConference = (): UsecaseRemoteAfterConference =>
  new RemoteAfterConference(
    makeApiUrl('/conferences/{short}/after-conf/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxAfterConference =
  (): UsecaseReduxAfterConference =>
    new ReduxAfterConference(); */
