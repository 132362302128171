import React from 'react';
import {
  Container,
  Description,
  DescriptionDiv,
  Header,
  Message,
  MessageDiv,
  Subtitle,
  Title,
  UserNameDiv,
} from './styles/StyledSacWaitingRoomInfoCard';
import { Button } from '../UI';

const SacWaitingRoomInfoCard: React.FC = () => {
  return (
    <Container>
      <Header>
        <Title>Licenciamento</Title>
      </Header>
      <DescriptionDiv>
        <MessageDiv>
          <Message>
            <div className="message">
              Seja bem-vindo à sala de espera. Seu atendimento começará em breve
              e você será notificado quando chegar a sua vez.
            </div>
            <div className="info">Mensagem automática - 00:00</div>
          </Message>
          <Message>
            <div className="message">
              Estamos aguardando um atendente disponível atender a sua
              solicitação. Fique atento.
            </div>
            <div className="info">Mensagem automática - 00:00</div>
          </Message>
        </MessageDiv>
      </DescriptionDiv>
      <UserNameDiv>
        <Button variant="primary" type="button">
          Cancelar solicitação
        </Button>
      </UserNameDiv>
    </Container>
  );
};

export default SacWaitingRoomInfoCard;
