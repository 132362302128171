import React, { useEffect } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import Translator from 'presentation/components/i18n/Translator';
import { IconSuccessIcon, IconWarning } from 'presentation/base/icons';

import { Button } from '../../UI';
import { ownProps } from '../interface';
import {
  Body,
  Container,
  Content,
  Header,
  ButtonsContainer,
} from './styles/StyledConfirmModal';

const ConfirmModal: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.confirm;

  const { active, actionOk, actionCancel, content, title } = message;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{title}</span>
            </Header>
            <Content hasContent={!!content}>
              <IconWarning width="60px" height="60px" />
              <>{content && <p>{content}</p>}</>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                rounded
                variant="secundary"
                size="small"
                autoFocus
                onClick={actionCancel}
              >
                {Translator('Cancelar')}
              </Button>
              <Button
                id="button_confirm"
                rounded
                variant="primary"
                size="small"
                autoFocus
                onClick={actionOk}
              >
                {Translator('Confirmar')}
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default ConfirmModal;
