import React, { useState, useMemo } from 'react';

import { Icon } from '@wisecare-tech/design-system-web';
import { iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import { getRole } from 'utils/getRole';
import {
  Container,
  Item,
  ItemsContainer,
  PBMeetLogo,
} from './styles/sidebarStyles';
import {
  Notes,
  RequestDetails,
  ServiceTransfer,
  SupportDocuments,
} from '../sidebarComponents';

enum SidebarOptions {
  NONE = 0,
  NOTES = 1,
  REQUESTDETAILS = 2,
  SUPPORTDOCS = 3,
  SERVICETRANSFER = 4,
}

export interface ParamsState {
  appointmentId: string;
}

const Sidebar: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<number>(
    SidebarOptions.NONE,
  );

  const { user } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const renderContent = useMemo(() => {
    switch (selectedOption) {
      case SidebarOptions.REQUESTDETAILS:
        return (
          <RequestDetails
            onClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      case SidebarOptions.SUPPORTDOCS:
        return (
          <SupportDocuments
            onClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      case SidebarOptions.NOTES:
        return <Notes onClose={() => setSelectedOption(SidebarOptions.NONE)} />;
      case SidebarOptions.SERVICETRANSFER:
        return (
          <ServiceTransfer
            onClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      default:
        return <div />;
    }
  }, [selectedOption]);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Container>
        <ItemsContainer>
          <Item
            selected={selectedOption === SidebarOptions.REQUESTDETAILS}
            onClick={() => {
              setSelectedOption(SidebarOptions.REQUESTDETAILS);
            }}
          >
            {selectedOption === SidebarOptions.REQUESTDETAILS ? (
              <Icon name="info" color="white-1" size="h3" />
            ) : (
              <Icon name="info" color="blue-7" size="h3" />
            )}
          </Item>

          <Item
            selected={selectedOption === SidebarOptions.SUPPORTDOCS}
            onClick={() => {
              setSelectedOption(SidebarOptions.SUPPORTDOCS);
            }}
          >
            {selectedOption === SidebarOptions.SUPPORTDOCS ? (
              <Icon name="description" color="white-1" size="h3" />
            ) : (
              <Icon name="description" color="blue-7" size="h3" />
            )}
          </Item>

          {role !== 'STANDARD' && (
            <Item
              selected={selectedOption === SidebarOptions.NOTES}
              onClick={() => {
                setSelectedOption(SidebarOptions.NOTES);
              }}
            >
              {selectedOption === SidebarOptions.NOTES ? (
                <Icon name="note_alt" color="white-1" size="h3" />
              ) : (
                <Icon name="note_alt" color="blue-7" size="h3" />
              )}
            </Item>
          )}

          {role !== 'STANDARD' && (
            <Item
              selected={selectedOption === SidebarOptions.SERVICETRANSFER}
              onClick={() => {
                setSelectedOption(SidebarOptions.SERVICETRANSFER);
              }}
            >
              {selectedOption === SidebarOptions.SERVICETRANSFER ? (
                <Icon name="swap_horiz" color="white-1" size="h3" />
              ) : (
                <Icon name="swap_horiz" color="blue-7" size="h3" />
              )}
            </Item>
          )}
        </ItemsContainer>
        <PBMeetLogo />
      </Container>
      {renderContent}
    </div>
  );
};

export default Sidebar;
