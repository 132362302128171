/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-one-expression-per-line */
import _ from 'lodash';
import Select from 'presentation/components/UI/select';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { IconPdf2 } from 'presentation/base/icons';
import { Icon } from 'presentation/components/icon';

import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'App';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteGetBillingByDay } from 'main/factories/usecases/billing/GetBillingByDayFactory';
import { makeRemoteGetBillingByOrgUnit } from 'main/factories/usecases/billing/GetBillingByOrgUnitFactory';
import { makeRemoteGetBillingByService } from 'main/factories/usecases/billing/GetBillingByServiceFactory';
import { makeRemoteGetBillingByUser } from 'main/factories/usecases/billing/GetBillingByUserFactory';
import { makeRemoteGetBillingDetailed } from 'main/factories/usecases/billing/GetBillingDetailedFactory';
import { makeRemoteGetBilling } from 'main/factories/usecases/billing/GetBillingFactory';
import Pagination from 'presentation/components/Pagination';
import Translator from 'presentation/components/i18n/Translator';
import { ListBilling } from 'presentation/components/list';
import { toast } from 'react-toastify';
import { currencyConvert } from 'utils/formatCurrency';
import { makeRemoteGetBillingReportPDF } from 'main/factories/usecases/billing/GetBillingReportPDF';
import {
  Container,
  Content,
  ContentBilling,
  ContentList,
  Header,
  HeaderButtonItem,
  HeaderButtons,
  HeaderData,
  HeaderDataItem,
  HeaderFilter,
  HeaderFilterItem,
  HeaderText,
} from './styles/StyledBilling';

interface iParams {
  id: string;
}

interface iBillingDetail {
  month: string;
  visualization:
    | 'orgUnit'
    | 'service'
    | 'user'
    | 'day'
    | 'serviceAndDay'
    | 'orgUnitAndDay'
    | 'serviceAndOrgUnit'
    | 'serviceAndUser'
    | 'detailed';
  startDate: string;
  endDate: string;
  dueDate: string;
  total: string;
  isPaid: boolean;
  selectedUnit: number;
  selectedUser: number;
}

type monthsKeys =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12';

const MONTHS = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro',
};

const formatDate = (date: string): string => {
  const [year, month, day] = date.split('-');

  return `${day}/${month}/${year}`;
};

const BillingPage: React.FC = (): JSX.Element => {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const formattedActualDate = useMemo(
    () => ({
      value: `${String(new Date().getMonth() + 1).padStart(
        2,
        '0',
      )}-${new Date().getFullYear()}`,
      label: `${
        MONTHS[String(new Date().getMonth() + 1).padStart(2, '0') as monthsKeys]
      } ${new Date().getFullYear()}`,
    }),
    [],
  );

  const [billingDetail, setBillingDetail] = useState<iBillingDetail>({
    month: formattedActualDate.value,
    visualization: 'orgUnit',
    startDate: firstDayOfMonth.toISOString().split('T')[0],
    endDate: lastDayOfMonth.toISOString().split('T')[0],
    dueDate: lastDayOfMonth.toISOString().split('T')[0],
    total: '0',
    isPaid: false,
    selectedUnit: 0,
    selectedUser: 0,
  });
  const [months, setMonths] = useState<
    { value: string; label: string; isPaid: boolean }[]
  >([]);
  const [data, setData] = useState<any>([]);
  const [currency, setCurrency] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const [isDownloadingReport, setIsDownloadingReport] =
    useState<boolean>(false);

  const { records: unitsRecords } = useSelector(
    (store: iStore) => store.orgUnits,
  );
  const { records: orgUsersRecords } = useSelector(
    (store: iStore) => store.orgUsers,
  );

  const { id } = useParams<iParams>();
  const { theme } = useContext(ThemeContext);

  const itemsPerPage = 12;

  const mockData = useMemo(
    () => [
      {
        id: 1,
        fullname: `${Translator('Recursos Humanos')}`,
        short: 'DRH',
        total: '1,20',
      },
      {
        id: 2,
        fullname: `${Translator('Centro de Atendimento ao cliente')}`,
        short: 'CAC',
        total: '2,04',
      },
      {
        id: 3,
        fullname: `${Translator('Manutenção')}`,
        short: 'MANUT',
        total: '0,19',
      },
      {
        id: 4,
        fullname: `${Translator('Tecnologia da Informação')}`,
        short: 'TI',
        total: '1,99',
      },
      {
        id: 5,
        fullname: `${Translator('Serviços Gerais')}`,
        short: 'SGER',
        total: '2,05',
      },
      {
        id: 6,
        fullname: `${Translator('Assessoria')}`,
        short: 'AS1',
        total: '2,32',
      },
      {
        id: 7,
        fullname: `${Translator('Segurança')}`,
        short: 'SEG',
        total: '1,99',
      },
      {
        id: 8,
        fullname: `${Translator('Assessoria de Imprensa')}`,
        short: 'AS2',
        total: '2,32',
      },
    ],
    [],
  );

  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;

    return data?.slice(start, start + itemsPerPage);
  }, [data, page]);

  const handleMonthChange = useCallback(
    (value: string) => {
      const [month, year] = value.split('-');

      const isPaid = months.find(
        monthItem => monthItem.value === value && monthItem.isPaid,
      );

      const lastDay = new Date(Number(year), Number(month), 0).getDate();

      setBillingDetail({
        ...billingDetail,
        month: value,
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-${lastDay}`,
        isPaid: !!isPaid,
      });
    },
    [billingDetail, months],
  );

  const handleDownloadReport = useCallback(async () => {
    try {
      setIsDownloadingReport(true);

      toast.warning(
        'Seu relatório será aberto em uma nova aba assim que estiver pronto.',
        {
          position: 'top-right',
          autoClose: 2500,
        },
      );

      const pdfStringResponse = await makeRemoteGetBillingReportPDF().download({
        orgId: Number(id),
        query: {
          type: billingDetail.visualization.toLocaleUpperCase(),
          begin: billingDetail.startDate,
          end: billingDetail.endDate,
          orgUnit: billingDetail.selectedUnit || undefined,
          user: billingDetail.selectedUser || undefined,
          limit: 9999,
        },
      });

      const blob = new Blob([pdfStringResponse], { type: 'application/pdf' });
      const urlToPdf = window.URL.createObjectURL(blob);

      window.open(urlToPdf, '_blank');
    } catch (err) {
      toast.error('Erro ao gerar relatório. Tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [id, billingDetail]);

  useEffect(() => {
    if (!id) return;

    makeRemoteGetBilling()
      .get({
        orgId: Number(id),
        query: {
          limit: 9999,
        },
      })
      .then(res => {
        const monthsWithBilling = res.records.map(item => {
          const itemMonth = item.begin.split('-')[1] as monthsKeys;
          const itemYear = item.begin.split('-')[0];

          return {
            value: `${itemMonth}-${itemYear}`,
            label: `${MONTHS[itemMonth]} ${itemYear}`,
            isPaid: Boolean(item.paid),
            orderParam: `${itemYear}-${itemMonth}`,
          };
        });

        const orderedMonthsByMostRecent = _.orderBy(
          monthsWithBilling,
          item => item.orderParam,
          ['desc'],
        );

        setMonths(orderedMonthsByMostRecent);
      })
      .catch(err => {
        console.log('err: ', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!billingDetail.visualization) return;

    switch (billingDetail.visualization) {
      case 'orgUnit':
        makeRemoteGetBillingByOrgUnit()
          .get({
            orgId: Number(id),
            query: {
              begin: billingDetail.startDate,
              end: billingDetail.endDate,
              orgUnit: billingDetail.selectedUnit || undefined,
              user: billingDetail.selectedUser || undefined,
              limit: 9999,
            },
          })
          .then(res => {
            setBillingDetail(prevState => ({
              ...prevState,
              dueDate: new Date(res.endTime).toISOString().split('T')[0],
              total: String(
                res.orgUnits.reduce((acc, item) => {
                  return acc + Number(item.total);
                }, 0),
              ),
            }));

            setData(res.orgUnits);
            setCurrency(res?.currency);
          })
          .catch(err => {
            console.log('err: ', err);
          });
        break;
      case 'service':
        makeRemoteGetBillingByService()
          .get({
            orgId: Number(id),
            query: {
              begin: billingDetail.startDate,
              end: billingDetail.endDate,
              orgUnit: billingDetail.selectedUnit || undefined,
              user: billingDetail.selectedUser || undefined,
              limit: 9999,
            },
          })
          .then(res => {
            setBillingDetail(prevState => ({
              ...prevState,
              dueDate: new Date(res.endTime).toISOString().split('T')[0],
              total: String(
                res.services.reduce((acc, item) => {
                  return acc + Number(item.total);
                }, 0),
              ),
            }));

            setData(res.services);
            setCurrency(res?.currency);
          })
          .catch(err => {
            console.log('err: ', err);
          });
        break;
      case 'user':
        makeRemoteGetBillingByUser()
          .get({
            orgId: Number(id),
            query: {
              begin: billingDetail.startDate,
              end: billingDetail.endDate,
              orgUnit: billingDetail.selectedUnit || undefined,
              user: billingDetail.selectedUser || undefined,
              limit: 9999,
            },
          })
          .then(res => {
            const lastDayOfResEndTime = new Date(
              Number(res.endTime.split('-')[0]),
              Number(res.endTime.split('-')[1]),
              0,
            );

            setBillingDetail(prevState => ({
              ...prevState,
              dueDate: lastDayOfResEndTime.toISOString().split('T')[0],
              total: String(
                res.users.reduce((acc, item) => {
                  return acc + Number(item.total);
                }, 0),
              ),
            }));

            setData(res.users);
            setCurrency(res?.currency);
          })
          .catch(err => {
            console.log('err: ', err);
          });
        break;
      case 'day':
        makeRemoteGetBillingByDay()
          .get({
            orgId: Number(id),
            query: {
              begin: billingDetail.startDate,
              end: billingDetail.endDate,
              orgUnit: billingDetail.selectedUnit || undefined,
              user: billingDetail.selectedUser || undefined,
              limit: 9999,
            },
          })
          .then(res => {
            const lastDayOfResEndTime = new Date(
              Number(res.endTime.split('-')[0]),
              Number(res.endTime.split('-')[1]),
              0,
            );

            setBillingDetail(prevState => ({
              ...prevState,
              dueDate: lastDayOfResEndTime.toISOString().split('T')[0],
              total: String(
                res.days.reduce((acc, item) => {
                  return acc + Number(item.total);
                }, 0),
              ),
            }));

            setData(res.days);
            setCurrency(res?.currency);
          })
          .catch(err => {
            console.log('err: ', err);
          });

        break;
      case 'detailed':
        makeRemoteGetBillingDetailed()
          .post({
            orgId: Number(id),
            begin: billingDetail.startDate,
            end: billingDetail.endDate,
            orgUnit: billingDetail.selectedUnit || undefined,
            user: billingDetail.selectedUser || undefined,
            limit: 9999,
          })
          .then(res => {
            const lastDayOfResEndTime = new Date(
              Number(res.endTime.split('-')[0]),
              Number(res.endTime.split('-')[1]),
              0,
            );

            setBillingDetail(prevState => ({
              ...prevState,
              dueDate: lastDayOfResEndTime.toISOString().split('T')[0],
              total: String(
                res?.orgs?.[0]?.billings?.reduce((acc, item) => {
                  return acc + Number(item?.services?.total);
                }, 0),
              ),
            }));

            setData(res?.orgs?.[0]?.billings);
            setCurrency(res?.currency);
          })
          .catch(err => {
            console.log('err: ', err);
          });

        break;
      default:
        break;
    }
  }, [
    id,
    billingDetail?.visualization,
    billingDetail?.startDate,
    billingDetail?.endDate,
    billingDetail?.selectedUnit,
    billingDetail?.selectedUser,
  ]);

  return (
    <Container>
      <Header>
        <HeaderFilter>
          <HeaderFilterItem>
            <HeaderText>{Translator('Mês')}:</HeaderText>
            <Select
              data-testid="month-filter"
              name="month-filter"
              border={false}
              width="75%"
              height="30px"
              value={billingDetail.month}
              onChange={e => handleMonthChange(e.target.value as string)}
            >
              <option
                id={formattedActualDate.value}
                value={formattedActualDate.value}
              >
                {formattedActualDate.label}
              </option>
              {months.map(item => (
                <option key={item.value} id={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </HeaderFilterItem>
          <HeaderFilterItem>
            <HeaderText>{Translator('Visualização')}:</HeaderText>
            <Select
              data-testid="visualization-filter"
              name="visualization-filter"
              border={false}
              width="75%"
              height="30px"
              value={billingDetail.visualization}
              onChange={e =>
                setBillingDetail({
                  ...billingDetail,
                  visualization: e.target.value as any,
                })
              }
            >
              <option id="use-by-unit" value="orgUnit">
                {Translator('Consumo por setor')}
              </option>
              <option id="use-by-service" value="service">
                {Translator('Consumo por serviço')}
              </option>
              <option id="use-by-user" value="user">
                {Translator('Consumo por usuário')}
              </option>
              <option id="use-by-day" value="day">
                {Translator('Consumo por dia do mês')}
              </option>
              <option id="use-by-detailed" value="detailed">
                {Translator('Extrato de consumo detalhado')}
              </option>
            </Select>
          </HeaderFilterItem>
        </HeaderFilter>
        <HeaderData>
          <HeaderDataItem>
            <HeaderText>
              {`${Translator('Vigência')}: ${formatDate(
                billingDetail.startDate,
              )} a ${formatDate(billingDetail.endDate)}`}
            </HeaderText>
          </HeaderDataItem>
          <HeaderDataItem>
            <HeaderText>
              {`${Translator('Vencimento')}: ${formatDate(
                billingDetail.dueDate,
              )}`}
            </HeaderText>
          </HeaderDataItem>
          <HeaderDataItem>
            <HeaderText>{Translator('Valor Total')}:</HeaderText>
            <HeaderText fontWeight="bold" color="#1756E8">
              {`R$ ${currencyConvert(billingDetail.total)}`}
            </HeaderText>
          </HeaderDataItem>
          {/* <HeaderDataItem>
            <HeaderText>Pagamento:</HeaderText>
            <HeaderText
              fontWeight="bold"
              color={
                billingDetail.isPaid
                  ? '#5BB974'
                  : `${theme.colors.primary.main}`
              }
            >
              {`${billingDetail.isPaid ? 'Pago' : 'Pendente'}`}
            </HeaderText>
          </HeaderDataItem> */}
        </HeaderData>
        <HeaderButtons>
          <HeaderButtonItem
            disabled={
              isDownloadingReport || billingDetail.visualization === 'detailed'
            }
            onClick={handleDownloadReport}
          >
            {isDownloadingReport ? (
              <CircularProgress size={40} color="error" />
            ) : (
              <Icon
                className="iconOrg"
                src={IconPdf2}
                width="40px"
                height="40px"
              />
            )}
            <HeaderText color="#929096">
              {isDownloadingReport ? 'Processando' : 'Baixar PDF'}
            </HeaderText>
          </HeaderButtonItem>
        </HeaderButtons>
        <HeaderFilter>
          <HeaderFilterItem>
            <HeaderText>{Translator('Setor')}: </HeaderText>
            <Select
              data-testid="unit-filter"
              name="unit-filter"
              border={false}
              width="75%"
              height="30px"
              value={billingDetail.selectedUnit}
              onChange={e =>
                setBillingDetail({
                  ...billingDetail,
                  selectedUnit: Number(e.target.value),
                })
              }
            >
              <option key="all-units" value={0}>
                {Translator('Todos')}
              </option>
              {unitsRecords?.map(unit => {
                if (unit.org?.id !== Number(id)) return <></>;

                return (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                );
              })}
            </Select>
          </HeaderFilterItem>
          <HeaderFilterItem>
            <HeaderText>{Translator('Usuário')}: </HeaderText>
            <Select
              data-testid="user-filter"
              name="user-filter"
              border={false}
              width="75%"
              height="30px"
              value={billingDetail.selectedUser}
              onChange={e =>
                setBillingDetail({
                  ...billingDetail,
                  selectedUser: Number(e.target.value),
                })
              }
            >
              <option id="all-users" value={0}>
                {Translator('Todos')}
              </option>
              {orgUsersRecords?.map(user => {
                if (user.orgUnit?.org?.id !== Number(id)) return <></>;

                return (
                  <option key={user?.user?.id} value={user?.user?.id}>
                    {user.user?.fullName}
                  </option>
                );
              })}
            </Select>
          </HeaderFilterItem>
        </HeaderFilter>
      </Header>
      <Content>
        <ContentBilling>
          <Pagination
            currentPage={page}
            pageSize={Math.ceil(mockData.length / itemsPerPage)}
            setPage={setPage}
            disableMarginTop
          />
        </ContentBilling>
        <ContentList>
          <ListBilling
            dataList={displayData}
            visualization={billingDetail.visualization}
            currency={currency}
          />
        </ContentList>
      </Content>
    </Container>
  );
};

export default BillingPage;
