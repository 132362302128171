import { call, put, select } from 'redux-saga/effects';
import { iActionList } from 'domain/interfaces/redux/org/list';
import { ListOrg } from 'domain/usecases/org/remote/ListOrg';
import { makeRemoteListOrg } from 'main/factories/usecases/org/ListOrgFactory';
import { listSuccess, listFailed } from 'data/store/reducer/org/actions/list';

export function* onList(action: iActionList) {
  const remoteListOrg = makeRemoteListOrg();

  try {
    const response: ListOrg.Model = yield call(
      remoteListOrg.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
