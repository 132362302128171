import { Avatar } from '@mui/material';
import Table, { tableClasses } from '@mui/material/Table';
import TableBody, { tableBodyClasses } from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer, {
  tableContainerClasses,
} from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import {
  IconCalendarDashboard,
  IconCamDashboard,
  IconOrgDashboard,
  IconStorageDashboard,
} from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import { Pagination } from 'presentation/pages/RankingOrg/Pagination';
import React, { useEffect, useMemo, useState } from 'react';
import { pxToRem } from 'utils/pixelToRem';
import { CellContent, User } from './styles/styledListUserInfo';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    maxHeight: '55vh',
    minHeight: '55vh',
    marginBottom: `${pxToRem(24)}`,
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: pxToRem(8),
  borderCollapse: 'collapse',
  maxHeight: '30%',
  [`&.${tableClasses.stickyHeader}`]: {},
}));

const StyledTableCell = styled(TableCell)(({ theme, className }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: '#A6A6A6',
    border: 'none',
    padding: '0 16px',
    marginBottom: '16px',
    textTransform: 'uppercase',
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: pxToRem(16),
    fontWeight: '600',
    lineHeight: pxToRem(16),
    color: '#233242',
    gap: '8px',
    paddingLeft: '0px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  [`&.${tableBodyClasses.root}`]: {
    border: '0.5px solid rgba(105, 127, 150, 0.25)',
    // make a border radius for the first and last cell
  },
}));

interface ownProps {
  ranking?: {
    metadata: {
      limit: number;
      offset: number;
      total: number;
    };
    records: {
      name: string;
      avatar: string;
      role: string;
      org: string;
      scheduledConferences: number;
      recordedConferences: number;
      storage: number;
    }[];
  };
}

const ListUserInfo: React.FC<ownProps> = ({ ranking }): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 5;

  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;

    const rankingWithIdx = ranking?.records.map((item, idx) => {
      return {
        ...item,
        idx: idx + 1,
      };
    });

    return rankingWithIdx?.slice(start, start + itemsPerPage) || [];
  }, [ranking, page]);

  useEffect(() => {
    setPage(1);
  }, [ranking])

  return (
    <StyledTableContainer>
      <StyledTable sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>{Translator('Nome do usuário')}</StyledTableCell>
            <StyledTableCell>{Translator('Organização')}</StyledTableCell>
            <StyledTableCell>{Translator('Agendamentos')}</StyledTableCell>
            <StyledTableCell>{Translator('Gravações')}</StyledTableCell>
            <StyledTableCell>{Translator('Armazenamento')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {displayData?.map(row => (
            <StyledTableRow key={`${row.idx}-${row.name}`}>
              <StyledTableCell component="th" scope="row">
                <CellContent
                  style={{ justifyContent: 'center', padding: 'none' }}
                >
                  {`${row.idx}º`}
                </CellContent>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{
                  paddingLeft: '0px',
                }}
              >
                <CellContent>
                  <Avatar sx={{ fontSize: 16, fontWeight: '400' }}>OP</Avatar>
                  <User>
                    <span className="name">{row.name}</span>
                    <span className="role">{row.role}</span>
                  </User>
                </CellContent>
              </StyledTableCell>
              <StyledTableCell>
                <CellContent>
                  <IconOrgDashboard />
                  <span>{row.org}</span>
                </CellContent>
              </StyledTableCell>
              <StyledTableCell>
                <CellContent>
                  <IconCalendarDashboard />
                  {row.scheduledConferences}
                </CellContent>
              </StyledTableCell>
              <StyledTableCell>
                <CellContent>
                  <IconCamDashboard />
                  {row.recordedConferences}
                </CellContent>
              </StyledTableCell>
              <StyledTableCell>
                <CellContent>
                  <IconStorageDashboard />
                  {`${row.storage} GB`}
                </CellContent>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
      <Pagination
        itemsPerPage={itemsPerPage}
        total={ranking?.records.length || 0}
        activePage={page}
        onChangePage={setPage}
      />
    </StyledTableContainer>
  );
};

export default ListUserInfo;
