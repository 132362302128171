import styled from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

interface ownProps {
  renderButton?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentSectors = styled.div<ownProps>`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: ${({ renderButton }) => renderButton && '26px'};
  margin-bottom: ${({ renderButton }) => renderButton && '12px'};
  align-items: center;
`;

export const Body = styled.div`
  max-width: 1920px;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 60px 35px;
  }
`;

export const Left = styled.div`
  height: 100%;

  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
  }
`;

export const Right = styled.div`
  height: 100%;
  min-height: calc(100vh - 213px);
  width: 100%;

  .title {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 21px;
  }

  .summary {
    display: flex;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .graph {
    width: 100%;
  }

  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .title {
      font-size: 18px;
      margin: 0px;
    }

    .buttons {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    .label {
      margin: 0px 10px 0px 22px;
    }
  }

  @media (max-width: 1024px) {
    box-shadow: none;
    margin: 0;
    padding: 31px 0 0 0;
    width: 100%;
  }
`;

export const Cards = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const CardOne = styled.div`
  width: 180px;
  height: 119px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  font-weight: 500;
  font-size: 18px;

  .blue {
    color: #45b4f2;
  }

  .green {
    color: #45f275;
  }

  .red {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .purple {
    color: #471eed;
  }

  .title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }
  }

  .value {
    font-weight: bold;
    font-size: 48px;
    text-align: center;
  }

  @media (max-width: 1024px) {
    width: 25%;
    margin: 0px 3px;
    padding-left: 10px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const CardTwo = styled.div`
  width: 213px;
  height: 99px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 18px;
  color: #494949;

  .value {
    font-weight: bold;
    font-size: 42px;
    color: #471eed;
    text-align: center;
  }

  @media (max-width: 1024px) {
    margin: 0px 5px 20px 5px;
    width: 33%;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
  height: 318px;
  margin-left: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  .header {
    height: 84px;
    background: rgba(71, 30, 237, 0.1);
    font-weight: bold;
    font-size: 36px;
    display: flex;
    align-items: center;
    padding: 21px;
    border-radius: 8px 8px 0px 0px;
  }

  .body {
    padding: 0px 19px;
    display: flex;
  }

  .colTitle {
    width: calc(100% - 220px);
    padding: 69px 0px 13px 0px;

    div {
      border-bottom: 1px solid #a6a6a6;
      padding: 10px 0px 7px 0px;
      color: #471eed;

      &:last-child {
        border: none;
      }
    }
  }

  .colValue {
    width: 90px;
    padding: 27px 0px 0px 0px;
    text-align: end;

    .title {
      font-weight: normal;
      font-size: 18px;
      color: #471eed;
    }

    .value {
      border-bottom: 1px solid #a6a6a6;
      padding: 10px 0px 7px 0px;
      color: #494949;

      &:last-child {
        border: none;
      }
    }

    &:last-child {
      width: 130px;
    }
  }

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const ContainerMenu = styled.div`
  /* background: white;
  width: 100%;
  box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, 0.25); */
  /* padding: 0px 20px; */
  border-bottom: 1px solid #656a6e;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0px auto;
  height: 52px;
  /* padding: 10px 0px; */
  color: #656a6e;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResponsiveMenu = styled.div`
  display: none;
  width: 32px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
    margin-right: 15px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
`;
export const ButtonBack = styled.div``;

export const LeftMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const RightMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* gap: 5px; */
`;

export const Options = styled.div`
  height: 100%;
  /* padding: 20px 10px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 18px;
  color: black;

  :hover {
    border-bottom: 2px solid red;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Avatar = styled.div`
  height: 100%;
  margin-left: 20px;
  cursor: pointer;
`;

export const Drop = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 15px;
  color: black;
  background: white;
  cursor: pointer;
  border: none;

  span {
    margin-left: 15px;
  }

  :hover {
    background: ${({ theme }) => `${theme.colors.primary.main}1a`};
  }
`;

export const DropInfo = styled.div`
  height: 81px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 20px;

  .name {
    font-weight: bold;
    font-size: 18px;
  }

  .role {
    font-size: 12px;
  }
`;

export const UserOptions = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 8px 8px;
  width: 235px;
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }
`;

export const DropUnit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: black;
  border: none;
`;

export const RowContainer = withAccess(styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 18px 20px;
  gap: 18px;

  :hover {
    background: ${({ theme }) => `${theme.colors.primary.main}1a`};
  }
`);
