import React, { useEffect, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GetConferenceDataDashboard } from 'domain/usecases/dashboard/remote';

import {
  OrgBlue,
  UnitGreen,
  UserRed,
  CamPurple,
  IconCardMeets,
  IconCardConsumption,
  IconCardRecordings,
  IconCardStorage,
  IconCardHistory,
  IconCardParticipants,
  IconCardClock,
} from 'presentation/base/icons';
import { Select } from 'presentation/components/UI';

import Translator from 'presentation/components/i18n/Translator';
import {
  Container,
  Card,
  CardInfo,
  Separator,
} from './styles/styledGeneralData';

const COLORS = ['#7E79E8', '#E3E3E3'];

interface ownProps {
  conferenceData: GetConferenceDataDashboard.Model;
  conferenceHistory: {
    time: string;
    records: number;
    notRecords: number;
    hoursRecorded: number;
  }[];
}

interface iData {
  name: string;
  value: number;
}

const GeneralData: React.FC<ownProps> = ({
  conferenceData,
  conferenceHistory,
}): JSX.Element => {
  const [data, setData] = useState<iData[]>([
    { name: 'Filled', value: 0 },
    { name: 'Empty', value: 100 },
  ]);

  const [visualization, setVisualization] = useState<'hours' | 'amount'>(
    'amount',
  );

  const percentageUsed = useMemo(() => {
    if (!conferenceData.storage?.total || !conferenceData.storage?.available) {
      return 0;
    }
    return Math.round(
      (conferenceData.storage?.total - conferenceData.storage?.available) /
        conferenceData.storage?.total,
    );
  }, [conferenceData.storage?.available, conferenceData.storage?.total]);

  useEffect(() => {
    setData([
      { name: 'Filled', value: percentageUsed },
      { name: 'Empty', value: 100 - percentageUsed },
    ]);
  }, [percentageUsed]);

  return conferenceData.conferences ? (
    <Container>
      <div
        className="line"
        style={{
          height: '20%',
        }}
      >
        <Card width="60%" height="100%" breakpoint="1440px">
          <div className="title-card">
            <IconCardMeets />
            {Translator('Reuniões')}
          </div>
          <div className="info">
            <CardInfo>
              <div className="value">{conferenceData.conferences?.ongoing}</div>
              <div className="title">{Translator('Em andamento')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">
                {conferenceData.conferences?.scheduled}
              </div>
              <div className="title">{Translator('Agendadas')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">
                {conferenceData.conferences?.concluded}
              </div>
              <div className="title">{Translator('Realizadas')}</div>
            </CardInfo>
            <Separator />
            <CardInfo>
              <div className="value">
                <IconCardClock />
                {conferenceData.conferences?.averageDuration}
              </div>
              <div className="title">{Translator('Duração média')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">
                <IconCardParticipants />
                {conferenceData.conferences?.averageParticipants}
              </div>
              <div className="title">
                {Translator('Média de participantes')}
              </div>
            </CardInfo>
          </div>
        </Card>
        <Card width="40%" height="100%" breakpoint="1440px">
          <div className="title-card">
            <IconCardConsumption />
            {Translator('Consumo')}
          </div>
          <div className="info">
            <CardInfo>
              <div className="value">
                {`R$ ${conferenceData.consumption?.notRecorded}`}
              </div>
              <div className="title">{Translator('Reuniões não gravadas')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">{`R$ ${conferenceData.consumption?.recorded}`}</div>
              <div className="title">{Translator('Reuniões gravadas')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">{`R$ ${conferenceData.consumption?.total}`}</div>
              <div className="title">{Translator('Total')}</div>
            </CardInfo>
          </div>
        </Card>
      </div>
      <div
        className="line"
        style={{
          height: '20%',
        }}
      >
        <Card width="55%" height="100%" breakpoint="1440px">
          <div className="title-card">
            <IconCardRecordings />
            {Translator('Gravações')}
          </div>
          <div className="info">
            <CardInfo>
              <div className="value">
                {`${conferenceData.recordings?.totalCount}`}
              </div>
              <div className="title">{Translator('Reuniões gravadas')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">
                {`${
                  conferenceData.recordings?.totalDuration
                    ? conferenceData.recordings?.totalDuration
                    : 0 * 100
                } min`}
              </div>
              <div className="title">{Translator('Duração total')}</div>
            </CardInfo>
            <CardInfo>
              <div className="value">
                {`${conferenceData.recordings?.usedStorage} gb`}
              </div>
              <div className="title">{Translator('Utilizados')}</div>
            </CardInfo>
            <Separator />
            <CardInfo>
              <div className="value">
                <IconCardClock />
                {`${
                  conferenceData.recordings?.averageDuration
                    ? conferenceData.recordings?.averageDuration
                    : 0 * 100
                } min`}
              </div>
              <div className="title">{Translator('Duração média')}</div>
            </CardInfo>
          </div>
        </Card>
        <Card
          width="45%"
          height="100%"
          style={{ padding: '24px 24px 24px 24px' }}
          breakpoint="1440px"
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '28px' }}>
            <div>
              <div className="title-card">
                <IconCardStorage />
                {Translator('Armazenamento')}
              </div>
              <div className="info">
                <CardInfo>
                  <div className="value">
                    {`${conferenceData.storage?.total} gb`}
                  </div>
                  <div className="title">{Translator('Utilizados')}</div>
                </CardInfo>
                <CardInfo>
                  <div className="value">
                    {`${conferenceData.storage?.available} gb`}
                  </div>
                  <div className="title">{Translator('Disponíveis')}</div>
                </CardInfo>
                <CardInfo>
                  <div className="value">{`${conferenceData.storage?.dailyAverage} gb`}</div>
                  <div className="title">
                    {Translator('Média de consumo diário')}
                  </div>
                </CardInfo>
              </div>
            </div>
            <PieChart width={100} height={100}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={40}
                outerRadius={50}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="none"
                  />
                ))}
                <Label
                  width={30}
                  position="center"
                  fill="#233242"
                  fontSize="15px"
                  fontWeight="500"
                  transform="translate(0, -10)"
                >
                  {`${percentageUsed}%`}
                </Label>
                <Label
                  width={30}
                  position="center"
                  fill="#233242"
                  fontSize="16px"
                  fontWeight="400"
                  transform="translate(0, 10)"
                >
                  {Translator('usado')}
                </Label>
              </Pie>
            </PieChart>
          </div>
        </Card>
      </div>
      <div
        className="line"
        style={{
          height: '60%',
        }}
      >
        <Card width="100%" height="100%">
          <div className="between">
            <div className="title-card">
              <IconCardHistory />
              {Translator('Informações históricas')}
            </div>
            <div className="label">
              <span className="t">{`${Translator('Visualização')}:`}</span>
              <Select
                width="300px"
                height="40px"
                value={visualization}
                onChange={e =>
                  setVisualization(e.target.value as 'hours' | 'amount')
                }
              >
                <option value="amount">
                  {Translator('Número de reuniões')}
                </option>
                <option value="hours">{Translator('Horas de reuniões')}</option>
              </Select>
            </div>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart width={500} height={50} data={conferenceHistory}>
              <XAxis dataKey="time" fontSize="14px" />
              <Tooltip contentStyle={{ fontSize: '14px' }} />
              <Legend
                iconType="circle"
                align="left"
                formatter={(value, entry, index) => {
                  return <span style={{ fontSize: '14px' }}>{value}</span>;
                }}
              />
              <ReferenceLine y={0} stroke="#000" fontSize="14px" />
              {visualization === 'amount' && (
                <>
                  <Bar
                    name={Translator('Gravadas')}
                    width={9}
                    dataKey="records"
                    fill="#1B4A7A"
                  />
                  <Bar
                    name={Translator('Não gravadas')}
                    width={9}
                    dataKey="notRecords"
                    fill="#4B9EF4"
                  />
                </>
              )}
              {visualization === 'hours' && (
                <Bar
                  name={Translator('Horas')}
                  width={9}
                  dataKey="hoursRecorded"
                  fill="#1B4A7A"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </Container>
  ) : (
    <div />
  );
};

export default GeneralData;
