import { call, put, select } from 'redux-saga/effects';
import { iActionListLogs } from 'domain/interfaces/redux/audit/listLogs';
import { ListLogsAudit } from 'domain/usecases/audit/remote/ListLogsAudit';
import { makeRemoteListLogsAudit } from 'main/factories/usecases/audit/ListLogsAuditFactory';
import {
  listLogsSuccess,
  listLogsFailed,
} from 'data/store/reducer/audit/actions/listLogs';

export function* onListLogs(action: iActionListLogs) {
  const remoteListLogsAudit = makeRemoteListLogsAudit();

  try {
    const response: ListLogsAudit.Model = yield call(
      remoteListLogsAudit.listLogs,
      action.payload,
    );
    yield put(
      listLogsSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listLogsFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListLogsSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListLogsFailed() {}
