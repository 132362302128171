import { iConferenceWaitingRoom } from 'domain/interfaces/models/ConferenceWaitingRoom';
import { ConferenceWaitingRoomTypes } from 'domain/interfaces/redux/conferenceWaitingRoom/types';
import { ConferenceWaitingRoomActions } from './actions';

export const initialState: iConferenceWaitingRoom = {
  mic: false,
  cam: false,
  name: '',
  requesting: false,
  requestQueue: [],
};

const reducer = (
  state = initialState,
  action: ConferenceWaitingRoomActions,
): iConferenceWaitingRoom => {
  switch (action.type) {
    case ConferenceWaitingRoomTypes.GET:
      return state;
    case ConferenceWaitingRoomTypes.GET_FAILED:
      return state;
    case ConferenceWaitingRoomTypes.GET_SUCCESS: {
      return state;
    }
    case ConferenceWaitingRoomTypes.SETUP: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
