import { call, put, select } from 'redux-saga/effects';
import { iActionListActions } from 'domain/interfaces/redux/audit/listActions';
import { ListActionsAudit } from 'domain/usecases/audit/remote/ListActionsAudit';
import { makeRemoteListActionsAudit } from 'main/factories/usecases/audit/ListActionsAuditFactory';
import {
  listActionsSuccess,
  listActionsFailed,
} from 'data/store/reducer/audit/actions/listActions';

export function* onListActions(action: iActionListActions) {
  const remoteListActionsAudit = makeRemoteListActionsAudit();

  try {
    const response: ListActionsAudit.Model = yield call(
      remoteListActionsAudit.listActions,
      action.payload,
    );
    yield put(
      listActionsSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listActionsFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListActionsSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListActionsFailed() {}
