import React from 'react';

import { Header as H, HeaderBack } from 'presentation/components/header';
import { AppointmentForm } from 'presentation/components/appointmentForm';
import { History } from 'main/routes';

import Translator from 'presentation/components/i18n/Translator';
import { Container, Body } from './styles/styledEditAppointment';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
}

interface OwnProps {
  data?: iUsers[];
}

const EditAppointment: React.FC<OwnProps> = ({ data }): JSX.Element => {
  return (
    <Container>
      <H title="teste" />
      <HeaderBack
        onBack={() => {
          History.getHistory().goBack();
        }}
        title={Translator('Editar reunião')}
      />
      <Body>
        <AppointmentForm users={data} edit />
      </Body>
    </Container>
  );
};

export default EditAppointment;
