import styled, { css } from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

interface iGroup {
  quant?: string[];
  gap?: string;
}

interface iContainerProps {
  isClosed?: boolean;
}

export const Container = styled.div<iContainerProps>`
  display: ${({ isClosed }) => (isClosed ? 'flex' : 'none')};
  flex-direction: column;
  width: 449px;
  /* border: 1px solid #bfc4ca; */
  box-sizing: border-box;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 12px;
  border: 1px solid #dedede;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  font-size: 16px;
  font-weight: normal;
  color: #000000;

  .icon {
    position: absolute;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
`;

interface iTypographyProps {
  weight?: string;
  size?: string;
  line?: string;
  color?: string;
}

export const Typography = styled.p<iTypographyProps>`
  font-style: normal;
  font-weight: ${({ weight }) => weight || 'normal'};
  font-size: ${({ size }) => size || '16px'};
  line-height: ${({ line }) => line || '19px'};
  color: ${({ color }) => color || '#000000'};
`;

export const FormItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #f8f8f8;
  border-top: 1px solid #dedede;
`;

export const Options = styled.option`
  width: 100%;
  min-width: 211px;
  background-color: #ffffff;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 16px;
`;

export const ContainerSearch = withAccess(styled.div``);
