import { GetConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux/GetConferenceWaitingRoom';
import {
  iActionGet,
  iActionGetFailed,
  iActionGetSuccess,
  ConferenceWaitingRoomTypes,
} from 'domain/interfaces/redux/conferenceWaitingRoom/get';

export const getRequest = (
  payload: GetConferenceWaitingRoom.Params,
): iActionGet => ({
  type: ConferenceWaitingRoomTypes.GET,
  payload,
});

export const getSuccess = (
  params: GetConferenceWaitingRoom.Model,
): iActionGetSuccess => ({
  type: ConferenceWaitingRoomTypes.GET_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetFailed => ({
  type: ConferenceWaitingRoomTypes.GET_FAILED,
});
