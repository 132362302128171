import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 22px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

interface RowContainerProps {
  alignEnd?: boolean;
}

export const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  align-items: ${({ alignEnd }) => (alignEnd ? 'flex-end' : 'center')};
  width: 100%;
  flex-direction: row;
  gap: 10px;
`;

export const Title = styled.text`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #353535;
  margin-bottom: 4px;
`;

export const Subtitle = styled.text`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  margin-top: 4px;
  color: #a6a6a6;
`;
