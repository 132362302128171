import { iGetDashboardOrg } from 'domain/interfaces/models/Dashboard';
import { DashboardTypes } from 'domain/interfaces/redux/dashboard/types';
import { DashboardActions } from './actions';

export const initialState: iGetDashboardOrg = {
  loading: false,
  loadingNextPage: false,
  response: {} as iGetDashboardOrg['response'],
};

const reducer = (
  state = initialState,
  action: DashboardActions,
): iGetDashboardOrg => {
  switch (action.type) {
    case DashboardTypes.GET_DASHBOARD_ORG:
      return {
        ...state,
        loading: !action.payload.loadingNextPage,
        loadingNextPage: action.payload.loadingNextPage,
      };
    case DashboardTypes.GET_DASHBOARD_ORG_SUCCESS: {
      return {
        ...state,
        response: action.payload,
        loading: false,
        loadingNextPage: false,
      };
    }
    case DashboardTypes.GET_DASHBOARD_ORG_FAILED:
      return {
        ...state,
        response: {} as iGetDashboardOrg['response'],
        loading: false,
        loadingNextPage: false,
      };
    default:
      return state;
  }
};

export default reducer;
