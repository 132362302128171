import React from 'react';
import { PbMeetLogo } from 'presentation/base/icons';
import { Header, Content, Title } from './styles/StyledHeaderPublicSac';

interface iHeaderPublicSacProps {
  shortname?: string;
  name?: string;
}

export const HeaderPulicSac: React.FC<iHeaderPublicSacProps> = ({
  name,
  shortname,
}) => {
  return (
    <Header>
      <Content>
        <PbMeetLogo width="76px" />
        {shortname && name && (
          <>
            <Title>{`(${shortname?.toUpperCase()}) ${name?.toUpperCase()}`}</Title>
            <div />
          </>
        )}
      </Content>
    </Header>
  );
};
