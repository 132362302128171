import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetUserDataDashboard as UsecaseRemoteGetUserDataDashboard } from 'domain/usecases/dashboard/remote';
// import { GetUserDataDashboard as UsecaseReduxGetUserDataDashboard } from 'domain/usecases/dashboard/redux';

import { RemoteGetUserDataDashboard } from 'data/repository/dashboard';
// import { ReduxGetUserDataDashboard } from 'data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetUserDataDashboard =
  (): UsecaseRemoteGetUserDataDashboard =>
    new RemoteGetUserDataDashboard(
      makeApiUrl('/dashboard/user'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetUserDataDashboard =
  (): UsecaseReduxGetUserDataDashboard =>
    new ReduxGetUserDataDashboard(); */
