import { rrulestr } from 'rrule';
import * as Yup from 'yup';

export enum MessageError {
  REQUIRED_FIELD = 'Campo obrigatório',
}

export const appointmentSchema = Yup.object().shape({
  owner: Yup.number().positive().integer().optional(),
  org: Yup.number().positive().integer().optional(),
  title: Yup.string()
    .max(128, 'O título deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),
  descr: Yup.string()
    .max(1024, 'A descrição deve conter no máximo 1024 caracteres.')
    .optional(),
  type: Yup.mixed()
    .oneOf(['STANDARD', 'RESTRICT'])
    .default('STANDARD')
    .optional(),
  scheduled: Yup.string().required(MessageError.REQUIRED_FIELD),
  notifyOptions: Yup.object()
    .shape({
      email: Yup.boolean().default(false).optional(),
      sms: Yup.boolean().default(false).optional(),
      whatsapp: Yup.boolean().default(false).optional(),
    })
    .optional(),
  recurrence: Yup.object()
    .shape({
      rrule: Yup.string().transform(value => {
        try {
          rrulestr(value);
          return value;
        } catch {
          return false;
        }
      }),
    })
    .optional(),
  expectedDuration: Yup.number().positive().integer().optional(),
  allowInvite: Yup.boolean().optional().default(false),
  allowTranscription: Yup.boolean().optional().default(false),
  allowBlockchain: Yup.boolean().optional().default(false),
  waitingRoom: Yup.boolean().optional().default(false),
  participants: Yup.array().of(
    Yup.object().shape({
      user: Yup.number().positive().integer().optional(),
      role: Yup.mixed()
        .oneOf(['MODERATOR', 'ATTENDEE'])
        .default('ATTENDEE')
        .optional(),
      fullName: Yup.string()
        .max(128)
        .optional()
        .when('user', (user: number, schema: Yup.StringSchema) => {
          if (!user) return schema.required(MessageError.REQUIRED_FIELD);
          return schema.optional();
        }),
      email: Yup.string()
        .email()
        .optional()
        .when('user', (user: number, schema: Yup.StringSchema) => {
          if (!user) return schema.required(MessageError.REQUIRED_FIELD);
          return schema.optional();
        }),
      phone: Yup.string().optional().max(14),
      confirmation: Yup.mixed()
        .oneOf(['ACCEPTED', 'DECLINED', 'TENTATIVE', 'NEEDS-ACTION'])
        .default('NEEDS-ACTION')
        .optional(),
    }),
  ),
});

export const stepOneSchema = Yup.object().shape({
  title: Yup.string()
    .max(128, 'O título deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),
  descr: Yup.string()
    .max(1024, 'A descrição deve conter no máximo 1024 caracteres.')
    .optional(),
  scheduled: Yup.string().required(MessageError.REQUIRED_FIELD),
  notifyOptions: Yup.object()
    .shape({
      email: Yup.boolean().default(false).optional(),
      sms: Yup.boolean().default(false).optional(),
      whatsapp: Yup.boolean().default(false).optional(),
    })
    .optional(),
  recurrence: Yup.object()
    .shape({
      rrule: Yup.string().transform(value => {
        try {
          rrulestr(value);
          return value;
        } catch {
          return false;
        }
      }),
    })
    .optional(),
  expectedDuration: Yup.number().positive().integer().optional(),
});

export const stepTwoSchema = Yup.object().shape({
  participants: Yup.array().of(
    Yup.object().shape({
      user: Yup.number().positive().integer().optional(),
      role: Yup.mixed()
        .oneOf(['MODERATOR', 'ATTENDEE'])
        .default('ATTENDEE')
        .optional(),
      fullName: Yup.string()
        .max(128)
        .optional()
        .when('user', (user: number, schema: Yup.StringSchema) => {
          if (!user) return schema.required(MessageError.REQUIRED_FIELD);
          return schema.optional();
        }),
      email: Yup.string()
        .email()
        .optional()
        .when('user', (user: number, schema: Yup.StringSchema) => {
          if (!user) return schema.required(MessageError.REQUIRED_FIELD);
          return schema.optional();
        }),
      phone: Yup.string()
        .optional()
        .max(14)
        .when('user', (user: number, schema: Yup.StringSchema) => {
          if (!user) return schema.required(MessageError.REQUIRED_FIELD);
          return schema.optional();
        }),
      confirmation: Yup.mixed()
        .oneOf(['ACCEPTED', 'DECLINED', 'TENTATIVE', 'NEEDS-ACTION'])
        .default('NEEDS-ACTION')
        .optional(),
    }),
  ),
});

export const stepThreeSchema = Yup.object().shape({
  allowInvite: Yup.boolean().optional().default(false),
  allowTranscription: Yup.boolean().optional().default(false),
  allowBlockchain: Yup.boolean().optional().default(false),
  waitingRoom: Yup.boolean().optional().default(false),
  type: Yup.mixed()
    .oneOf(['STANDARD', 'RESTRICT'])
    .default('STANDARD')
    .optional(),
});
