import { takeLatest } from 'redux-saga/effects';
import { AuditTypes } from 'domain/interfaces/redux/audit/types';
import { onListLogs, onListLogsSuccess, onListLogsFailed } from './ListLogs';
import {
  onListGroups,
  onListGroupsSuccess,
  onListGroupsFailed,
} from './ListGroups';
import {
  onListActions,
  onListActionsSuccess,
  onListActionsFailed,
} from './ListActions';

const tasks = [
  takeLatest(AuditTypes.LISTLOGS, onListLogs),
  takeLatest(AuditTypes.LISTLOGS_SUCCESS, onListLogsSuccess),
  takeLatest(AuditTypes.LISTLOGS_FAILED, onListLogsFailed),
  takeLatest(AuditTypes.LISTGROUPS, onListGroups),
  takeLatest(AuditTypes.LISTGROUPS_SUCCESS, onListGroupsSuccess),
  takeLatest(AuditTypes.LISTGROUPS_FAILED, onListGroupsFailed),
  takeLatest(AuditTypes.LISTACTIONS, onListActions),
  takeLatest(AuditTypes.LISTACTIONS_SUCCESS, onListActionsSuccess),
  takeLatest(AuditTypes.LISTACTIONS_FAILED, onListActionsFailed),
];

export default tasks;
