import { takeLatest } from 'redux-saga/effects';
import { ConferenceWaitingRoomTypes } from 'domain/interfaces/redux/conferenceWaitingRoom/types';
import { onGet, onGetSuccess, onGetFailed } from './Get';

const tasks = [
  takeLatest(ConferenceWaitingRoomTypes.GET, onGet),
  takeLatest(ConferenceWaitingRoomTypes.GET_SUCCESS, onGetSuccess),
  takeLatest(ConferenceWaitingRoomTypes.GET_FAILED, onGetFailed),
];

export default tasks;
