import { RRule, Weekday } from 'rrule';

export const daysOfWeekTranslations: Record<
  string,
  { label: string; value: Weekday }
> = {
  sunday: {
    label: 'Dom',
    value: RRule.SU,
  },
  monday: {
    label: 'Seg',
    value: RRule.MO,
  },
  tuesday: {
    label: 'Ter',
    value: RRule.TU,
  },
  wednesday: {
    label: 'Qua',
    value: RRule.WE,
  },
  thursday: {
    label: 'Qui',
    value: RRule.TH,
  },
  friday: {
    label: 'Sex',
    value: RRule.FR,
  },
  saturday: {
    label: 'Sab',
    value: RRule.SA,
  },
};

export const getWeekdayByString: Record<string, Weekday> = {
  SU: RRule.SU,
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
};

export const getWeekdayByNumber: Record<number, Weekday> = {
  0: RRule.MO,
  1: RRule.TU,
  2: RRule.WE,
  3: RRule.TH,
  4: RRule.FR,
  5: RRule.SA,
  6: RRule.SU,
};

export const frequency = [
  {
    id: -1,
    name: 'Única vez',
  },
  {
    id: 3,
    name: 'Diária',
  },
  {
    id: 2,
    name: 'Semanal',
  },
  {
    id: 1,
    name: 'Mensal',
  },
];

export const daysOfMonth = [
  {
    id: 1,
    name: '1º',
  },
  {
    id: 2,
    name: '2º',
  },
  {
    id: 3,
    name: '3º',
  },
  {
    id: 4,
    name: '4º',
  },
  {
    id: 5,
    name: '5º',
  },
];
