import React, { useEffect, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import Translator from 'presentation/components/i18n/Translator';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteCreateConference } from 'main/factories/usecases/conference/CreateConferenceFactory';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { makeReduxListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import { Typography } from '../../filter/styles/StyledFilterAppointment';
import {
  Body,
  Container,
  Content,
  ButtonsContainer,
} from './styles/StyledInstantConference';
import { ownProps } from '../interface';
import { Button } from '../../UI';

const InstantConference: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.instantConference;

  const { active, actionOk, actionCancel, content } = message;

  const [allowTranscription, setAllowTranscription] = useState<boolean>(false);
  const [allowBlockchain, setAllowBlockchain] = useState<boolean>(false);
  const [waitingRoom, setWaitingRoom] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { user: userRedux } = useSelector((store: iStore) => store.auth);

  const instantConference = async () => {
    const now = new Date();

    if (!userRedux?.administrator) return;

    setLoading(true);

    await makeRemoteCreateConference()
      .create({
        body: {
          title: `Reunião em ${now.toLocaleDateString(
            'pt-PT',
          )} ${now.toLocaleTimeString('pt-PT')}`,
          descr: '',
          scheduled: now.toISOString(),
          expectedDuration: 60,
          allowInvite: true,
          allowTranscription,
          allowBlockchain,
          notifyOptions: {
            email: true,
            sms: false,
            whatsapp: false,
          },
          ...(waitingRoom ? { type: 'RESTRICT' } : { type: 'STANDARD' }),
        },
      })
      .then(res => {
        makeReduxListFilteredConference().list({
          dataControl: {
            limit: 9999,
          },
        });

        toast.success(Translator('Reunião Agendada com sucesso!'));
        actionOk?.();

        setTimeout(() => {
          window
            .open(`/join?t=${res?.short}&u=${userRedux.fullName}`, '_blank')
            ?.focus();
        }, 1500);
      })
      .catch(() => {
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Criação de Reunião Instantânea'),
          content: Translator('Não foi possível criar a reunião'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content hasContent={!!content}>
              <p>{Translator('Transcrever reunião')}</p>
              <span>
                {Translator(
                  'Deseja gerar um documento com a transcrição da reunião?',
                )}
              </span>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  margin: '12px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={allowTranscription === true}
                    onChange={() => {
                      setAllowTranscription(true);
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={allowTranscription === false}
                    onChange={() => setAllowTranscription(false)}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>

              <p>Sala de espera</p>
              <span>
                {Translator(
                  'O moderador precisa entrar na reunião antes de qualquer participante?',
                )}
              </span>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  margin: '12px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={waitingRoom === true}
                    onChange={() => {
                      setWaitingRoom(true);
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={waitingRoom === false}
                    onChange={() => setWaitingRoom(false)}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>

              <p>Blockchain</p>
              <span>{Translator('Registrar reunião na blockchain:')}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  margin: '12px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={allowBlockchain === true}
                    onChange={() => {
                      setAllowBlockchain(true);
                    }}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Sim
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <input
                    type="radio"
                    checked={allowBlockchain === false}
                    onChange={() => setAllowBlockchain(false)}
                  />
                  <Typography size="14px" color="#222529" line="16px">
                    Não
                  </Typography>
                </div>
              </div>

              <span>
                *O documento estará disponível para download em alguns dias.
              </span>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                variant="secundary"
                size="small"
                autoFocus
                onClick={actionCancel}
              >
                {Translator('Cancelar')}
              </Button>
              <Button
                id="button_confirm"
                variant="primary"
                size="fit-content"
                autoFocus
                onClick={instantConference}
                disabled={loading}
              >
                Ir para a conferência
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default InstantConference;
