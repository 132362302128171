import { Dispatch } from 'redux';
import store from 'data/store';
import { guestRequest } from 'data/store/reducer/auth/actions/guest';

import { iActionGuest } from 'domain/interfaces/redux/auth/guest';

import { GuestAuth } from 'domain/usecases/auth/redux';

export class ReduxGuestAuth implements GuestAuth {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  guest = (params: GuestAuth.Params): iActionGuest =>
    this.send(guestRequest(params));
}
