import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListSacRequestById as UsecaseRemoteListSacRequestById } from 'domain/usecases/sacRequest/remote';

import { RemoteListSacRequestById } from 'data/repository/sacRequest';

/**
 * send request via API.
 */

export const makeRemoteListSacRequestById =
  (): UsecaseRemoteListSacRequestById =>
    new RemoteListSacRequestById(makeApiUrl('/sac/requests'), makeHttpClient());
