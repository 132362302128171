export enum AuditTypes {
  LISTLOGS = '@audit/LISTLOGS',
  LISTLOGS_SUCCESS = '@audit/LISTLOGS_SUCCESS',
  LISTLOGS_FAILED = '@audit/LISTLOGS_FAILED',
  LISTGROUPS = '@audit/LISTGROUPS',
  LISTGROUPS_SUCCESS = '@audit/LISTGROUPS_SUCCESS',
  LISTGROUPS_FAILED = '@audit/LISTGROUPS_FAILED',
  LISTACTIONS = '@audit/LISTACTIONS',
  LISTACTIONS_SUCCESS = '@audit/LISTACTIONS_SUCCESS',
  LISTACTIONS_FAILED = '@audit/LISTACTIONS_FAILED',
}
