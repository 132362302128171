import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBillingByDay as UsecaseRemoteGetBillingByDay } from 'domain/usecases/billing/remote';

import { RemoteGetBillingByDay } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBillingByDay = (): UsecaseRemoteGetBillingByDay =>
  new RemoteGetBillingByDay(
    makeApiUrl('/orgs/{orgId}/billing/day/'),
    makeHttpClient(),
  );
