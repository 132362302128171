import { UpdatePasswordValidate } from 'domain/usecases/password/remote';
import { BadRequestError, Forbidden, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteUpdatePasswordValidate implements UpdatePasswordValidate {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdatePasswordValidate.Model>;

  constructor(
    url: string,
    httClient: HttpClient<UpdatePasswordValidate.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdatePasswordValidate.Params,
  ): Promise<UpdatePasswordValidate.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      body: params.body,
      method: 'patch',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return {};
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
