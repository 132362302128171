import { call, put, select } from 'redux-saga/effects';
import { iActionUpdate } from 'domain/interfaces/redux/user/update';
import { UpdateUser } from 'domain/usecases/user/remote/UpdateUser';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUserFactory';
import {
  updateSuccess,
  updateFailed,
} from 'data/store/reducer/user/actions/update';

export function* onUpdate(action: iActionUpdate) {
  const remoteUpdateUser = makeRemoteUpdateUser();

  try {
    const response: UpdateUser.Model = yield call(
      remoteUpdateUser.update,
      action.payload,
    );
    yield put(
      updateSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(updateFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateFailed() {}
