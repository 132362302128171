import styled from 'styled-components';

export const CloseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

type PaddingProps = {
  paddingTopBottom?: string;
  paddingLeftRight?: string;
};

export const Padding = styled.div<PaddingProps>`
  padding: ${({ paddingTopBottom, paddingLeftRight }) =>
    `${paddingTopBottom || '1.25rem'} ${paddingLeftRight || '1.75rem'}`};
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  width: 1.5rem;
  height: 1.5rem;

  svg path {
    stroke: #000000;
  }
`;

export const DrawerTitle = styled.h2`
  font-size: 2.25rem;
  font-weight: 700;
  color: #000000;
  margin-top: 1.12rem;
`;

export const Divider = styled.div`
  width: 31.75rem;
  height: 0.0625rem;
  background: #ecedee;
  /* margin: 1.25rem 0; */
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem 0;
`;

export const TitleSection = styled.h3`
  color: #222529;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const ContentSection = styled.div`
  display: flex;
  width: 28.125rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
`;

type ContentSectionItemProps = {
  flexDirection?: 'column' | 'row';
};

export const ContentSectionItem = styled.div<ContentSectionItemProps>`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  color: #222529;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

export const ContentSectionItemLabel = styled.span`
  font-size: 1rem;
`;

export const ContentSectionItemValue = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

export const TableOrganizations = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 0.5rem;

  border: 1px solid #ecedee;
  background: #fff;

  border-radius: 6px;
`;

export const TableOrganizationsBody = styled.tbody`
  display: block;
  max-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TableOrganizationsRow = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #ecedee;
  }
`;

export const TableOrganizationsCell = styled.td`
  padding: 1rem 0.75rem;
  font-size: 1rem;
  color: #222529;
  font-weight: 500;
  padding: 1rem;
`;
