import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetConferenceDataDashboard,
  GetDashboardOrg,
  GetUserDataDashboard,
} from 'domain/usecases/dashboard/remote';
import { Header, PageHeaderDashboard } from 'presentation/components/header';
import RankingOrg from 'presentation/pages/RankingOrg';

import * as dateFns from 'date-fns';

import { getRole } from 'utils/getRole';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxGetDashboardOrg } from '../../usecases/dashboard/GetDashboardOrgFactory';

interface dataHistory {
  time: string;
  records: number;
  notRecords: number;
}

export const MakeRankingFactory: React.FC = () => {
  const LoadDataToRanking = useCallback((params: GetDashboardOrg.Params) => {
    makeReduxGetDashboardOrg().getDashboardOrgData(params);
  }, []);

  useEffect(() => {
    LoadDataToRanking({});
  }, [LoadDataToRanking]);

  const [org, setOrg] = useState('All');
  const [unit, setUnit] = useState('All');
  const [dateFrom, setDateFrom] = useState('');
  const [period, setPeriod] = useState('All');
  const [conferenceData, setConferenceData] =
    useState<GetConferenceDataDashboard.Model>({});
  const [userData, setUserData] = useState<GetUserDataDashboard.Model>({});
  const [conferenceHistory, setConferenceHistory] = useState<dataHistory[]>([]);

  const { user } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const verifyRole = () => {
    if (role === 'ORG_ADMIN') {
      return {
        org: user?.org?.id,
      };
    }
    if (role === 'UNIT_ADMIN') {
      return {
        org: user?.org?.id,
        unit: user?.orgUnit?.id,
      };
    }
    return {};
  };

  const getScale = (p: string) => {
    if (p === 'All' || p === 'lastYear') return 'month';
    return p === 'lastMonth' ? 'day' : 'hour';
  };

  useEffect(() => {
    LoadDataToRanking({
      query: {
        ...(dateFrom !== '' ? { from: dateFrom } : {}),
        ...(org !== 'All' ? { org: Number(org) } : verifyRole()),
        ...(unit !== 'All' ? { unit: Number(unit) } : verifyRole()),
        limit: 9999,
        until: dateFns.format(new Date(), 'yyyy-MM-dd'),
      },
    });
  }, [org, unit, dateFrom, period, LoadDataToRanking]);

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}>
      <div style={{ position: 'fixed', width: '100%' }}>
        <Header title="teste" />
        <PageHeaderDashboard
          org={org}
          setOrg={setOrg}
          unit={unit}
          setUnit={setUnit}
          period={period}
          setPeriod={setPeriod}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          disableSelect
        />
      </div>
      <RankingOrg />
    </div>
  );
};
