import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 18.7rem;

  padding-top: 26px;
`;

export const InputFields = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 18px;

  margin-bottom: 18px;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;
