/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'App';
import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { History } from 'main/routes';
import {
  Agendar,
  IconAddProfessional,
  IconFilter,
  IconSearch,
  Instantanea,
} from 'presentation/base/icons';
import { Drawer } from 'presentation/components/drawer';
import { Button } from 'presentation/components/UI/buttons';
import Popover from 'presentation/components/UI/popover/Popover';
import Select from 'presentation/components/UI/select';
import { closeModal } from 'utils/closeModal';
import ConferenceDetails from '../conferenceDetails/ConferenceDetails';
import Translator from '../i18n/Translator';
import {
  Container,
  Content,
  Drop,
  Left,
  Options,
  Right,
  Search,
} from './styles/StylePageHeader';
import FilterAppointment, {
  iFilterAppointment,
} from '../filter/FilterAppointment';
import { FilterIcon } from './styles/StyledBackButton';

interface ownProps {
  setViz: Function;
  typeViz: string;
  search?: string;
  setSearch?: Function;
  onSearch?: Function;
}

type Props = ownProps;

const PageHeader: React.FC<Props> = ({
  setViz,
  typeViz,
  search,
  setSearch,
  onSearch,
}): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('');
  const [isClosed, setIsClosed] = useState(false);

  const now = new Date();

  const [filterAppointment, setFilterAppointment] =
    useState<iFilterAppointment>({
      conference: {
        timestamp: {
          begin: new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate(),
          ).toISOString(),
          end: new Date().toISOString(),
        },
        search: undefined,
        moderator: undefined,
        ownedBy: {
          orgId: undefined,
          orgUnitId: undefined,
          userId: undefined,
        },
        participants: undefined,
        recorded: undefined,
        transcribed: undefined,
      },
    });

  const url = window.location.pathname;
  const showDetails = useSelector(
    (store: iStore) => store.conference.controller?.showDetails,
  );

  const [loading, setLoading] = useState(false);

  const { theme } = useContext(ThemeContext);

  const iconRef = useRef<any>();

  useEffect(() => {
    const keyPressEvent = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        iconRef?.current?.click();
      }
    };

    document.addEventListener('keydown', keyPressEvent);

    return () => {
      document.removeEventListener('keydown', keyPressEvent);
    };
  }, []);

  useEffect(() => {
    makeReduxUpdateControllerConference().updateController({
      selected: undefined,
      showDetails: false,
    });
  }, []);

  const searchConference = (
    <div>
      <Drop disablePadding>
        <FilterAppointment
          search={searchFilter}
          setSearch={setSearchFilter}
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          filterAppointment={filterAppointment}
          setFilterAppointment={setFilterAppointment}
        />
      </Drop>
    </div>
  );

  const instantConference = async () => {
    makeReduxActiveMessage().active({
      active: 'instantConference',
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  const MenuOptions = (
    <Options>
      <button
        data-testid="btn-instant-conference"
        type="button"
        onClick={instantConference}
        disabled={loading}
      >
        <Drop id="btn_login" loading={loading}>
          {loading ? (
            <CircularProgress
              style={{
                width: '30px',
                height: '30px',
                color: `${theme.colors.primary.main}`,
              }}
            />
          ) : (
            <>
              <Instantanea />
              <span
                style={{
                  marginLeft: '12px',
                }}
              >
                {Translator('Reunião instantânea')}
              </span>
            </>
          )}
        </Drop>
      </button>

      <Drop
        id="btn_logout"
        data-testid="btn-new-conference"
        onClick={() => {
          History.getHistory().push('appointment/create', {
            from: url.replaceAll('/', ''),
          });
        }}
      >
        <Agendar />
        <span
          style={{
            marginLeft: '12px',
          }}
        >
          {Translator('Agendar reunião')}
        </span>
      </Drop>
    </Options>
  );

  const isFilterAppointmentActive = useMemo(() => {
    if (filterAppointment?.conference) {
      const { conference } = filterAppointment;

      const {
        moderator,
        ownedBy,
        participants,
        recorded,
        // timestamp,
        transcribed,
      } = conference;

      if (
        moderator ||
        (ownedBy &&
          (ownedBy?.orgId || ownedBy?.orgUnitId || ownedBy?.userId)) ||
        participants ||
        recorded ||
        // (timestamp && (timestamp?.begin || timestamp?.end)) ||
        transcribed
      ) {
        return true;
      }
    }

    return false;
  }, [filterAppointment]);

  return (
    <>
      <Container>
        <Content>
          <Left>
            <div data-testid="text-visualization">
              {Translator('Visualização')}
            </div>
            <Select
              data-testid="select-visualization"
              width="150px"
              height="34px"
              value={typeViz}
              onChange={e => setViz(e.target.value)}
            >
              <option data-testid="option1-visualization" value="S">
                {Translator('Dia')}
              </option>
              <option data-testid="option2-visualization" value="M">
                {Translator('Mês')}
              </option>
            </Select>
            {/* TODO: Implement search function */}
            <Search ref={iconRef} onClick={() => onSearch?.()}>
              <input
                id="input_search"
                placeholder={Translator('Pesquisar')}
                onChange={e => setSearch?.(e.target.value)}
                value={search}
              />
              <IconSearch id="btn_searchFilter" onClick={() => onSearch?.()} />
            </Search>
            <Popover content={searchConference}>
              <FilterIcon
                active={isFilterAppointmentActive}
                onClick={() => setIsClosed(true)}
              >
                <IconFilter />
              </FilterIcon>
            </Popover>
          </Left>
          <Right>
            <Popover
              content={MenuOptions}
              originX="right"
              originY="bottom"
              positionY="top"
              positionX="right"
              resourcers={['CONFERENCE']}
              actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
            >
              <Button
                data-testid="btn-create-conference"
                size="medium"
                icon={IconAddProfessional}
                background={theme.colors.primary.main}
                rounded
              >
                {Translator('Nova reunião')}
              </Button>
            </Popover>
          </Right>
        </Content>
      </Container>
      <Drawer
        anchor="right"
        isOpen={showDetails}
        handleClose={() =>
          makeReduxUpdateControllerConference().updateController({
            showDetails: false,
          })
        }
      >
        <ConferenceDetails />
      </Drawer>
    </>
  );
};

export default PageHeader;
