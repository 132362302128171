/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-duplicates */
import { createContext, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt';
import { ThemeProvider } from 'styled-components';

import AccessControl from 'presentation/hooks/access';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import CircularProgress from '@material-ui/core/CircularProgress';
import store from 'data/store';
import { ConnectSocket } from 'infra/socket/ConnectSocket';
import { Routes } from 'main/routes';
import Notifications from 'presentation/components/messages';
import { ToastContainer } from 'react-toastify';

import { defaultTranslations, psiTranslations } from './infra/i18n/locales';
import GlobalStyle from './presentation/base/styles/globals';
import { defaultTheme } from './presentation/base/themes';

import '@wisecare-tech/design-system-web/dist/styles.css';
import 'animate.css/animate.compat.css';
import 'react-notifications-component/dist/theme.css';
import 'react-toastify/dist/ReactToastify.min.css';

import './infra/global/variables';
import { makeRemoteMatchOrg } from './main/factories/usecases/org/MatchOrgFactory';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
    | 'pt-PT'
    | 'pt-pt'
    | 'pt-BR'
    | 'pt-br'
    | 'en-US'
    | 'en-us'
    | 'es'
    | 'es-ES'
    | 'es-es'
    | 'es-DO'
    | 'es-do'
    | 'es-GT'
    | 'es-gt'
    | 'es-HN'
    | 'es-hn'
    | 'es-MX'
    | 'es-mx'
    | 'es-PA'
    | 'es-pa'
    | 'es-PE'
    | 'es-pe'
    | 'es-SV'
    | 'es-SE'
    | 'es-sv'
    | 'es-se';
}

type ThemeContextProps = {
  theme: typeof defaultTheme;
  setTheme: (theme: typeof defaultTheme) => void;
};

export const ThemeContext = createContext<ThemeContextProps>(
  {} as ThemeContextProps,
);

function App(): JSX.Element {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('en-US');
  const [dateLocale, setDateLocale] = useState(ptBR);
  const [theme, setTheme] = useState(defaultTheme);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem('i18nextLng');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('i18nextLng', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
    if (userLanguage.includes('pt')) setDateLocale(ptBR);
    if (userLanguage.includes('en')) setDateLocale(enUS);
    if (userLanguage.includes('es')) setDateLocale(es);

    setLoading(true);

    makeRemoteMatchOrg()
      .match({
        url: window.location.origin,
      })
      .then(res => {
        if (res && res.skin) {
          setTheme(res.skin);

          let link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");

          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }

          document.title = res.skin.emailParams.systemName;
          link.href = res.skin.images.favicon;
        }
      })
      .catch(err => {
        // toast.error('Não foi possível carregar a skin');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const LoadingCircularProgress = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  };

  return (
    <IntlProvider
      messages={
        theme.systemName !== 'V4HPsi' && theme.systemName !== 'WisePsi'
          ? psiTranslations[selectedLocale]
          : defaultTranslations[selectedLocale]
      }
      locale={String(selectedLocale)}
      defaultLocale="pt-PT"
      onError={(err): void => {
        // console.log(
        //   'Supress missing keys error, uncomment to fill missing keys: ',
        //   err,
        // );
      }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessControl>
            <ConnectSocket>
              <ThemeContext.Provider value={{ theme, setTheme }}>
                <ThemeProvider theme={theme}>
                  <Notifications>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={dateLocale}
                    >
                      <GlobalStyle />
                      <ToastContainer />
                      {loading ? <LoadingCircularProgress /> : <Routes />}
                    </MuiPickersUtilsProvider>
                  </Notifications>
                </ThemeProvider>
              </ThemeContext.Provider>
            </ConnectSocket>
          </AccessControl>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}

export default App;
