import { ListOrg } from 'domain/usecases/org/redux/ListOrg';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  OrgTypes,
} from 'domain/interfaces/redux/org/list';

export const listRequest = (payload: ListOrg.Params): iActionList => ({
  type: OrgTypes.LIST,
  payload,
});

export const listSuccess = (params: ListOrg.Model): iActionListSuccess => ({
  type: OrgTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: OrgTypes.LIST_FAILED,
});
