import React from 'react';

import { Container } from './styles/StyledUsers';
import UserDetails from '../../User/UserForms';

interface ownProps {
  userId?: number;
  editing?: boolean;
}

const UserPage: React.FC<ownProps> = ({
  userId,
  editing = false,
}): JSX.Element => {
  return (
    <Container>
      <UserDetails idUser={userId} editing={editing} />
    </Container>
  );
};

export default UserPage;
