import { call, put, select } from 'redux-saga/effects';
import { iActionListFilteredByACL } from 'domain/interfaces/redux/conference/listFilteredByACL';
import { ListConferenceFilteredByACL } from 'domain/usecases/conference/remote/ListConferenceFilteredByACL';
import { makeRemoteListConferenceFilteredByACL } from 'main/factories/usecases/conference/ListConferenceFilteredByACLFactory';
import {
  listFilteredByACLSuccess,
  listFilteredByACLFailed,
} from 'data/store/reducer/conference/actions/listFilteredByACL';

export function* onListFilteredByACL(action: iActionListFilteredByACL) {
  const remoteListConferenceFilteredByACL =
    makeRemoteListConferenceFilteredByACL();

  try {
    const response: ListConferenceFilteredByACL.Model = yield call(
      remoteListConferenceFilteredByACL.list,
      action.payload,
    );
    yield put(
      listFilteredByACLSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFilteredByACLFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredByACLSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredByACLFailed() {}
