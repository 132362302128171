import { DeleteConferenceFiles } from 'domain/usecases/conferenceFiles/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDeleteConferenceFiles implements DeleteConferenceFiles {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteConferenceFiles.Model>;

  constructor(url: string, httClient: HttpClient<DeleteConferenceFiles.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (
    params: DeleteConferenceFiles.Params,
  ): Promise<DeleteConferenceFiles.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.short}/files/${params.file}`,
      method: 'delete',
      headers: params?.token
        ? {
            Authorization: `APIKEY ${params?.token}`,
          }
        : undefined,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
