export enum OrgTypes {
  LIST = '@org/LIST',
  LIST_SUCCESS = '@org/LIST_SUCCESS',
  LIST_FAILED = '@org/LIST_FAILED',
  LIST_ACL_FILTERED = '@org/LIST_ACL_FILTERED',
  LIST_ACL_FILTERED_SUCCESS = '@org/LIST_ACL_FILTERED_SUCCESS',
  LIST_ACL_FILTERED_FAILED = '@org/LIST_ACL_FILTERED_FAILED',
  LIST_ACL_FILTERED_V2 = '@org/LIST_ACL_FILTERED_V2',
  LIST_ACL_FILTERED_V2_SUCCESS = '@org/LIST_ACL_FILTERED_V2_SUCCESS',
  LIST_ACL_FILTERED_V2_FAILED = '@org/LIST_ACL_FILTERED_V2_FAILED',
  SET = '@org/SET',
  SET_SUCCESS = '@org/SET_SUCCESS',
  SET_FAILED = '@org/SET_FAILED',
  LIST_FILTERED_ORGS = '@org/LIST_FILTERED_ORGS',
  LIST_FILTERED_ORGS_SUCCESS = '@org/LIST_FILTERED_ORGS_SUCCESS',
  LIST_FILTERED_ORGS_FAILED = '@org/LIST_FILTERED_ORGS_FAILED',
}
