import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetDashboardOrg as UsecaseRemoteGetDashboardOrg } from 'domain/usecases/dashboard/remote';
import { GetDashboardOrg as UsecaseReduxGetDashboardOrg } from 'domain/usecases/dashboard/redux';

import { RemoteGetDashboardOrg } from 'data/repository/dashboard';
import { ReduxGetDashboardOrg } from 'data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 * @returns {UsecaseRemoteGetDashboardOrg}
 * @constructor
 */
export const makeRemoteGetDashboardOrg = (): UsecaseRemoteGetDashboardOrg =>
  new RemoteGetDashboardOrg(makeApiUrl('/dashboard/org'), makeHttpClient());

/**
 * send request via REDUX.
 * @returns {UsecaseReduxGetDashboardOrg}
 * @constructor
 */
export const makeReduxGetDashboardOrg = (): UsecaseReduxGetDashboardOrg =>
  new ReduxGetDashboardOrg();
