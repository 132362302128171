import { call, put, select } from 'redux-saga/effects';
import { iActionGet } from 'domain/interfaces/redux/conferenceWaitingRoom/get';
import { GetConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/remote/GetConferenceWaitingRoom';
import { makeRemoteGetConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/GetConferenceWaitingRoomFactory';
import {
  getSuccess,
  getFailed,
} from 'data/store/reducer/conferenceWaitingRoom/actions/get';

export function* onGet(action: iActionGet) {
  const remoteGetConferenceWaitingRoom = makeRemoteGetConferenceWaitingRoom();

  try {
    const response: GetConferenceWaitingRoom.Model = yield call(
      remoteGetConferenceWaitingRoom.get,
      action.payload,
    );
    yield put(
      getSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}
