import { all } from 'redux-saga/effects';

import AuthTasks from './auth';
import UserTasks from './user';
import ConferenceTasks from './conference';
import OrgTasks from './org';
import OrgUsersTasks from './orgUsers';
import OrgUnitsTasks from './orgUnits';
import DashboardOrdTasks from './dashboard';
import Audit from './audit';
import MyConferences from './myConferences';
import ConferenceWaitingRoomTasks from './conferenceWaitingRoom';
import SacRequestTasks from './sacRequest';

export default function* rootSaga(): any {
  return yield all([
    ...AuthTasks,
    ...UserTasks,
    ...ConferenceTasks,
    ...OrgTasks,
    ...OrgUsersTasks,
    ...OrgUnitsTasks,
    ...DashboardOrdTasks,
    ...Audit,
    ...MyConferences,
    ...ConferenceWaitingRoomTasks,
    ...SacRequestTasks,
  ]);
}
