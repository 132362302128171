export enum UserTypes {
  LIST = '@user/LIST',
  LIST_SUCCESS = '@user/LIST_SUCCESS',
  LIST_FAILED = '@user/LIST_FAILED',
  UPDATE = '@user/UPDATE',
  UPDATE_SUCCESS = '@user/UPDATE_SUCCESS',
  UPDATE_FAILED = '@user/UPDATE_FAILED',
  LIST_USER_FILTERED = '@user/LIST_USER_FILTERED',
  LIST_USER_FILTERED_SUCCESS = '@user/LIST_USER_FILTERED_SUCCESS',
  LIST_USER_FILTERED_FAILED = '@user/LIST_USER_FILTERED_FAILED',
}
