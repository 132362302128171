import { takeLatest } from 'redux-saga/effects';
import { OrgUserTypes } from 'domain/interfaces/redux/orgUser/types';

import { onList, onListSuccess, onListFailed } from './List';
import {
  onListByOrgId,
  onListByOrgIdSuccess,
  onListByOrgIdFailed,
} from './ListByOrgId';
import {
  onListByUnitId,
  onListByUnitIdSuccess,
  onListByUnitIdFailed,
} from './ListByOrgUnitId';

const tasks = [
  takeLatest(OrgUserTypes.LIST, onList),
  takeLatest(OrgUserTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(OrgUserTypes.LIST_FAILED, onListFailed),

  takeLatest(OrgUserTypes.LIST_BY_ORGID, onListByOrgId),
  takeLatest(OrgUserTypes.LIST_BY_ORGID_SUCCESS, onListByOrgIdSuccess),
  takeLatest(OrgUserTypes.LIST_BY_ORGID_FAILED, onListByOrgIdFailed),

  takeLatest(OrgUserTypes.LIST_BY_ORGUNITID, onListByUnitId),
  takeLatest(OrgUserTypes.LIST_BY_ORGUNITID_SUCCESS, onListByUnitIdSuccess),
  takeLatest(OrgUserTypes.LIST_BY_ORGUNITID_FAILED, onListByUnitIdFailed),
];

export default tasks;
