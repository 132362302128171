import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetFilteredOrgs as UsecaseRemoteGetFilteredOrgs } from 'domain/usecases/org/remote';
import { ListFilteredOrgs as UsecaseReduxGetFilteredOrgs } from 'domain/usecases/org/redux';

import { RemoteGetFilteredOrgs } from 'data/repository/org';
import { ReduxListFilteredOrgs } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetFilteredOrgs = (): UsecaseRemoteGetFilteredOrgs =>
  new RemoteGetFilteredOrgs(makeApiUrl('/orgs/filtered/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetFilteredOrgs = (): UsecaseReduxGetFilteredOrgs =>
  new ReduxListFilteredOrgs();
