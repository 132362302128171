import React, { useMemo } from 'react';
import Translator from 'presentation/components/i18n/Translator';
import { ButtonPage } from './ButtonPage';

type PaginationProps = {
  total: number;
  itemsPerPage?: number;
  activePage?: number;
  onChangePage: (page: number) => void;
};

const siblingsCount = 4;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0);
}

export const Pagination: React.FC<PaginationProps> = ({
  total,
  itemsPerPage = 10,
  activePage = 1,
  onChangePage,
}) => {
  const lastPage = Math.ceil(total / itemsPerPage);

  const siblingsPages = useMemo(() => {
    return activePage >= siblingsCount - 1 &&
      activePage < lastPage - siblingsCount
      ? 2
      : siblingsCount;
  }, [activePage, lastPage]);

  const previousPages =
    activePage > 1
      ? generatePagesArray(activePage - 1 - siblingsPages, activePage - 1)
      : [];

  const nextPages =
    activePage < lastPage
      ? generatePagesArray(
          activePage,
          Math.min(activePage + siblingsPages, lastPage),
        )
      : [];

  const handlePageChangeWithButton = (page: number) => {
    if (page < 1 || page > lastPage) {
      return;
    }

    onChangePage(page);
  };

  return (
    <div
      style={{
        padding: '16px',
        fontSize: '16px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ButtonPage
        disabled={activePage === 1}
        onClick={() => handlePageChangeWithButton(activePage - 1)}
      >
        {Translator('Anterior')}
      </ButtonPage>
      {activePage > 1 + siblingsPages && (
        <>
          <ButtonPage onClick={() => onChangePage(1)}>1</ButtonPage>
          {activePage > 2 + siblingsPages && <ButtonPage>...</ButtonPage>}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map(page => (
          <ButtonPage key={page} onClick={() => onChangePage(page)}>
            {page}
          </ButtonPage>
        ))}

      <ButtonPage onClick={() => onChangePage(activePage)} active>
        {activePage}
      </ButtonPage>

      {nextPages.length > 0 &&
        nextPages.map(page => (
          <ButtonPage onClick={() => onChangePage(page)} key={page}>
            {page}
          </ButtonPage>
        ))}

      {activePage + siblingsPages < lastPage && (
        <>
          {activePage + 1 + siblingsPages < lastPage && (
            <ButtonPage>...</ButtonPage>
          )}

          <ButtonPage onClick={() => onChangePage(lastPage)}>
            {lastPage}
          </ButtonPage>
        </>
      )}
      <ButtonPage
        disabled={activePage === lastPage}
        onClick={() => handlePageChangeWithButton(activePage + 1)}
      >
        {Translator('Próximo')}
      </ButtonPage>
    </div>
  );
};
