import { call, put, select } from 'redux-saga/effects';
import { iActionRefresh } from 'domain/interfaces/redux/auth/refresh';
import { RefreshAuth } from 'domain/usecases/auth/remote/RefreshAuth';
import { makeRemoteRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';
import {
  refreshSuccess,
  refreshFailed,
} from 'data/store/reducer/auth/actions/refresh';
import { History } from 'main/routes';
import { makeReduxLogoutAuth } from 'main/factories/usecases/auth/LogoutAuthFactory';
import { toast } from 'react-toastify';
import { request } from 'http';
import AxiosHttpClient from 'infra/http/axios/AxiosHttpClient';
import { iStore } from 'domain/interfaces/models';

export function* onRefresh(action: iActionRefresh) {
  const remoteRefreshAuth = makeRemoteRefreshAuth();

  try {
    const response: RefreshAuth.Model = yield call(
      remoteRefreshAuth.refresh,
      action.payload,
    );

    const { requestConfig } = action.payload as RefreshAuth.Params;

    yield put(
      refreshSuccess({
        ...response,
        requestConfig,
      }),
    );
  } catch (e) {
    yield put(refreshFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function onRefreshSuccess(action: any) {
  const axios = new AxiosHttpClient();

  const {
    requestConfig,
    access: { token },
  } = action.payload as RefreshAuth.Model;

  if (requestConfig) {
    await axios.request({
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    });
  }
  if (window.location.pathname === '/') {
    History.getHistory().push('/home');
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onRefreshFailed() {
  makeReduxLogoutAuth().logout({});
  // toast.error('Login expirado, efetue login novamente.');
}
