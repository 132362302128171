import React, { useEffect } from 'react';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { HeaderPulicSac } from 'presentation/components/sac/HeaderPublicSac';
import { Container } from './styles/StyledSelectSubject';

const SelectSubject: React.FC = () => {
  // UseEffect temporario para testar a mensagem
  useEffect(() => {
    makeReduxActiveMessage().active({
      active: 'chooseSubject',
      actionCancel: () => closeModal(),
    });
  }, []);

  return (
    <Container>
      <HeaderPulicSac />
    </Container>
  );
};

export default SelectSubject;
