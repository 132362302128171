import * as Yup from 'yup';

export const passwordRecoverSchema = Yup.object()
  .shape({
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.'),
    newPassword: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.'),
    confirmNewPassword: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
      .oneOf([Yup.ref('newPassword')], 'As senhas não conferem'),
  })
  .required();
