import { ListMyConferences } from 'domain/usecases/myConferences/remote';
import {
  BadRequestError,
  Forbidden,
  UnexpectedError,
  UnProcessableEntity,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteListMyConferences implements ListMyConferences {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListMyConferences.Model>;

  constructor(url: string, httClient: HttpClient<ListMyConferences.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (
    params: ListMyConferences.Params,
  ): Promise<ListMyConferences.Model> => {
    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
