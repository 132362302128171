import { ListOrgUser } from 'domain/usecases/orgUser/redux/ListOrgUser';
import {
  iActionListOrgUser,
  iActionListOrgUserFailed,
  iActionListOrgUserSuccess,
  OrgUserTypes,
} from 'domain/interfaces/redux/orgUser/list';

export const listRequest = (
  payload: ListOrgUser.Params,
): iActionListOrgUser => ({
  type: OrgUserTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListOrgUser.Model,
): iActionListOrgUserSuccess => ({
  type: OrgUserTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUserFailed => ({
  type: OrgUserTypes.LIST_FAILED,
});
