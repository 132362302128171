import { GetFilteredUsers } from 'domain/usecases/user/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
  UnProcessableEntity,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetFilteredUsers implements GetFilteredUsers {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetFilteredUsers.Model>;

  constructor(url: string, httClient: HttpClient<GetFilteredUsers.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetFilteredUsers.Params,
  ): Promise<GetFilteredUsers.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
