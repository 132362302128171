import * as Yup from 'yup';
import Translator from 'presentation/components/i18n/Translator';

export enum MessageError {
  REQUIRED_FIELD = 'Campo obrigatório',
  TOO_BIG_NAME = 'O nome do setor deve conter no máximo 64 caracteres.',
  TOO_BIG_SHORTNAME = 'A sigla deve conter no máximo 32 caracteres.',
}

export const createOrgUnitSchema = Yup.object()
  .shape({
    name: Yup.string()
      .max(64, Translator(MessageError.TOO_BIG_NAME))
      .required(Translator(MessageError.REQUIRED_FIELD)),

    shortname: Yup.string()
      .max(32, Translator(MessageError.TOO_BIG_SHORTNAME))
      .required(Translator(MessageError.REQUIRED_FIELD)),
  })
  .required();
