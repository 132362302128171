import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 0;

  opacity: 0.3;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 512px;
  padding: 36px;
  gap: 16px;
  z-index: 1;

  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 100%;
    min-width: 0;
  }
`;

export const Camera = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 236px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
`;

export const CameraVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
`;

export const Watermark = styled.img`
  position: absolute;
  top: 12px;
  left: 12px;
  width: 62px;
  height: 31px;
  align-self: flex-start;
  justify-self: flex-start;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  gap: 48px;
`;

interface iIconBox {
  active?: boolean;
}

export const IconBox = styled.div<iIconBox>`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${({ active, theme }) =>
    active ? `${theme.colors.primary.main}` : '#7A7A7A'};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};

    svg {
      fill: #f5f5f5;
    }
  }
`;

interface iTextProps {
  color?: string;
  bold?: boolean;
  fontSize?: string;
}

export const Text = styled.h3<iTextProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: ${props => props.fontSize ?? '16px'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => props.color};
  text-align: center;
`;

export const ElipsedText = styled.h3<iTextProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  font-size: ${props => props.fontSize ?? '16px'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => props.color};
  text-align: left;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ButtonsContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 32px;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
`;
