import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const FieldsetContainer = styled.fieldset`
  /* border: 0; */

  width: 100%;

  border-radius: 0.5rem;
  padding: 1rem;

  legend {
    padding: 0 0.5rem;
  }

  .field:first-child {
    margin-top: 1rem;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      text-align: right;
    }
  }
`;
