import { ListLogsAudit } from 'domain/usecases/audit/redux/ListLogsAudit';
import {
  iActionListLogs,
  iActionListLogsFailed,
  iActionListLogsSuccess,
  AuditTypes,
} from 'domain/interfaces/redux/audit/listLogs';

export const listLogsRequest = (
  payload: ListLogsAudit.Params,
): iActionListLogs => ({
  type: AuditTypes.LISTLOGS,
  payload,
});

export const listLogsSuccess = (
  params: ListLogsAudit.Model,
): iActionListLogsSuccess => ({
  type: AuditTypes.LISTLOGS_SUCCESS,
  payload: params,
});

export const listLogsFailed = (): iActionListLogsFailed => ({
  type: AuditTypes.LISTLOGS_FAILED,
});
