/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { HTMLAttributes } from 'react';
import Translator from '../i18n/Translator';

import { AccessNav, Container, Content } from './styles/StyledMenuNavigator';

interface propsHeaderMenu extends HTMLAttributes<HTMLDivElement> {
  setState?: (e: number) => void;
  options: string[];
  active: number;
}

const HeaderMenu: React.FC<propsHeaderMenu> = ({
  active,
  options,
  setState,
}) => {
  return (
    <div>
      <Container>
        <Content>
          {options.map((elem, index) => (
            <AccessNav
              data-testid={`nav-${elem}`.toLocaleLowerCase()}
              onClick={() => setState?.(index)}
              resourcers={elem === 'Avançado' ? ['USER'] : undefined}
              actions={
                elem === 'Avançado'
                  ? ['UPDATE_UNIT', 'UPDATE_ORG', 'UPDATE_ANY']
                  : undefined
              }
            >
              <a className={active === index ? 'active' : ''}>
                {Translator(elem)}
              </a>
            </AccessNav>
          ))}
        </Content>
      </Container>
    </div>
  );
};

export default HeaderMenu;
