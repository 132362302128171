import styled from 'styled-components';

export const Container = styled.div``;

export const CellContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .name {
    font-weight: 500;
  }

  .role {
    font-weight: 400;
    color: #697f96;
  }
`;
