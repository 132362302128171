/* eslint-disable no-underscore-dangle */
import { IconRemoveRed } from 'presentation/base/icons';
import { Select } from 'presentation/components/UI';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { mapperConferenceRoles } from 'utils/mapperRoles';

import { iStore } from 'domain/interfaces/models';
import { Participant } from 'domain/usecases/conference/remote';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getOrgUserRole, getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import Checkbox from 'presentation/components/checkbox';
import { AvatarImage } from '../../../avatar';
import Translator from '../../../i18n/Translator';
import {
  Avatar,
  Info,
  ListActions,
  ListContainer,
  ListItem,
  User,
} from '../../styles/StyledAppointmentForm';
import {
  CheckboxContainer,
  Control,
  DropdownIndicator,
  Option,
  selectStyles,
} from './styles';

export interface ParticipantsListRef {
  getParticipants: () => Participant[];
  getOwner: () => { owner?: number; org?: number };
  setParticipants: (participants: Participant[]) => void;
  getNotifyOptions: () => NotifyOptions;
  setNotifyOptions: (options: NotifyOptions) => void;
}

type NotifyOptions = {
  whatsapp: boolean;
  email: boolean;
  sms: boolean;
};

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
  email?: string;
}

const ParticipantsList: React.ForwardRefRenderFunction<
  ParticipantsListRef,
  { edit: boolean }
> = ({ edit = false, children }, ref) => {
  const [valueUser, setValueUser] = useState(0);
  const [selectUsers, setSelectUsers] = useState<any[]>([]);
  const [newUsers, setNewUsers] = useState<any[]>([]);

  const [users, setUsers] = useState<iUsers[]>([]);
  const [notifyOptions, setNotifyOptions] = useState<NotifyOptions>({
    whatsapp: false,
    email: false,
    sms: false,
  });

  const { records } = useSelector((store: iStore) => store.orgUsers);
  const { user } = useSelector((store: iStore) => store.auth);

  const roleRedux = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  useEffect(() => {
    UpdateListOrgUser(roleRedux, user?.org?.id, user?.orgUnit?.id);
  }, [roleRedux, user?.org?.id, user?.orgUnit?.id]);

  useEffect(() => {
    const newData = records.map(item => {
      const role = getOrgUserRole(item.sysAdmin, item.orgAdmin, item.unitAdmin);

      return {
        id: item.user.id,
        name: `${item.user.fullName}`,
        role,
        Image: item.user.avatar,
        orgName: item.orgUnit?.org?.shortname ?? '',
        unitName: item.orgUnit?.shortname ?? '',
        email: item.user.email,
      };
    });

    setUsers(newData);
  }, [records]);

  const { user: userRedux } = useSelector((store: iStore) => store.auth);

  const owner = useMemo(
    () => ({
      user: userRedux?.id,
      name: userRedux?.fullName,
      image: userRedux?.avatar,
      role: 'MODERATOR',
      email: userRedux?.email,
      orgName: userRedux?.org.shortname,
      unitName: userRedux?.orgUnit.shortname,
    }),
    [userRedux],
  );

  const getOrgUnitLabelName = useCallback(
    (name: string, orgName?: string, unitName?: string) => {
      if ((!orgName && !unitName) || (orgName === '' && unitName === ''))
        return name;

      if (orgName && !unitName && orgName !== '')
        return `${name} (${orgName?.toLocaleUpperCase()})`;

      return `${name} (${orgName?.toLocaleUpperCase()}/${unitName?.toLocaleUpperCase()})`;
    },
    [],
  );

  const generateSelectOptions = useCallback(() => {
    return _.sortBy(
      users
        ?.map(value => {
          if (
            value.id !== userRedux?.id &&
            !selectUsers.find(el => el.user === value.id)
          )
            return {
              value: value.id,
              label: getOrgUnitLabelName(
                value.name,
                value.orgName,
                value.unitName,
              ),
            };

          return null;
        })
        .filter(Boolean),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [getOrgUnitLabelName, selectUsers, users, userRedux]);

  useImperativeHandle(
    ref,
    () => ({
      getParticipants: () => {
        return [...selectUsers, ...newUsers];
      },
      getOwner: () => {
        return { owner: owner?.user, org: userRedux?.org.id };
      },
      setParticipants(participants) {
        setSelectUsers(participants);
      },
      getNotifyOptions: () => notifyOptions,
      setNotifyOptions: notifyOptionsParam => {
        setNotifyOptions(notifyOptionsParam);
      },
    }),
    [newUsers, notifyOptions, owner?.user, selectUsers, userRedux?.org.id],
  );

  return (
    <>
      <div className="title">{Translator('Participantes')}</div>
      <CreatableSelect
        className="select"
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Option,
        }}
        placeholder={Translator(
          'Digite o nome ou e-mail de um participante...',
        )}
        formatCreateLabel={(label: string) => `Adicionar ${label}`}
        value={valueUser}
        options={generateSelectOptions()}
        onChange={(e: any) => {
          if (e.value && newUsers.find(el => el.email === e.value)) return;

          if (e?.__isNew__ && e.value) {
            const currentEmail = e.value;
            const foundUser = users?.find(item => item.email === currentEmail);

            if (!foundUser) {
              setNewUsers([
                ...newUsers,
                {
                  email: e.value,
                  fullName: e.value?.split('@')[0],
                  role: 'ATTENDEE',
                },
              ]);
              return;
            }

            const auxLixt = selectUsers;
            auxLixt.push({
              user: foundUser.id,
              role: 'ATTENDEE',
            });

            setSelectUsers(auxLixt);
            setValueUser(foundUser.id);
          } else {
            const auxLixt = selectUsers;
            auxLixt.push({
              user: Number(e.value),
              role: 'ATTENDEE',
            });

            setSelectUsers(auxLixt);
            setValueUser(Number(e.value));
          }
        }}
        onInputChange={(e: any) => {
          // console.log('Updated input: ', e);
        }}
        styles={selectStyles}
      />
      <ListContainer>
        {!edit && owner && (
          <ListItem>
            <User>
              <Avatar>
                <AvatarImage src={owner.image} name={owner.name} size="mini" />
              </Avatar>
              <Info>
                <div>
                  {getOrgUnitLabelName(
                    String(owner.name),
                    owner.orgName,
                    owner.unitName,
                  )}
                </div>
                <div style={{ color: '#818385' }}>
                  {mapperConferenceRoles(String(owner.role))}
                </div>
              </Info>
            </User>
          </ListItem>
        )}
        {users &&
          selectUsers.map((value, index) => {
            const foundUser = users?.find(item => item.id === value.user);

            return (
              <ListItem>
                <User>
                  <Avatar>
                    {value.image || foundUser?.Image ? (
                      <img
                        alt=""
                        src={value.image || foundUser?.Image}
                        style={{
                          borderRadius: '50%',
                          width: '44px',
                          height: '44px',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <AvatarImage
                        name={value.fullName || foundUser?.name}
                        size="mini"
                      />
                    )}
                  </Avatar>
                  <Info>
                    <div>
                      {getOrgUnitLabelName(
                        String(value.fullName || foundUser?.name),
                        foundUser?.orgName || undefined,
                        foundUser?.unitName || undefined,
                      )}
                    </div>
                    <div>
                      <Select
                        data-testid={`select-user-role-${value.id}`}
                        width="150px"
                        height="17px"
                        id="test"
                        value={value.role}
                        onChange={e => {
                          setSelectUsers(
                            selectUsers.map(el => {
                              if (el.user === value.user) {
                                return {
                                  ...el,
                                  role: e.target.value as
                                    | 'MODERATOR'
                                    | 'ATTENDEE',
                                };
                              }
                              return el;
                            }),
                          );
                        }}
                        minimal
                      >
                        <option data-testid="attendee-option" value="ATTENDEE">
                          Convidado
                        </option>
                        <option
                          data-testid="moderator-option"
                          value="MODERATOR"
                        >
                          Moderador
                        </option>
                      </Select>
                    </div>
                  </Info>
                </User>
                <ListActions>
                  <IconRemoveRed
                    data-testid={`icon-delete-user-${index}`}
                    onClick={() => {
                      setSelectUsers(selectUsers.filter(el => el !== value));
                      setValueUser(0);
                    }}
                  />
                </ListActions>
              </ListItem>
            );
          })}
        {newUsers.map(value => (
          <ListItem key={value.id}>
            <User>
              <Avatar>
                <AvatarImage name={value.name} size="mini" />
              </Avatar>
              <Info>
                <div>{value.email}</div>
                <div>
                  <Select
                    data-testid={`new-user-role-${value.id}`}
                    width="150px"
                    height="17px"
                    value={value.role}
                    onChange={e => {
                      setNewUsers(
                        newUsers.map(el => {
                          if (el.email === value.email) {
                            return {
                              ...el,
                              role: e.target.value as 'MODERATOR' | 'ATTENDEE',
                            };
                          }
                          return el;
                        }),
                      );
                    }}
                    minimal
                  >
                    <option data-testid="attendee-option" value="ATTENDEE">
                      Convidado
                    </option>
                    <option data-testid="moderator-option" value="MODERATOR">
                      Moderador
                    </option>
                  </Select>
                </div>
              </Info>
            </User>
            <ListActions>
              <IconRemoveRed
                onClick={() => {
                  setNewUsers(newUsers.filter(el => el !== value));
                  setValueUser(0);
                }}
              />
            </ListActions>
          </ListItem>
        ))}
      </ListContainer>
      <div className="title">{Translator('Notificar')}</div>
      <CheckboxContainer>
        <Checkbox
          checked={notifyOptions.whatsapp}
          defaultChecked={notifyOptions.whatsapp}
          label={Translator('WhatsApp')}
          onClick={() => {
            setNotifyOptions({
              ...notifyOptions,
              whatsapp: !notifyOptions.whatsapp,
            });
          }}
        />
        <Checkbox
          checked={notifyOptions.sms}
          defaultChecked={notifyOptions.sms}
          label={Translator('SMS')}
          onClick={() => {
            setNotifyOptions({ ...notifyOptions, sms: !notifyOptions.sms });
          }}
        />
        <Checkbox
          checked={notifyOptions.email}
          defaultChecked={notifyOptions.email}
          label={Translator('E-mail')}
          onClick={() => {
            setNotifyOptions({ ...notifyOptions, email: !notifyOptions.email });
          }}
        />
      </CheckboxContainer>
    </>
  );
};

export default forwardRef(ParticipantsList);
