import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  padding-bottom: 3rem;

  #test {
    padding-left: 0;
    font-size: 14px;
    color: #818385;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 189px;
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 0px 30px 0px 45px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;

  gap: 2rem;

  /* max-height: 493px; */

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;

    textarea {
      display: none;
    }
  }
`;

export const BackButton = styled.div`
  height: 85px;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 85px);

  span {
    font-size: 45px;
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      margin-left: 21px;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;

  span {
    font-size: 18px;
    margin-top: 5px;
  }
`;

export const Left = styled.div`
  /* width: 100%; */
  /* max-width: 33.4375rem; */
  display: flex;
  flex-grow: 1;

  textarea {
    border: none;
    border-radius: 8px;
    color: #444a51;
    width: 100%;
    resize: none;
    height: 174px;
    padding: 14px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;

    ::placeholder {
      color: #bfc4ca;
    }
  }

  .input {
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    border: none;
    width: 100%;
    padding: 0px;
  }
`;

export const Center = styled.div`
  width: 90%;
  /* max-width: 22.5rem; */
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px !important;
  }

  .select {
    width: 100%;
    margin-bottom: 12px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px;

    .select {
      max-width: 100%;
    }
  }
`;

export const Right = styled.aside`
  display: flex;
  width: 50%;
  /* max-width: 18.375rem; */
  align-items: flex-start;
  flex-direction: column;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 50px;

  .time {
    margin-left: 20px;
  }
`;

type LabelProps = {
  required?: boolean;
};

export const Label = styled.span<LabelProps>`
  color: #222529;
  margin-bottom: 1rem;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  flex-wrap: nowrap;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.25rem;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p.switch-label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  // max-width: 389px;
  max-height: 281px;
  /* padding: 31px 17px; */
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  border: 1px solid #b4b7b9;
  margin-bottom: 48px;
  overflow-y: scroll;
  background-color: #fdfdfd;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 11px;
`;

export const User = styled.div`
  display: flex;
  gap: 10px;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 6px;

  .icon {
    border: 1px solid red;
    border-radius: 50%;
  }

  .img {
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0px;
`;

export const ListActions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CheckBoxGroup = styled.div`
  display: flex;
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: 8px;
    border-radius: 8px;
    height: 22px;
    width: 22px;
  }

  .text {
    font-size: 14px;
    margin-right: 15px;
  }
`;

export const Footer = styled.div`
  max-width: 389px;
  padding: 63px 450px;
  display: flex;
  justify-content: end;
`;

export const Navigator = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 4.5rem;

  background-color: #f8f8f8;
  border-top: 1px solid #dedede;

  div.buttons-container {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
  }
`;
