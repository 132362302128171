import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9000;
`;

export const Container = styled.div`
  border-radius: 1.2rem;
  border: 1px solid #dedede;
  background-color: #fdfdfd;
  width: 20.8rem;
`;

export const Content = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #222529;
  margin-bottom: 0.75rem;
`;

export const Subtitle = styled.span`
  font-size: 0.875rem;
  color: #636d77;
  margin-bottom: 1.5rem;
  font-weight: 400;
`;

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: #222529;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border-top: 1px solid #dedede;
`;
