import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 92px;
  height: 40px;
  background: #fdfdfd;
  border-radius: 6px;
  border: 1px #b4b7b9 solid;
`;

export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 69px;
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: none;
    padding-left: 12px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconBorder = styled.button`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #b4b7b9;
  cursor: pointer;

  &:disabled {
    cursor: initial;
  }

  height: 20px;
  width: 20px;
`;
