import { Dispatch } from 'redux';
import store from 'data/store';
import { listFilteredConferenceRequest } from 'data/store/reducer/conference/actions/listFiltered';

import { iActionListFilteredConference } from 'domain/interfaces/redux/conference/listFiltered';

import { ListFilteredConference } from 'domain/usecases/conference/redux';

export class ReduxListFilteredConference implements ListFilteredConference {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (
    params: ListFilteredConference.Params,
  ): iActionListFilteredConference =>
    this.send(listFilteredConferenceRequest(params));
}
