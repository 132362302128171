import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListAdmin as UsecaseRemoteListAdmin } from 'domain/usecases/admin/remote';
// import { ListAdmin as UsecaseReduxListAdmin } from 'domain/usecases/admin/redux';

import { RemoteListAdmin } from 'data/repository/admin';
// import { ReduxListAdmin } from 'data/store/reducer/admin/usecases';

/**
 * send request via API.
 */
export const makeRemoteListAdmin = (): UsecaseRemoteListAdmin =>
  new RemoteListAdmin(makeApiUrl('/orgs/admins/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxListAdmin =
  (): UsecaseReduxListAdmin =>
    new ReduxListAdmin(); */
