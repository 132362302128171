/* eslint-disable react/react-in-jsx-scope */
import { defaultTheme } from 'presentation/base/themes';

import { IconPeople } from 'presentation/base/icons';
import { components } from 'react-select';
import styled from 'styled-components';

export const selectStyles = (theme: typeof defaultTheme) => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    border: '1px solid #dfdfdf',
    boxShadow: 'none',
    borderRadius: '8px',
    background: '#fff',
    padding: '6px',

    '&:hover': {
      borderColor: `${theme.colors.primary.main}`,
    },

    '&:focus': {
      borderColor: `${theme.colors.primary.main}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
});

export const Option = ({ children: childrenOption, ...props }: any) => (
  <components.Option {...props} data-testid="option-participant">
    {childrenOption}
  </components.Option>
);

export const Control = ({ children: childrenControl, ...props }: any) => (
  <components.Control {...props} data-testid="input-participants">
    {childrenControl}
  </components.Control>
);

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconPeople data-testid="icon-participants" />
    </components.DropdownIndicator>
  );
};

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
`;
