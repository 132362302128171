import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUser } from 'domain/interfaces/redux/orgUser/list';
import { ListOrgUser } from 'domain/usecases/orgUser/remote/ListOrgUser';
import { makeRemoteListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUsers/actions/list';

export function* onList(action: iActionListOrgUser) {
  const remoteListOrgUser = makeRemoteListOrgUser();

  try {
    const response: ListOrgUser.Model = yield call(
      remoteListOrgUser.list,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
