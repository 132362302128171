import { GetPublicTopics } from 'domain/usecases/sacTopic/remote';
import { useLocation } from 'react-router-dom';
import { BadRequestError, Forbidden, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetPublicTopics implements GetPublicTopics {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetPublicTopics.Model>;

  constructor(url: string, httClient: HttpClient<GetPublicTopics.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (
    params: GetPublicTopics.Params,
  ): Promise<GetPublicTopics.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      headers: params?.token
        ? {
            Authorization: `APIKEY ${params.token}`,
          }
        : undefined,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
