import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 18.3rem;
`;

export const ContentFields = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

export const Fields = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  div {
    & + div {
      padding-top: 15px;
    }
  }
`;

export const ContentInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 30px;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  color: #a6a6a6;

  input {
    accent-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
