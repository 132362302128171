/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import { EditorContainer } from './StyledNotes';
import 'quill/dist/quill.snow.css';

interface ownProps {
  value: string;
  onChange?: (e: string) => void;
  disabledToolbar?: boolean;
}

export const Editor: React.FC<ownProps> = ({
  value,
  onChange,
  disabledToolbar,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: disabledToolbar
            ? '#toolbar'
            : [
                [{ header: '1' }, { header: '2' }],
                [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
                [{ color: [] }, { background: [] }],
                ['bold', 'italic', 'underline'],
                ['clean'],
              ],
        },
        formats: [
          'header',
          'font',
          'list',
          'bullet',
          'bold',
          'italic',
          'underline',
          'link',
          'align',
        ],
        placeholder: disabledToolbar ? '' : 'Escreva aqui...',
      });

      quillRef.current.on('text-change', () => {
        const html = editorRef.current!.children[0].innerHTML;
        onChange?.(html);
      });
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off('text-change');
      }
    };
  }, [onChange]);

  useEffect(() => {
    if (quillRef.current) {
      const currentHtml = editorRef.current!.children[0].innerHTML;
      if (value !== currentHtml) {
        quillRef.current.root.innerHTML = value;
      }
    }
    if (disabledToolbar) {
      quillRef.current?.disable();
    }
  }, [value, disabledToolbar]);

  return <EditorContainer ref={editorRef} disabledToolbar={disabledToolbar} />;
};
