import React, { useEffect, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import Translator from 'presentation/components/i18n/Translator';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteRespondConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/RespondConferenceWaitingRoomFactory';
import { closeModal } from 'utils/closeModal';
import { makeReduxSetupConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/SetupConferenceWaitingRoomFactory';
import { iConferenceWaitingRoom } from 'domain/interfaces/models/ConferenceWaitingRoom';
import {
  Body,
  Container,
  Content,
  ButtonsContainer,
} from './styles/StyledrequestWaitingRoom';
import { Button } from '../../UI';

const RequestWaitingRoom: React.FC = () => {
  const msgName = MessageOptions.requestWaitingRoom;

  const message = useSelector((store: iStore) => store.message);
  const { requestQueue } = useSelector(
    (store: iStore) => store.conferenceWaitingRoom,
  );
  const { active, actionOk, actionCancel, content } = message;

  const [loading, setLoading] = useState<boolean>(false);

  const respondSoliciation = () => {
    console.log('respondSoliciation');
  };

  useEffect(() => {
    if (requestQueue?.length > 0) {
      makeReduxActiveMessage().active({
        active: 'requestWaitingRoom',
        actionCancel: () => {
          makeRemoteRespondConferenceWaitingRoom().respond({
            conferenceShort: requestQueue[0].conference.short,
            body: {
              session: requestQueue[0].user.session,
              participant: requestQueue[0].user.id ?? undefined,
              allowJoin: false,
            },
          });
          closeModal();
          removeRequest(requestQueue);
        },
        actionOk: () => {
          makeRemoteRespondConferenceWaitingRoom().respond({
            conferenceShort: requestQueue[0].conference.short,
            body: {
              session: requestQueue[0].user.session,
              participant: requestQueue[0].user.id ?? undefined,
              allowJoin: true,
            },
          });
          closeModal();
          removeRequest(requestQueue);
        },
      });
    }
  }, [requestQueue]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const removeRequest = (rQueue: iConferenceWaitingRoom['requestQueue']) => {
    const el = rQueue.shift();
    makeReduxSetupConferenceWaitingRoom().setup({
      requestQueue: el ? [...rQueue] : [],
    });
  };

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content hasContent={!!content}>
              <div className="textContent">
                <div className="title">Solicitar entrada</div>
                <div className="description">
                  O usuário
                  <strong>
                    {` ${requestQueue.length && requestQueue[0].user.name} `}
                  </strong>
                  acaba de pedir permissão para entrar. Como deseja proceder?
                </div>
              </div>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                variant="primary"
                autoFocus
                onClick={actionOk}
              >
                {Translator('Autorizar entrada')}
              </Button>
              <Button
                id="button_confirm"
                variant="secundary"
                autoFocus
                onClick={actionCancel}
                disabled={loading}
              >
                Recusar
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default RequestWaitingRoom;
