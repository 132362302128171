import styled from 'styled-components';

interface ContainerProps {
  active?: boolean;
}

export const Container = styled.button<ContainerProps>`
  height: 40px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active }) => (active ? '#0565D9' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#222529')};

  padding: 12px;
  border-radius: 6px;
  border: ${({ active }) =>
    active ? '1px solid #0565D9' : '1px #b4b7b9 solid'};

  font-size: 13px;
  font-weight: 400;

  user-select: none;

  &:disabled {
    border: 1px #b4b7b9 solid;
    background-color: #dedede;
  }

  &:hover {
    border-color: ${({ active }) => (active ? '#3D94FD' : '#b4b7b9')};
    background-color: ${({ active }) => (active ? '#3D94FD' : '#ececec')};
  }

  &:focus-visible {
    outline: 2px solid ${({ active }) => (active ? '#fdfdfd' : '#3d94fd')};
    box-shadow: 0px 0px 12px 0px
      ${({ active }) => (active ? '#3d94fd' : '#b4b7b9')};
  }

  transition: all 0.1s ease-in;
`;
