import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetPublicTopics as UsecaseRemoteGetPublicTopics } from 'domain/usecases/sacTopic/remote';

import { RemoteGetPublicTopics } from 'data/repository/sacTopic';

/**
 * send request via API.
 */
export const makeRemoteGetPublicTopics = (): UsecaseRemoteGetPublicTopics =>
  new RemoteGetPublicTopics(makeApiUrl('/sac/public/topics'), makeHttpClient());
