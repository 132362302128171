import { Dispatch } from 'redux';
import store from 'data/store';
import { listAclFilteredV2Request } from 'data/store/reducer/org/actions/listAclFilteredV2';

import { iActionListAclFilteredV2 } from 'domain/interfaces/redux/org/listAclFilteredV2';

import { ListOrgAclFilteredV2 } from 'domain/usecases/org/redux';

export class ReduxListOrgAclFilteredV2 implements ListOrgAclFilteredV2 {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListOrgAclFilteredV2.Params): iActionListAclFilteredV2 =>
    this.send(listAclFilteredV2Request(params));
}
