import { Dispatch } from 'redux';
import store from 'data/store';
import { listGroupsRequest } from 'data/store/reducer/audit/actions/listGroups';

import { iActionListGroups } from 'domain/interfaces/redux/audit/listGroups';

import { ListGroupsAudit } from 'domain/usecases/audit/redux';

export class ReduxListGroupsAudit implements ListGroupsAudit {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listGroups = (params: ListGroupsAudit.Params): iActionListGroups =>
    this.send(listGroupsRequest(params));
}
