import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ListOrgsUnits = styled.div`
  width: 100%;
  margin-top: 14px;
`;
