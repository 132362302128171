import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { AgreedTerms as UsecaseRemoteAgreedTerms } from 'domain/usecases/user/remote';

import { RemoteAgreedTerms } from 'data/repository/user';

/**
 * send request via API.
 */
export const makeRemoteAgreedTerms = (): UsecaseRemoteAgreedTerms =>
  new RemoteAgreedTerms(
    makeApiUrl('/users/{userId}/agreed-terms'),
    makeHttpClient(),
  );
