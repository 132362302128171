import { Dispatch } from 'redux';
import store from 'data/store';
import { openRequest } from 'data/store/reducer/modal/actions/open';

import { iActionOpen } from 'domain/interfaces/redux/modal/open';

import { OpenModal } from 'domain/usecases/modal/redux';

export class ReduxOpenModal implements OpenModal {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  open = (params: OpenModal.Params): iActionOpen =>
    this.send(openRequest(params));
}
