import { ListSacRequest } from 'domain/usecases/sacRequest/redux/ListSacRequest';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  SacRequestTypes,
} from 'domain/interfaces/redux/sacRequest/list';

export const listRequest = (payload: ListSacRequest.Params): iActionList => ({
  type: SacRequestTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListSacRequest.Model,
): iActionListSuccess => ({
  type: SacRequestTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: SacRequestTypes.LIST_FAILED,
});
