import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/pixelToRem';

type ButtonPageStyleProps = {
  active?: boolean;
};

export const ButtonPageStyle = styled.button<ButtonPageStyleProps>`
  padding: ${pxToRem(8)};
  min-width: ${pxToRem(32)};
  background: #fbfbfb;
  border: ${pxToRem(0.8)} solid #c2c7cf;
  border-radius: ${pxToRem(4)};

  color: #383f47;

  cursor: pointer;

  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(16)};
  align-text: center;

  & + & {
    margin-left: ${pxToRem(8)};
  }
  &:hover {
    background: #e5e6e9;
    border-color: #c2c7cf;
    color: #383f47;
  }

  &:focus-visible {
    box-shadow: ${({ theme }) =>
      `0px 0px 9.6px ${theme.colors.primary.main}66`};
    outline: ${({ theme }) =>
      `${pxToRem(1.6)} solid ${theme.colors.secondary.main}`};
    background: #fbfbfb;
    border-color: #fbfbfb;
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.primary.main};
      color: #fff;
      border-color: ${({ theme }) => theme.colors.primary.main};

      &:hover {
        background: ${({ theme }) => theme.colors.secondary.main};
        border-color: ${({ theme }) => theme.colors.secondary.main};
        color: #fff;
      }

      &:focus-visible {
        box-shadow: ${({ theme }) =>
          `0px 0px 9.6px ${theme.colors.primary.main}66`};
        outline: ${pxToRem(1.6)} solid #fbfbfb;
        background: ${({ theme }) => theme.colors.secondary.main};
        border-color: ${({ theme }) => theme.colors.secondary.main};
      }
    `}

  &:disabled {
    background: #e5e6e9;
    border-color: #c2c7cf;
    color: #74767a;
    cursor: default;
  }

  transition: all 0.3s ease;
`;
