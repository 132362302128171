import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateOrg as UsecaseRemoteUpdateOrg } from 'domain/usecases/org/remote';
// import { UpdateOrg as UsecaseReduxUpdateOrg } from 'domain/usecases/org/redux';

import { RemoteUpdateOrg } from 'data/repository/org';
// import { ReduxUpdateOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateOrg = (): UsecaseRemoteUpdateOrg =>
  new RemoteUpdateOrg(makeApiUrl('/orgs/{orgId}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateOrg =
  (): UsecaseReduxUpdateOrg =>
    new ReduxUpdateOrg(); */
