import styled from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

interface iImage {
  size?: string;
  isCircular?: boolean;
  hasBorder?: boolean;
}

export const Container = styled.div<iImage>`
  position: relative;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #d8d8d8' : 'none')};

  #uploader {
    position: absolute;
    bottom: ${({ isCircular }) => (isCircular ? '18px' : '-8px')};
    right: -8px;
  }
`;

export const Img = styled.img<iImage>`
  border: ${({ isCircular }) => (isCircular ? '1px solid #0000001a' : 'none')};
  border-radius: ${({ isCircular }) => (isCircular ? '50%' : '0')};
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  object-fit: contain;
`;

export const Initials = styled.div<iImage>`
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ isCircular }) => (isCircular ? '50%' : '0')};
  font-size: 60px;
  background: ${props => props.theme.colors.text.lightGray};
`;

export const AccessDiv = withAccess(styled.div``);
