import styled, { css } from 'styled-components';

import { Button } from 'presentation/components/UI/buttons';

interface iContainer {
  selected: boolean;
}

export const Container = styled.div<iContainer>`
  padding-left: 15px;
  height: 40px;
  width: 100%;
  :hover {
    background: rgba(226, 6, 19, 0.1);
  }

  ${props =>
    props.selected === true &&
    css`
      background: rgba(226, 6, 19, 0.1);
    `}
  cursor: pointer;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 11px;
  margin-top: 13px;
  color: #2d2d2d;
  display: flex;
  align-items: center;

  span {
    font-size: 10px;
    color: #a9a9a9;
    margin-left: 2px;
  }
`;

interface iItemRole {
  selected: boolean;
}

export const ItemRole = styled(Button)<iItemRole>`
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 3px;
  padding: 2px 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: black;
  width: auto;
  height: 24px;
  padding: 0px 15px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.main};
    color: white;
    font-style: normal;
    font-weight: 500;
  }

  ${props =>
    props.selected === true &&
    css`
      background: ${({ theme }) => theme.colors.primary.main};
      color: white;
      font-style: normal;
      font-weight: 500;
    `}
`;

export const Itens = styled.div`
  display: flex;
`;
