import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgUnitAclFiltered as UsecaseRemoteListOrgUnitAclFiltered } from 'domain/usecases/orgUnit/remote';
import { ListOrgUnitAclFiltered as UsecaseReduxListOrgUnitAclFiltered } from 'domain/usecases/orgUnit/redux';

import { RemoteListOrgUnitAclFiltered } from 'data/repository/orgUnit';
import { ReduxListOrgUnitAclFiltered } from 'data/store/reducer/orgUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgUnitAclFiltered =
  (): UsecaseRemoteListOrgUnitAclFiltered =>
    new RemoteListOrgUnitAclFiltered(
      makeApiUrl('/orgs/units/acl-filtered/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUnitAclFiltered =
  (): UsecaseReduxListOrgUnitAclFiltered => new ReduxListOrgUnitAclFiltered();
