export enum OrgUserTypes {
  LIST = '@orgUser/LIST',
  LIST_SUCCESS = '@orgUser/LIST_SUCCESS',
  LIST_FAILED = '@orgUser/LIST_FAILED',
  LIST_BY_ORGID = '@orgUser/LIST_BY_ORGID',
  LIST_BY_ORGID_SUCCESS = '@orgUser/LIST_BY_ORGID_SUCCESS',
  LIST_BY_ORGID_FAILED = '@orgUser/LIST_BY_ORGID_FAILED',
  LIST_BY_ORGUNITID = '@orgUser/LIST_BY_ORGUNITID',
  LIST_BY_ORGUNITID_SUCCESS = '@orgUser/LIST_BY_ORGUNITID_SUCCESS',
  LIST_BY_ORGUNITID_FAILED = '@orgUser/LIST_BY_ORGUNITID_FAILED',
}
