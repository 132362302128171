import React, { useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import Translator from 'presentation/components/i18n/Translator';

import { OrgInfos } from 'domain/usecases/org/remote';
import Input from 'presentation/components/UI/input';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { Container, ContentInput, Fields } from './styles';
import { initialValuesOrg } from '..';

interface ownProps {
  org: OrgInfos;
  onChangeOrgInfo: Function;
}

export const Name: React.FC<ownProps> = ({ org, onChangeOrgInfo }) => {
  const { setFieldValue, errors, handleBlur, touched } =
    useFormikContext<initialValuesOrg>();

  const { resources } = useSelector((store: iStore) => store.auth.permissions);
  const { user } = useSelector((store: iStore) => store.auth);

  const disableUpdate = useMemo(() => {
    if (user?.administrator?.system) return false;
    if (!resources) return true;

    // Go to resource ORG and check if user have UPDATE permission
    const haveUpdate = resources.find(
      res =>
        res.name === 'ORG' &&
        res.actions.find(item => item.name.includes('UPDATE')),
    );

    return !haveUpdate;
  }, [resources, user?.administrator?.system]);

  return (
    <Container>
      <Fields>
        <ContentInput>
          <Input
            autoFocus
            label={`${Translator('Nome da organização')}`}
            required
            name="name"
            defaultValue={org?.name}
            onBlur={handleBlur}
            value={org?.name}
            message={touched.name ? errors.name : ''}
            error={Boolean(touched.name && errors?.name)}
            onChange={e => {
              setFieldValue('name', e.target.value);
              onChangeOrgInfo({ ...org, name: e.target.value });
            }}
            disabled={disableUpdate}
          />
          <Input
            label={`${Translator('Sigla')}`}
            required
            name="shortname"
            defaultValue={org?.shortname}
            value={org?.shortname}
            onBlur={handleBlur}
            message={touched.shortname ? errors.shortname : ''}
            error={Boolean(touched.shortname && errors?.shortname)}
            onChange={e => {
              setFieldValue('shortname', e.target.value);
              onChangeOrgInfo({
                ...org,
                shortname: e.target.value.replace(/\s/g, ''),
              });
            }}
            disabled={disableUpdate}
          />
        </ContentInput>
      </Fields>
    </Container>
  );
};

export default Name;
