import { ListFilteredConference } from 'domain/usecases/conference/redux/ListFilteredConference';
import {
  iActionListFilteredConference,
  iActionListFilteredConferenceFailed,
  iActionListFilteredConferenceSuccess,
  ConferenceTypes,
} from 'domain/interfaces/redux/conference/listFiltered';

export const listFilteredConferenceRequest = (
  payload: ListFilteredConference.Params,
): iActionListFilteredConference => ({
  type: ConferenceTypes.LIST_FILTERED_CONFERENCE,
  payload,
});

export const listFilteredConferenceSuccess = (
  params: ListFilteredConference.Model,
): iActionListFilteredConferenceSuccess => ({
  type: ConferenceTypes.LIST_FILTERED_CONFERENCE_SUCCESS,
  payload: params,
});

export const listFilteredConferenceFailed =
  (): iActionListFilteredConferenceFailed => ({
    type: ConferenceTypes.LIST_FILTERED_CONFERENCE_FAILED,
  });
