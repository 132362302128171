import { takeLatest } from 'redux-saga/effects';
import { SacRequestTypes } from 'domain/interfaces/redux/sacRequest/types';
import { onList, onListSuccess, onListFailed } from './List';

const tasks = [
  takeLatest(SacRequestTypes.LIST, onList),
  takeLatest(SacRequestTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(SacRequestTypes.LIST_FAILED, onListFailed),
];

export default tasks;
