import { Dispatch } from 'redux';
import store from 'data/store';
import { logoutRequest } from 'data/store/reducer/auth/actions/logout';

import { iActionLogout } from 'domain/interfaces/redux/auth/logout';

import { LogoutAuth } from 'domain/usecases/auth/redux';

export class ReduxLogoutAuth implements LogoutAuth {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  logout = (params: LogoutAuth.Params): iActionLogout =>
    this.send(logoutRequest(params));
}
