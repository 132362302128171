import { ListFilteredUser } from 'domain/usecases/user/redux/ListFilteredUser';
import {
  iActionListFilteredUser,
  iActionListFilteredUserFailed,
  iActionListFilteredUserSuccess,
  UserTypes,
} from 'domain/interfaces/redux/user/listUserFiltered';

export const listFilteredUserRequest = (
  payload: ListFilteredUser.Params,
): iActionListFilteredUser => ({
  type: UserTypes.LIST_USER_FILTERED,
  payload,
});

export const listFilteredUserSuccess = (
  params: ListFilteredUser.Model,
): iActionListFilteredUserSuccess => ({
  type: UserTypes.LIST_USER_FILTERED_SUCCESS,
  payload: params,
});

export const listFilteredUserFailed = (): iActionListFilteredUserFailed => ({
  type: UserTypes.LIST_USER_FILTERED_FAILED,
});
