import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgAclFilteredV2 as UsecaseRemoteListOrgAclFilteredV2 } from 'domain/usecases/org/remote';
import { ListOrgAclFilteredV2 as UsecaseReduxListOrgAclFilteredV2 } from 'domain/usecases/org/redux';

import { RemoteListOrgAclFilteredV2 } from 'data/repository/org';
import { ReduxListOrgAclFilteredV2 } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgAclFilteredV2 =
  (): UsecaseRemoteListOrgAclFilteredV2 =>
    new RemoteListOrgAclFilteredV2(
      makeApiUrl('/orgs/acl-filtered-v2/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgAclFilteredV2 =
  (): UsecaseReduxListOrgAclFilteredV2 => new ReduxListOrgAclFilteredV2();
