import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBillingByService as UsecaseRemoteGetBillingByService } from 'domain/usecases/billing/remote';

import { RemoteGetBillingByService } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBillingByService =
  (): UsecaseRemoteGetBillingByService =>
    new RemoteGetBillingByService(
      makeApiUrl('/orgs/{orgId}/billing/service/'),
      makeHttpClient(),
    );
