import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetOrgUnit as UsecaseRemoteGetOrgUnit } from 'domain/usecases/orgUnit/remote';
// import { GetOrgUnit as UsecaseReduxGetOrgUnit } from 'domain/usecases/orgUnit/redux';

import { RemoteGetOrgUnit } from 'data/repository/orgUnit';
// import { ReduxGetOrgUnit } from 'data/store/reducer/orgUnit/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetOrgUnit = (): UsecaseRemoteGetOrgUnit =>
  new RemoteGetOrgUnit(
    makeApiUrl('/orgs/{orgId}/units/{unitId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetOrgUnit =
  (): UsecaseReduxGetOrgUnit =>
    new ReduxGetOrgUnit(); */
