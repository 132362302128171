import { iOrgUnit } from 'domain/interfaces/models/OrgUnit';
import { OrgUnitTypes } from 'domain/interfaces/redux/orgUnit/types';
import { OrgUnitActions } from './actions';

export const initialState: iOrgUnit = {
  loading: false,
  metadata: {
    limit: 0,
    total: 0,
    offset: 0,
  },
  records: [],
};

const reducer = (state = initialState, action: OrgUnitActions): iOrgUnit => {
  switch (action.type) {
    case OrgUnitTypes.LIST:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        records: action.payload.records,
      };
    }

    case OrgUnitTypes.LIST_ACL_FILTERED:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_ACL_FILTERED_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_ACL_FILTERED_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        records: action.payload.records,
      };
    }

    case OrgUnitTypes.LIST_ACL_FILTERED_V2:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_ACL_FILTERED_V2_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_ACL_FILTERED_V2_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        records: action.payload.records,
      };
    }

    case OrgUnitTypes.LIST_BY_ORGID:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_BY_ORGID_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_BY_ORGID_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        records: action.payload.records,
      };
    }

    case OrgUnitTypes.LIST_BY_ORGUNITID:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_BY_ORGUNITID_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_BY_ORGUNITID_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        records: action.payload.records,
      };
    }

    case OrgUnitTypes.SET: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
