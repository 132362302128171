import styled from 'styled-components';

interface successProps {
  content?: boolean;
}

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 40px 40px 20px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 33rem;
  max-height: 18rem;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3rem;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ff5b5b;

  span {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div<successProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  width: 100%;
  height: 120px;
  text-align: left;

  p {
    color: black;
    font-size: 18px;
    text-align: left;
    text-overflow: ellipsis;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 4px;
  color: #a6a6a6;

  input {
    margin-right: 7px;
    accent-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
