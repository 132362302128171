import { takeLatest } from 'redux-saga/effects';
import { UserTypes } from 'domain/interfaces/redux/user/types';
import { onList, onListSuccess, onListFailed } from './List';
import { onUpdate, onUpdateSuccess, onUpdateFailed } from './Update';

import {
  onListFilteredUser,
  onListFilteredUserFailed,
  onListFilteredUserSuccess,
} from './ListFilteredUser';

const tasks = [
  takeLatest(UserTypes.LIST, onList),
  takeLatest(UserTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(UserTypes.LIST_FAILED, onListFailed),
  takeLatest(UserTypes.UPDATE, onUpdate),
  takeLatest(UserTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(UserTypes.UPDATE_FAILED, onUpdateFailed),
  takeLatest(UserTypes.LIST_USER_FILTERED, onListFilteredUser),
  takeLatest(UserTypes.LIST_USER_FILTERED_SUCCESS, onListFilteredUserSuccess),
  takeLatest(UserTypes.LIST_USER_FILTERED_FAILED, onListFilteredUserFailed),
];

export default tasks;
