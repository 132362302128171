import { ListConferenceFilteredByACL } from 'domain/usecases/conference/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteListConferenceFilteredByACL
  implements ListConferenceFilteredByACL
{
  private readonly url: string;

  private readonly httpClient: HttpClient<ListConferenceFilteredByACL.Model>;

  constructor(
    url: string,
    httClient: HttpClient<ListConferenceFilteredByACL.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (
    params: ListConferenceFilteredByACL.Params,
  ): Promise<ListConferenceFilteredByACL.Model> => {
    let query = '';

    if (params.query) {
      query = '?';

      Object.entries(params.query).forEach(([key, value]) => {
        if (value) query += `${key}=${value}&`;
      });
    }

    const httpResponse = await this.httpClient.request({
      url: `${this.url}${query}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
      case HttpStatusCode.notModified:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
