import { Dispatch } from 'redux';
import store from 'data/store';
import { getDashboardOrgRequest } from 'data/store/reducer/dashboard/actions/getDashboardOrg';

import { iActionGetDashboardOrg } from 'domain/interfaces/redux/dashboard/getDashboardOrg';

import { GetDashboardOrg } from 'domain/usecases/dashboard/redux';

export class ReduxGetDashboardOrg implements GetDashboardOrg {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getDashboardOrgData = (
    params: GetDashboardOrg.Params,
  ): iActionGetDashboardOrg => this.send(getDashboardOrgRequest(params));
}
