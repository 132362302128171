import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

interface ContainerProps {
  open: boolean;
}
export const ContainerAccordion = styled.div`
  .MuiAccordionSummary-root {
    padding-left: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 1.6rem;
  :hover {
    color: #df2c26;
  }
`;
