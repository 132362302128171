import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 416px;
  min-height: 616px;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
`;

export const Title = styled.div`
  width: 100%;
  height: 57px;
  background: #62c97f;
  border-radius: 8px 8px 0px 0px;
  color: white;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 29px 0 29px;
`;

export const ContentBody = styled.div`
  width: 100%;
  display: flex;
  margin-top: 46px;
  flex-direction: column;
  gap: 20px;
`;

export const ListContainer = styled.div`
  width: 100%;
  // max-width: 389px;
  min-height: 358px;
  max-height: 358px;
  padding: 31px 17px;
  border: 1px solid rgba(166, 166, 166, 0.5);
  border-radius: 10px;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 11px;
`;

export const User = styled.div`
  display: flex;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 6px;

  .icon {
    border: 1px solid red;
    border-radius: 50%;
  }

  .img {
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0px;
`;

export const ListActions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
