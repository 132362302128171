import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { RespondConferenceWaitingRoom as UsecaseRemoteRespondConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/remote';
// import { RespondConferenceWaitingRoom as UsecaseReduxRespondConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

import { RemoteRespondConferenceWaitingRoom } from 'data/repository/conferenceWaitingRoom';
// import { ReduxRespondConferenceWaitingRoom } from 'data/store/reducer/conferenceWaitingRoom/usecases';

/**
 * send request via API.
 */
export const makeRemoteRespondConferenceWaitingRoom =
  (): UsecaseRemoteRespondConferenceWaitingRoom =>
    new RemoteRespondConferenceWaitingRoom(
      makeApiUrl('/conferences/{conferenceShort}/waitingRoom/respond'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRespondConferenceWaitingRoom =
  (): UsecaseReduxRespondConferenceWaitingRoom =>
    new ReduxRespondConferenceWaitingRoom(); */
