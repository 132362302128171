/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  Typography,
  IconButton,
  Button,
} from '@wisecare-tech/design-system-web';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { iMessage } from 'domain/interfaces/models';
import { format } from 'date-fns';
import {
  Header,
  Container,
  Footer,
  NotesContainer,
  ContainerModal,
  TitleAndSubtitle,
  ContentNote,
  Note,
} from './styles/StyledNotesHistory';
import { Editor } from '../../sidebarComponents/NotesSAC/Editor';

export interface propsModalNotesHistory {
  message: iMessage;
}

const NotesHistory: React.FC<propsModalNotesHistory> = ({ message }) => {
  const msgName = MessageOptions.notesHistory;

  const { active, actionOk, actionCancel, data } = message;

  const formattedDate = (date: string) => {
    const hour = format(new Date(date), 'HH:mm');
    const noteDate = format(new Date(date), 'dd/MM/yyyy');

    return `${noteDate} às ${hour}`;
  };

  return (
    <>
      {msgName === active ? (
        <ContainerModal>
          <Container>
            <Header>
              <TitleAndSubtitle>
                <Typography variant="t2_16bold">
                  Histórico de anotações
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  Aqui estão as anotações feitas por outros atendentes dessa
                  solicitação.
                </Typography>
              </TitleAndSubtitle>
              <IconButton
                variant="primary"
                icon="close"
                size="sm"
                onClick={actionCancel}
              />
            </Header>
            <NotesContainer>
              {data?.notes?.map((note: any) => (
                <ContentNote>
                  <Typography variant="b3_14medium">
                    {`${note?.sentBy?.fullname}, ${formattedDate(
                      note?.created,
                    )}`}
                  </Typography>
                  <Note>
                    <Editor key={note?.id} value={note?.note} disabledToolbar />
                  </Note>
                </ContentNote>
              ))}
            </NotesContainer>
            <Footer>
              <Button type="submit" variant="primary" onClick={actionOk}>
                Entendi
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default NotesHistory;
