export enum OrgUnitTypes {
  LIST = '@orgUnit/LIST',
  LIST_SUCCESS = '@orgUnit/LIST_SUCCESS',
  LIST_FAILED = '@orgUnit/LIST_FAILED',
  LIST_ACL_FILTERED = '@orgUnit/LIST_ACL_FILTERED',
  LIST_ACL_FILTERED_SUCCESS = '@orgUnit/LIST_ACL_FILTERED_SUCCESS',
  LIST_ACL_FILTERED_FAILED = '@orgUnit/LIST_ACL_FILTERED_FAILED',
  LIST_ACL_FILTERED_V2 = '@orgUnit/LIST_ACL_FILTERED_V2',
  LIST_ACL_FILTERED_V2_SUCCESS = '@orgUnit/LIST_ACL_FILTERED_V2_SUCCESS',
  LIST_ACL_FILTERED_V2_FAILED = '@orgUnit/LIST_ACL_FILTERED_V2_FAILED',
  LIST_BY_ORGID = '@orgUnit/LIST_BY_ORGID',
  LIST_BY_ORGID_SUCCESS = '@orgUnit/LIST_BY_ORGID_SUCCESS',
  LIST_BY_ORGID_FAILED = '@orgUnit/LIST_BY_ORGID_FAILED',
  LIST_BY_ORGUNITID = '@orgUnit/LIST_BY_ORGUNITID',
  LIST_BY_ORGUNITID_SUCCESS = '@orgUnit/LIST_BY_ORGUNITID_SUCCESS',
  LIST_BY_ORGUNITID_FAILED = '@orgUnit/LIST_BY_ORGUNITID_FAILED',
  SET = '@orgUnit/SET',
  SET_SUCCESS = '@orgUnit/SET_SUCCESS',
  SET_FAILED = '@orgUnit/SET_FAILED',
}
