import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgUnitAclFilteredV2 as UsecaseRemoteListOrgUnitAclFilteredV2 } from 'domain/usecases/orgUnit/remote';
import { ListOrgUnitAclFilteredV2 as UsecaseReduxListOrgUnitAclFilteredV2 } from 'domain/usecases/orgUnit/redux';

import { RemoteListOrgUnitAclFilteredV2 } from 'data/repository/orgUnit';
import { ReduxListOrgUnitAclFilteredV2 } from 'data/store/reducer/orgUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgUnitAclFilteredV2 =
  (): UsecaseRemoteListOrgUnitAclFilteredV2 =>
    new RemoteListOrgUnitAclFilteredV2(
      makeApiUrl('/orgs/units/acl-filtered-v2/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgUnitAclFilteredV2 =
  (): UsecaseReduxListOrgUnitAclFilteredV2 =>
    new ReduxListOrgUnitAclFilteredV2();
