import { Skeleton } from '@mui/material';
import React, { cloneElement } from 'react';
import {
  IconArrowUpDown,
  IconCalendarDashboard,
  IconUser,
} from 'presentation/base/icons';
import { pxToRem } from 'utils/pixelToRem';
import {
  StyledTableContainer,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableFooter,
  StyledTableHead,
  StyledTablePagination,
  StyledTableRow,
  InfoCard,
  CellContent,
  HeaderLabelStyled,
} from './styles';

const objToRenderLabelColumn: Record<string, any> = {
  org: {
    name: 'Organização',
  },
  users: {
    name: 'Usuários',
  },
  schedules: {
    name: 'Agendamentos',
  },
  recordings: {
    name: 'Gravações',
  },
  storage: {
    name: 'Armazenamento',
  },
};

const SkeletonTable: React.FC = () => {
  return (
    <StyledTableContainer>
      <StyledTable stickyHeader>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell />
            {Object.keys(objToRenderLabelColumn)?.map(key => (
              <StyledTableCell key={key}>
                <HeaderLabelStyled>
                  <Skeleton
                    variant="text"
                    width={pxToRem(200)}
                    height={pxToRem(20)}
                  />
                </HeaderLabelStyled>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {Array.from({ length: 20 }).map((_, idx) => (
            <StyledTableRow>
              <StyledTableCell style={{ paddingLeft: '1.6rem' }}>
                <InfoCard>
                  <CellContent>
                    <Skeleton
                      variant="text"
                      width={pxToRem(100)}
                      height={pxToRem(30)}
                    />
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  {/* <Skeleton
                    variant="text"
                    width={pxToRem(200)}
                    height={pxToRem(30)}
                  /> */}
                  <h2 className="info">
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(25)}
                    />
                  </h2>
                  <p className="subInfo">
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(15)}
                    />
                  </p>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  <CellContent>
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(30)}
                    />
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  <CellContent>
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(30)}
                    />
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  <CellContent>
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(30)}
                    />
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
              <StyledTableCell>
                <InfoCard>
                  <CellContent>
                    <Skeleton
                      variant="text"
                      width={pxToRem(200)}
                      height={pxToRem(30)}
                    />
                  </CellContent>
                </InfoCard>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default SkeletonTable;
