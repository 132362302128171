import { Dispatch } from 'redux';
import store from 'data/store';

import { listFilteredUserRequest } from 'data/store/reducer/user/actions/listFilteredUser';

import { iActionListFilteredUser } from 'domain/interfaces/redux/user/listUserFiltered';

import { ListFilteredUser } from 'domain/usecases/user/redux';

export class ReduxListFilteredUser implements ListFilteredUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListFilteredUser.Params): iActionListFilteredUser =>
    this.send(listFilteredUserRequest(params));
}
