import React, { useEffect, useMemo, useState } from 'react';
import { HeaderPulicSac } from 'presentation/components/sac/HeaderPublicSac';
import { AvailableAttendantForm } from 'presentation/components/sac/AvailableAttendantForm';
import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';
import { makeRemoteGetPublicTopicById } from 'main/factories/usecases/sacTopic/GetPublicTopicsByIdFactory';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Container, Body } from './styles/StyledAttendantForm';

const AttendantFormPage: React.FC = () => {
  const topicId = Number(window.location.pathname.split('/')[3]);
  const { search } = useLocation();

  const [orgInfo, setOrgInfo] = useState<GetPublicTopicById.Model['org']>();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return {
      token: searchParams.get('token'),
    };
  }, [search]);

  useEffect(() => {
    const getPublicTopicById = makeRemoteGetPublicTopicById();
    getPublicTopicById
      .getById({
        topic: topicId,
        token: params.token ?? undefined,
      })
      .then(response => {
        setOrgInfo(response.org);
      })
      .catch(() => {
        toast.error('Erro ao buscar tópico');
      });
  }, [topicId]);

  return (
    <Container>
      <HeaderPulicSac name={orgInfo?.name} shortname={orgInfo?.shortname} />
      <Body>
        <AvailableAttendantForm />
      </Body>
    </Container>
  );
};

export default AttendantFormPage;
