import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { AcceptSacRequest as UsecaseRemoteAcceptSacRequest } from 'domain/usecases/sacRequest/remote';
// import { AcceptSacRequest as UsecaseReduxAcceptSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteAcceptSacRequest } from 'data/repository/sacRequest';
// import { ReduxAcceptSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteAcceptSacRequest = (): UsecaseRemoteAcceptSacRequest =>
  new RemoteAcceptSacRequest(
    makeApiUrl('/sac/requests/{request}/ACCEPT'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxAcceptSacRequest =
  (): UsecaseReduxAcceptSacRequest =>
    new ReduxAcceptSacRequest(); */
