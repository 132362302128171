import styled from 'styled-components';

export const Container = styled.div`
  max-width: 50%;
  height: 100%;
  width: 100%;
  min-height: 18.3rem;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
`;

export const AdvancedSettingsFields = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  span {
    font-size: 16px;
  }
`;

export const ChangePasswordDiv = styled.div`
  /* width: 445px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;
