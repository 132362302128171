import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DownloadConferenceTranscription as UsecaseRemoteDownloadConferenceTranscription } from 'domain/usecases/conference/remote';

import { RemoteDownloadConferenceTranscription } from 'data/repository/conference';

/**
 * send request via API.
 */
export const makeRemoteDownloadConferenceTranscription =
  (): UsecaseRemoteDownloadConferenceTranscription =>
    new RemoteDownloadConferenceTranscription(
      makeApiUrl('/conferences/{conferenceId}/transcriptions/download'),
      makeHttpClient(),
    );
