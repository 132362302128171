/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';

import HeaderMenu from 'presentation/components/MenuNavigation/MenuNavigation';
import Translator from 'presentation/components/i18n/Translator';
import { Container, Body, Right, Left } from './styles/StyledChangePassword';
import ChangePassword from './ChangePassword';
import AdvancedSettings from './AdvancedSettings';

interface ownProps {
  userId: number;
  orgId?: number;
}

const ChangePasswordPage: React.FC<ownProps> = ({
  userId,
  orgId,
}): JSX.Element => {
  const { resources } = useSelector((store: iStore) => store.auth.permissions);
  const { user } = useSelector((store: iStore) => store.auth);

  // Go to resource USER and check if user have UPDATE permission
  const canUpdatePassword = useMemo(
    () =>
      resources?.find(
        res =>
          res.name === 'USER' &&
          res.actions.find(item => item.name === 'UPDATE_OWN'),
      ) && userId === user?.id,
    [resources, user?.id, userId],
  );

  const [tabNavigation, setTabNavigation] = useState(0);

  const options = useMemo(
    () =>
      canUpdatePassword
        ? [`${Translator('Segurança')}`, `${Translator('Avançado')}`]
        : [`${Translator('Avançado')}`],
    [canUpdatePassword],
  );

  const RenderSubPagesTab = useMemo(() => {
    const stepIndex: Record<number, JSX.Element> = canUpdatePassword
      ? {
          0: <ChangePassword userId={userId} />,
          1: <AdvancedSettings userId={userId} orgId={orgId} />,
        }
      : {
          0: <AdvancedSettings userId={userId} orgId={orgId} />,
        };

    return (
      <>
        <HeaderMenu
          options={options}
          setState={setTabNavigation}
          active={tabNavigation}
        />
        {stepIndex[tabNavigation] || <div />}
      </>
    );
  }, [options, tabNavigation, userId]);

  return (
    <Container>
      <Body>
        <Left />
        <Right>{RenderSubPagesTab}</Right>
      </Body>
    </Container>
  );
};

export default ChangePasswordPage;
