import { ListOrgUserByOrgUnitId } from 'domain/usecases/orgUser/redux/ListOrgUserByOrgUnitId';
import {
  iActionListOrgUserByOrgUnitId,
  iActionListOrgUserByOrgUnitIdFailed,
  iActionListOrgUserByOrgUnitIdSuccess,
  OrgUserTypes,
} from 'domain/interfaces/redux/orgUser/listByOrgUnitId';

export const listRequest = (
  payload: ListOrgUserByOrgUnitId.Params,
): iActionListOrgUserByOrgUnitId => ({
  type: OrgUserTypes.LIST_BY_ORGUNITID,
  payload,
});

export const listSuccess = (
  params: ListOrgUserByOrgUnitId.Model,
): iActionListOrgUserByOrgUnitIdSuccess => ({
  type: OrgUserTypes.LIST_BY_ORGUNITID_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUserByOrgUnitIdFailed => ({
  type: OrgUserTypes.LIST_BY_ORGUNITID_FAILED,
});
