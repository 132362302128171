/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { iStore } from 'domain/interfaces/models';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addMinutesToDate, padWithZeroes } from 'utils/formattedDate';
import { externalProps, ownProps } from '../appointmentForm';
import { times } from '../externValues';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const { pathname } = useLocation();
    const { records } = useSelector((store: iStore) => store.conference);

    let data: ownProps['data'] = {
      selectDate: new Date(),
      title: '',
      timeStart: 0,
      timeEnd: 0,
      description: '',
      rrule: '',
      notifyOptions: {
        email: true,
        sms: false,
        whatsapp: false,
      },
      participants: [],
      expectedDuration: 0,
    };

    if (pathname.includes('edit')) {
      const conferenceId = Number(pathname.split('/').pop());
      const conference = records?.filter(el => {
        return el.id === conferenceId;
      });

      if (conference) {
        const auxDate = new Date(conference[0].scheduled);
        const endDate = addMinutesToDate(
          auxDate,
          conference[0].expectedDuration,
        );

        data = {
          selectDate: new Date(conference[0].scheduled),
          title: conference[0].title,
          timeStart: getTime(
            `${padWithZeroes(auxDate.getHours(), 2)}:${padWithZeroes(
              auxDate.getMinutes(),
              2,
            )}`,
          ),
          timeEnd: getTime(
            `${padWithZeroes(endDate.getHours(), 2)}:${padWithZeroes(
              endDate.getMinutes(),
              2,
            )}`,
          ),
          description: conference[0].descr,
          conferenceId: conference[0].id,
          notifyOptions: conference[0].notifyOptions,
          participants: conference[0]
            .participants as ownProps['data']['participants'],
          allowTranscription: conference[0].allowTranscription,
          allowBlockchain: conference[0].allowBlockchain,
          allowInvite: conference[0].allowInvite,
          waitingRoom: conference[0].waitingRoom,
          type: conference[0].type,
          rrule: conference[0].rrule,
          expectedDuration: conference[0].expectedDuration,
        };
      }
    }

    Component.defaultProps = {
      data,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}

export const roundTime = (hours: number, minutes: number): string => {
  const roundMinutes = (parseInt(String((minutes + 7.5) / 15), 10) * 15) % 60;
  const roundHours = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;

  return `${padWithZeroes(roundHours, 2)}:${padWithZeroes(roundMinutes, 2)}`;
};

export const getTime = (time: string) => {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);

  const roundedTime = roundTime(hours, minutes);

  return times.findIndex(el => {
    return el === roundedTime;
  });
};
