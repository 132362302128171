import React, { HTMLAttributes } from 'react';

import { CheckboxContainer, Label, StyledCheckbox } from './styles';

interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
  checked: boolean;
  label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, label, ...rest }) => {
  return (
    <CheckboxContainer>
      <StyledCheckbox id={`checkbox-${label}`} checked={checked} {...rest} />
      <Label htmlFor={`checkbox-${label}`}>
        {label}
      </Label>
    </CheckboxContainer>
  );
};

export default Checkbox;
