import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 18.7rem;
`;

export const Fields = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  div {
    & + div {
      padding-top: 15px;
    }
  }
`;

export const ContentInput = styled.div`
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 30px;
`;

export const ContentButton = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  margin-top: 50px;
`;
