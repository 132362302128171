import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { JoinSacRequest as UsecaseRemoteJoinSacRequest } from 'domain/usecases/sacRequest/remote';
// import { JoinSacRequest as UsecaseReduxJoinSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteJoinSacRequest } from 'data/repository/sacRequest';
// import { ReduxJoinSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinSacRequest = (): UsecaseRemoteJoinSacRequest =>
  new RemoteJoinSacRequest(
    makeApiUrl('/sac/requests/{request}/JOIN'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinSacRequest =
  (): UsecaseReduxJoinSacRequest =>
    new ReduxJoinSacRequest(); */
