export enum AuthTypes {
  LOGIN = '@auth/LOGIN',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILED = '@auth/LOGIN_FAILED',
  REFRESH = '@auth/REFRESH',
  REFRESH_SUCCESS = '@auth/REFRESH_SUCCESS',
  REFRESH_FAILED = '@auth/REFRESH_FAILED',
  LOGOUT = '@auth/LOGOUT',
  LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILED = '@auth/LOGOUT_FAILED',
  GUEST = '@auth/GUEST',
  GUEST_SUCCESS = '@auth/GUEST_SUCCESS',
  GUEST_FAILED = '@auth/GUEST_FAILED',
}
