import React from 'react';

import { History } from 'main/routes';
import { AppointmentForm } from 'presentation/components/appointmentForm';
import { Header as H, HeaderBack } from 'presentation/components/header';

import Translator from 'presentation/components/i18n/Translator';
import { Body, Container } from './styles/styledNewAppointment';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
}

interface OwnProps {
  data?: iUsers[];
}

const NewAppointment: React.FC<OwnProps> = ({ data }): JSX.Element => {
  return (
    <Container>
      <H title="teste" />
      <HeaderBack
        onBack={() => {
          History.getHistory().goBack();
        }}
        title={Translator('Criação de reunião')}
      />
      <Body>
        <AppointmentForm users={data} />
      </Body>
    </Container>
  );
};

export default NewAppointment;
