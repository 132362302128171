import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { SendConferenceFiles as UsecaseRemoteSendConferenceFiles } from 'domain/usecases/conferenceFiles/remote';

import { RemoteSendConferenceFiles } from 'data/repository/conferenceFiles';

/**
 * send request via API.
 */
export const makeRemoteSendConferenceFiles =
  (): UsecaseRemoteSendConferenceFiles =>
    new RemoteSendConferenceFiles(makeApiUrl('/conferences'), makeHttpClient());
