import styled, { css } from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;

  gap: 35px;
`;

interface ActiveBorderProps {
  active: boolean;
}

export const TitleView = styled.div``;

export const Border = styled.div<ActiveBorderProps>`
  width: 100%;
  border-bottom: 1px solid gray;

  margin-top: 10px;

  position: relative;

  ${({ active }) =>
    active &&
    css`
      ::after {
        content: '';
        position: absolute;
        height: 22px;
        width: 44px;
        border-bottom: ${({ theme }) =>
          `2px solid ${theme.colors.primary.main}`};
      }
    `}
`;

export const Container = styled.div`
  height: 3rem;
  border-bottom: 1px solid gray;
`;

export const Content = styled.div`
  height: 3rem;
  margin: 0 auto;

  display: flex;
  align-items: center;

  gap: 30px;

  nav {
    height: 3rem;

    a {
      display: inline-block;
      position: relative;
      height: 3rem;
      line-height: 3rem;
      color: black;

      transition: color 0.2s;

      &:hover {
        cursor: pointer;
      }

      &:active {
        color: gray;
      }

      &.active::after {
        content: '';
        height: 3px;
        border-radius: 3px 3px 0 0;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`;

export const Title = styled.div``;

export const AccessNav = withAccess(styled.nav``);
