import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';

import Users from 'presentation/pages/User';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';

export const MakeUsersFactory: React.FC = () => {
  const user = useSelector((store: iStore) => store.auth.user);

  useEffect(() => {
    if (!user?.administrator) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    UpdateListOrgUser(role, user?.org?.id, user?.orgUnit?.id);
  }, [user]);

  return <Users />;
};
