import { GetBillingReportPDF as UsecaseRemoteGetBillingReportPDF } from 'domain/usecases/billing/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteGetBillingReportPDF } from 'data/repository/billing/RemoteGetBillingReportPDF';

export const makeRemoteGetBillingReportPDF =
  (): UsecaseRemoteGetBillingReportPDF =>
    new RemoteGetBillingReportPDF(
      makeApiUrl('/orgs/{orgId}/billing/report/'),
      makeHttpClient(),
    );
