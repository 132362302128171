import { UpdateControllerConference } from 'domain/usecases/conference/redux/UpdateControllerConference';
import {
  iActionUpdateController,
  ConferenceTypes,
} from 'domain/interfaces/redux/conference/updateController';

export const updateControllerRequest = (
  payload: UpdateControllerConference.Params,
): iActionUpdateController => ({
  type: ConferenceTypes.UPDATE_CONTROLLER,
  payload,
});
