import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListUser as UsecaseRemoteListUser } from 'domain/usecases/user/remote';
import { ListUser as UsecaseReduxListUser } from 'domain/usecases/user/redux';

import { RemoteListUser } from 'data/repository/user';
import { ReduxListUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteListUser = (): UsecaseRemoteListUser =>
  new RemoteListUser(makeApiUrl('/users/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListUser = (): UsecaseReduxListUser =>
  new ReduxListUser();
