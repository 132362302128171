import { GetAddressData } from 'domain/usecases/externalServices/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from 'domain/errors';

export class RemoteGetAddressData implements GetAddressData {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAddressData.Model>;

  constructor(url: string, httpClient: HttpClient<GetAddressData.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getAddressData = async (
    params: GetAddressData.Params,
  ): Promise<GetAddressData.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url + params.zipcode}/json`,
      method: 'get',
      headers: {},
    });
    console.log('GetAddressData request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.notModified:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
