import styled, { css } from 'styled-components';

interface iPaginationContainer {
  disableMargin?: boolean;
}

export const PaginationContainer = styled.div<iPaginationContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ disableMargin }) => !disableMargin && '2rem'};
`;

export const Button = styled.button<{
  active?: boolean;
  pageDynamicSize?: boolean;
}>`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  width: ${({ pageDynamicSize }) => (pageDynamicSize ? 'fit-content' : '2rem')};
  height: ${({ pageDynamicSize }) =>
    pageDynamicSize ? 'fit-content' : '2rem'};
  min-width: 2rem;

  &:hover {
    ${({ active }) =>
      !active &&
      css`
        background: ${({ theme }) => theme.colors.background.hover};
      `}
  }

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const PageButton = styled(Button)`
  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.colors.primary.main};
      color: ${theme.colors.background.default};
      font-weight: bold;
    `};
`;
