import { GetDashboardOrg } from 'domain/usecases/dashboard/redux/GetDashboardOrg';
import {
  iActionGetDashboardOrg,
  iActionGetDashboardOrgSuccess,
  iActionGetDashboardOrgFailed,
  DashboardTypes,
} from 'domain/interfaces/redux/dashboard/getDashboardOrg';

export const getDashboardOrgRequest = (
  payload: GetDashboardOrg.Params,
): iActionGetDashboardOrg => ({
  type: DashboardTypes.GET_DASHBOARD_ORG,
  payload,
});

export const getDashboardOrgSuccess = (
  params: GetDashboardOrg.Model,
): iActionGetDashboardOrgSuccess => ({
  type: DashboardTypes.GET_DASHBOARD_ORG_SUCCESS,
  payload: params,
});

export const getDashboardOrgFailed = (): iActionGetDashboardOrgFailed => ({
  type: DashboardTypes.GET_DASHBOARD_ORG_FAILED,
});
