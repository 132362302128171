const pt = {
  Organizador: 'Organizador',
  Paciente: 'Paciente',
  Profissional: 'Profissional',
  Convidado: 'Convidado',
  Administrador: 'Administrador',
  'Novo Administrador': 'Novo Administrador',
  'Administrador Geral': 'Administrador Geral',
  'Documentos clínicos': 'Documentos clínicos',
  'Agenda de atendimentos': 'Agenda de atendimentos',
  'Perfil do usuário': 'Perfil do usuário',
  'Detalhes do atendimento': 'Detalhes do atendimento',
  'Documento clínico': 'Documento clínico',
  'Pacientes cadastrados': 'Pacientes cadastrados',
  'Profissionais cadastrados': 'Profissionais cadastrados',
  'Organizadores cadastrados': 'Organizadores cadastrados',
  'Agendar novo atendimento': 'Agendar novo atendimento',
  'Cadastrar novo documento clínico': 'Cadastrar novo documento clínico',
  'Cadastrar novo participante': 'Cadastrar novo participante',
  'Digite o nome do participante': 'Digite o nome do participante',
  'Cadastrar novo profissional': 'Cadastrar novo profissional',
  'Cadastrar novo organizador': 'Cadastrar novo organizador',
  'Digite o nome do organizador': 'Digite o nome do organizador',
  Cancelar: 'Cancelar',
  Voltar: 'Voltar',
  Próximo: 'Próximo',
  Enviar: 'Enviar',
  Entrar: 'Entrar',
  'Carregando...': 'Carregando...',
  'Clique para ver as notas de release': 'Clique para ver as notas de release',
  'Enviar e concluir': 'Enviar e concluir',
  'Sim, cancelar': 'Sim, cancelar',
  'Não cancelar': 'Não cancelar',
  'Excluir usuário': 'Excluir usuário',
  'Novo paciente': 'Novo paciente',
  'Novo profissional': 'Novo profissional',
  'Novo organizador': 'Novo organizador',
  'Novo documento': 'Novo documento',
  'Novo agendamento': 'Novo agendamento',
  Disponível: 'Disponível',
  Cancelado: 'Cancelado',
  Finalizado: 'Finalizado',
  Agendado: 'Agendado',
  'Em andamento': 'Em andamento',
  Confirmado: 'Confirmado',
  'Documento não enviado': 'Documento não enviado',
  'Dados perfil': 'Dados perfil',
  'Mudar senha': 'Mudar senha',
  Permissão: 'Permissão',
  'Excluir conta': 'Excluir conta',
  'Login habilitado': 'Login habilitado',
  'Login habilitado com sucesso!': 'Login habilitado com sucesso!',
  'Login desabilitado com sucesso!': 'Login desabilitado com sucesso!',
  Sair: 'Sair',
  'Aviso importante': 'Aviso importante',
  'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ':
    'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ',
  'Dados profissionais': 'Dados profissionais',
  Profissão: 'Profissão',
  'Salvar alterações': 'Salvar alterações',
  'Mudança de senha': 'Mudança de senha',
  'Nova senha': 'Nova senha',
  'Digite nova senha': 'Digite nova senha',
  'Não foi possível alterar senha': 'Não foi possível alterar senha',
  'Senha atual': 'Senha atual',
  'Digite a senha atual': 'Digite a senha atual',
  'Confirmar nova senha': 'Confirmar nova senha',
  'Confime sua senha': 'Confime sua senha',
  'Digite a confirmação da nova senha': 'Digite a confirmação da nova senha',
  'Repita nova senha': 'Repita nova senha',
  'Digite novamente a nova senha': 'Digite novamente a nova senha',
  'Não foi possível alterar a senha': 'Não foi possível alterar a senha',
  'Senha alterada com sucesso!': 'Senha alterada com sucesso!',
  'Dados do perfil': 'Dados do perfil',
  'Dados gerais': 'Dados gerais',
  'Enviar documento por e-mail': 'Enviar documento por e-mail',
  'Dados Pessoais': 'Dados Pessoais',
  'Dados Profissionais': 'Dados Profissionais',
  'Selecione sua profissão': 'Selecione sua profissão',
  Médico: 'Médico',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Digite apenas letras maiusculas e minúsculas e espaço.',
  Dentista: 'Dentista',
  Contatos: 'Contatos',
  Status: 'Status',
  Ações: 'Ações',
  Visualização: 'Visualização',
  Período: 'Período',
  Tipo: 'Tipo',
  'Documentos de apoio': 'Documentos de apoio',
  Atendimento: 'Atendimento',
  Retorno: 'Retorno',
  Emergency: 'Emergency',
  Gravado: 'Gravado',
  'Cancelar atendimento?': 'Cancelar atendimento?',
  'Deseja realmente cancelar o atendimento: ':
    'Deseja realmente cancelar o atendimento: ',
  'Nome completo': 'Nome completo',
  'Seu primeiro nome': 'Seu primeiro nome',
  'Seu segundo nome': 'Seu segundo nome',
  'Digite seu nome': 'Digite seu nome',
  'Digite seu sobrenome': 'Digite seu sobrenome',
  Gênero: 'Gênero',
  Masculino: 'Masculino',
  Feminino: 'Feminino',
  Cpf: 'Cpf',
  Rg: 'Rg',
  'Nome da mãe': 'Nome da mãe',
  Especialidade: 'Especialidade',
  'Data do Atendimento': 'Data do Atendimento',
  'Selecione uma data disponível': 'Selecione uma data disponível',
  'Hora início': 'Hora início',
  'Hora fim': 'Hora fim',
  Selecione: 'Selecione',
  'Tipo de Atendimento': 'Tipo de Atendimento',
  Convidados: 'Convidados',
  'Digite o(s) email(s) para convidar': 'Digite o(s) email(s) para convidar',
  'Um Link estará disponível após a confirmação do agendamento.':
    'Um Link estará disponível após a confirmação do agendamento.',
  'Data de nascimento': 'Data de nascimento',
  Endereço: 'Endereço',
  Cep: 'Cep',
  Número: 'Número',
  Complemento: 'Complemento',
  Bairro: 'Bairro',
  UF: 'UF',
  State: 'State',
  Cidade: 'Cidade',
  Contato: 'Contato',
  'E-mail': 'E-mail',
  Senha: 'Senha',
  'Telefone celular': 'Telefone celular',
  'Telefone alternativo': 'Telefone alternativo',
  'Telefone celular 1': 'Telefone celular 1',
  'Telefone celular 2': 'Telefone celular 2',
  Salvar: 'Salvar',
  'Digite o seu email': 'Digite o seu email',
  'Digite sua Senha': 'Digite sua Senha',
  Código: 'Código',
  'Digite o código do atendimento': 'Digite o código do atendimento',
  'Sua relação familiar com o paciente': 'Sua relação familiar com o paciente',
  'Selecione uma opção': 'Selecione uma opção',
  Anterior: 'Anterior',
  'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.':
    'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.',
  'Termos de uso': 'Termos de uso',
  'Política de privacidade': 'Política de privacidade',
  Assinado: 'Assinado',
  Pendente: 'Pendente',
  'Lista de Documentos clínicos': 'Lista de Documentos clínicos',
  'Lista de Documentos de apoio': 'Lista de Documentos de apoio',
  Tamanho: 'Tamanho',
  Data: 'Data',
  Cod: 'Cod',
  Assinatura: 'Assinatura',
  Hora: 'Hora',
  Origem: 'Origem',
  CPF: 'CPF',
  'Profissional da saúde': 'Profissional da saúde',
  'Registro de conselho': 'Registro de conselho',
  'Data de inclusão': 'Data de inclusão',
  Idade: 'Idade',
  Início: 'Início',
  Fim: 'Fim',
  'Iniciar atendimento': 'Iniciar atendimento',
  Detalhes: 'Detalhes',
  'Acessas farmácia': 'Acessas farmácia',
  'Ver perfil': 'Ver perfil',
  'Editar setor': 'Editar setor',
  'Baixar documento': 'Baixar documento',
  'Ir para sala de espera': 'Ir para sala de espera',
  'Compartilhar por email': 'Compartilhar por email',
  'Novo documento clínico': 'Novo documento clínico',
  Painel: 'Painel',
  'Painel de monitoramento': 'Painel de monitoramento',
  'Painel de reuniões': 'Painel de atendimentos',
  'Excluir usuario': 'Excluir usuario',
  'Cancelar atendimento': 'Cancelar atendimento',
  'Finalizar atendimento': 'Finalizar atendimento',
  'Editar especialidade': 'Editar especialidade',
  'Excluir especialidade': 'Excluir especialidade',
  'Copiar link': 'Copiar link',
  'Copiar link de atendimento': 'Copiar link de atendimento',
  'URL copiada para a área de transferência':
    'URL copiada para a área de transferência',
  'Reenviar email': 'Reenviar email',
  'Enviar convite por Whatsapp': 'Enviar convite por Whatsapp',
  'Excluir documento': 'Excluir documento',
  'Limite de reuniões mensais (horas)':
    'Limite de atendimentos mensais (horas)',
  Órgão: 'Órgão',
  Avulso: 'Avulso',
  Simples: 'Simples',
  'Atestado Médico': 'Atestado Médico',
  'Requisição de exame': 'Requisição de exame',
  'Status do atendimento': 'Status do atendimento',
  'Participantes da Consulta': 'Participantes da Consulta',
  'Dados do paciente': 'Dados do paciente',
  Participante: 'Participante',
  Nome: 'Nome',
  anos: 'anos',
  'Pronto para participar?': 'Pronto para participar?',
  'Ações do atendimento': 'Ações do atendimento',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.',
  'Atendimento cancelado': 'Atendimento cancelado',
  'Dados do profissional de saúde': 'Dados do profissional de saúde',
  'Registro no conselho profissional': 'Registro no conselho profissional',
  'Data e Hora': 'Data e Hora',
  Profissionais: 'Profissionais',
  Concluir: 'Concluir',
  'Paciente em questão': 'Paciente em questão',
  'Selecione um paciente': 'Selecione um paciente',
  'Convidar paciente para participar': 'Convidar paciente para participar',
  'Selecione uma especialidade': 'Selecione uma especialidade',
  'Data do atendimento': 'Data do atendimento',
  'Hora de início': 'Hora de início',
  'Hora do fim': 'Hora do fim',
  'Selecione um profissional': 'Selecione um profissional',
  'Convidar profissional externo': 'Convidar profissional externo',
  'Digite o nome do profissinal': 'Digite o nome do profissinal',
  'Nome do Profissional': 'Nome do Profissional',
  'Digite o e-mail do profissional': 'Digite o e-mail do profissional',
  'E-mail do profissional': 'E-mail do profissional',
  'Não foi possível enviar e-mail de recuperação':
    'Não foi possível enviar e-mail de recuperação',
  'E-mail enviado com sucesso!': 'E-mail enviado com sucesso!',
  'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.':
    'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.',
  Add: 'Add',
  'Lista de profissionais participantes':
    'Lista de profissionais participantes',
  'Digite seu CEP': 'Digite seu CEP',
  'Digite seu estado': 'Digite seu estado',
  Estado: 'Estado',
  'Digite sua cidade': 'Digite sua cidade',
  'Digite seu país': 'Digite seu país',
  País: 'País',
  'Digite seu bairro': 'Digite seu bairro',
  'Rua Av. Ladeira...': 'Rua Av. Ladeira...',
  'Número da residência': 'Número da residência',
  'Digite um complemento': 'Digite um complemento',
  'Seu nome completo': 'Seu nome completo',
  'Nome Completo': 'Nome Completo',
  'Cadastro de Pessoa Física': 'Cadastro de Pessoa Física',
  'Número da identidade': 'Número da identidade',
  'Selecione seu Gênero': 'Selecione seu Gênero',
  Outros: 'Outros',
  'DD/MM/AAAA': 'DD/MM/AAAA',
  'Data de Nascimento': 'Data de Nascimento',
  'Nome da sua mãe': 'Nome da sua mãe',
  'Digite uma senha pessoal': 'Digite uma senha pessoal',
  'Senha de Acesso': 'Senha de Acesso',
  'Confirme a senha': 'Confirme a senha',
  'Repita a senha de acesso': 'Repita a senha de acesso',
  Fraca: 'Fraca',
  Médio: 'Médio',
  Forte: 'Forte',
  'Senha de': 'Senha de',
  segurança: 'segurança',
  Segurança: 'Segurança',
  Avançar: 'Avançar',
  Avançado: 'Avançado',
  Cadastro: 'Cadastro',
  'Cadastro de conferência': 'Cadastro de conferência',
  'Selecione um papel': 'Selecione um papel',
  'Permissões concedidas': 'Permissões concedidas',
  Descrição: 'Descrição',
  Papel: 'Papel',
  'Seleção de papeis': 'Seleção de papeis',
  '+Adicionar': '+Adicionar',
  'Profissional de saúde': 'Profissional de saúde',
  'Última edição': 'Última edição',
  'Profissionais participantes': 'Profissionais participantes',
  'Não foi possível carregar o arquivo': 'Não foi possível carregar o arquivo',
  'Dados Gerais': 'Dados Gerais',
  'Acesso à Receita': 'Acesso à Receita',
  Acessar: 'Acessar',
  'Digite o código da receita': 'Digite o código da receita',
  'Digite a senha da receita': 'Digite a senha da receita',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insira o CÓDIGO e SENHA disponíveis na receita do paciente',
  'para visualizar o arquivo assinado pelo médico':
    'para visualizar o arquivo assinado pelo médico',
  'Criado em': 'Criado em',
  'Acesso para farmácia': 'Acesso para farmácia',
  'Receita Especial': 'Receita Especial',
  'Validador ICP Brasil': 'Validador ICP Brasil',
  'Baixar PDF': 'Baixar PDF',
  'Um link estará disponível após confirmação do agendamento':
    'Um link estará disponível após confirmação do agendamento',
  'Digite o(s) e-mail(s) para convidar': 'Digite o(s) e-mail(s) para convidar',
  'Selecione um tipo': 'Selecione um tipo',
  'Tipo de serviço': 'Tipo de serviço',
  Hoje: 'Hoje',
  Domingo: 'Domingo',
  'Segunda-Feira': 'Segunda-Feira',
  'Terça-Feira': 'Terça-Feira',
  'Quarta-Feira': 'Quarta-Feira',
  'Quinta-Feira': 'Quinta-Feira',
  'Sexta-Feira': 'Sexta-Feira',
  Sábado: 'Sábado',
  ' de Janeiro': ' de Janeiro',
  ' de Fevereiro': ' de Fevereiro',
  ' de Março': ' de Março',
  ' de Maio': ' de Maio',
  ' de Abril': ' de Abril',
  ' de Junho': ' de Junho',
  ' de Julho': ' de Julho',
  ' de Agosto': ' de Agosto',
  ' de Setembro': ' de Setembro',
  ' de Outubro': ' de Outubro',
  ' de Novembro': ' de Novembro',
  ' de Dezembro': ' de Dezembro',
  'Receita simples': 'Receita simples',
  'Solicite a farmácia que acesse o endereço':
    'Solicite a farmácia que acesse o endereço',
  'para baixar sua receita digital.': 'para baixar sua receita digital.',
  'Utilize o código e senha para acessar':
    'Utilize o código e senha para acessar',
  'Código:': 'Código:',
  'Senha:': 'Senha:',
  'Esqueceu sua senha?': 'Esqueceu sua senha?',
  'Ver QR Code': 'Ver QR Code',
  'Código e Senha': 'Código e Senha',
  'Acesse o documento': 'Acesse o documento',
  'via o QR code do documento': 'via o QR code do documento',
  Perfil: 'Perfil',
  'Minha conta': 'Minha conta',
  Geral: 'Geral',
  Pacientes: 'Pacientes',
  'Profissionais de saúde': 'Profissionais de saúde',
  Organizações: 'Organizações',
  'Selecione uma Organização': 'Selecione uma Organização',
  'Ranking de organizações': 'Ranking de organizações',
  'Lista de organizações': 'Lista de organizações',
  'Detalhes de organização': 'Detalhes de organização',
  'Criação de organização': 'Criação de organização',
  'Todas as organizações': 'Todas as organizações',
  'Organização expira?': 'Organização expira?',
  'Organização habilitada até:': 'Organização habilitada até:',
  Painéis: 'Painéis',
  'Log de atividade': 'Log de atividade',
  'Serviços e faturamentos': 'Serviços e faturamentos',
  Faturamento: 'Faturamento',
  Valor: 'Valor',
  Setores: 'Setores',
  'Selecione um Setor': 'Selecione um Setor',
  'Detalhes do setor': 'Detalhes do setor',
  'Todos os setores': 'Todos os setores',
  Agenda: 'Agenda',
  Usuário: 'Usuário',
  Usuários: 'Usuários',
  usuários: 'usuários',
  'Ranking de usuários': 'Ranking de usuários',
  'Lista de usuários': 'Lista de usuários',
  'Detalhes do usuário': 'Detalhes do usuário',
  'Criação de usuário': 'Criação de usuário',
  Administração: 'Administração',
  'Limpar todas': 'Limpar todas',
  'Ocultar notificações': 'Ocultar notificações',
  'criou um atendimento': 'criou um atendimento',
  'cancelou um atendimento': 'cancelou um atendimento',
  'confirmou o atendimento': 'confirmou o atendimento',
  'atualizou um atendimento': 'atualizou um atendimento',
  'agendou o atendimento': 'agendou o atendimento',
  'deletou um atendimento': 'deletou um atendimento',
  'criou um atendimento com profissional acompanhante':
    'criou um atendimento com profissional acompanhante',
  'entrou na sala de espera': 'entrou na sala de espera',
  'saiu da sala de espera': 'saiu da sala de espera',
  'Nova mensagem': 'Nova mensagem',
  Notificações: 'Notificações',
  'Últimas conversas': 'Últimas conversas',
  'Selecione um tipo de documento clínico':
    'Selecione um tipo de documento clínico',
  'Selecione um certificado': 'Selecione um certificado',
  'Receita Simples': 'Receita Simples',
  'Receita Antimicrobiano': 'Receita Antimicrobiano',
  'Requisição de Exames': 'Requisição de Exames',
  Instituição: 'Instituição',
  'Assinar Documento': 'Assinar Documento',
  'Data de emissão': 'Data de emissão',
  Droga: 'Droga',
  Dosagem: 'Dosagem',
  Posologia: 'Posologia',
  'Certificado Digital': 'Certificado Digital',
  'Excluir Receita': 'Excluir Receita',
  'Enviar ao Paciente': 'Enviar ao Paciente',
  'Tipo de documento': 'Tipo de documento',
  'Informações gerais': 'Informações gerais',
  Prescrição: 'Prescrição',
  'Assinatura do documento': 'Assinatura do documento',
  Pesquisar: 'Pesquisar',
  'Buscar por...': 'Buscar por...',
  'Pesquisar usuário': 'Pesquisar usuário',
  Redefinir: 'Redefinir',
  'Redefinição de senha': 'Redefinição de senha',
  'Redefinir senha': 'Redefinir senha',
  Qualquer: 'Qualquer',
  Estatísticas: 'Estatísticas',
  Gravados: 'Gravados',
  'Não gravado': 'Não gravado',
  'Permite gravação de reuniões?': 'Permite gravação de atendimentos?',
  'Limite de gravações mensais (horas)': 'Limite de gravações mensais (horas)',
  'Documentos Clínicos': 'Documentos Clínicos',
  'Digite o nome do administrador': 'Digite o nome do administrador',
  'Digite o CPF do administrador': 'Digite o CPF do administrador',
  'Digite o código do paciente': 'Digite o código do paciente',
  'Digite o nome do profissional': 'Digite o nome do profissional',
  'Digite o nome do paciente': 'Digite o nome do paciente',
  'Digite o nome de um organizador': 'Digite o nome de um organizador',
  'Digite o CPF do organizador': 'Digite o CPF do organizador',
  'Digite o CPF do paciente': 'Digite o CPF do paciente',
  'Selecione uma data': 'Selecione uma data',
  Dia: 'Dia',
  Mês: 'Mês',
  Ontem: 'Ontem',
  'Últimos 7 dias': 'Últimos 7 dias',
  'Últimos 30 dias': 'Últimos 30 dias',
  'Últimos 90 dias': 'Últimos 90 dias',
  'Últimos 12 meses': 'Últimos 12 meses',
  Ínicio: 'Ínicio',
  'Em progresso': 'Em progresso',
  'Digite o nome de um médico': 'Digite o nome de um médico',
  'atendimentos neste dia': 'atendimentos neste dia',
  'O paciente foi informado que': 'O paciente foi informado que',
  'você está na sala de espera': 'você está na sala de espera',
  'Aguarde o início do atendimento': 'Aguarde o início do atendimento',
  'nesta sala de espera': 'nesta sala de espera',
  'Deixar sala': 'Deixar sala',
  'O paciente está pronto': 'O paciente está pronto',
  'Você já pode iniciar o atendimento': 'Você já pode iniciar o atendimento',
  'clicando em avançar': 'clicando em avançar',
  Cadastrados: 'Cadastrados',
  'Eventos do atendimento': 'Eventos do atendimento',
  'Criação de reunião': 'Criação de atendimento',
  'Nova reunião': 'Novo atendimento',
  'Reunião instantânea': 'Atendimento instantânea',
  Agendar: 'Agendar',
  'Agendar reunião': 'Agendar atendimento',
  'Editar reunião': 'Editar atendimento',
  'Reunião Agendada com sucesso!': 'Atendimento Agendada com sucesso!',
  'Não foi possível criar a reunião': 'Não foi possível criar o atendimento',
  'Conferência atualizada com sucesso': 'Conferência atualizada com sucesso',
  'Deseja realmente excluir a reunião?':
    'Deseja realmente excluir o atendimento?',
  'Edição de conferência': 'Edição de conferência',
  'Remoção de Reunião': 'Remoção de Atendimento',
  'Reunião excluida com sucesso': 'Atendimento excluida com sucesso',
  'Não foi possível remover a reunião':
    'Não foi possível remover o atendimento',
  'Não foi possível atualizar a reunião':
    'Não foi possível atualizar o atendimento',
  'Adicione um título': 'Adicione um título',
  'Informações da reunião': 'Informações do atendimento',
  'Descrição da reunião': 'Descrição do atendimento',
  'Adicione uma descrição': 'Adicione uma descrição',
  'Adicione participantes ou digite um novo e-mail':
    'Adicione participantes ou digite um novo e-mail',
  'Formas de convite': 'Formas de convite',
  Título: 'Título',
  Anotações: 'Anotações',
  Gravação: 'Gravação',
  'Você poderá fazer o download da gravação assim que disponível.':
    'Você poderá fazer o download da gravação assim que disponível.',
  Chat: 'Chat',
  'Gravações do atendimento': 'Gravações do atendimento',
  'Novo documento de apoio': 'Novo documento de apoio',
  'Antes de entrar na sala de espera': 'Antes de entrar na sala de espera',
  'Você precisa concordar com os termos de uso da':
    'Você precisa concordar com os termos de uso da',
  'plataforma e também concordar que o atendimento poderá':
    'plataforma e também concordar que o atendimento poderá',
  'ser gravado mediante escolha do médico.':
    'ser gravado mediante escolha do médico.',
  'Aceito os': 'Aceito os',
  'termos de uso.': 'termos de uso.',
  'Aceito que o atendimento poderá ser gravado.':
    'Aceito que o atendimento poderá ser gravado.',
  'O médico foi informado que': 'O médico foi informado que',
  'Aguarde nesta tela.': 'Aguarde nesta tela.',
  'Você será avisado quando o': 'Você será avisado quando o',
  'médico estiver pronto': 'médico estiver pronto',
  'Entrar na consulta': 'Entrar na consulta',
  'Deixar a sala': 'Deixar a sala',
  'O médico está pronto': 'O médico está pronto',
  'O médico já está na sala de atendimento':
    'O médico já está na sala de atendimento',
  'Você já pode iniciar seu atendimento':
    'Você já pode iniciar seu atendimento',
  'Insira seu nome completo.': 'Insira seu nome completo.',
  'CPF inválido.': 'CPF inválido.',
  'RG inválido.': 'RG inválido.',
  'RG inválido, existe(m) digitos faltando':
    'RG inválido, existe(m) digitos faltando',
  'RG inválido, número de digitos ultrapassou o limite':
    'RG inválido, número de digitos ultrapassou o limite',
  'Email inválido.': 'Email inválido.',
  'E-mail já cadastrado': 'E-mail já cadastrado',
  'Telefone inválido.': 'Telefone inválido.',
  'CEP inválido.': 'CEP inválido.',
  'CEP inválido, existe(m) digitos faltando':
    'CEP inválido, existe(m) digitos faltando',
  'CEP inválido, número de digitos ultrapassou o limite':
    'CEP inválido, número de digitos ultrapassou o limite',
  'Data de nascimento é obrigatório.': 'Data de nascimento é obrigatório.',
  'Data inválida.': 'Data inválida.',
  'Nome da mãe é obrigatório.': 'Nome da mãe é obrigatório.',
  'UF inválido.': 'UF inválido.',
  'UF inválido, existe(m) caracteres faltando':
    'UF inválido, existe(m) caracteres faltando',
  'UF inválido, número de caracteres ultrapassados':
    'UF inválido, número de caracteres ultrapassados',
  'Insira o nome da sua cidade.': 'Insira o nome da sua cidade.',
  'Insira o nome do seu bairro.': 'Insira o nome do seu bairro.',
  'Insira o seu endereço.': 'Insira o seu endereço.',
  'Insira o número de sua residência': 'Insira o número de sua residência',
  'Número inválido, ultrapassou o limite':
    'Número inválido, ultrapassou o limite',
  'Consultante inválido ou não encontrado.':
    'Consultante inválido ou não encontrado.',
  'Profissional inválido ou não encontrado.':
    'Profissional inválido ou não encontrado.',
  'Especialidade inválida ou não encontrada':
    'Especialidade inválida ou não encontrada',
  'Opção inválida': 'Opção inválida',
  'Data inválida': 'Data inválida',
  'Hora de ínicio incorreta': 'Hora de ínicio incorreta',
  'Hora final incorreta': 'Hora final incorreta',
  'Senha inválida': 'Senha inválida',
  'Insira o nome da droga': 'Insira o nome da droga',
  'Insira a dosagem': 'Insira a dosagem',
  'Insira a posologia da medicação': 'Insira a posologia da medicação',
  'Insira o tempo de afastamento': 'Insira o tempo de afastamento',
  'Insira uma indicação clínica.': 'Insira uma indicação clínica.',
  'Insira o número do seu documento profissional':
    'Insira o número do seu documento profissional',
  'Insira a UF do seu documento profissional':
    'Insira a UF do seu documento profissional',
  'Insira a data de emissão do seu documento profissional':
    'Insira a data de emissão do seu documento profissional',
  'Selecione a área de atendimento': 'Selecione a área de atendimento',
  'Falha no servidor, tente novamente mais tarde.':
    'Falha no servidor, tente novamente mais tarde.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.',
  'Assinar agora': 'Assinar agora',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Selecione seu certificado digital e finalize a criação do documento agora.',
  'Assinar depois': 'Assinar depois',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.',
  'Você não tem nenhum documento associado ao atendimento.':
    'Você não tem nenhum documento associado ao atendimento.',
  'Você não tem nenhuma gravação.': 'Você não tem nenhuma gravação.',
  'Antes de iniciar': 'Antes de iniciar',
  'Não foi possível desativar a câmera.':
    'Não foi possível desativar a câmera.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'Recomendamos que entre com o microfone e câmera ligados.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Aceite os termos de uso e de gravação para iniciar.',
  'Não foi possível buscar usuário.': 'Não foi possível buscar usuário.',
  'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.':
    'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.',
  'Usuário promovido com sucesso!': 'Usuário promovido com sucesso!',
  'Usuário não é mais administrador do sistema!':
    'Usuário não é mais administrador do sistema!',
  'Você não pode editar o usuário sem uma organização':
    'Você não pode editar o usuário sem uma organização',
  'Não foi possível buscar detalhes de administração do usuário':
    'Não foi possível buscar detalhes de administração do usuário',
  'Informações do usuário atualizadas com sucesso!':
    'Informações do usuário atualizadas com sucesso!',
  'Não foi possível atualizar as informações do usuário':
    'Não foi possível atualizar as informações do usuário',
  'Usuário criado com sucesso!': 'Usuário criado com sucesso!',
  'Não foi possível criar usuário': 'Não foi possível criar usuário',
  'Não foi possível carregar dados do usuário':
    'Não foi possível carregar dados do usuário',
  DESATIVADA: 'DESATIVADA',
  'Entrar com:': 'Entrar com:',
  Ligado: 'Ligado',
  Desligado: 'Desligado',
  Ligada: 'Ligada',
  Desligada: 'Desligada',
  'termos de uso': 'termos de uso',
  'Aceito que o atendimento poderá ser gravado':
    'Aceito que o atendimento poderá ser gravado',
  'Participar do atendimento': 'Participar do atendimento',
  'Sair do atendimento': 'Sair do atendimento',
  Sim: 'Sim',
  Não: 'Não',
  Ativado: 'Ativado',
  Desativado: 'Desativado',
  'Organizações cadastradas': 'Organizações cadastradas',
  'Nova Organização': 'Nova Organização',
  Sigla: 'Sigla',
  'Usuários Cadastrados': 'Usuários Cadastrados',
  'Novo Usuário': 'Novo Usuário',
  Vínculo: 'Vínculo',
  Email: 'Email',
  Telefone: 'Telefone',
  'Este número é whatsapp': 'Este número é whatsapp?',
  CEP: 'CEP',
  Logradouro: 'Logradouro',
  'Nome da Organização': 'Nome da Organização',
  'Nome da pessoa de contato': 'Nome da pessoa de contato',
  'Telefone 1': 'Telefone 1',
  'Telefone 2': 'Telefone 2',
  'Campo obrigatório': 'Campo obrigatório',
  'O nome é obrigatório': 'O nome é obrigatório',
  'O e-mail é obrigatório': 'O e-mail é obrigatório',
  'O telefone é obrigatório': 'O telefone é obrigatório',
  'O e-mail não está em formato válido': 'O e-mail não está em formato válido',
  Fechar: 'Fechar',
  'Digite seu e-mail': 'Digite seu e-mail',
  'Digite o número do seu celular': 'Digite o número do seu celular',
  'Nome da organização': 'Nome da organização',
  Setor: 'Setor',
  Organização: 'Organização',
  'Remoção de Organização': 'Remoção de Organização',
  'Deseja realmente excluir a organização':
    'Deseja realmente excluir a organização',
  Configurações: 'Configurações',
  'Configurações da organização atualizadas com sucesso!':
    'Configurações da organização atualizadas com sucesso!',
  'Não foi possível atualizar configurações da organização':
    'Não foi possível atualizar configurações da organização',
  Confirmar: 'Confirmar',
  'Não é possível deletar organização com usuários ativos':
    'Não é possível deletar organização com usuários ativos.',
  'Editar organização': 'Editar organização',
  'Remover organização': 'Remover organização',
  'Ver organização': 'Ver organização',
  'Remover usuário': 'Remover usuário',
  'Editar usuário': 'Editar usuário',
  'Deseja realmente excluir o usuário': 'Deseja realmente excluir o usuário',
  'Remoção de Usuário': 'Remoção de Usuário',
  'Organização removida com sucesso': 'Organização removida com sucesso!',
  'Usuário removido com sucesso': 'Usuário removido com sucesso!',
  'Não foi possível remover usuário': 'Não foi possível remover usuário',
  'Organização atualizada com sucesso': 'Organização atualizada com sucesso!',
  'Atualizar organização': 'Atualizar organização',
  'Não foi possível atualizar organização':
    'Não foi possível atualizar organização',
  'Organização criada com sucesso': 'Organização criada com sucesso!',
  'Já existe uma organização com este nome':
    'Já existe uma organização com este nome',
  'Erro ao buscar dados da organização': 'Erro ao buscar dados da organização',
  'Não foi possível cadastrar a organização':
    'Não foi possível cadastrar a organização.',
  'Informações do usuário atualizadas com sucesso':
    'Informações do usuário atualizadas com sucesso!',
  'Usuário criado com sucesso': 'Usuário criado com sucesso!',
  'Organizadores do setor': 'Organizadores do setor',
  'Nome do setor': 'Nome do setor',
  'Não foi possível buscar dados da organização do setor':
    'Não foi possível buscar dados da organização do setor',
  'Altere algum campo para atualizar o setor.':
    'Altere algum campo para atualizar o setor.',
  'Setor atualizado com sucesso!': 'Setor atualizado com sucesso!',
  'Atualizar setor': 'Atualizar setor',
  'Não foi possível atualizar o setor.': 'Não foi possível atualizar o setor.',
  'Setor criado com sucesso!': 'Setor criado com sucesso!',
  'Já existe um setor com este nome.': 'Já existe um setor com este nome.',
  'Não foi possível criar o setor.': 'Não foi possível criar o setor.',
  Login: 'Login',
  'Último acesso': 'Último acesso',
  'Desvinculo de usuário do setor': 'Desvinculo de usuário do setor',
  'Deseja realmente desvincular esse usuário do setor?':
    'Deseja realmente desvincular esse usuário do setor?',
  'Usuário desassociado do setor com sucesso!':
    'Usuário desassociado do setor com sucesso!',
  'Não foi possível desassociar o usuário do setor.':
    'Não foi possível desassociar o usuário do setor.',
  'Adicionar novo usuário': 'Adicionar novo usuário',
  'Vincular usuário existente': 'Vincular usuário existente',
  'Desvincular Organizador do Setor': 'Desvincular Organizador do Setor',
  'Novo setor': 'Novo setor',
  'Usuários lotados': 'Usuários lotados',
  'Remover setor': 'Remover setor',
  'Ver setor': 'Ver setor',
  'Remoção de setor': 'Remoção de setor',
  'Não foi possível remover setor.': 'Não foi possível remover setor.',
  'Deseja realmente excluir o setor?': 'Deseja realmente excluir o setor?',
  'Setor excluído com sucesso!': 'Setor excluído com sucesso!',
  'O nome do setor deve conter no máximo 64 caracteres.':
    'O nome do setor deve conter no máximo 64 caracteres.',
  'A sigla deve conter no máximo 32 caracteres.':
    'A sigla deve conter no máximo 32 caracteres.',
  'Criação de setor': 'Criação de setor',
  'Recursos Humanos': 'Recursos Humanos',
  'Centro de Atendimento ao cliente': 'Centro de Atendimento ao cliente',
  Manutenção: 'Manutenção',
  'Tecnologia da Informação': 'Tecnologia da Informação',
  'Serviços Gerais': 'Serviços Gerais',
  Assessoria: 'Assessoria',
  'Assessoria de Imprensa': 'Assessoria de Imprensa',
  'Consumo por setor': 'Consumo por setor',
  'Consumo por serviço': 'Consumo por serviço',
  'Consumo por usuário': 'Consumo por usuário',
  'Consumo por dia do mês': 'Consumo por dia do mês',
  'Extrato de consumo detalhado': 'Extrato de consumo detalhado',
  'Valor Total': 'Valor Total',
  'Atualizar logo da organização': 'Atualizar logo da organização',
  'Logo da organização alterado com sucesso!':
    'Logo da organização alterado com sucesso!',
  'Não foi possível atualizar logo da organização':
    'Não foi possível atualizar logo da organização',
  'Atualizar imagem do usuário': 'Atualizar imagem do usuário',
  'Desvinculo de Usuário da organização':
    'Desvinculo de Usuário da organização',
  'Deseja realmente desvincular esse usuário da organização?':
    'Deseja realmente desvincular esse usuário da organização?',
  'Usuário desassociado com sucesso': 'Usuário desassociado com sucesso',
  'Não foi possível desvincular o usuário da organização':
    'Não foi possível desvincular o usuário da organização',
  'Imagem do usuário alterada com sucesso!':
    'Imagem do usuário alterada com sucesso!',
  'Não foi possível atualizar imagem do usuário':
    'Não foi possível atualizar imagem do usuário',
  'Confirmação de conta': 'Confirmação de conta',
  'Reenviar confirmação': 'Reenviar confirmação',
  'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.':
    'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.',
  'E-mail reenviado com sucesso!': 'E-mail reenviado com sucesso!',
  'Não foi possível reenviar e-mail de confirmação':
    'Não foi possível reenviar e-mail de confirmação',
  'Confirmação do cadastro de usuário': 'Confirmação do cadastro de usuário',
  'Cadastrado em': 'Cadastrado em',
  'Prazo para confirmação': 'Prazo para confirmação',
  'sem prazo para confirmação': 'sem prazo para confirmação',
  'E-mail confirmado em': 'E-mail confirmado em',
  'Não foi possível enviar e-mail': 'Não foi possível enviar e-mail',
  'Confirmação de e-mail': 'Confirmação de e-mail',
  'não confirmado': 'não confirmado',
  'Último login em': 'Último login em',
  'não fez login': 'não fez login',
  'Não foi possível confirmar conta': 'Não foi possível confirmar conta',
  'Conta confirmada com sucesso!': 'Conta confirmada com sucesso!',
  Vigência: 'Vigência',
  Vencimento: 'Vencimento',
  AUTH_MISSING_AUTHORIZATION_HEADER: 'Cabeçalho de autorização ausente',
  AUTH_FORBIDDEN: 'Você não tem permissão para executar essa ação',
  AUTH_FAILED: 'Falha na autenticação',
  AUTH_REFRESH_FAILED: 'Não foi possível renovar o login',
  AUTH_NOT_CREATED_USER:
    'Não foi possível criar usuário no serviço de autenticação',
  AUTH_NOT_UPDATED_USER:
    'Não foi possível atualizar usuário no serviço de autenticação',
  AUTH_NOT_DELETED_USER:
    'Não foi possível excluir usuário no serviço de autenticação',
  AUTH_COULD_NOT_RESET_PASSWORD:
    'Não foi possível redefinir a senha do usuário no serviço de autenticação',
  AUTH_COULD_NOT_LIST_USER:
    'Não foi possível listar a senha do usuário no serviço de autenticação',
  AUTH_MULTIPLES_ORG_AVAILABLE: 'Múltiplas organizações disponíveis',
  AUTH_REFRESH_INVALID_TOKEN: 'Token de renovação inválido',
  AUTH_USER_DOES_NOT_MATCH_WITH_TOKEN:
    'O ID do usuário não corresponde ao token',
  AUTH_ACL_NOT_PROVIDED: 'A ACL do usuário deve ser fornecida para esta ação',
  AUTH_PWD_RECOVERY_TOKEN_INCORRECT: 'Token de recuperação de senha incorreto',
  AUTH_CONFIRMATION_TOKEN_INCORRECT: 'Token de confirmação incorreto',
  VALIDATOR_REQUIRED_FIELD: 'Campo obrigatório faltando',
  VALIDATOR_UNRECOGNIZED_FIELD: 'Campo não reconhecido',
  VALIDATOR_DEFAULT_ISSUE: 'Erro de validação desconhecido',
  FILE_REQUIRED: 'Arquivo é necessário para esta ação',
  AUTHORIZATION_API_LOGIN_ERROR:
    'Não foi possível fazer login no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_CREATE_RESOURCE:
    'Não foi possível criar recurso no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_CREATE_ROLE:
    'Não foi possível criar função no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION:
    'Não foi possível criar ação no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_LIST_RESOURCES:
    'Não foi possível listar recursos de autorização',
  AUTHORIZATION_API_COULD_NOT_LIST_ACTIONS:
    'Não foi possível listar ações de autorização',
  AUTHORIZATION_API_COULD_NOT_LIST_ROLES:
    'Não foi possível listar funções de autorização',
  AUTHORIZATION_API_COULD_NOT_CREATE_USER:
    'Não foi possível criar usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_UPDATE_USER:
    'Não foi possível atualizar usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_REMOVE_USER:
    'Não foi possível remover usuário do sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_GIVE_ROLE_TO_USER:
    'Não foi possível dar função para usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_USER:
    'Não foi possível dar permissão para usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_ROLE:
    'Não foi possível dar permissão para função no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_TO_USER:
    'Não foi possível remover função do usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_REMOVE_PERMISSION_TO_USER:
    'Não foi possível remover permissão do usuário no sistema de autorização',
  AUTHORIZATION_API_COULD_NOT_GET_USER_ACL:
    'Não foi possível obter a ACL do usuário no sistema de autorização',
  CONFERENCE_NOT_FOUND: 'Não foi possível encontrar a conferência',
  CONFERENCE_NOT_CREATED:
    'Não foi possível criar a conferência no banco de dados',
  CONFERENCE_NOT_DELETED:
    'Não foi possível excluir a conferência no banco de dados',
  CONFERENCE_COULD_NOT_LIST:
    'Não foi possível listar as conferências no banco de dados',
  CONFERENCE_IS_CANCELED: 'A conferência foi cancelada ou terminou',
  CONFERENCE_NOT_STARTED:
    'A conferência ainda não foi iniciada pelo proprietário',
  CONFERENCE_ALREADY_STARTED: 'A conferência já foi iniciada',
  CONFERENCE_MEMBER_NOT_CREATED:
    'Não foi possível criar o membro da conferência no banco de dados',
  CONFERENCE_MEMBER_NOT_DELETED:
    'Não foi possível excluir o participante da conferência no banco de dados',
  CONFERENCE_MEMBER_NOT_FOUND: 'Não foi possível encontrar a conferência',
  CONFERENCE_MEMBERS_USER_NOT_FOUND:
    'Não foi possível encontrar o usuário do participante',
  CONFERENCE_MEMBER_COULD_NOT_LIST:
    'Não foi possível listar os participantes no banco de dados',
  CONFERENCE_MEMBER_ALREADY_WITH_ROLE:
    'O participante já está atribuído a esta função',
  CONFERENCE_MEMBER_ALREADY: 'O usuário já é um participante',
  CONFERENCE_OWNER_CANNOT_QUIT:
    'O proprietário não pode sair de sua própria conferência',
  CONFERENCE_OWNER_NOT_IN_ORG:
    'O proprietário não é um membro desta organização',
  CONFERENCE_RECORDINGS_NOT_FOUND:
    'Não foram encontradas gravações para esta conferência',
  CONFERENCE_SUMMARY_NOT_FOUND:
    'Não foi possível encontrar informações de resumo da conferência',
  CONFERENCE_LIMIT_REACHED: 'Limite atingido. Pagamento é necessário',
  NOTIFICATION_API_LOGIN_ERROR:
    'Não foi possível fazer login no serviço de centro de notificação',
  NOTIFICATION_API_CHECK_MESSAGE_EXIST:
    'Não foi possível verificar se a mensagem existe no serviço de centro de notificação',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE:
    'Não foi possível enviar mensagem no serviço de centro de notificação',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE_WITH_ATTACHEMENTS:
    'Não foi possível enviar mensagem com anexos no serviço de centro de notificação',
  SETTING_DYNAMIC_GET:
    'O sistema parece estar faltando configurações obrigatórias.',
  STORAGE_COULD_NOT_GET_URL:
    'Não foi possível obter o URL do arquivo do serviço de armazenamento',
  STORAGE_COULD_NOT_SEND_FILE:
    'Não foi possível enviar o arquivo do serviço de armazenamento',
  ORG_NOT_FOUND: 'Não foi possível encontrar a organização',
  ORG_SHORT_ALREADY_IN_USE: 'O nome curto da organização já está em uso',
  ORG_NOT_CREATED: 'Não foi possível criar a organização',
  ORG_NOT_UPDATED: 'Não foi possível atualizar a organização',
  ORG_NOT_DELETED: 'Não foi possível atualizar a organização',
  ORG_EXPIRED: 'Organização expirada',
  ORG_COULD_NOT_LINK_USER: 'Não foi possível vincular o usuário à organização',
  ORG_LIMIT_REACHED: 'Limite da unidade organizacional atingido',
  ORG_USER_LIMIT_REACHED:
    'A organização já atingiu o limite máximo de usuários',
  ORG_APIKEY_DECRYPT_ERROR:
    'Não foi possível descriptografar a chave da API: Chave da API ausente ou inválida',
  ORG_COULD_NOT_LIST: 'Não foi possível listar as organizações',
  ORG_ADMIN_NOT_CREATED:
    'Não foi possível criar o administrador da organização',
  ORG_ADMIN_NOT_DELETED:
    'Não foi possível deletar o administrador da organização',
  ORG_USER_NOT_FOUND: 'Não foi possível encontrar o membro da organização',
  ORG_ADMIN_NOT_FOUND:
    'Não foi possível encontrar o administrador da organização',
  ORG_USER_NOT_DELETED:
    'Não foi possível remover o administrador da organização',
  ORG_ADMIN_COULD_NOT_LIST: 'Não foi possível listar os administradores',
  ORG_USER_COULD_NOT_LIST: 'Não foi possível listar os membros da organização',
  ORG_CANNOT_DELETE_ACTIVE_USERS:
    'Não é possível excluir org com usuários ativos',
  ORGUNIT_EXPIRED: 'Setor expirado',
  ORGUNIT_NOT_FOUND: 'Não foi possível encontrar o setor',
  ORGUNIT_NOT_CREATED: 'Não foi possível criar o setor',
  ORGUNIT_NOT_UPDATED: 'Não foi possível atualizar o setor',
  ORGUNIT_NOT_DELETED: 'Não foi possível deletar o setor',
  ORGUNIT_LIMIT_REACHED:
    'Este setor já atingiu o limite de quantidade de unidades',
  ORGUNIT_USER_LIMIT_REACHED:
    'Este setor já atingiu o limite de quantidade de usuários',
  ORGUNIT_CANNOT_DELETE_ACTIVE_USERS:
    'Não é possível excluir setor com usuários ativos',
  ORGUNIT_PARENT_NOT_FOUND: 'Não foi possível encontrar o parente deste setor',
  ORGUNIT_SHORT_ALREADY_IN_USE: 'Já existe um setor com esta sigla',
  ORGUNIT_NAME_ALREADY_IN_USE: 'Já existe um setor com este nome',
  ORGUNIT_COULD_NOT_LIST: 'Não foi possível listar o setor',
  USER_NOT_FOUND: 'Não foi possível encontrar o usuário',
  USER_NOT_CREATED: 'Não foi possível criar o usuário',
  // TODO: Atentar as traducoes de USER_NOT_UPDATED e USER_NOT_DELETED
  USER_NOT_UPDATED: 'Não foi possível atualizar o usuário',
  USER_NOT_DELETED: 'Não foi possível deletar o usuário',
  USER_ALREADY_EXISTS: 'Login de usuário ou e-mail já em uso',
  USER_SUSPENDED: 'Usuário suspenso',
  USER_NOT_IN_ORG: 'O usuário não existe nesta organização',
  USER_COULD_NOT_FIND_ORG:
    'Não foi possível encontrar a organização do usuário',
  USER_ALREADY_IN_ORG:
    'O usuário já está atribuído a esta organização e unidade',
  USER_COULD_NOT_LIST: 'Não foi possível listar conferências no banco de dados',
  USER_ALREADY_CONFIRMED:
    'Não foi possível listar conferências no banco de dados',
  USER_ALREADY_AGREED: 'O usuário já concordou com os Termos',
  DASHBOARD_COULD_NOT_GET: 'Não foi possível obter o painel',
  ADDRESS_NOT_CREATED: 'Não foi possível criar o endereço',
  ADDRESS_NOT_UPDATED: 'Não foi possível atualizar o endereço',
  ADDRESS_INCOMPLETE: 'Endereço incompleto',
  SKIN_NOT_FOUND: 'Não foi possível encontrar a skin',
  SKIN_NOT_CREATED: 'Não foi possível criar skin',
  SKIN_NAME_ALREADY_IN_USE: 'Nome da skin já em uso',
  SKIN_NOT_UPDATED: 'Não foi possível atualizar a skin',
  SKIN_NOT_DELETED: 'Não foi possível atualizar a skin',
  SYSTEM_SETTING_NOT_FOUND: 'Não foi possível encontrar systemSetting',
  SYSTEM_SETTING_NOT_CREATED: 'Não foi possível criar systemSetting',
  SYSTEM_SETTING_NAME_ALREADY_IN_USE: 'Nome SystemSetting já em uso',
  SYSTEM_SETTING_NOT_UPDATED: 'Não foi possível atualizar systemSetting',
  SYSTEM_SETTING_NOT_RESETED:
    'Não foi possível redefinir as configurações do sistema',
  SYSTEM_SETTING_NOT_DELETED: 'Não foi possível atualizar systemSetting',
  SESSION_MANAGER_COULD_NOT_CREATE_ROOM:
    'Não foi possível criar espaço no Session Manager',
  SESSION_MANAGER_COULD_NOT_OPEN_ROOM:
    'Não foi possível abrir a sala no Session Manager',
  SESSION_MANAGER_COULD_NOT_JOIN_ROOM:
    'Não foi possível entrar na sala no Session Manager',
  SESSION_MANAGER_COULD_NOT_LIST_RECORDING:
    'Não foi possível recuperar as gravações do Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_RECORDING:
    'Não foi possível encontrar a gravação no Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_DASHBOARD:
    'Não foi possível obter informações do painel do SessionManager',
  SESSION_MANAGER_COULD_NOT_GET_USER:
    'Não foi possível obter informações do usuário do SessionManager',
  SESSION_MANAGER_BILLING_NOT_FOUND: 'Não foi possível encontrar o faturamento',
  SESSION_MANAGER_ORG_ALREADY_EXISTS: 'Org com este nome abreviado já existe',
  SESSION_MANAGER_ORGUNIT_ALREADY_EXISTS:
    'OrgUnit com este nome abreviado já existe',
  SESSION_MANAGER_USER_ALREADY_EXISTS: 'Usuário com este login já existe',
  SESSION_MANAGER_COULD_NOT_GET_INFO: 'Não foi possível obter informações',
  SESSION_MANAGER_COULD_NOT_CREATE_ORG: 'Não foi possível criar a organização',
  SESSION_MANAGER_COULD_NOT_CREATE_ORGUNIT:
    'Não foi possível criar orgUnit no Session Manager',
  SESSION_MANAGER_COULD_NOT_CREATE_USER:
    'Não foi possível criar usuário no Session Manager',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORG:
    'Não foi possível atualizar a organização',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORGUNIT:
    'Não foi possível atualizar orgUnit',
  SESSION_MANAGER_COULD_NOT_UPDATE_USER: 'Não foi possível atualizar orgUnit',
  SESSION_MANAGER_COULD_NOT_DELETE_ORG: 'Não foi possível deletar org',
  SESSION_MANAGER_COULD_NOT_DELETE_ORGUNIT: 'Não foi possível excluir orgUnit',
  SESSION_MANAGER_COULD_NOT_DELETE_USER: 'Não foi possível excluir o usuário',
  FACE_RECOGNITION_API_COULD_NOT_UPDT_PICTURE:
    'Não foi possível atualizar a imagem do usuário',
  FACE_RECOGNITION_API_NO_FACE_RECORD:
    'Usuário não possui registro facial anexado ao perfil',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_FACE:
    'Não foi possível verificar o rosto do usuário',
  FACE_RECOGNITION_API_COULD_NOT_CREATE_SUBJECT:
    'Não foi possível criar assunto',
  FACE_RECOGNITION_API_COULD_NOT_GET_SUBJECT:
    'Não foi possível obter o assunto',
  FACE_RECOGNITION_API_COULD_NOT_SEND_PICTURE: 'Não foi possível enviar a foto',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_SUBJECT:
    'Não foi possível verificar o assunto',
  FACE_RECOGNITION_API_COULD_NOT_ADD_FILE_TO_FORM: 'Erro ao ler o arquivo',
  USERACTION_ALREADY_EXISTS: 'UserAction com este nome já existe',
  USERACTION_GROUP_ALREADY_EXISTS: 'UserActionGroup com este nome já existe',
  USERACTION_NOT_CREATED: 'Não foi possível criar UserAction no banco de dados',
  USERACTION_GROUP_NOT_CREATED:
    'Não foi possível criar UserActionGroup no banco de dados',
  USERACTION_NOT_FOUND: 'Não foi possível encontrar UserAction',
  USERACTION_GROUP_NOT_FOUND: 'Não foi possível encontrar UserActionGroup',
  USERACTION_COULD_NOT_LIST:
    'Não foi possível listar userActions no banco de dados',
  USERACTION_GROUP_COULD_NOT_LIST:
    'Não foi possível listar userActionGroup no banco de dados',
  USERACTION_LOG_COULD_NOT_LIST:
    'Não foi possível listar os logs do usuário no banco de dados',
  UNRECOGNIZED_ORDERBY: 'Coluna de pedido não reconhecida',
  LINK_GEN_API_LOGIN_ERROR:
    'Não foi possível fazer login no sistema LinkGenerator',
  LINK_GEN_API_COULD_NOT_CREATE_LINK: 'Não foi possível criar o link',
  'Classificação de usuários': 'Classificação de usuários',
  'Classificação de organizações': 'Classificação de organizações',
  Agendadas: 'Agendadas',
  Realizadas: 'Realizadas',
  'Duração média': 'Duração média',
  'Média de participantes': 'Média de participantes',
  Consumo: 'Consumo',
  'Reuniões não gravadas': 'Atendimentos não gravados',
  'Reuniões gravadas': 'Atendimentos gravados',
  Total: 'Total',
  Gravações: 'Gravações',
  'Duração total': 'Duração total',
  Utilizados: 'Utilizados',
  Armazenamento: 'Armazenamento',
  Disponíveis: 'Disponíveis',
  'Média de consumo diário': 'Média de consumo diário',
  'Informações históricas': 'Informações históricas',
  'Número de reuniões': 'Número de atendimentos',
  'Horas de reuniões': 'Horas de atendimentos',
  Gravadas: 'Gravadas',
  'Não gravadas': 'Não gravadas',
  Horas: 'Horas',
  usado: 'usado',
  Reuniões: 'Atendimentos',
  'Usuários padrão': 'Usuários padrão',
  Administradores: 'Administradores',
  'Administradores de sistema': 'Administradores de sistema',
  'Novos usuários': 'Novos usuários',
  Logados: 'Logados',
  'Usuários ativos': 'Usuários ativos',
  'Sem atividade': 'Sem atividade',
  Agendamentos: 'Agendamentos',
  'Nome do usuário': 'Nome do usuário',
  Agora: 'Agora',
  Organizadores: 'Organizadores',
  'Armazenamento total': 'Armazenamento total',
  'Quantidade de reuniões': 'Quantidade de atendimentos',
  'Horas gravadas': 'Horas gravadas',
  Evento: 'Evento',
  'Detalhes da operação': 'Detalhes da operação',
  Cadastrado: 'Cadastrado',
  'Data e hora': 'Data e hora',
  Nível: 'Nível',
  Grupo: 'Grupo',
  'Objeto da ação': 'Objeto da ação',
  'Detalhes da auditoria': 'Detalhes da auditoria',
  ID: 'ID',
  'Minhas reuniões': 'Meus atendimentos',
  Duração: 'Duração',
  Participantes: 'Participantes',
  Serviços: 'Serviços',
  'Não possui reuniões': 'Não possui atendimentos',
  'Detalhes da conferência': 'Detalhes da conferência',
  'Baixar conferência': 'Baixar conferência',
  Blockchain: 'Blockchain',
  'Você poderá fazer o download do registro assim que estiver disponível.':
    'Você poderá fazer o download do registro assim que estiver disponível.',
  'Digite o nome ou e-mail de um participante...':
    'Digite o nome ou e-mail de um participante...',
  'Editar reunião recorrente': 'Editar atendimento recorrente',
  'Excluir reunião recorrente': 'Excluir atendimento recorrente',
  'Quais das reuniões associadas a este evento você deseja editar?':
    'Quais das reuniões associadas a este evento você deseja editar?',
  'Quais das reuniões associadas a este evento você deseja excluir?':
    'Quais das reuniões associadas a este evento você deseja excluir?',
  'Apenas esta reunião': 'Apenas este atendimento',
  'Esta e todas as seguintes': 'Esta e todas as seguintes',
  'Todas as reuniões': 'Todos os atendimentos',
  OK: 'OK',
  'Erro ao buscar reunião': 'Erro ao buscar atendimento',
  'Gerar documento com a transcrição da reunião':
    'Gerar documento com a transcrição do atendimento',
  'Registrar reunião na blockchain': 'Registrar atendimento na blockchain',
  'Título de reunião': 'Título de atendimento',
  'O campo "Título de reunião" é obrigatório.':
    'O campo "Título de atendimento" é obrigatório.',
  'Não foi possível remover a reunião.':
    'Não foi possível remover o atendimento.',
  Reunião: 'Atendimento',
  reunião: 'atendimento',
  'Registrar reunião na blockchain:': 'Registrar atendimento na blockchain:',
  'O moderador precisa entrar na reunião antes de qualquer participante?':
    'O moderador precisa entrar no atendimento antes de qualquer participante?',
  'Deseja gerar um documento com a transcrição da reunião?':
    'Deseja gerar um documento com a transcrição do atendimento?',
  'Transcrever reunião': 'Transcrever atendimento',
  'Criação de Reunião Instantânea': 'Criação de Atendimento Instantânea',
  'Você saiu da reunião': 'Você saiu do atendimento',
  'Entrar na reunião': 'Entrar no atendimento',
  'Reuniões transcritas': 'Atendimentos transcritos',
};

export default pt;
