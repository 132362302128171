import React, { useEffect, useMemo, useState } from 'react';

import { iOneUser } from 'domain/interfaces/models';
import { IconDefaultUser } from 'presentation/base/icons';
import { makeRemoteGetUser } from 'main/factories/usecases/user/GetUserFactory';
import { Container, Actions } from './styles/StyledPerfilNavigator';

import { Avatar } from '../UI/avatar';
import { Button } from '../UI/buttons';
import Translator from '../i18n/Translator';

interface OwnProps {
  nav: Function;
  active: number;
  creating?: boolean;
  user?: iOneUser;
  haveUpdated?: () => void;
}

const PerfilNavigator: React.FC<OwnProps> = ({
  nav,
  active,
  user,
  creating = false,
  haveUpdated,
}) => {
  const [userAvatar, setUserAvatar] = useState('');

  const generalData = useMemo(() => {
    return (
      <Button
        id="button_generalData"
        variant={active === 1 ? 'primary' : 'secundary'}
        onClick={() => nav(1)}
        active={active === 1}
      >
        {Translator('Dados Pessoais')}
      </Button>
    );
  }, [active, nav]);

  const security = useMemo(() => {
    return (
      <Button
        id="button_sectors"
        variant={active === 2 ? 'primary' : 'secundary'}
        onClick={() => nav(2)}
        active={active === 2}
      >
        {Translator('Segurança')}
      </Button>
    );
  }, [active, nav]);

  useEffect(() => {
    makeRemoteGetUser()
      .get({
        id: Number(user?.id),
      })
      .then(res => {
        setUserAvatar(res.avatar);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, [user?.id, haveUpdated]);

  return (
    <Container>
      <Avatar
        fullName={String(user?.id && `${user?.fullName}`)}
        src={creating ? IconDefaultUser : userAvatar ?? ''}
        size="205px"
        showUploadButton={!creating}
        haveUpdated={haveUpdated}
      />

      <Actions>
        {generalData}
        {!creating && security}
      </Actions>
    </Container>
  );
};

export default PerfilNavigator;
