import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateConference as UsecaseRemoteCreateConference } from 'domain/usecases/conference/remote';
// import { CreateConference as UsecaseReduxCreateConference } from 'domain/usecases/conference/redux';

import { RemoteCreateConference } from 'data/repository/conference';
// import { ReduxCreateConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateConference = (): UsecaseRemoteCreateConference =>
  new RemoteCreateConference(makeApiUrl('/conferences/ '), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateConference =
  (): UsecaseReduxCreateConference =>
    new ReduxCreateConference(); */
