export const FILTERED_HOURS = [
  {
    label: '1 minuto',
    value: 1,
  },
  {
    label: '30 minutos',
    value: 30,
  },
  {
    label: '1 hora',
    value: 60,
  },
  {
    label: '2 horas',
    value: 120,
  },
  {
    label: '3 horas',
    value: 180,
  },
  {
    label: '4 horas',
    value: 240,
  },
  {
    label: '5 horas',
    value: 300,
  },
  {
    label: '6 horas',
    value: 360,
  },
  {
    label: '7 horas',
    value: 420,
  },
  {
    label: '8 horas',
    value: 480,
  },
  {
    label: '9 horas',
    value: 540,
  },
  {
    label: '10 horas',
    value: 600,
  },
  {
    label: '11 horas',
    value: 660,
  },
  {
    label: '12 horas',
    value: 720,
  },
  {
    label: '13 horas',
    value: 780,
  },
  {
    label: '14 horas',
    value: 840,
  },
  {
    label: '15 horas',
    value: 900,
  },
  {
    label: '16 horas',
    value: 960,
  },
  {
    label: '17 horas',
    value: 1020,
  },
  {
    label: '18 horas',
    value: 1080,
  },
  {
    label: '19 horas',
    value: 1140,
  },
  {
    label: '20 horas',
    value: 1200,
  },
  {
    label: '21 horas',
    value: 1260,
  },
  {
    label: '22 horas',
    value: 1320,
  },
  {
    label: '23 horas',
    value: 1380,
  },
  {
    label: '24 horas',
    value: 1440,
  },
];
