import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ListSacRequestById } from 'domain/usecases/sacRequest/remote';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import { toast } from 'react-toastify';
import { Typography, IconButton } from '@wisecare-tech/design-system-web';
import {
  Container,
  Header,
  RequestInfo,
  Info,
  Description,
  LoadingDiv,
} from './StyledRequestDetails';

interface iRequestDetails {
  onClose: () => void;
}

export const RequestDetails: React.FC<iRequestDetails> = ({ onClose }) => {
  const [requestDetails, setRequestDetails] =
    useState<ListSacRequestById.Model>();

  const [loading, setLoading] = useState(false);

  const requestId = new URLSearchParams(useLocation().search).get('sr');

  useEffect(() => {
    if (!requestId) return;

    setLoading(true);

    makeRemoteListSacRequestById()
      .listById({
        request: requestId,
        token: new URLSearchParams(window.location.search).get('ak') || '',
      })
      .then(response => {
        setRequestDetails(response);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao carregar detalhes da solicitação');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestId]);

  const { locator, name, docValue, email, phone, descr } = requestDetails || {};

  const formattedPhone = (number?: string): string => {
    const phoneFormatted = number?.replace('+55', '');

    return `(${phoneFormatted?.slice(0, 2)}) ${phoneFormatted?.slice(
      2,
      7,
    )}-${phoneFormatted?.slice(7, 11)}`;
  };

  const addedCharsToCPF = (cpf?: string): string => {
    return `${cpf?.slice(0, 3)}.${cpf?.slice(3, 6)}.${cpf?.slice(
      6,
      9,
    )}-${cpf?.slice(9, 11)}`;
  };

  return (
    <Container>
      <Header>
        <div />
        <Typography variant="t3_16semibold">Detalhes da solicitação</Typography>

        <IconButton
          icon="close"
          variant="primary"
          size="sm"
          onClick={onClose}
        />
      </Header>

      {loading ? (
        <LoadingDiv>
          <CircularProgress />
        </LoadingDiv>
      ) : (
        <>
          <RequestInfo>
            <Info>
              <Typography variant="t3_16semibold">
                ID de atendimento:
              </Typography>
              <Typography variant="b1_16regular">{locator}</Typography>
            </Info>

            <Info>
              <Typography variant="t3_16semibold">Assunto: </Typography>
              <Typography variant="b1_16regular">
                {requestDetails?.topic.title}
              </Typography>
            </Info>
          </RequestInfo>

          <RequestInfo>
            <Info>
              <Typography variant="t3_16semibold">Nome: </Typography>
              <Typography variant="b1_16regular">{name}</Typography>
            </Info>

            <Info>
              <Typography variant="t3_16semibold">CPF: </Typography>
              <Typography variant="b1_16regular">
                {addedCharsToCPF(docValue)}
              </Typography>
            </Info>

            <Info>
              <Typography variant="t3_16semibold">E-mail: </Typography>
              <Typography variant="b1_16regular">{email}</Typography>
            </Info>

            <Info>
              <Typography variant="t3_16semibold">Telefone: </Typography>
              <Typography variant="b1_16regular">
                {formattedPhone(phone)}
              </Typography>
            </Info>
          </RequestInfo>

          <Description>
            <Typography variant="t3_16semibold">Descrição: </Typography>
            <Typography variant="b1_16regular">{descr}</Typography>
          </Description>
        </>
      )}
    </Container>
  );
};
