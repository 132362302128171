import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 36rem;
  border-radius: 1rem;
  background-color: #fdfdfd;
  border: 1px solid #dedede;
`;

export const Header = styled.div`
  width: 100%;
  padding: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dedede;
`;

export const Title = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #222529;
`;

export const DateAndHour = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1rem;
  border-bottom: 1px solid #dedede;
`;

export const DateInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.8rem;
`;

export const Info = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #62666c;
  line-height: normal;
`;

export const DescriptionDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.6rem;
  border-bottom: 1px solid #dedede;
`;

export const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #62666c;
  line-height: normal;
`;

export const UserNameDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.6rem;
`;

export const Subtitle = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
