import React, { useState, SelectHTMLAttributes } from 'react';
import { IconArrowSelect } from 'presentation/base/icons';
import { withAccess } from 'presentation/hooks/access';

import { Container, Label, SelectContainer, Message } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  className?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  border?: boolean;
  minimal?: boolean;
  labelStyle?: React.CSSProperties;
  register?: () => any;
}

const Select: React.FC<SelectProps> = ({
  children,
  name,
  label,
  className,
  message,
  error = false,
  icon,
  border = true,
  width = '100%',
  height = '40px',
  register,
  required = false,
  labelStyle = {},
  minimal,
  ...rest
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  const Icon = icon || IconArrowSelect;

  //
  // if the component are use react-hook-form.
  if (register) register();

  if (required) label = `${label}*`;

  return (
    <Container width={width} className={className}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <SelectContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
        border={border}
        minimal={minimal}
      >
        <select
          {...rest}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
        >
          {children}
        </select>

        <Icon color="black" />
      </SelectContainer>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default withAccess(Select);
