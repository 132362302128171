import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUserByOrgId } from 'domain/interfaces/redux/orgUser/listByOrgId';
import { ListByOrgIdOrgUser } from 'domain/usecases/orgUser/remote/ListByOrgIdOrgUser';
import { makeRemoteListByOrgIdOrgUser } from 'main/factories/usecases/orgUser/ListByOrgIdOrgUserFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUsers/actions/listByOrgId';

export function* onListByOrgId(action: iActionListOrgUserByOrgId) {
  const remoteListByOrgIdOrgUser = makeRemoteListByOrgIdOrgUser();

  try {
    const response: ListByOrgIdOrgUser.Model = yield call(
      remoteListByOrgIdOrgUser.listByOrgId,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByOrgIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByOrgIdFailed() {}
