import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withAccess } from 'presentation/hooks/access';
import { ContainerAccordion, StyledTypography } from './styles/StyledAccordeon';

interface ownProps {
  label: string;
  children: JSX.Element;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
  },
  accordionStyle: {
    boxShadow: 'none',
    maxHeight: '30rem',
    overflowY: 'auto',
  },
});

const Accordeon: React.FC<ownProps> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <ContainerAccordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledTypography style={{ fontSize: '14px', color: '#7A7A7A' }}>
            {label}
          </StyledTypography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #EFEFEF',
            paddingLeft: 0,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </ContainerAccordion>
  );
};

export default withAccess(Accordeon);
