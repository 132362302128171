import { Dispatch } from 'redux';
import store from 'data/store';
import { closeRequest } from 'data/store/reducer/modal/actions/close';

import { iActionClose } from 'domain/interfaces/redux/modal/close';

import { CloseModal } from 'domain/usecases/modal/redux';

export class ReduxCloseModal implements CloseModal {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  close = (params: CloseModal.Params): iActionClose =>
    this.send(closeRequest(params));
}
