import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { toast } from 'react-toastify';
import { makeRemoteLoginAuth } from 'main/factories/usecases/auth/LoginAuthFactory';
import { Button, Input } from '../../UI';
import { ownProps } from '../interface';
import {
  Body,
  Container,
  Content,
  Header,
  ButtonsContainer,
  UserData,
  InputContent,
} from './styles/StyledAdvConfigModal';

const AdvancedConfigModal: React.FC<ownProps> = ({ message }) => {
  const [password, setPassword] = useState('');

  const user = useSelector((store: iStore) => store.auth.user);

  const msgName = MessageOptions.advConfig;

  const {
    active,
    actionOk,
    actionCancel,
    content,
    title,
    userName,
    userMail,
    inputContent,
  } = message;

  const handlePassword = useCallback(
    // eslint-disable-next-line consistent-return
    (e: FormEvent) => {
      e.preventDefault();

      makeRemoteLoginAuth()
        .login({
          body: {
            login: String(user?.email),
            password,
          },
        })
        .then(res => {
          actionOk?.();
        })
        .catch(err => {
          // toast.error('Senha incorreta!');
        });
    },
    [user?.email, password, actionOk],
  );

  const handleClose = () => {
    actionCancel?.();
    setPassword('');
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
        setPassword('');
      }

      if (e.key === 'Enter') {
        actionCancel?.();
        setPassword('');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body inputPass={inputContent}>
            <Header>
              <span>{title}</span>
            </Header>
            <Content>
              <UserData>
                <span>
                  Usuário:
                  <span style={{ color: '#0970de', marginLeft: '5px' }}>
                    {userName}
                  </span>
                </span>
                <span>
                  E-mail:
                  <span style={{ color: '#0970de', marginLeft: '5px' }}>
                    {userMail}
                  </span>
                </span>
              </UserData>
              <p>
                {content}
                {inputContent ? (
                  <InputContent>
                    <Input
                      autoFocus
                      label="Digite a sua senha"
                      id="password"
                      type="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      width="260px"
                      height="40px"
                    />
                  </InputContent>
                ) : (
                  <></>
                )}
              </p>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                rounded
                variant="secundary"
                size="medium"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                id="button_confirm"
                rounded
                variant="primary"
                size="medium"
                onClick={inputContent ? handlePassword : actionOk}
                disabled={inputContent ? !password : false}
              >
                Confirmar
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default AdvancedConfigModal;
