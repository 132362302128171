import React, { useCallback, useEffect, useState } from 'react';

import { IconOrg } from 'presentation/base/icons';
import { makeReduxRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';
import { makeReduxCloseModal } from 'main/factories/usecases/modal/CloseModalFactory';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { Button } from 'presentation/components/UI';
import { getRole } from 'utils/getRole';
import { makeRemoteListAgreedTerms } from 'main/factories/usecases/user/ListAgreedTermsFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { RefreshAuth } from 'domain/usecases/auth/remote';
import { iOrgUnits } from './interface';
import OrgItem from './OrgItem';

import {
  Container,
  Body,
  Itens,
  Text,
  Orgs,
  Title,
  Line,
  Actions,
} from './styles/StyledChangeRoles';

import { ConnectComponent } from './mapper/MapperChangeRoles';

export interface ownProps {
  orgs?: iOrgUnits[];
  role: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD';
  data: any;
}

const ChangeRole: React.FC<ownProps> = ({ orgs, data, role }): JSX.Element => {
  const path = window.location.pathname;
  const [selected, setSelected] = useState({
    orgIdx: -1,
    unitIdx: -1,
  });
  const { refresh, user } = useSelector((store: iStore) => store.auth);

  const handleAgreedTerms = useCallback(
    (userId: number, body: RefreshAuth.Params['body']) => {
      makeRemoteListAgreedTerms()
        .list({
          userId,
        })
        .then(res => {
          if (res.agreedTerms) {
            makeReduxRefreshAuth().refresh({ body });
          } else {
            makeReduxActiveMessage().active({
              active: 'firstAccess',
              actionOk: () => {
                makeReduxRefreshAuth().refresh({ body });
                closeModal();
              },
              actionCancel: () => closeModal(),
              userId,
            });
          }
        });
    },
    [],
  );

  /* Automatic login */
  useEffect(() => {
    if (orgs?.length === 1)
      if (orgs?.[0]?.orgUnits?.length === 1) {
        if (refresh.token && user) {
          const body: any = {
            refreshToken: refresh.token,
            org: orgs?.[0]?.org?.id,
            user: user?.id,
          };

          makeReduxCloseModal().close({});
          handleAgreedTerms(user?.id, body);
        }
      }
  }, [handleAgreedTerms, orgs, refresh.token, user]);

  return (
    <Container>
      <Body>
        {path !== '/' ? (
          <Text>
            Para alterar sua função, selecione o papel em uma organização
          </Text>
        ) : (
          <Text>Defina seu papel na organização desejada.</Text>
        )}
        {orgs?.map((value, orgIdx) => {
          return (
            <Orgs>
              <Itens>
                <Title selected={false}>
                  {` ${value.org.name}`}
                  <Line />
                </Title>
                {value.orgUnits?.map((item, unitIdx) => {
                  if (!item.administrator) return <></>;

                  return (
                    <OrgItem
                      /* orgUnitId={Number(item.id)} */
                      title={item.name}
                      role={getRole(
                        item.administrator,
                        value.org.id,
                        value.orgUnits?.[0]?.id ?? undefined,
                      )}
                      /* orgId={value.org.id} */
                      setSelected={(sel: any) => setSelected(sel)}
                      selector={{ orgIdx, unitIdx }}
                      selected={selected}
                    />
                  );
                })}
              </Itens>
            </Orgs>
          );
        })}
        <Actions>
          <Button
            id="cancel"
            height="33px"
            size="small"
            variant="secundary"
            onClick={() => {
              makeReduxCloseModal().close({});
            }}
          >
            Cancelar
          </Button>
          <Button
            id="select-org"
            height="33px"
            size="small"
            onClick={() => {
              const orgUnitId =
                orgs?.[selected.orgIdx]?.orgUnits[selected.unitIdx]?.id;

              if (refresh.token && user) {
                const body: any = {
                  refreshToken: refresh.token,
                  org: orgs?.[selected.orgIdx]?.org?.id,
                  user: user?.id,
                };

                makeReduxCloseModal().close({});
                handleAgreedTerms(user?.id, body);
              }
            }}
          >
            Confirmar
          </Button>
        </Actions>
      </Body>
    </Container>
  );
};

export default ConnectComponent(ChangeRole);
