import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListConference as UsecaseRemoteListConference } from 'domain/usecases/conference/remote';
import { ListConference as UsecaseReduxListConference } from 'domain/usecases/conference/redux';

import { RemoteListConference } from 'data/repository/conference';
import { ReduxListConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListConference = (): UsecaseRemoteListConference =>
  new RemoteListConference(makeApiUrl('/conferences/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListConference = (): UsecaseReduxListConference =>
  new ReduxListConference();
