import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 32px 56px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 64px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  margin-top: 64px;
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
`;

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 26px;

  color: #353a40;

  margin-bottom: 41px;
`;

export const LoginContent = styled.div`
  display: flex;
  align-items: center;

  margin-right: 40px;

  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const LoginText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  text-align: center;

  color: #1e5ece;

  margin-left: 6px;
`;

export const InfoContent = styled.div`
  margin-top: 81px;

  max-height: 300px;
  overflow: auto;
`;

export const Space = styled.div`
  display: flex;
  align-items: center;
`;

export const SpaceDescription = styled.div`
  display: flex;

  p {
    margin-top: 4px;
  }
`;

export const TextBold = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;

  color: #353a40;

  margin-right: 5px;
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;

  img {
    width: 100%;
    height: 80%;
    opacity: 0.8;
  }
`;
