import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateOrgUnit as UsecaseRemoteCreateOrgUnit } from 'domain/usecases/orgUnit/remote';
// import { CreateOrgUnit as UsecaseReduxCreateOrgUnit } from 'domain/usecases/orgUnit/redux';

import { RemoteCreateOrgUnit } from 'data/repository/orgUnit';
// import { ReduxCreateOrgUnit } from 'data/store/reducer/orgUnit/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateOrgUnit = (): UsecaseRemoteCreateOrgUnit =>
  new RemoteCreateOrgUnit(makeApiUrl('/orgs/{orgId}/units/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateOrgUnit =
  (): UsecaseReduxCreateOrgUnit =>
    new ReduxCreateOrgUnit(); */
