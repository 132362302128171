import React, { HTMLAttributes } from 'react';

import { RadioContainer, RadioRound } from './styles';

interface RadioProps extends HTMLAttributes<HTMLDivElement> {
  checked: boolean;
}

const Radio: React.FC<RadioProps> = ({ checked, ...rest }) => {
  return (
    <RadioContainer {...rest}>
      <RadioRound checked={checked} />
    </RadioContainer>
  );
};

export default Radio;
