import { DownloadConferenceFiles } from 'domain/usecases/conferenceFiles/remote';
import { NotFound, Forbidden, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDownloadConferenceFiles implements DownloadConferenceFiles {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadConferenceFiles.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadConferenceFiles.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: DownloadConferenceFiles.Params,
  ): Promise<DownloadConferenceFiles.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.short}/files/${params.file}/DOWNLOAD`,
      method: 'get',
      headers: params?.token
        ? {
            Authorization: `APIKEY ${params?.token}`,
          }
        : undefined,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
