import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iAuth, iStore } from 'domain/interfaces/models';

import { iOrgUnits, iRoles } from '../interface';
import { ownProps } from '../ChangeRoles';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { auth } = useSelector((store: iStore) => store);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      orgs: MapOrgsData(auth),
      ...(rest as P & ownProps),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

const MapOrgsData = (auth: iAuth): iOrgUnits[] => {
  const orgs = auth.user?.orgs;
  const array: iOrgUnits[] = [];

  orgs?.forEach(v => {
    if (!auth.user?.administrator) return;

    const idx = array.findIndex(el => el.org.id === v.id);

    const orgUnit = {
      name: v.name,
      id: v.id,
      administrator: auth.user.administrator,
    };

    if (idx >= 0) {
      array[idx].orgUnits?.push(orgUnit);
    } else {
      const aux: iOrgUnits = {
        org: {
          id: v.id,
          name: v.name,
        },
        orgUnits: [orgUnit],
      };
      array.push(aux);
    }
  });

  return array;
};
