import { takeLatest } from 'redux-saga/effects';
import { DashboardTypes } from 'domain/interfaces/redux/dashboard/types';
import {
  onGetDashboardOrg,
  onGetDashboardOrgSuccess,
  onGetDashboardOrgFailed,
} from './GetDashboardOrg';

const tasks = [
  takeLatest(DashboardTypes.GET_DASHBOARD_ORG, onGetDashboardOrg),
  takeLatest(
    DashboardTypes.GET_DASHBOARD_ORG_SUCCESS,
    onGetDashboardOrgSuccess,
  ),
  takeLatest(DashboardTypes.GET_DASHBOARD_ORG_FAILED, onGetDashboardOrgFailed),
];

export default tasks;
