import { makeHttpClient } from 'main/factories/http';
import { GetAddressData as UsecaseRemoteGetAddressData } from 'domain/usecases/externalServices/remote';

import { RemoteGetAddressData } from 'data/repository/externalServices/RemoteGetAddressData';

/**
 * send request via API.
 */
export const makeRemoteGetAddressData = (): UsecaseRemoteGetAddressData =>
  new RemoteGetAddressData('https://viacep.com.br/ws/', makeHttpClient());
