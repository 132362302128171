import styled, { css } from 'styled-components';

interface iDropProps {
  loading?: boolean;
  disablePadding?: boolean;
}

export const Drop = styled.div<iDropProps>`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: ${({ loading }) => (loading ? 'center' : 'flex-start')};
  padding: ${({ disablePadding }) => (disablePadding ? '0' : '13px 15px')};
  color: black;
  background: white;
  cursor: pointer;
  border: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  gap: 11px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 24px;
`;

export const RowContainer = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface iFilterIconProps {
  active?: boolean;
}

export const FilterIcon = styled.div<iFilterIconProps>`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.colors.background.hover};
    `}
`;
