/* eslint-disable no-nested-ternary */
import { Switch as MUISwitch } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/styles';
import { ThemeContext } from 'App';
import { MessageOptions } from 'domain/interfaces/redux/message';
import {
  CreateConference,
  Participant,
  UpdateConference,
} from 'domain/usecases/conference/remote';
import { validateYupSchema } from 'formik';
import { makeRemoteCreateConference } from 'main/factories/usecases/conference/CreateConferenceFactory';
import { makeRemoteUpdateConference } from 'main/factories/usecases/conference/UpdateConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { History } from 'main/routes';
import { Button } from 'presentation/components/UI';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { closeModal } from 'utils/closeModal';
import { appointmentSchema } from 'validation/appointment/CreateAppointmentValidation';
import Translator from 'presentation/components/i18n/Translator';
import CreateConferenceLeftCard, {
  CreateConferenceLeftCardRef,
} from '../createConferenceLeftCard';
import { AlertMessage } from '../messages/AlertMessage';
import ParticipantsList, {
  ParticipantsListRef,
} from './components/ParticipantsList';
import { ConnectComponent } from './mapper/MapperappointmentForm';
import {
  Center,
  Container,
  Content,
  Label,
  Left,
  Navigator,
  Right,
  SwitchContainer,
  ToggleContainer,
} from './styles/StyledAppointmentForm';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
}

interface iInitialUsers {
  id: number;
  confUser: number;
  email?: string;
  find?: boolean;
}

export interface ownProps {
  data: {
    selectDate: Date;
    title: string;
    timeStart: number;
    timeEnd: number;
    description: string;
    conferenceId?: number;
    rrule: string;
    notifyOptions: { sms: boolean; email: boolean; whatsapp: boolean };
    participants?: {
      id: number;
      role: 'MODERATOR' | 'ATTENDEE';
      scheduled: string;
      fullName: string;
      email: string;
      phone: string;
      passwd: string;
      confirmation: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'NEEDS-ACTION';
      user: {
        id: number;
        login: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        email: string;
        avatar: string;
        lastAccess: string;
        fullName: string;
      };
      conference: any;
    }[];
    allowTranscription?: boolean;
    allowBlockchain?: boolean;
    allowInvite?: boolean;
    waitingRoom?: boolean;
    type?: 'STANDARD' | 'RESTRICT';
    expectedDuration?: number;
  };
}

interface Invites {
  whatsapp: boolean;
  email: boolean;
  sms: boolean;
}
export interface externalProps {
  users?: iUsers[];
  edit?: boolean;
}

export interface initialValuesAppointment {
  title: string;
}

export interface iSelectUser {
  id?: number;
  user?: number;
  name?: string;
  role: 'MODERATOR' | 'ATTENDEE';
  image?: string;
  email?: string;
  orgName?: string;
  unitName?: string;
  confirmation?: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'NEEDS-ACTION';
  phone?: string;
}

const AppointmentForm: React.FC<ownProps & externalProps> = ({
  users,
  edit,
  data,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [waitingRoom, setWaitingRoom] = useState<boolean>(
    data.type === 'RESTRICT' ?? false,
  );

  const [allowTranscription, setAllowTranscription] = useState<boolean>(
    data.allowTranscription ?? false,
  );
  const [allowBlockchain, setAllowBlockchain] = useState<boolean>(
    data?.allowBlockchain ?? false,
  );

  const { theme } = useContext(ThemeContext);

  const participantsListRef = useRef<ParticipantsListRef>(null);
  const recurrenceRef = useRef<CreateConferenceLeftCardRef>(null);

  const Switch = styled(MUISwitch)(() => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  useEffect(() => {
    const getInitialUsers = async () => {
      const auxSel: Participant[] = [];

      if (edit && users?.length) {
        data?.participants?.forEach(el => {
          const isRegistered = !!el.user.id;

          auxSel.push({
            role: el.role || 'ATTENDEE',
            confirmation: el.confirmation || undefined,
            ...(isRegistered
              ? {
                  user: el.user.id,
                }
              : {
                  fullName: el.user?.fullName ?? el.fullName ?? 'Convidado',
                  email: el.user?.email ?? el.email ?? undefined,
                  phone: el.phone ?? undefined,
                }),
          });
        });

        participantsListRef.current?.setParticipants(auxSel);
      }
    };

    getInitialUsers();
  }, [data, edit, users]);

  const cancel = () => {
    History.getHistory().push(`/home`);
  };

  const handleUpdateConference = async (
    values: UpdateConference.Params['body'] & {
      updateScheduled: string | undefined;
      isOnce: boolean;
    },
  ) => {
    try {
      const updateConference = makeRemoteUpdateConference();

      const { updateScheduled, isOnce, ...updateValues } = values;

      await updateConference.update({
        conferenceId: Number(data.conferenceId),
        body: {
          ...updateValues,
        },
      });

      handleRedirect();
    } catch {
      AlertMessage({
        message: Translator('Não foi possível criar a reunião'),
        type: 'danger',
      });
    }
  };

  const handleRedirect = () => {
    const feedback = edit
      ? 'Conferência atualizada com sucesso'
      : 'Reunião Agendada com sucesso!';

    toast.success(Translator(feedback), {
      onClose: () => History.getHistory().push(`/home`),
      autoClose: 1500,
    });

    History.getHistory().push(`/home`);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const { title, descr, recurrence, scheduled, expectedDuration } =
      recurrenceRef.current?.getRecurrence() ?? {
        title: '',
        descr: '',
        expectedDuration: 0,
      };
    const participants = participantsListRef.current?.getParticipants() ?? [];
    const { owner, org } = participantsListRef.current?.getOwner() ?? {
      owner: undefined,
      org: undefined,
    };

    e.preventDefault();

    try {
      setLoading(true);

      const body: CreateConference.Params['body'] = {
        org,
        owner,
        title,
        descr,
        expectedDuration,
        scheduled: scheduled ?? new Date().toISOString(),
        participants,
        allowBlockchain,
        allowTranscription,
        recurrence,
        notifyOptions: participantsListRef.current?.getNotifyOptions() ?? {
          whatsapp: false,
          email: false,
          sms: false,
        },
        ...(waitingRoom && { type: 'RESTRICT' }),
      };

      const isOnce = recurrenceRef.current?.isOnce ?? true;

      recurrenceRef.current?.validate();
      const value = await validateYupSchema(body, appointmentSchema);
      const createConference = makeRemoteCreateConference();

      const {
        owner: _,
        org: __,
        scheduled: updateScheduled,
        ...updateValues
      } = value;

      if (edit) {
        if (recurrenceRef.current?.isOnce) {
          await handleUpdateConference({
            updateScheduled,
            isOnce,
            ...updateValues,
            recurrence: undefined,
          });
          handleRedirect();
          return;
        }
        makeReduxActiveMessage().active({
          active: MessageOptions.recurringConferenceModal,
          type: 'EDIT',
          actionOk: () => (effect: string) => {
            handleUpdateConference({
              updateScheduled,
              isOnce,
              ...updateValues,
              recurrence: {
                ...value.recurrence,
                effect,
                id: Number(data.conferenceId),
                rrule: recurrenceRef.current?.hasUpdatedRrule
                  ? value.recurrence?.rrule
                  : undefined,
                ...(effect === 'ONLYTHIS' && {
                  scheduled: updateScheduled,
                  rrule: undefined,
                }),
              },
            });
          },
        });
      } else {
        await createConference.create({
          body: { ...value, ...(isOnce ? { recurrence: undefined } : {}) },
        });

        handleRedirect();
      }
    } catch {
      if (edit) {
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Edição de conferência'),
          content: Translator('Não foi possível atualizar a reunião'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });

        return;
      }

      AlertMessage({
        message: Translator('Não foi possível criar a reunião'),
        type: 'danger',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit && data.rrule) recurrenceRef.current?.setInitialData(data);
  }, [recurrenceRef, edit, data]);

  useEffect(() => {
    if (edit && data.notifyOptions) {
      participantsListRef.current?.setNotifyOptions(data.notifyOptions);
    }
  }, [data.notifyOptions, edit]);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Content>
          <Left>
            <CreateConferenceLeftCard ref={recurrenceRef} initialData={data} />
          </Left>
          <Center>
            <ParticipantsList ref={participantsListRef} edit={!!edit} />
          </Center>
          <Right>
            <ToggleContainer>
              <Label>
                {Translator('Gerar documento com a transcrição da reunião')}
              </Label>
              <SwitchContainer>
                <Switch
                  data-testid="switch-transcription"
                  checked={allowTranscription}
                  onClick={() => setAllowTranscription(prevState => !prevState)}
                />
                <p className="switch-label">
                  {Translator(allowTranscription ? 'Sim' : 'Não')}
                </p>
              </SwitchContainer>
            </ToggleContainer>
            <ToggleContainer>
              <Label>{Translator('Registrar reunião na blockchain')}</Label>
              <SwitchContainer>
                <Switch
                  data-testid="switch-blockchain"
                  checked={allowBlockchain}
                  onClick={() => setAllowBlockchain(prevState => !prevState)}
                />
                <p className="switch-label">
                  {Translator(allowBlockchain ? 'Sim' : 'Não')}
                </p>
              </SwitchContainer>
            </ToggleContainer>
            <ToggleContainer>
              <Label>
                Convidados precisam de aprovação do(s) moderador(es) para
                ingressar na conferência
              </Label>
              <SwitchContainer>
                <Switch
                  data-testid="switch-waiting-room"
                  checked={waitingRoom}
                  onClick={() => setWaitingRoom(prevState => !prevState)}
                />
                <p className="switch-label">
                  {Translator(waitingRoom ? 'Sim' : 'Não')}
                </p>
              </SwitchContainer>
            </ToggleContainer>
          </Right>
        </Content>
        <Navigator>
          <div className="buttons-container">
            <Button
              data-testid="btn-cancel"
              onClick={cancel}
              variant="secundary"
              color={theme.colors.primary.main}
              id="cancel"
              size="small"
            >
              Cancelar
            </Button>
            <Button
              data-testid="btn-save"
              type="submit"
              background={theme.colors.primary.main}
              id="schedule"
              size="fit-content"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    width: '30px',
                    height: '30px',
                    color: `${theme.colors.primary.main}`,
                  }}
                />
              ) : edit ? (
                `${Translator('Salvar')}`
              ) : (
                `${Translator('Agendar reunião')}`
              )}
            </Button>
          </div>
        </Navigator>
      </Container>
    </form>
  );
};

export default ConnectComponent(AppointmentForm);
