import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetConferenceDataDashboard as UsecaseRemoteGetConferenceDataDashboard } from 'domain/usecases/dashboard/remote';
// import { GetConferenceDataDashboard as UsecaseReduxGetConferenceDataDashboard } from 'domain/usecases/dashboard/redux';

import { RemoteGetConferenceDataDashboard } from 'data/repository/dashboard';
// import { ReduxGetConferenceDataDashboard } from 'data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetConferenceDataDashboard =
  (): UsecaseRemoteGetConferenceDataDashboard =>
    new RemoteGetConferenceDataDashboard(
      makeApiUrl('/dashboard/conference'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetConferenceDataDashboard =
  (): UsecaseReduxGetConferenceDataDashboard =>
    new ReduxGetConferenceDataDashboard(); */
