import { call, put, select } from 'redux-saga/effects';
import { iActionListFilteredByACLV2 } from 'domain/interfaces/redux/conference/listFilteredByACLV2';
import { ListConferenceFilteredByACLV2 } from 'domain/usecases/conference/remote/ListConferenceFilteredByACLV2';
import { makeRemoteListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import {
  listFilteredByACLV2Success,
  listFilteredByACLV2Failed,
} from 'data/store/reducer/conference/actions/listFilteredByACLV2';

export function* onListFilteredByACLV2(action: iActionListFilteredByACLV2) {
  const remoteListConferenceFilteredByACLV2 =
    makeRemoteListConferenceFilteredByACLV2();

  try {
    const response: ListConferenceFilteredByACLV2.Model = yield call(
      remoteListConferenceFilteredByACLV2.list,
      action.payload,
    );
    yield put(
      listFilteredByACLV2Success({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFilteredByACLV2Failed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredByACLV2Success() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFilteredByACLV2Failed() {}
