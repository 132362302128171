import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListRecordingsConference as UsecaseRemoteListRecordingsConference } from 'domain/usecases/conference/remote';
// import { ListRecordingsConference as UsecaseReduxListRecordingsConference } from 'domain/usecases/conference/redux';

import { RemoteListRecordingsConference } from 'data/repository/conference';
// import { ReduxListRecordingsConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListRecordingsConference =
  (): UsecaseRemoteListRecordingsConference =>
    new RemoteListRecordingsConference(
      makeApiUrl('/conferences/{conferenceId}/recordings/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxListRecordingsConference =
  (): UsecaseReduxListRecordingsConference =>
    new ReduxListRecordingsConference(); */
