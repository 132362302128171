import React, { useEffect } from 'react';
import * as dateFns from 'date-fns';
import { Calendar as CalendarMui } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { Container } from './styles';

export interface ownProps {
  // set: Function;
  date: Date;
  setDate: Function;
  minDate?: Date;
}

const GlobalCss = withStyles({
  '@global': {
    '.MuiPickersCalendarHeader-switchHeader': {
      justifyContent: 'flex-end',
      position: 'relative',
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      position: 'absolute',
      marginTop: '0px',
      /* textAlign: 'start !important',
      right: '76px', */
    },
    '.MuiPickersCalendarHeader-transitionContainer > p': {
      textAlign: 'start',
      paddingLeft: '6px',
      textTransform: 'uppercase',
      color: '#424043',
    },
    '.MuiPickersSlideTransition-slideEnter-left': {
      transform: 'translate(0%)',
      willChange: 'transform',
    },
    '.MuiPickersSlideTransition-slideEnter-right': {
      transform: 'translate(0%)',
      willChange: 'transform',
    },
    '.MuiPickersSlideTransition-slideEnterActive': {
      transform: 'translate(0%)',
      transition: 'transform 350ms cubic-bezier(0.35, 0.8, 0.4, 1) 0ms',
    },
    '.MuiPickersSlideTransition-slideExit': {
      transform: 'translate(0%)',
    },
    '.MuiPickersSlideTransition-slideExitActiveLeft-left': {
      opacity: 0,
    },
    '.MuiPickersSlideTransition-slideExitActiveLeft-right': {
      opacity: 0,
    },
    '.MuiPickersDay-day': {
      width: '30px',
      height: '30px',
      fontSize: '0.75rem',
    } /*
    '.MuiPickersDay-hidden': {
      opacity: 100,
      color: '#A8A8A8',
    }, */,
  },
})(() => null);

const Calendar: React.FC<ownProps> = ({
  date,
  setDate,
  minDate,
}): JSX.Element => {
  const selectDate = (data: any) => {
    setDate(data);
  };

  const changeMonth = (data: any) => {
    if (dateFns.compareAsc(data, date) === 1)
      setDate(dateFns.addMonths(date, 1));
    else if (dateFns.compareAsc(data, date) === -1)
      setDate(dateFns.addMonths(date, -1));
  };

  useEffect(() => {
    makeReduxUpdateControllerConference().updateController({
      selectedDate: date,
    });
  }, [date]);

  return (
    <>
      <GlobalCss />
      <Container>
        <CalendarMui
          date={date}
          onChange={selectDate}
          onMonthChange={changeMonth}
          minDate={minDate}
          leftArrowButtonProps={{ id: 'calendar-left-arrow' }}
          rightArrowButtonProps={{ id: 'calendar-right-arrow' }}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => (
            <div data-testid={`day-${day?.getDate()}`}>{dayComponent}</div>
          )}
        />
      </Container>
    </>
  );
};

export default Calendar;
