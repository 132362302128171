import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Translator from 'presentation/components/i18n/Translator';
import { HeaderBack, Header } from 'presentation/components/header';
import ProfileNavigator from 'presentation/components/PerfilNavigator/PerfilNavigator';
import UserPage from '../Admin/Users';

import { Body, Container, Left, Right } from './styles/StyledCreateUser';

const CreateUserPage: React.FC = () => {
  const [navigation, setNavigation] = useState<number>(1);

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return <UserPage />;
      default:
        return <div />;
    }
  }, [navigation]);

  const { push, goBack } = useHistory();

  return (
    <Container>
      <Header title="teste" />
      <HeaderBack
        onBack={() => goBack()}
        title={`${Translator('Criação de usuário')}`}
      />
      <Body>
        <Left>
          <ProfileNavigator active={navigation} nav={setNavigation} creating />
        </Left>
        <Right>{RenderSubPages}</Right>
      </Body>
    </Container>
  );
};

export default CreateUserPage;
