import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/pixelToRem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  margin-top: ${pxToRem(150)};
`;

export const HeaderContainer = styled.header`
  width: 100%;
`;

export const CardsContainer = styled.section`
  width: 100%;
  padding: 3.2rem 7.2rem;
  padding: ${pxToRem(32)} ${pxToRem(72)};
  display: grid;
  flex-direction: row;

  background: transparent;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${pxToRem(32)};

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    padding: ${pxToRem(32)} ${pxToRem(24)};
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    padding: ${pxToRem(32)} ${pxToRem(16)};
  }
`;

export const Card = styled.div`
  width: 100%;
  height: ${pxToRem(100)};
  padding: ${pxToRem(16)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.background.default};

  border-radius: ${pxToRem(8)};
  border: 1px solid rgba(105, 127, 150, 0.25);

  min-width: fit-content;
`;

const CardContentStructure = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  ${CardContentStructure}
`;

export const CardBody = styled.div`
  ${CardContentStructure}
  gap: ${pxToRem(23)};
  width: 100%;
`;

export const CardTitle = styled.h2`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(19)};
  font-weight: 500;
  color: #667b93;

  // no wrap text
  white-space: nowrap;
`;

export const Counter = styled.span`
  margin: 0;
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(38)};
  font-weight: 500;

  color: #233242;
`;

export const TableContainer = styled.section`
  width: 100%;
`;
