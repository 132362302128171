import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListAgreedTerms as UsecaseRemoteListAgreedTerms } from 'domain/usecases/user/remote';

import { RemoteListAgreedTerms } from 'data/repository/user';

/**
 * send request via API.
 */
export const makeRemoteListAgreedTerms = (): UsecaseRemoteListAgreedTerms =>
  new RemoteListAgreedTerms(
    makeApiUrl('/users/{userId}/agreed-terms'),
    makeHttpClient(),
  );
