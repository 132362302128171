import React, { useCallback, useEffect } from 'react';

import { DashboardUser } from 'presentation/pages';
import { makeReduxListOrgUnitAclFilteredV2 } from '../../usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';

export const MakeDashboardUserFactory: React.FC = () => {
  const loadOrgs = useCallback(() => {
    makeReduxListOrgUnitAclFilteredV2().list({
      limit: 9999,
    });
  }, []);

  useEffect(() => {
    loadOrgs();
  }, [loadOrgs]);

  return <DashboardUser />;
};
