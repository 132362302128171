import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListMyConferences as UsecaseRemoteListMyConferences } from 'domain/usecases/myConferences/remote';
import { ListMyConferences as UseCaseReduxListMyConferences } from 'domain/usecases/myConferences/redux';

import { RemoteListMyConferences } from 'data/repository/myConferences';
import { ReduxListMyConferences } from 'data/store/reducer/myConference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListMyConferences = (): UsecaseRemoteListMyConferences =>
  new RemoteListMyConferences(makeApiUrl('/conferences/my'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListMyConferences = (): UseCaseReduxListMyConferences =>
  new ReduxListMyConferences();
