import { UpdateUser } from 'domain/usecases/user/redux/UpdateUser';
import {
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSuccess,
  UserTypes,
} from 'domain/interfaces/redux/user/update';

export const updateRequest = (payload: UpdateUser.Params): iActionUpdate => ({
  type: UserTypes.UPDATE,
  payload,
});

export const updateSuccess = (
  params: UpdateUser.Model,
): iActionUpdateSuccess => ({
  type: UserTypes.UPDATE_SUCCESS,
  payload: params,
});

export const updateFailed = (): iActionUpdateFailed => ({
  type: UserTypes.UPDATE_FAILED,
});
