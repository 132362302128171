import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateConference as UsecaseRemoteUpdateConference } from 'domain/usecases/conference/remote';
// import { UpdateConference as UsecaseReduxUpdateConference } from 'domain/usecases/conference/redux';

import { RemoteUpdateConference } from 'data/repository/conference';
// import { ReduxUpdateConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateConference = (): UsecaseRemoteUpdateConference =>
  new RemoteUpdateConference(
    makeApiUrl('/conferences/{conferenceId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateConference =
  (): UsecaseReduxUpdateConference =>
    new ReduxUpdateConference(); */
