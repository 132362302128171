import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBillingByOrgUnit as UsecaseRemoteGetBillingByOrgUnit } from 'domain/usecases/billing/remote';

import { RemoteGetBillingByOrgUnit } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBillingByOrgUnit =
  (): UsecaseRemoteGetBillingByOrgUnit =>
    new RemoteGetBillingByOrgUnit(
      makeApiUrl('/orgs/{orgId}/billing/orgUnit/'),
      makeHttpClient(),
    );
