import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteByOrgAdmin as UsecaseRemoteDeleteByOrgAdmin } from 'domain/usecases/admin/remote';
// import { DeleteByOrgAdmin as UsecaseReduxDeleteByOrgAdmin } from 'domain/usecases/admin/redux';

import { RemoteDeleteByOrgAdmin } from 'data/repository/admin';
// import { ReduxDeleteByOrgAdmin } from 'data/store/reducer/admin/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteByOrgAdmin = (): UsecaseRemoteDeleteByOrgAdmin =>
  new RemoteDeleteByOrgAdmin(
    makeApiUrl('/orgs/{orgId}/admins/{userId}/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteByOrgAdmin =
  (): UsecaseReduxDeleteByOrgAdmin =>
    new ReduxDeleteByOrgAdmin(); */
