/* eslint-disable react/jsx-wrap-multilines */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as dateFns from 'date-fns';

import { Frequency, RRule, Weekday } from 'rrule';
import Chips from '../Chips';
import InputDate from '../InputDate';
import Input from '../UI/input';
import RadioButton from '../UI/radio';
import Select from '../UI/select';
import Repetitions, { RepetitionsRef } from '../repetitions';
import {
  daysOfMonth,
  daysOfWeekTranslations,
  frequency,
  getWeekdayByNumber,
  getWeekdayByString,
} from './constants';
import { Closure, Start } from './interfaces';

import { ownProps } from '../appointmentForm/appointmentForm';
import {
  Container,
  ContainerDaily,
  ContainerMonthly,
  DateAndHour,
  Label,
  Required,
  Text,
} from './styles';
import Translator from '../i18n/Translator';

export interface CreateConferenceLeftCardRef {
  getRecurrence: () => {
    title: string;
    descr: string;
    scheduled: string;
    expectedDuration: number;
    recurrence?: { rrule: string };
  };
  handleDurationError: () => void;
  isOnce: boolean;
  validate: () => boolean;
  isValid: boolean;
  setInitialData: (data: ownProps['data']) => void;
  hasUpdatedRrule: boolean;
}

interface CreateConferenceLeftCardProps {
  initialData?: ownProps['data'];
}

interface iParams {
  effect: string;
}

const CreateConferenceLeftCard: React.ForwardRefRenderFunction<
  CreateConferenceLeftCardRef,
  CreateConferenceLeftCardProps
> = ({ initialData }, ref) => {
  const [closure, setClosure] = useState<Closure>({
    name: 'lastDay',
    date: new Date(),
    repetitions: 1,
  });
  const [currentFrequency, setCurrentFrequency] = useState<Frequency | -1>(-1);
  const [onNDay, setOnNDay] = useState(1);

  const [title, setTitle] = useState<string>(initialData?.title ?? '');
  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [descr, setDescr] = useState<string>(initialData?.description ?? '');
  const [scheduled, setScheduled] = useState<Date>(
    initialData?.selectDate ?? new Date(),
  );
  const [scheduledEnd, setScheduledEnd] = useState<Date>(() => {
    if (
      initialData?.selectDate &&
      initialData?.expectedDuration &&
      initialData?.expectedDuration > 0
    )
      return dateFns.addMinutes(scheduled, initialData?.expectedDuration ?? 60);

    const newDate = new Date(scheduled);
    newDate.setHours(newDate.getHours() + 1);
    return newDate;
  });

  const [repetitions, setRepetitions] = useState<number>(1);

  const [durationError, setDurationError] = useState<boolean>(false);
  const [sendRrule, setSendRrule] = useState<boolean>(false);

  const repetitionsRef = useRef<RepetitionsRef>(null);
  const AlwaysOnDayRef = useRef<RepetitionsRef>(null);

  const [rruleOptions, setRRuleOptions] = useState({
    freq: RRule.DAILY,
    interval: 1,
    byweekday: [] as Weekday[],
    bymonthday: [],
  });

  const [start, setStart] = useState<Start>({ type: 0 });

  const getDuration = useMemo(() => {
    return dateFns.differenceInMinutes(scheduledEnd, scheduled);
  }, [scheduled, scheduledEnd]);

  const handleErrorTitle = useCallback(() => {
    setErrorTitle(title === '');
  }, [title]);

  const handleDurationError = useCallback(() => {
    setDurationError(getDuration <= 0);
  }, [getDuration]);

  const isValid = useMemo(() => {
    return !errorTitle && !durationError;
  }, [errorTitle, durationError]);

  useImperativeHandle(
    ref,
    () => ({
      getRecurrence() {
        const closureDateUntilLastHour = new Date(closure?.date ?? '');
        closureDateUntilLastHour.setHours(23);
        closureDateUntilLastHour.setMinutes(59);

        const byweekday =
          start.type === 2
            ? [rruleOptions.byweekday[0].nth(onNDay)]
            : rruleOptions.byweekday;

        const rrule = new RRule({
          ...rruleOptions,
          dtstart: scheduled,
          ...(start.type === 1
            ? {
                bymonthday: [AlwaysOnDayRef.current?.getRepetitions() ?? 1],
              }
            : {
                byweekday,
                ...(currentFrequency === 4 && { bysetpos: onNDay }),
              }),
          ...(closure.name === 'lastDay'
            ? { until: closureDateUntilLastHour }
            : { count: repetitionsRef.current?.getRepetitions() ?? 1 }),
        });

        return {
          title,
          descr,
          scheduled: scheduled.toISOString(),
          expectedDuration: getDuration,
          ...(currentFrequency === -1
            ? undefined
            : {
                recurrence: {
                  rrule: rrule.toString(),
                },
              }),
        };
      },
      isOnce: currentFrequency === -1,
      handleDurationError,
      isValid,
      validate() {
        handleErrorTitle();
        handleDurationError();
        return !errorTitle && !durationError;
      },
      setInitialData(data) {
        const rrule = RRule.fromString(data.rrule);

        const isNWeekDay = rrule.options.bynweekday?.[0]?.length === 2;

        setCurrentFrequency(rrule.origOptions?.freq ?? -1);

        setClosure({
          name: rrule.origOptions?.until ? 'lastDay' : 'after',
          date: rrule.origOptions?.until ?? new Date(),
        });

        if (rrule.options.bynweekday?.[0]) {
          setStart(prevState => ({
            ...prevState,
            type: isNWeekDay ? 2 : prevState.type,
          }));

          setOnNDay(isNWeekDay ? rrule.options.bynweekday?.[0][1] : 0);
        } else if (rrule.options.byweekday) {
          setStart(prevState => ({
            ...prevState,
            type: 1,
          }));
        }

        setRRuleOptions(prevState => ({
          ...prevState,
          freq: rrule.options.freq,
          interval: rrule.options.interval,
          ...(rrule.options.bymonthday.length > 0 && {
            bymonthday: rrule.options.bymonthday as any,
          }),
          ...(rrule.options.byweekday && {
            byweekday: rrule.options.byweekday.map(
              weekday => getWeekdayByNumber[weekday],
            ),
          }),
          ...(isNWeekDay && {
            byweekday: [
              getWeekdayByNumber[rrule.options.bynweekday?.[0]?.[0] ?? 0],
            ],
          }),
        }));

        if (rrule.origOptions?.count) {
          setRepetitions(rrule.origOptions?.count);
        }
      },
      hasUpdatedRrule: sendRrule,
    }),
    [
      currentFrequency,
      handleDurationError,
      closure?.date,
      closure.name,
      rruleOptions,
      start.type,
      onNDay,
      title,
      descr,
      scheduled,
      getDuration,
      handleErrorTitle,
      errorTitle,
      durationError,
      sendRrule,
    ],
  );

  useEffect(() => {
    if (scheduledEnd < scheduled) setScheduledEnd(new Date(scheduled));
  }, [scheduled, scheduledEnd]);

  return (
    <Container>
      <div>
        <div style={{ display: 'flex', marginBottom: 12 }}>
          <Label>{Translator('Título de reunião')}</Label>
          <Required>*</Required>
        </div>
        <Input
          width="535px"
          name="title"
          id="title"
          onBlur={handleErrorTitle}
          error={errorTitle}
          message={Translator('O campo "Título de reunião" é obrigatório.')}
          data-testid="conference-title"
          value={title}
          onChange={e => {
            setTitle(e.target.value);
            handleErrorTitle();
          }}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Label>Data e horário</Label>
        <Required>*</Required>
      </div>
      <DateAndHour>
        <InputDate
          id="validityBegin"
          name="validityBegin"
          height="40px"
          width="140px"
          state={scheduled}
          setState={(date: Date) => {
            handleDurationError();
            setScheduled(date);
          }}
          minDate={initialData?.selectDate ?? new Date()}
          endAdornment
        />
        <Input
          width="92px"
          type="time"
          onBlur={handleDurationError}
          onChange={e => {
            if (e.target.value.match(/[^0-9:]/g)) return;
            const [hours, minutes] = e.target.value.split(':');
            const newDate = new Date(scheduled);
            newDate.setHours(Number(hours));
            newDate.setMinutes(Number(minutes));
            setScheduled(newDate);
            handleDurationError();
          }}
          value={scheduled.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        />
        <Text>até</Text>
        <Input
          width="92px"
          type="time"
          onBlur={handleDurationError}
          onChange={e => {
            if (e.target.value.match(/[^0-9:]/g)) return;
            const [hours, minutes] = e.target.value.split(':');
            const newDate = new Date(scheduledEnd);
            newDate.setHours(Number(hours));
            newDate.setMinutes(Number(minutes));
            setScheduledEnd(newDate);
            handleDurationError();
          }}
          value={scheduledEnd.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        />
      </DateAndHour>
      {durationError && (
        <div style={{ marginTop: 10, fontSize: 12, color: '#ea3e4f' }}>
          O horário final deve ser maior que o inicial.
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ display: 'flex', marginTop: 20, marginBottom: 12 }}>
            <Label>Frequência</Label>
            <Required>*</Required>
          </div>
          <Select
            width="140px"
            value={currentFrequency}
            onChange={event => {
              setCurrentFrequency(Number(event.target.value));
              setRRuleOptions(prevState => ({
                ...prevState,
                byweekday: [],
              }));
              setSendRrule(
                !!event.target.value && Number(event.target.value) !== -1,
              );
              if (Number(event.target.value) === RRule.MONTHLY)
                setStart({ type: 1 });
              if (event.target.value !== '-1') {
                setRRuleOptions(prevState => ({
                  ...prevState,
                  freq: Number(event.target.value),
                }));
              }
            }}
          >
            {frequency.map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        {currentFrequency === RRule.MONTHLY && (
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 12,
                marginLeft: 12,
              }}
            >
              <div>a cada</div>
              <Input
                width="92px"
                defaultValue={1}
                value={rruleOptions.interval ?? 1}
                onChange={event => {
                  setRRuleOptions(prevState => ({
                    ...prevState,
                    interval: Number(event.target.value),
                  }));
                }}
              />
              <div>mês</div>
            </div>
          </div>
        )}
        {currentFrequency === RRule.WEEKLY && (
          <div style={{ marginLeft: 12 }}>
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 12 }}>
              <Label>Repetir em</Label>
              <Required>*</Required>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
              {Object.values(daysOfWeekTranslations).map(item => (
                <Chips
                  key={item.value.toString()}
                  week={item.label}
                  active={rruleOptions.byweekday.includes(item.value)}
                  onClick={() => {
                    setRRuleOptions(prevState => ({
                      ...prevState,
                      byweekday: rruleOptions.byweekday.includes(item.value)
                        ? rruleOptions.byweekday.filter(
                            day => day !== item.value,
                          )
                        : [...rruleOptions.byweekday, item.value],
                    }));
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div>
        {currentFrequency === RRule.MONTHLY && (
          <ContainerMonthly>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RadioButton
                checked={start.type === 1}
                onClick={() => {
                  setStart({ type: 1 });
                  setRRuleOptions(prevState => ({
                    ...prevState,
                    byweekday: [],
                  }));
                }}
              />
              <Text>Sempre no dia</Text>
              <Repetitions
                max={31}
                initialValue={rruleOptions.bymonthday[0] ?? 1}
                ref={AlwaysOnDayRef}
                disabled={start.type === 2}
              />
              <Text>de cada mês</Text>
            </div>
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RadioButton
                  checked={start.type === 2}
                  onClick={() => {
                    setStart({ type: 2 });
                    setRRuleOptions(prevState => ({
                      ...prevState,
                      byweekday: [RRule.SU.nth(onNDay)],
                    }));
                  }}
                />
                <Text>Sempre no</Text>
                <Select
                  width="92px"
                  height="40px"
                  value={onNDay}
                  defaultValue={onNDay}
                  onChange={e => setOnNDay(Number(e.target.value))}
                  disabled={start.type === 1}
                >
                  {daysOfMonth.map(day => (
                    <option key={day.id} value={day.id}>
                      {day.name}
                    </option>
                  ))}
                </Select>
                <div style={{ marginLeft: 10 }}>
                  <Select
                    width="173px"
                    height="40px"
                    defaultValue={RRule.SU.toString()}
                    value={rruleOptions.byweekday?.[0]?.toString()}
                    onChange={e => {
                      const day = getWeekdayByString[e.target.value];
                      setRRuleOptions(prevState => ({
                        ...prevState,
                        byweekday: [day],
                      }));
                    }}
                    disabled={start.type === 1}
                  >
                    <option value={RRule.SU.toString()}>Domingo</option>
                    <option value={RRule.MO.toString()}>Segunda-feira</option>
                    <option value={RRule.TU.toString()}>Terça-feira</option>
                    <option value={RRule.WE.toString()}>Quarta-feira</option>
                    <option value={RRule.TH.toString()}>Quinta-feira</option>
                    <option value={RRule.FR.toString()}>Sexta-feira</option>
                    <option value={RRule.SA.toString()}>Sábado</option>
                  </Select>
                </div>
                <Text>de cada mês</Text>
              </div>
            </div>
          </ContainerMonthly>
        )}
        {currentFrequency !== -1 && (
          <ContainerDaily>
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 12 }}>
              <Label>Encerramento</Label>
              <Required>*</Required>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 12,
              }}
            >
              <RadioButton
                checked={closure.name === 'lastDay'}
                onClick={() => {
                  setClosure(prevState => ({
                    ...prevState,
                    name: 'lastDay',
                  }));
                }}
              />
              <Text>Em</Text>
              <InputDate
                id="validityBegin"
                name="validityBegin"
                height="40px"
                width="140px"
                disabled={closure.name !== 'lastDay'}
                state={new Date(closure.date ?? '')}
                setState={(date: Date) =>
                  setClosure(prevState => ({
                    ...prevState,
                    date,
                  }))
                }
                minDate={new Date()}
                endAdornment
              />
              <div style={{ display: 'flex', marginLeft: 8 }}>
                <RadioButton
                  checked={closure.name === 'after'}
                  onClick={() => {
                    setClosure(prevState => ({
                      ...prevState,
                      name: 'after',
                    }));
                  }}
                />
                <Text>Após</Text>
              </div>
              <Repetitions
                ref={repetitionsRef}
                initialValue={repetitions}
                disabled={closure.name !== 'after'}
              />
              <Text>repetições</Text>
            </div>
          </ContainerDaily>
        )}
      </div>
      <div>
        <div style={{ display: 'flex', marginTop: 20, marginBottom: 12 }}>
          <Label>Descrição</Label>
        </div>
        <Input
          width="535px"
          height="120px"
          value={descr}
          onChange={e => setDescr(e.target.value)}
        />
      </div>
    </Container>
  );
};

export default forwardRef(CreateConferenceLeftCard);
