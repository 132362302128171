import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteByOrgUnitOrgUser as UsecaseRemoteDeleteByOrgUnitOrgUser } from 'domain/usecases/orgUser/remote';
// import { DeleteByOrgUnitOrgUser as UsecaseReduxDeleteByOrgUnitOrgUser } from 'domain/usecases/orgUser/redux';

import { RemoteDeleteByOrgUnitOrgUser } from 'data/repository/orgUser';
// import { ReduxDeleteByOrgUnitOrgUser } from 'data/store/reducer/orgUser/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteByOrgUnitOrgUser =
  (): UsecaseRemoteDeleteByOrgUnitOrgUser =>
    new RemoteDeleteByOrgUnitOrgUser(
      makeApiUrl('/orgs/{orgId}/units/{unitId}/users/{userId}/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteByOrgUnitOrgUser =
  (): UsecaseReduxDeleteByOrgUnitOrgUser =>
    new ReduxDeleteByOrgUnitOrgUser(); */
