/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { ActionsList } from 'presentation/components/actionsList';
import { ScheduleStatus } from 'presentation/components/statusList';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { format } from 'date-fns';
import { iColumnsConfig, iListAudit } from './interface';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

interface ownProps {
  dataList?: iListAudit[];
}

type iColumns = 'date' | 'hour' | 'group' | 'event' | 'user' | 'actions';

type iOrder =
  | 'fullName'
  | 'timeStart'
  | 'timeEnd'
  | 'title'
  | 'typeIs'
  | 'specialty'
  | 'status';

const Auditoria: React.FC<ownProps> = ({ dataList }) => {
  const loading = false;
  const url = window.location.pathname;
  const arrToCompare = [
    'fullName',
    'timeStart',
    'timeEnd',
    'typeIs',
    'specialty',
    'status',
  ];
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'date',
      label: 'Data',
      minWidth: 80,
      maxWidth: 80,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'hour',
      label: 'Hora',
      minWidth: 40,
      maxWidth: 40,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'group',
      label: 'Grupo',
      minWidth: 48,
      maxWidth: 48,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'event',
      label: 'Evento',
      minWidth: 564,
      maxWidth: 564,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'user',
      label: 'Usuário',
      minWidth: 140,
      maxWidth: 140,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: 'Ações',
      minWidth: 40,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const RenderComponents: React.FC<{ id: iColumns; log: iListAudit }> = ({
    id,
    log,
  }) => {
    const timestampDate = new Date(log?.timestamp);

    switch (id) {
      case 'date':
        return <div>{timestampDate.toLocaleDateString('pt-PT')}</div>;
      case 'hour':
        return <text>{format(timestampDate, 'HH:mm')}</text>;
      case 'group':
        return <text>{log?.action.groupName}</text>;
      case 'event':
        return <text>{log?.action.descr}</text>;
      case 'user':
        return <text>{log?.user?.fullName}</text>;
      case 'actions':
        return (
          <ActionsList
            actions={{
              viewAudit: () =>
                makeReduxActiveMessage().active({
                  active: MessageOptions.viewAudit,
                  userId: log?.id,
                }),
            }}
          />
        );
      default:
        return <div>teste</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => <div style={{ paddingLeft: 3 }} />}
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(() => {
                return (
                  <TableRow>
                    <div>skeleton</div>
                  </TableRow>
                );
              })}
            </>
          ) : (
            dataList?.map((log: iListAudit) => (
              <TableRow key={log.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.minWidth,
                        minWidth: columnProps.maxWidth,
                        fontSize: columnProps.fontSize,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <RenderComponents id={columnProps.id} log={log} />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Auditoria;
