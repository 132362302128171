import styled from 'styled-components';

interface iConferenceTags {
  status: 'RECORDING' | 'BLOCKCHAIN' | 'TRANSCRIPTION' | 'LIBRAS';
}

const colorByService: Record<
  iConferenceTags['status'],
  { background: string; color: string }
> = {
  RECORDING: {
    background: '#E8F6EC',
    color: '#116427',
  },
  BLOCKCHAIN: { background: '#DFEEFA', color: '#0A5D9E' },
  TRANSCRIPTION: { color: '#790275', background: '#FDECFA' },
  LIBRAS: { color: '#795102', background: '#FDF4EC' },
};

export const ConferencesTags = styled.div<iConferenceTags>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 94px;
  border-radius: 4px;
  padding: 4px 10px;
  color: ${({ status }) => colorByService[status].color || '#000'};
  font-weight: 500;
  font-size: 14px;
  background-color: ${({ status }) =>
    colorByService[status].background || '#fff'};
`;

export const ContainerTags = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
