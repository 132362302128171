import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { GetDashboardOrg } from 'domain/usecases/dashboard/remote';
import { makeReduxGetDashboardOrg } from 'main/factories/usecases/dashboard/GetDashboardOrgFactory';

// import {
//   Avatar,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from '@material-ui/core';
import { pxToRem } from 'utils/pixelToRem';
import Translator from 'presentation/components/i18n/Translator';
import { Counter } from './counter';
import { Pagination } from './Pagination';
import {
  Card,
  CardBody,
  CardHeader,
  CardsContainer,
  CardTitle,
  Container,
} from './styles';
import Table from './table';

const itemsPerPage = 6;

const RankingOrg: React.FC = () => {
  const { loading, response } = useSelector(
    (store: iStore) => store.dashboardOrg,
  );

  const orgs = response?.orgs;

  const transformStorage = useMemo(() => {
    return typeof orgs?.storage === 'number' ? `${orgs.storage} GB` : null;
  }, [orgs]);

  const [activePage, setActivePage] = React.useState(1);

  const handlePageChange = useCallback(
    (page: number) => {
      if (page === activePage) return;

      const dataLoaded = response?.orgs?.ranking.metadata.total;
      if (page * itemsPerPage >= dataLoaded) {
        makeReduxGetDashboardOrg().getDashboardOrgData({
          loadingNextPage: true,
          query: {
            limit: page * itemsPerPage,
          },
        });
      }
      setActivePage(page);
    },
    [activePage, response?.orgs?.ranking.metadata.total],
  );

  return (
    <Container>
      <CardsContainer>
        <Card>
          <CardHeader>
            <CardTitle>{Translator('Organizações')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Counter loading={loading}>{orgs?.total}</Counter>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{Translator('Armazenamento total')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Counter loading={loading}>{transformStorage}</Counter>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{Translator('Quantidade de reuniões')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Counter loading={loading}>{orgs?.conference.total}</Counter>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{Translator('Horas gravadas')}</CardTitle>
          </CardHeader>
          <CardBody>
            <Counter loading={loading}>
              {orgs?.conference.recordedHours?.toString()}
            </Counter>
          </CardBody>
        </Card>
      </CardsContainer>
      <Table itemsPerPage={itemsPerPage} activePage={activePage} />
      <div style={{ padding: `0 ${pxToRem(72)}` }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          total={orgs?.ranking.metadata.total}
          onChangePage={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default RankingOrg;
