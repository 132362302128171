import { iMyConference } from 'domain/interfaces/models/MyConference';
import { MyConferenceTypes } from 'domain/interfaces/redux/myConferences/types';

import { myConferenceActions } from './actions';

export const initialState: iMyConference = {
  loading: false,
  metadata: undefined,
  records: undefined,
};

const reducer = (
  state = initialState,
  action: myConferenceActions,
): iMyConference => {
  switch (action.type) {
    case MyConferenceTypes.LIST_MY_CONFERENCE:
      return { ...state, loading: true };
    case MyConferenceTypes.LIST_MY_CONFERENCE_FAILED:
      return { ...state, loading: false };
    case MyConferenceTypes.LIST_MY_CONFERENCE_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    default:
      return state;
  }
};

export default reducer;
