import styled from 'styled-components';

interface hasTextArea {
  isActive: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Header = styled.div`
  width: 100%;
  padding: 16px 24px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-bottom: 1px solid #dedede;

  p {
    font-size: 16px;
    color: #656a6e;
    text-align: justify;
  }
`;

export const Body = styled.div`
  background-color: #ffffff;
  width: 530px;
  border-radius: 16px;
`;

export const ReportFinishReasonContainer = styled.div<hasTextArea>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const ReportFinishReasonContent = styled.div<hasTextArea>`
  width: 100%;
  background-color: ${props => (props.isActive ? '#EBF4FF' : 'none')};
`;

export const TextAreaDiv = styled.div`
  width: 100%;
  padding: 10px;
`;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  border: 1px solid #b4b7b9;
  background: #fdfdfd;
  padding: 12px;
  outline: initial;
  line-height: 0;

  ::placeholder {
    color: #818385;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid #dedede;
  padding: 12px;
`;
