import { ListSacRequestById } from 'domain/usecases/sacRequest/remote';
import { NotFound, Forbidden, UnexpectedError } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteListSacRequestById implements ListSacRequestById {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListSacRequestById.Model>;

  constructor(url: string, httClient: HttpClient<ListSacRequestById.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  listById = async (
    params: ListSacRequestById.Params,
  ): Promise<ListSacRequestById.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.request}`,
      method: 'get',
      headers: params?.token
        ? {
            Authorization: `APIKEY ${params?.token}`,
          }
        : undefined,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
