import styled from 'styled-components';

export const Container = styled.div``;

export const UnorderedList = styled.ul``;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 4px;
`;
