import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateOrg as UsecaseRemoteCreateOrg } from 'domain/usecases/org/remote';
// import { CreateOrg as UsecaseReduxCreateOrg } from 'domain/usecases/org/redux';

import { RemoteCreateOrg } from 'data/repository/org';
// import { ReduxCreateOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateOrg = (): UsecaseRemoteCreateOrg =>
  new RemoteCreateOrg(makeApiUrl('/orgs/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateOrg =
  (): UsecaseReduxCreateOrg =>
    new ReduxCreateOrg(); */
