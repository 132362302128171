import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdatePasswordValidate as UsecaseRemoteUpdatePasswordValidate } from 'domain/usecases/password/remote';

import { RemoteUpdatePasswordValidate } from 'data/repository/password';

/**
 * send request via API.
 */
export const makeRemoteUpdatePasswordValidate =
  (): UsecaseRemoteUpdatePasswordValidate =>
    new RemoteUpdatePasswordValidate(
      makeApiUrl('/password/validate'),
      makeHttpClient(),
    );
