import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteOrg as UsecaseRemoteDeleteOrg } from 'domain/usecases/org/remote';
// import { DeleteOrg as UsecaseReduxDeleteOrg } from 'domain/usecases/org/redux';

import { RemoteDeleteOrg } from 'data/repository/org';
// import { ReduxDeleteOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteOrg = (): UsecaseRemoteDeleteOrg =>
  new RemoteDeleteOrg(makeApiUrl('/orgs/{orgId}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteOrg =
  (): UsecaseReduxDeleteOrg =>
    new ReduxDeleteOrg(); */
