import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetBillingByUser as UsecaseRemoteGetBillingByUser } from 'domain/usecases/billing/remote';

import { RemoteGetBillingByUser } from 'data/repository/billing';

/**
 * send request via API.
 */
export const makeRemoteGetBillingByUser = (): UsecaseRemoteGetBillingByUser =>
  new RemoteGetBillingByUser(
    makeApiUrl('/orgs/{orgId}/billing/user/'),
    makeHttpClient(),
  );
