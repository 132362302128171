import { ListOrgUnitAclFiltered } from 'domain/usecases/orgUnit/redux/ListOrgUnitAclFiltered';
import {
  iActionListOrgUnitAclFiltered,
  iActionListOrgUnitAclFilteredFailed,
  iActionListOrgUnitAclFilteredSuccess,
  OrgUnitTypes,
} from 'domain/interfaces/redux/orgUnit/listAclFiltered';

export const listAclFilteredRequest = (
  payload: ListOrgUnitAclFiltered.Params,
): iActionListOrgUnitAclFiltered => ({
  type: OrgUnitTypes.LIST_ACL_FILTERED,
  payload,
});

export const listAclFilteredSuccess = (
  params: ListOrgUnitAclFiltered.Model,
): iActionListOrgUnitAclFilteredSuccess => ({
  type: OrgUnitTypes.LIST_ACL_FILTERED_SUCCESS,
  payload: params,
});

export const listAclFilteredFailed =
  (): iActionListOrgUnitAclFilteredFailed => ({
    type: OrgUnitTypes.LIST_ACL_FILTERED_FAILED,
  });
