/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Tooltip } from '@material-ui/core';
import Translator from 'presentation/components/i18n/Translator';
import { withAccess } from 'presentation/hooks/access';
import React from 'react';
import {
  Actions,
  Cancel,
  CopyLink,
  Dashboard,
  Delete,
  Details,
  Download,
  EditIcon,
  Home,
  NewDocument,
  Person,
  SendWhats,
  ShareEmail,
  Start,
  Stop,
  Wait,
  IconDownload,
} from './styles/StyledActions';

interface ownProps {
  actions?: {
    details?: () => any;
    cancel?: () => any;
    person?: () => any;
    dashboard?: () => any;
    editSector?: () => any;
    delete?: () => any;
    start?: () => any;
    stop?: () => any;
    wait?: () => any;
    menuInfo?: () => any;
    newDocument?: () => any;
    shareEmail?: () => any;
    home?: () => any;
    download?: () => any;
    deleteSpecialty?: () => any;
    editSpecialty?: () => any;
    deleteClinicalDocument?: () => any;
    deleteOrgUnit?: () => any;
    copyLink?: () => any;
    sendWhats?: () => any;
    resendEmail?: () => any;
    detailsOrg?: () => any;
    detailsOrgUnit?: () => any;
    detailsConference?: () => any;
    deleteOrg?: () => any;
    deleteOrgUser?: () => any;
    deleteOrgUnitUser?: () => any;
    deleteConference?: () => any;
    viewAudit?: () => void;
    viewMyConferencesDetails?: () => void;
    downloadConference?: () => void;
  };
  enablers?: {
    isViewDetailsOrg?: boolean;
  };
}

const TooltipWithAccess = withAccess(Tooltip);

const ActionsList: React.FC<ownProps> = ({ actions, enablers }) => {
  return (
    <Actions>
      {actions?.start && (
        <TooltipWithAccess
          title={Translator('Entrar na reunião')}
          placement="top"
          arrow
          onClick={actions.start}
        >
          <div>
            <Start data-testid="start_appointment" id="start_appointment" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.home && (
        <TooltipWithAccess
          title={Translator('Acessar farmácia')}
          placement="top"
          onClick={actions.home}
          arrow
        >
          <Home data-testid="pharmacy_access" id="pharmacy_access" />
        </TooltipWithAccess>
      )}

      {actions?.details && (
        <TooltipWithAccess
          title={`${Translator('Editar usuário')}`}
          placement="top"
          arrow
          onClick={actions.details}
          resourcers={['USER']}
          actions={['UPDATE_ANY', 'UPDATE_UNIT', 'UPDATE_ORG']}
        >
          <div>
            <Details data-testid="details" id="details" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.detailsOrg && (
        <TooltipWithAccess
          title={`${
            enablers?.isViewDetailsOrg
              ? `${Translator('Ver organização')}`
              : `${Translator('Editar organização')}`
          }`}
          placement="top"
          arrow
          onClick={actions.detailsOrg}
        >
          <div>
            <Details
              data-testid="details"
              id="details"
              isView={enablers?.isViewDetailsOrg}
            />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.detailsOrgUnit && (
        <TooltipWithAccess
          title={`${Translator(
            `${enablers?.isViewDetailsOrg ? 'Ver' : 'Editar'} setor`,
          )}`}
          placement="top"
          onClick={actions.detailsOrgUnit}
          arrow
        >
          <div>
            <Details
              data-testid="details"
              id="details"
              isView={enablers?.isViewDetailsOrg}
            />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.detailsConference && (
        <TooltipWithAccess
          title={Translator('Editar reunião')}
          placement="top"
          onClick={actions.detailsConference}
          arrow
        >
          <div>
            <Details data-testid="details" id="details" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.person && (
        <TooltipWithAccess
          title={Translator('Ver perfil')}
          placement="top"
          arrow
          onClick={actions.person}
        >
          <div>
            <Person data-testid="profile_details" id="profile_details" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.download && (
        <TooltipWithAccess
          title={Translator('Baixar documento')}
          placement="top"
          onClick={actions.download}
          arrow
        >
          <Download data-testid="download_document" id="download_document" />
        </TooltipWithAccess>
      )}

      {actions?.wait && (
        <TooltipWithAccess
          title={Translator('Ir para sala de espera')}
          placement="top"
          onClick={actions.wait}
          arrow
        >
          <div>
            <Wait data-testid="join_waitingRoom" id="join_waitingRoom" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.shareEmail && (
        <TooltipWithAccess
          title={Translator('Compartilhar por email')}
          placement="top"
          onClick={actions.shareEmail}
          arrow
        >
          <div>
            <ShareEmail data-testid="share_email" id="share_email" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.newDocument && (
        <TooltipWithAccess
          title={Translator('Novo documento clínico')}
          placement="top"
          onClick={actions.newDocument}
          arrow
        >
          <div>
            <NewDocument data-testid="new_clinicalDocs" id="new_clinicalDocs" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.dashboard && (
        <TooltipWithAccess
          title={Translator('Painel')}
          placement="top"
          arrow
          onClick={actions.dashboard}
        >
          <div>
            <Dashboard data-testid="start_appointment" id="start_appointment" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.editSector && (
        <TooltipWithAccess
          title={Translator('Editar setor')}
          placement="top"
          arrow
          onClick={actions.editSector}
        >
          <div>
            <EditIcon data-testid="edit_sector" id="edit_sector" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.delete && (
        <TooltipWithAccess
          title={`${Translator('Remover usuário')}`}
          placement="top"
          arrow
          onClick={actions.delete}
          resourcers={['USER']}
          actions={['DELETE_ANY', 'DELETE_ORG', 'DELETE_UNIT']}
        >
          <div>
            <Delete data-testid="profile_delete" id="profile_delete" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteOrgUnit && (
        <TooltipWithAccess
          title={Translator('Remover setor')}
          placement="top"
          onClick={actions.deleteOrgUnit}
          arrow
        >
          <div>
            <Delete />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteOrg && (
        <TooltipWithAccess
          title={`${Translator('Remover organização')}`}
          placement="top"
          onClick={actions.deleteOrg}
          arrow
        >
          <div>
            <Delete data-testid="btn-remove-org" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteOrgUser && (
        <TooltipWithAccess
          title={Translator('Desvincular Organizador')}
          placement="top"
          onClick={actions.deleteOrgUser}
          arrow
          resourcers={['USER']}
          actions={['DELETE_ANY', 'DELETE_ORG', 'DELETE_UNIT']}
        >
          <div>
            <Delete />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteOrgUnitUser && (
        <TooltipWithAccess
          title={Translator('Desvincular Organizador do Setor')}
          placement="top"
          onClick={actions.deleteOrgUnitUser}
          arrow
          resourcers={['USER']}
          actions={['DELETE_ANY', 'DELETE_ORG', 'DELETE_UNIT']}
        >
          <div>
            <Delete />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteConference && (
        <TooltipWithAccess
          title={Translator('Remover reunião')}
          placement="top"
          onClick={actions.deleteConference}
          arrow
        >
          <div>
            <Delete />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.cancel && (
        <TooltipWithAccess
          title={Translator('Cancelar reunião')}
          placement="top"
          onClick={actions.cancel}
          arrow
        >
          <div>
            <Cancel data-testid="cancel_appointment" id="cancel_appointment" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.stop && (
        <TooltipWithAccess
          title={Translator('Finalizar atendimento')}
          placement="top"
          onClick={actions.stop}
          arrow
        >
          <Stop data-testid="finish_appointment" id="finish_appointment" />
        </TooltipWithAccess>
      )}

      {actions?.editSpecialty && (
        <TooltipWithAccess
          title={Translator('Editar especialidade')}
          placement="top"
          onClick={actions.editSpecialty}
          arrow
        >
          <div>
            <EditIcon data-testid="edit_specialty" id="edit_specialty" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteSpecialty && (
        <TooltipWithAccess
          title={Translator('Remover especialidade')}
          placement="top"
          onClick={actions.deleteSpecialty}
          arrow
        >
          <div>
            <Delete data-testid="delete_specialty" id="delete_specialty" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.copyLink && (
        <TooltipWithAccess
          title={Translator('Copiar link de atendimento')}
          placement="top"
          arrow
          onClick={actions.copyLink}
        >
          <div>
            <CopyLink data-testid="copy_link" id="copy_link" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.resendEmail && (
        <TooltipWithAccess
          title={Translator('Reenviar email')}
          placement="top"
          arrow
          onClick={actions.resendEmail}
        >
          <div>
            <ShareEmail data-testid="resend_email" id="resend_email" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.sendWhats && (
        <TooltipWithAccess
          title={Translator('Enviar convite por Whatsapp')}
          placement="top"
          arrow
          onClick={actions.sendWhats}
        >
          <div>
            <SendWhats data-testid="send_whats" id="send_whats" />
          </div>
        </TooltipWithAccess>
      )}

      {actions?.deleteClinicalDocument && (
        <TooltipWithAccess
          title={Translator('Remover documento')}
          placement="top"
          onClick={actions.deleteClinicalDocument}
          arrow
        >
          <div>
            <Delete />
          </div>
        </TooltipWithAccess>
      )}
      {actions?.viewAudit && (
        <TooltipWithAccess
          title={Translator('Detalhes da auditoria')}
          placement="top"
          arrow
          onClick={actions?.viewAudit}
        >
          <div>
            <Details data-testid="audit_details" id="audit_details" isView />
          </div>
        </TooltipWithAccess>
      )}
      {actions?.viewMyConferencesDetails && (
        <TooltipWithAccess
          title={Translator('Detalhes da conferência')}
          placement="top"
          arrow
          onClick={actions?.viewMyConferencesDetails}
        >
          <div>
            <Details
              data-testid="conference_details"
              id="conference_details"
              isView
            />
          </div>
        </TooltipWithAccess>
      )}
      {actions?.downloadConference && (
        <TooltipWithAccess
          title={Translator('Baixar conferência')}
          placement="top"
          arrow
          onClick={actions?.downloadConference}
        >
          <IconDownload />
        </TooltipWithAccess>
      )}
    </Actions>
  );
};
export default ActionsList;
