import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateNameConferenceUser as UsecaseRemoteUpdateNameConferenceUser } from 'domain/usecases/conferenceUser/remote';
// import { UpdateNameConferenceUser as UsecaseReduxUpdateNameConferenceUser } from 'domain/usecases/conferenceUser/redux';

import { RemoteUpdateNameConferenceUser } from 'data/repository/conferenceUser';
// import { ReduxUpdateNameConferenceUser } from 'data/store/reducer/conferenceUser/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateNameConferenceUser =
  (): UsecaseRemoteUpdateNameConferenceUser =>
    new RemoteUpdateNameConferenceUser(
      makeApiUrl('/conferences/users/{confirmCode}/name'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateNameConferenceUser =
  (): UsecaseReduxUpdateNameConferenceUser =>
    new ReduxUpdateNameConferenceUser(); */
