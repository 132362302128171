import { ListRecordingsConference } from 'domain/usecases/conference/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteListRecordingsConference
  implements ListRecordingsConference
{
  private readonly url: string;

  private readonly httpClient: HttpClient<ListRecordingsConference.Model>;

  constructor(
    url: string,
    httClient: HttpClient<ListRecordingsConference.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  listRecordings = async (
    params: ListRecordingsConference.Params,
  ): Promise<ListRecordingsConference.Model> => {
    let query = '';

    if (params.query) {
      Object.entries(params.query).forEach(([key, value]) => {
        query += `${key}=${value}&`;
      });
    }

    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace(
        '{conferenceId}',
        `${params.conferenceId}`,
      )}?${query}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
      case HttpStatusCode.notModified:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
