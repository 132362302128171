import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListSacRequest as UsecaseRemoteListSacRequest } from 'domain/usecases/sacRequest/remote';
import { ListSacRequest as UsecaseReduxListSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteListSacRequest } from 'data/repository/sacRequest';
import { ReduxListSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteListSacRequest = (): UsecaseRemoteListSacRequest =>
  new RemoteListSacRequest(makeApiUrl('/sac/requests/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListSacRequest = (): UsecaseReduxListSacRequest =>
  new ReduxListSacRequest();
