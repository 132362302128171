import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { TransferSacRequest as UsecaseRemoteTransferSacRequest } from 'domain/usecases/sacRequest/remote';
// import { TransferSacRequest as UsecaseReduxTransferSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteTransferSacRequest } from 'data/repository/sacRequest';
// import { ReduxTransferSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteTransferSacRequest =
  (): UsecaseRemoteTransferSacRequest =>
    new RemoteTransferSacRequest(
      makeApiUrl('/sac/requests/{request}/TRANSFER'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxTransferSacRequest =
  (): UsecaseReduxTransferSacRequest =>
    new ReduxTransferSacRequest(); */
