import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  background: white;
  /* border-color: #0565D9; */
  border: 1px solid #0565d9;
  cursor: pointer;
  border-radius: 5px;

  accent-color: #0565D9;

  &:checked {
    background: #0565D9;
  }

  &:active {
    background: #0565D9;
  }
`;

interface CheckboxRoundProps {
  checked: boolean;
}

export const CheckboxRound = styled.div<CheckboxRoundProps>`
  width: 10px;
  height: 10px;
  /* background: white; */
  background: ${({ checked }) => (checked ? '#0565D9' : 'transparent')};
`;

export const Label = styled.label`
  font-size: 14px;
  weight: 400;
  color: #222529;
`;
