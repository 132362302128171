import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  #meet {
    width: 100vw;
    height: 100vh;
  }

  iframe {
    width: 100vw !important;
    height: 100vh !important;
  }
`;
