import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { UpdateControllerConference as UsecaseReduxUpdateControllerConference } from 'domain/usecases/conference/redux';

import { ReduxUpdateControllerConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via REDUX.
 */
export const makeReduxUpdateControllerConference =
  (): UsecaseReduxUpdateControllerConference =>
    new ReduxUpdateControllerConference();
