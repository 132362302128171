import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { LeftConferenceWaitingRoom as UsecaseRemoteLeftConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/remote';
// import { LeftConferenceWaitingRoom as UsecaseReduxLeftConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

import { RemoteLeftConferenceWaitingRoom } from 'data/repository/conferenceWaitingRoom';
// import { ReduxLeftConferenceWaitingRoom } from 'data/store/reducer/conferenceWaitingRoom/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeftConferenceWaitingRoom =
  (): UsecaseRemoteLeftConferenceWaitingRoom =>
    new RemoteLeftConferenceWaitingRoom(
      makeApiUrl('/conferences/{conferenceShort}/waitingRoom/left'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeftConferenceWaitingRoom =
  (): UsecaseReduxLeftConferenceWaitingRoom =>
    new ReduxLeftConferenceWaitingRoom(); */
