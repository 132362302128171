/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Typography } from '@wisecare-tech/design-system-web';
import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';
import {
  Container,
  ListItem,
  UnorderedList,
} from './styles/StyledScheduleList';

interface iScheduleListProps {
  schedule: GetPublicTopicById.Model['schedule'];
}

export const ScheduleList: React.FC<iScheduleListProps> = ({ schedule }) => {
  const daysOfWeek: { [key: string]: string } = {
    MO: 'Segunda',
    TU: 'Terça',
    WE: 'Quarta',
    TH: 'Quinta',
    FR: 'Sexta',
    ST: 'Sábado',
    SU: 'Domingo',
  };

  const formatSchedule = (
    weekSchedules: GetPublicTopicById.Model['schedule'],
  ): string[] => {
    const sortedSchedule = weekSchedules?.sort((a, b) => a.index - b.index);

    const formattedSchedule = sortedSchedule?.map(item => {
      const dayName = daysOfWeek[item.day];
      const times = item.times
        .map(
          time => `${time.start.slice(0, -3)} às ${time.finish.slice(0, -3)}`,
        )
        .join(', ');
      return `${dayName}: ${times}`;
    });

    return formattedSchedule;
  };

  const schedulesList = formatSchedule(schedule);

  return (
    <Container>
      <UnorderedList>
        {schedulesList?.map((formattedDay, index) => (
          <ListItem key={index}>
            <Typography variant="b4_14regular">{formattedDay}</Typography>
          </ListItem>
        ))}
      </UnorderedList>
    </Container>
  );
};
