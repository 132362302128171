import styled from 'styled-components';

export const Container = styled.div`
  width: 410px;
  height: 100%;
  border: 1px solid #dedede;
  background-color: #ffffff;
`;

export const Header = styled.header`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22.5px 24px 22.5px 0;
  border-bottom: 1px solid #dedede;
`;

export const Subject = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Label = styled.span`
  ::after {
    content: '*';
    color: #ff0000;
  }
`;

export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #ff0000;
  gap: 8px;
`;
