/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { iMessage } from 'domain/interfaces/models';
import { IconInfoBlue } from 'presentation/base/icons';
import { closeModal } from 'utils/closeModal';
import { History } from 'main/routes';
import {
  Body,
  Container,
  Footer,
  ModalContent,
} from './styles/StyledAppointmentWithdrew';
import { Button } from '../../UI';

export interface propsModalAppointmentWithdrew {
  message: iMessage;
}

const AppointmentWithdrew: React.FC<propsModalAppointmentWithdrew> = ({
  message,
}) => {
  const msgName = MessageOptions.appointmentWithdrew;

  const { active, actionOk, actionCancel } = message;

  // const goTo = History.push('/home');

  // useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (e.key === 'Escape') {
  //       actionCancel?.();
  //     }

  //     if (e.key === 'Enter') {
  //       actionOk?.()(goTo);
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <ModalContent>
              <div>
                <IconInfoBlue />
              </div>
              <div className="text-div">
                <h3>O solicitante desistiu do atendimento.</h3>
                <p>
                  Clicando no botão abaixo, você será redirecionado para a tela
                  inicial do SAC.
                </p>
              </div>
            </ModalContent>
            <Footer>
              <Button variant="primary" size="medium" onClick={actionOk}>
                Voltar para o SAC
              </Button>
            </Footer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default AppointmentWithdrew;
