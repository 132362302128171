import * as Yup from 'yup';

export const passwordResetSchema = Yup.object()
  .shape({
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.'),
    confirmPassword: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
      .oneOf([Yup.ref('password')], 'As senhas não conferem'),
  })
  .required();
