/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconErrorInput, IconV4Hlogo } from 'presentation/base/icons';
import { withAccess } from 'presentation/hooks/access';
import React, { forwardRef, useState } from 'react';
import InputMask from 'react-input-mask';

import { Container, InputContainer, Label, Message } from './styles';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string;
  label?: string;
  isLabelBold?: boolean;
  className?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  required?: boolean;
  mask?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  backgroundColor?: string;
  iconRef?: React.RefObject<any>;
  onClick?: () => void;
  register?: () => any;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    isLabelBold = false,
    message,
    className,
    error = false,
    width = '100%',
    height = '40px',
    required = false,
    mask = '',
    icon,
    register,
    onClick,
    value,
    onChange,
    onBlur,
    disabled,
    iconRef,
    backgroundColor,
    ...rest
  },
  ref,
) => {
  const [focus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';
  // const { disabled } = rest;

  const Logo = icon || IconV4Hlogo;

  // if the component are use react-hook-form.
  if (register) register();

  let inputLabel = label;
  if (required) inputLabel = `${label}*`;

  return (
    <Container width={width} className={className}>
      {label && (
        <Label isLabelBold={isLabelBold} disabled={disabled}>
          {inputLabel}
        </Label>
      )}
      <InputContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
        backgroundColor={backgroundColor}
      >
        <InputMask
          mask={mask}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        >
          {() => (
            <input
              {...rest}
              value={value}
              onChange={onChange}
              disabled={disabled}
              ref={ref}
            />
          )}
        </InputMask>
        {icon && (
          <div
            ref={iconRef}
            onClick={() => onClick?.()}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Logo className="icon" style={{ cursor: 'pointer' }} />
          </div>
        )}
        {error && <IconErrorInput className="icon" />}
      </InputContainer>
      {error && <Message error={error}>{message}</Message>}
    </Container>
  );
};

export default withAccess(forwardRef(Input));
