export function mapperRoles(role: String): string {
  switch (role) {
    case 'SYSADMIN':
      return 'Administrador do Sistema';
    case 'ORG_ADMIN':
      return 'Administrador da Organização';
    case 'UNIT_ADMIN':
      return 'Organizador do Setor';
    case 'STANDARD':
      return 'Usuário Padrão';
    case 'MODERATOR':
      return 'Moderador';
    case 'ATTENDEE':
      return 'Convidado';
    default:
      return 'Error';
  }
}

export function mapperConferenceRoles(role: String): string {
  switch (role) {
    case 'MODERATOR':
      return 'Moderador';
    case 'ATTENDEE':
      return 'Convidado';
    default:
      return 'Convidado';
  }
}
