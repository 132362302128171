import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  background: white;
  /* border-color: #0565D9; */
  border-radius: 50%;
  border: 1px solid #0565d9;
  cursor: pointer;
`;

interface RadioRoundProps {
  checked: boolean;
}

export const RadioRound = styled.div<RadioRoundProps>`
  width: 10px;
  height: 10px;
  /* background: white; */
  background: ${({ checked }) => (checked ? '#0565D9' : 'transparent')};
  border-radius: 50%;
`;
