import styled from 'styled-components';

export const Container = styled.div`
  min-width: 360px;
  max-width: 360px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar-track {
    margin: 12px 0px !important;
  }
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #dedede;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  width: 100%;
  row-gap: 16px;
  /* max-height: 60svh; */
  height: 77%;
`;

export const ConsultInfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 8px;
  text-align: start;
`;

export const DataGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding: 8px 0 8px 0;

  // lastt child border none
  &:last-child {
    border-bottom: none;
  }
`;

export const ScrollContainer = styled.div`
  padding: 0px 22px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* text-wrap: balance; */
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  overflow-wrap: anywhere;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  /* padding: 24px; */
  padding: 18px 24px 12px 24px;
  border-top: 1px solid #dedede;
  background: #f8f8f8;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldSmall = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222529;
`;

export const TextNormalSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components

export const Loader = styled.div`
  width: 24px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #818385 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;

  @keyframes l7 {
    33% {
      background-size: calc(100% / 3) 0%, calc(100% / 3) 100%,
        calc(100% / 3) 100%;
    }
    50% {
      background-size: calc(100% / 3) 100%, calc(100% / 3) 0%,
        calc(100% / 3) 100%;
    }
    66% {
      background-size: calc(100% / 3) 100%, calc(100% / 3) 100%,
        calc(100% / 3) 0%;
    }
  }
`;
