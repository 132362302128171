import { iAudit } from 'domain/interfaces/models/Audit';
import { AuditTypes } from 'domain/interfaces/redux/audit/types';
import { AuditActions } from './actions';

export const initialState: iAudit = {
  loading: false,
};

const reducer = (state = initialState, action: AuditActions): iAudit => {
  switch (action.type) {
    case AuditTypes.LISTLOGS:
      return { ...state, loading: true };
    case AuditTypes.LISTLOGS_FAILED:
      return { ...state, loading: false };
    case AuditTypes.LISTLOGS_SUCCESS: {
      return { ...state, loading: false, logs: action.payload };
    }
    case AuditTypes.LISTGROUPS:
      return { ...state, loading: true };
    case AuditTypes.LISTGROUPS_FAILED:
      return { ...state, loading: false };
    case AuditTypes.LISTGROUPS_SUCCESS: {
      return { ...state, loading: false, groups: action.payload };
    }
    case AuditTypes.LISTACTIONS:
      return { ...state, loading: true };
    case AuditTypes.LISTACTIONS_FAILED:
      return { ...state, loading: false };
    case AuditTypes.LISTACTIONS_SUCCESS: {
      return { ...state, loading: false, actions: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
