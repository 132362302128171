import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const List = styled.div`
  width: 100%;
  margin-top: 14px;
`;

export const Drop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: black;
  border: none;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 18px 20px;
  gap: 18px;

  :hover {
    background: ${({ theme }) => `${theme.colors.primary.main}1a`};
  }
`;
