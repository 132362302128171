import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgUserByOrgUnitId } from 'domain/interfaces/redux/orgUser/listByOrgUnitId';
import { ListByOrgUnitOrgUser } from 'domain/usecases/orgUser/remote/ListByOrgUnitOrgUser';
import { makeRemoteListByOrgUnitOrgUser } from 'main/factories/usecases/orgUser/ListByOrgUnitOrgUserFactory';
import {
  listSuccess,
  listFailed,
} from 'data/store/reducer/orgUsers/actions/listByOrgUnitId';

export function* onListByUnitId(action: iActionListOrgUserByOrgUnitId) {
  const remoteListOrgUserByOrgUnitId = makeRemoteListByOrgUnitOrgUser();

  try {
    const response: ListByOrgUnitOrgUser.Model = yield call(
      remoteListOrgUserByOrgUnitId.listByOrgUnit,
      action.payload,
    );

    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByUnitIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListByUnitIdFailed() {}
