import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetRegistry as UsecaseRemoteGetRegistry } from 'domain/usecases/registry/remote';

import { RemoteGetRegistry } from 'data/repository/registry';

/**
 * send request via API.
 */
export const makeRemoteGetRegistry = (): UsecaseRemoteGetRegistry =>
  new RemoteGetRegistry(
    makeApiUrl('/conferences/{conferenceId}/registry'),
    makeHttpClient(),
  );
