import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';

import { makeReduxSetData } from 'main/factories/usecases/message/SetData';
import { makeReduxCloseModal } from 'main/factories/usecases/modal/CloseModalFactory';

import {
  Container,
  Body,
  Title,
  ItemRole,
  Itens,
} from './styles/StyledOrgItem';

interface ownProps {
  title: string;
  role: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD';
  /* orgId: number;
  orgUnitId: number; */
  setSelected: (sel: any) => void;
  selected: { orgIdx: number; unitIdx: number };
  selector: { orgIdx: number; unitIdx: number };
}

export default function OrgItem(props: ownProps): JSX.Element {
  const title =
    props.title.charAt(0).toLocaleUpperCase() + props.title.slice(1);

  const roleName = {
    SYSADMIN: 'Administrador do sistema',
    ORG_ADMIN: 'Administrador da organização',
    UNIT_ADMIN: 'Organizador',
    STANDARD: 'Usuário Padrão',
  };

  const { user, refresh } = useSelector((store: iStore) => store.auth);

  return (
    <Container
      id={`${props.selector.orgIdx}-${title}`}
      selected={
        props.selected.orgIdx === props.selector.orgIdx &&
        props.selected.unitIdx === props.selector.unitIdx
      }
      onClick={() => {
        props.setSelected(props.selector);
        if (refresh.token && user) {
          /* const body: any = {
            refreshToken: refresh.token,
            org: props.orgId,
            user: user?.id,
          };

          if (props.orgUnitId !== -1) body.orgUnit = props.orgUnitId;

          makeReduxRefreshAuth().refresh({ body });
          makeReduxCloseModal().close({}); */
        }
      }}
    >
      <Body>
        <Title>
          {` ${title}`}
          <span>{` (${roleName[props.role]})`}</span>
        </Title>

        {/* /* makeReduxSelectUser().update({
                    orgId: props.orgId,
                    roleId: role.id,
                    role: role.role,
                  }); */
        /* makeReduxSetData().setData({
                data: {
                  orgUnitId: props.orgUnitId,
                  orgId: props.orgId,
                  roleId: role.id,
                  role: role.role,
                },
              }); */}
      </Body>
    </Container>
  );
}
