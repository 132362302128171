import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListLogsAudit as UsecaseRemoteListLogsAudit } from 'domain/usecases/audit/remote';
import { ListLogsAudit as UsecaseReduxListLogsAudit } from 'domain/usecases/audit/redux';

import { RemoteListLogsAudit } from 'data/repository/audit';
import { ReduxListLogsAudit } from 'data/store/reducer/audit/usecases';

/**
 * send request via API.
 */
export const makeRemoteListLogsAudit = (): UsecaseRemoteListLogsAudit =>
  new RemoteListLogsAudit(makeApiUrl('/audit/logs'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListLogsAudit = (): UsecaseReduxListLogsAudit =>
  new ReduxListLogsAudit();
