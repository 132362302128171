import { takeLatest } from 'redux-saga/effects';
import { MyConferenceTypes } from 'domain/interfaces/redux/myConferences/types';
import {
  onListMyConference,
  onListMyConferenceSuccess,
  onListMyConferenceFailed,
} from './ListMyConferences';

const tasks = [
  takeLatest(MyConferenceTypes.LIST_MY_CONFERENCE, onListMyConference),
  takeLatest(
    MyConferenceTypes.LIST_MY_CONFERENCE_SUCCESS,
    onListMyConferenceSuccess,
  ),
  takeLatest(
    MyConferenceTypes.LIST_MY_CONFERENCE_FAILED,
    onListMyConferenceFailed,
  ),
];

export default tasks;
