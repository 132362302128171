import { call, put, select } from 'redux-saga/effects';
import { iActionListMyConference } from 'domain/interfaces/redux/myConferences/myConference';
import { ListMyConferences } from 'domain/usecases/myConferences/remote/ListMyConferences';
import { makeRemoteListMyConferences } from 'main/factories/usecases/myConferences/ListMyConferencesFactory';
import {
  listMyConferenceFailed,
  listMyConferenceRequest,
  listMyConferenceSuccess,
} from 'data/store/reducer/myConference/actions/listMyConferences';

export function* onListMyConference(action: iActionListMyConference) {
  const remoteListMyConferences = makeRemoteListMyConferences();

  try {
    const response: ListMyConferences.Model = yield call(
      remoteListMyConferences.list,
      action.payload,
    );
    yield put(
      listMyConferenceSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listMyConferenceFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListMyConferenceSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListMyConferenceFailed() {}
