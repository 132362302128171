import { ListConferenceFilteredByACLV2 } from 'domain/usecases/conference/redux/ListConferenceFilteredByACLV2';
import {
  iActionListFilteredByACLV2,
  iActionListFilteredByACLV2Failed,
  iActionListFilteredByACLV2Success,
  ConferenceTypes,
} from 'domain/interfaces/redux/conference/listFilteredByACLV2';

export const listFilteredByACLV2Request = (
  payload: ListConferenceFilteredByACLV2.Params,
): iActionListFilteredByACLV2 => ({
  type: ConferenceTypes.LIST_FILTERED_BY_ACL_V2,
  payload,
});

export const listFilteredByACLV2Success = (
  params: ListConferenceFilteredByACLV2.Model,
): iActionListFilteredByACLV2Success => ({
  type: ConferenceTypes.LIST_FILTERED_BY_ACL_V2_SUCCESS,
  payload: params,
});

export const listFilteredByACLV2Failed =
  (): iActionListFilteredByACLV2Failed => ({
    type: ConferenceTypes.LIST_FILTERED_BY_ACL_V2_FAILED,
  });
