import React, { useEffect } from 'react';
import { Admin } from 'presentation/pages';
import { makeReduxListOrgAclFilteredV2 } from '../../usecases/org/ListOrgAclFilteredV2Factory';
import { makeReduxListOrgUnitAclFilteredV2 } from '../../usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';

export const MakeAdminFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListOrgAclFilteredV2().list({
      limit: 9999,
    });

    makeReduxListOrgUnitAclFilteredV2().list({
      limit: 9999,
    });
  }, []);

  return <Admin />;
};
