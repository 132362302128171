import styled from 'styled-components';
import { withAccess } from 'presentation/hooks/access';

export const Container = styled.div`
  width: 100%;
  min-height: 18.3rem;
`;

export const Fields = styled.div`
  width: 100%;
  height: 100%;

  padding-top: 30px;
  gap: 38px;

  display: flex;
`;

export const ContainerCheckbox = withAccess(styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 11px;
  color: #a6a6a6;

  input {
    accent-color: ${({ theme }) => theme.colors.primary.main};
  }
`);
