import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateUser as UsecaseRemoteUpdateUser } from 'domain/usecases/user/remote';
import { UpdateUser as UsecaseReduxUpdateUser } from 'domain/usecases/user/redux';

import { RemoteUpdateUser } from 'data/repository/user';
import { ReduxUpdateUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateUser = (): UsecaseRemoteUpdateUser =>
  new RemoteUpdateUser(makeApiUrl('/users/{userId}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxUpdateUser = (): UsecaseReduxUpdateUser =>
  new ReduxUpdateUser();
