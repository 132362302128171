import { HttpClient, HttpStatusCode } from 'data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
  Forbidden,
} from 'domain/errors';
import { CreateFreemium } from 'domain/usecases/org/remote';

export class RemoteCreateFreemium implements CreateFreemium {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateFreemium.Model>;

  constructor(url: string, httpClient: HttpClient<CreateFreemium.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  create = async (
    params: CreateFreemium.Params,
  ): Promise<CreateFreemium.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });

    console.log('CreateFreemium orgs response: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
