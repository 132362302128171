/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface FormattedDateTime {
  date: string;
  hour: string;
}


export function formatISODateHour(timestamp?: string): FormattedDateTime {
  if (!timestamp) return { date: '-', hour: '-' };
  const date = new Date(timestamp);

  const formattedDate = format(date, 'dd/MM/yyyy', { locale: ptBR });
  const formattedHour = format(date, 'HH:mm', { locale: ptBR });

  return {
    date: formattedDate,
    hour: formattedHour,
  };
}
