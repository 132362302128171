import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CloseSacRequest as UsecaseRemoteCloseSacRequest } from 'domain/usecases/sacRequest/remote';
// import { CloseSacRequest as UsecaseReduxCloseSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteCloseSacRequest } from 'data/repository/sacRequest';
// import { ReduxCloseSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteCloseSacRequest = (): UsecaseRemoteCloseSacRequest =>
  new RemoteCloseSacRequest(
    makeApiUrl('/sac/requests/{request}/CLOSE'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxCloseSacRequest =
  (): UsecaseReduxCloseSacRequest =>
    new ReduxCloseSacRequest(); */
