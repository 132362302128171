import { Avatar, AvatarGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { number } from 'yup/lib/locale';
import { GetUserDataDashboard } from 'domain/usecases/dashboard/remote';
import {
  IconCardArrowDown,
  IconCardArrowUp,
  IconCardUsersOnline,
} from 'presentation/base/icons';

import Translator from 'presentation/components/i18n/Translator';
import {
  Container,
  Card,
  CardContainer,
  CardsGroup,
  PieContainer,
  PieLegend,
  LegendItem,
} from './styles/styledUserData';

const COLORS = ['#7872EC', '#E16052', '#70B76F', '#4B9EF4'];

interface ownProps {
  userData: GetUserDataDashboard.Model;
}

const UserData: React.FC<ownProps> = ({ userData }): JSX.Element => {
  const [pieData, setPieData] = React.useState<
    { name: string; value: number }[]
  >([]);

  const renderVariation = (value: number | undefined): JSX.Element => {
    if (!value) return <div />;
    return value > 0 ? <IconCardArrowUp /> : <IconCardArrowDown />;
  };

  useEffect(() => {
    if (userData.users) {
      setPieData([
        {
          name: Translator('Usuários padrão'),
          value: userData.users.standards,
        },
        {
          name: Translator('Organizadores'),
          value: userData.users.unitAdmins,
        },
        {
          name: Translator('Administradores'),
          value: userData.users.orgAdmins,
        },
        {
          name: Translator('Administradores de sistema'),
          value: userData.users.systemAdmins,
        },
      ]);
    }
  }, [userData]);

  return (
    <Container>
      <CardContainer>
        <Card width="50%" height="244px" breakpoint="1140px">
          <PieContainer>
            <PieChart width={185} height={185}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={60}
                outerRadius={80}
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="none"
                  />
                ))}
                <Label
                  width={30}
                  position="center"
                  fill="#233242"
                  fontSize="24px"
                  fontWeight="500"
                  transform="translate(0, -10)"
                >
                  {userData.users?.total}
                </Label>
                <Label
                  width={30}
                  position="center"
                  fill="#436385"
                  fontSize="20px"
                  fontWeight="400"
                  transform="translate(0, 10)"
                >
                  {Translator('Usuários')}
                </Label>
              </Pie>
            </PieChart>
            <PieLegend>
              {pieData.map((entry, index) => (
                <LegendItem color={COLORS[index % COLORS.length]} border>
                  <div className="name">
                    <div className="color" />
                    <span>{entry.name}</span>
                  </div>
                  {entry.value}
                </LegendItem>
              ))}
            </PieLegend>
          </PieContainer>
        </Card>
        <CardsGroup>
          <Card className="card" width="48%" height="114px">
            <div className="header">
              <div className="title">{Translator('Novos usuários')}</div>
            </div>
            <div className="footer">
              <div className="value">{userData.users?.new.total}</div>
              {userData?.users?.new.increaseRate !== 0 && (
                <div className="percent">
                  {renderVariation(userData.users?.new.increaseRate)}
                  {`${userData.users?.new.increaseRate}%`}
                </div>
              )}
            </div>
          </Card>
          <Card className="card" width="48%" height="114px">
            <div className="header">
              <div className="title">{Translator('Logados')}</div>
              <span>
                <IconCardUsersOnline style={{ marginRight: '8px' }} />
                {Translator('Agora')}
              </span>
            </div>
            <div className="footer">
              <div className="value">{userData.users?.loggedIn.total}</div>
              <AvatarGroup max={userData?.users?.loggedIn.total}>
                {userData?.users?.loggedIn?.avatars?.map(avatar => (
                  <Avatar alt="Avatar" src={avatar} />
                ))}
              </AvatarGroup>
            </div>
          </Card>
          <Card className="card" width="48%" height="114px">
            <div className="header">
              <div className="title">{Translator('Usuários ativos')}</div>
            </div>
            <div className="footer">
              <div className="value">{userData.users?.active.total}</div>
              {userData?.users?.active.increaseRate !== 0 && (
                <div className="percent">
                  {renderVariation(userData.users?.active.increaseRate)}
                  {`${userData.users?.active.increaseRate}%`}
                </div>
              )}
            </div>
          </Card>
          <Card className="card" width="48%" height="114px">
            <div className="header">
              <div className="title">{Translator('Sem atividade')}</div>
            </div>
            <div className="footer">
              <div className="value">{userData.users?.inactive.total}</div>
              {userData?.users?.inactive.increaseRate !== 0 && (
                <div className="percent">
                  {renderVariation(userData.users?.inactive.increaseRate)}
                  {`${userData.users?.inactive.increaseRate}%`}
                </div>
              )}
            </div>
          </Card>
        </CardsGroup>
      </CardContainer>
    </Container>
  );
};

export default UserData;
