/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect } from 'react';
import * as dateFns from 'date-fns';
import { ConfirmConference } from 'domain/usecases/conference/remote';
import Translator from 'presentation/components/i18n/Translator';
import {
  IconLogo,
  IconLogoMini,
  ImageConfirmPage,
} from 'presentation/base/icons';
import { Button, Input } from 'presentation/components/UI';

import { makeRemoteUpdateNameConferenceUser } from 'main/factories/usecases/conferenceUser/UpdateNameConferenceUserFactory';
import { toast } from 'react-toastify';
import { ThemeContext } from 'App';
import { Container, Box, Title, Form, Info } from './styles/ConfirmMeetStyled';

interface ownProps {
  confirmCode: string;
  response: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE';
  data?: ConfirmConference.Model;
}

const ConfirmMeet: React.FC<ownProps> = ({
  confirmCode,
  response,
  data,
}): JSX.Element => {
  const [fullname, setFullname] = React.useState<string>(
    data?.participant?.fullName || '',
  );
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (data?.participant?.fullName) setFullname(data?.participant?.fullName);
  }, [data]);

  const renderMessage = () => {
    if (response === 'ACCEPTED') {
      return (
        <>
          Você
          <span> confirmou </span>
          sua participação na {Translator('reunião')} abaixo.
        </>
      );
    }

    return (
      <>
        Você confirmou que
        {response === 'DECLINED' ? (
          <span> não poderá </span>
        ) : (
          <>
            <span> não tem certeza </span>
            {'se poderá '}
          </>
        )}
        participar da {Translator('reunião')} abaixo.
      </>
    );
  };

  const handleSubmit = () => {
    makeRemoteUpdateNameConferenceUser()
      .updateName({
        confirmCode,
        body: { fullName: fullname },
      })
      .then(res => {
        toast.success(Translator('Nome atualizado com sucesso!'));
      })
      .catch(err => {
        // toast.error('Erro ao atualizar nome!');
      });
  };

  return (
    <Container>
      <Box>
        {theme.images.logo.opacity && (
          <img src={theme.images.logo.opacity} alt="Logo" />
        )}
        <Title response={response}>
          {renderMessage()}
          <div className="info">
            Caso mude de planos, você poderá escolher outra opção de confirmação
            pelo e-mail recebido.
          </div>
        </Title>
        <Form>
          <Input
            data-testid="input-title"
            className="input"
            label={fullname ? `${Translator('Nome')}` : 'Seu nome'}
            name="title"
            placeholder="Digite aqui seu nome"
            autoFocus
            height="40px"
            width="340px"
            disabled={data?.participant?.isInternalUser}
            value={fullname}
            onChange={e => setFullname(e.target.value)}
          />
          {fullname && (
            <Button
              size="small"
              height="40px"
              disabled={data?.participant?.isInternalUser}
              onClick={() => {
                handleSubmit();
              }}
            >
              Salvar
            </Button>
          )}
        </Form>
        <Info>
          <div className="main">
            <div>
              <span className="title">Link da {Translator('reunião')}</span>
              <br />
              <span className="link">{data?.conference.link}</span>
            </div>
            <div>
              <span className="title">Participantes</span>
              <div className="response">
                <span className="invite">
                  {`${data?.conference.participants.invited} `}
                </span>
                convidados,
                <span className="confirmed">
                  {` ${data?.conference.participants.confirmed} `}
                </span>
                confirmados,
                <span className="recused">
                  {` ${data?.conference.participants.declined} `}
                </span>
                não,
                <span className="maybe">
                  {` ${data?.conference.participants.maybe} `}
                </span>
                talvez,
                <span className="waiting">
                  {` ${data?.conference.participants.pending} `}
                </span>
                pendentes
              </div>
            </div>
          </div>
          <div className="general">
            <div className="title">Informações gerais</div>
            <div className="info">
              <span>
                <strong>Título: </strong>
                {data?.conference.title}
              </span>
              {/* <span>
                <strong>Link da reunião: </strong>
                {data?.conference.link}
              </span> */}
              <span>
                <strong>Date e hora: </strong>
                {data &&
                  `${dateFns.format(
                    dateFns.addHours(new Date(data?.conference.scheduled), 3),
                    'dd-MM-yyyy, HH:mm',
                  )}
                   às 
                  ${dateFns.format(
                    dateFns.addHours(
                      new Date(data?.conference.expectedEndTime),
                      3,
                    ),
                    'HH:mm',
                  )}`}
              </span>
              <span>
                <strong>Agendada por: </strong>
                {data?.conference.owner}
              </span>
              <span>
                <strong>Descrição: </strong>
                {data?.conference.descr}
              </span>
            </div>
          </div>
        </Info>
      </Box>

      <ImageConfirmPage />
    </Container>
  );
};

export default ConfirmMeet;
