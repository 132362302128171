import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import Table, { tableClasses } from '@mui/material/Table';
import TableBody, { tableBodyClasses } from '@mui/material/TableBody';
import TableHead, { tableHeadClasses } from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableFooter, { tableFooterClasses } from '@mui/material/TableFooter';
import TablePagination, {
  tablePaginationClasses,
} from '@mui/material/TablePagination';
import TableContainer, {
  tableContainerClasses,
} from '@mui/material/TableContainer';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { pxToRem } from 'utils/pixelToRem';

export const StyledTable = styledMui(Table)(({ theme }) => ({
  borderRadius: pxToRem(8),
  borderCollapse: 'collapse',
  maxHeight: '30%',
  [`&.${tableClasses.stickyHeader}`]: {},
}));

export const StyledTableHead = styledMui(TableHead)(({ theme }) => ({}));

export const StyledTableBody = styledMui(TableBody)(({ theme }) => ({
  [`&.${tableBodyClasses.root}`]: {
    border: '0.5px solid rgba(105, 127, 150, 0.25)',
    // make a border radius for the first and last cell
  },
}));

export const StyledTableFooter = styledMui(TableFooter)(({ theme }) => ({
  [`&.${tableFooterClasses.root}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StyledTablePagination = styledMui(TablePagination)(
  ({ theme }) => ({
    [`&.${tablePaginationClasses.root}`]: {
      width: '100%',
      color: '#697f96',
      fontSize: pxToRem(14),
      fontWeight: '400',
      lineHeight: pxToRem(16),
      padding: `0 ${pxToRem(72)}`,
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
    },
  }),
);

export const StyledTableCell = styledMui(TableCell)(({ theme, className }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: pxToRem(14),
    fontWeight: '600',
    lineHeight: pxToRem(16),
    color: className?.includes('orderActive') ? '#233242' : '#667b93',
    textTransform: 'uppercase',
    gap: pxToRem(8),
    paddingLeft: '0px',
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: pxToRem(16),
    fontWeight: '600',
    lineHeight: pxToRem(16),
    color: '#233242',
    gap: '8px',
    paddingLeft: '0px',
  },
}));

export const StyledTableContainer = styledMui(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    padding: `0 ${pxToRem(72)}`,
    maxHeight: '55vh',
    minHeight: '55vh',
    marginBottom: `${pxToRem(24)}`,
  },
}));

export const StyledTableRow = styledMui(TableRow)(({ theme }) => ({}));

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${pxToRem(6)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & .info {
    color: #233242;
    font-size: ${pxToRem(14)};
    font-weight: 500;
    line-height: ${pxToRem(16)};
  }

  & .subInfo {
    color: #697f96;
    font-size: ${pxToRem(14)};
    font-weight: 400;
    line-height: ${pxToRem(16)};

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CellContent = styled.div`
  display: flex;
  gap: ${pxToRem(10)};
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderLabelStyled = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
  cursor: pointer;
`;
