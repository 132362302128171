import React, { useState, InputHTMLAttributes, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteUpdateOrg } from 'main/factories/usecases/org/UpdateOrgFactory';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUserFactory';
// import {
//   makeReduxUpdateUser,
//   makeRemoteUpdateUser,
// } from 'main/factories/usecases/users/UpdateUserFactory';
import { IconCamera, IconErrorInput } from 'presentation/base/icons';
import { closeModal } from 'utils/closeModal';

import { Container, ContentUploadImage } from './styles/StyledUploader';
import Translator from '../i18n/Translator';

interface ImageData extends InputHTMLAttributes<HTMLInputElement> {
  src?: string;
}

interface ownProps {
  setUpdate?(state: boolean): void;
  size?: string;
  haveUpdated?: () => void;
}

interface iParams {
  id: string;
}

const Uploader: React.FC<ownProps> = props => {
  const { setUpdate, haveUpdated } = props;

  const { pathname } = useLocation();

  const userIdFromParams = useParams<iParams>();
  const userLogged = useSelector((store: iStore) => store.auth.user);
  // const selectedUser = useSelector(
  //   (store: iStore) => store.user.,
  // );

  // const id = useSelector((store: iStore) => store.users.selected);
  // eslint-disable-next-line consistent-return
  const onChange = (data: any) => {
    const formData = new FormData();

    if (!data.target.files.length) return;

    if (userIdFromParams.id || userLogged?.id || pathname) {
      if (pathname.includes('orgs')) {
        formData.append('logo', data.target.files[0]);
        makeRemoteUpdateOrg()
          .update({
            id: Number(userIdFromParams.id),
            logo: formData,
          })
          .then(() => {
            toast.success(
              Translator('Logo da organização alterado com sucesso!'),
            );

            setUpdate?.(false);
            haveUpdated?.();
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Atualizar logo da organização'),
              content: Translator(
                'Não foi possível atualizar logo da organização',
              ),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });

            setUpdate?.(true);
          });
      } else {
        formData.append('avatar', data.target.files[0]);
        makeRemoteUpdateUser()
          .update({
            body: {
              avatar: formData,
            },
            id: Number(userIdFromParams.id) ?? userLogged?.id,
          })
          .then(() => {
            toast.success(
              Translator('Imagem do usuário alterada com sucesso!'),
            );

            haveUpdated?.();
          })
          .catch(() => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Atualizar imagem do usuário'),
              content: Translator(
                'Não foi possível atualizar imagem do usuário',
              ),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });

            setUpdate?.(true);
          });
      }
    }
  };

  return (
    <>
      <Container size={props.size}>
        <ContentUploadImage>
          <IconCamera />
        </ContentUploadImage>
        <input
          type="file"
          id="photo"
          name="photo"
          accept="image/png, image/jpeg"
          onChange={onChange}
          style={{ display: 'none' }}
        />
      </Container>
      {/* <img src = {img?.src} width = "300" height = "300"/> */}
    </>
  );
};

export default Uploader;
