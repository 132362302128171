import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListActionsAudit as UsecaseRemoteListActionsAudit } from 'domain/usecases/audit/remote';
import { ListActionsAudit as UsecaseReduxListActionsAudit } from 'domain/usecases/audit/redux';

import { RemoteListActionsAudit } from 'data/repository/audit';
import { ReduxListActionsAudit } from 'data/store/reducer/audit/usecases';

/**
 * send request via API.
 */
export const makeRemoteListActionsAudit = (): UsecaseRemoteListActionsAudit =>
  new RemoteListActionsAudit(makeApiUrl('/audit/actions'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListActionsAudit = (): UsecaseReduxListActionsAudit =>
  new ReduxListActionsAudit();
