import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GuestAuth as UsecaseRemoteGuestAuth } from 'domain/usecases/auth/remote';
import { GuestAuth as UsecaseReduxGuestAuth } from 'domain/usecases/auth/redux';

import { RemoteGuestAuth } from 'data/repository/auth';
import { ReduxGuestAuth } from 'data/store/reducer/auth/usecases';

/**
 * send request via API.
 */
export const makeRemoteGuestAuth = (): UsecaseRemoteGuestAuth =>
  new RemoteGuestAuth(makeApiUrl('/auth/guest'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGuestAuth = (): UsecaseReduxGuestAuth =>
  new ReduxGuestAuth();
