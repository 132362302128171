import React, { useEffect } from 'react';
import { WaitingRoom } from 'presentation/pages';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { CircularProgress } from '@mui/material';
import {
  makeReduxGuestAuth,
  makeRemoteGuestAuth,
} from '../../usecases/auth/GuestAuthFactory';
import { makeReduxSetupConferenceWaitingRoom } from '../../usecases/conferenceWaitingRoom/SetupConferenceWaitingRoomFactory';

const MakeWaitingRoomFactory: React.FC = () => {
  const { access } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    makeReduxSetupConferenceWaitingRoom().setup({
      requesting: false,
      requestQueue: [],
    });
  }, []);

  useEffect(() => {
    if (!access.token) {
      console.log('access is null');
      makeReduxGuestAuth().guest({});
    }
  }, [access]);

  return access?.token ? (
    <WaitingRoom />
  ) : (
    <CircularProgress color="secondary" />
  );
};

export default MakeWaitingRoomFactory;
