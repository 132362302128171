import { SendConferenceFiles } from 'domain/usecases/conferenceFiles/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteSendConferenceFiles implements SendConferenceFiles {
  private readonly url: string;

  private readonly httpClient: HttpClient<SendConferenceFiles.Model>;

  constructor(url: string, httClient: HttpClient<SendConferenceFiles.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  send = async (
    params: SendConferenceFiles.Params,
  ): Promise<SendConferenceFiles.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.short}/files`,
      body: params.body,
      method: 'post',

      headers: params?.token
        ? {
            ContentType: 'multipart/form-data',
            Authorization: `APIKEY ${params?.token}`,
          }
        : undefined,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
