import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListConferenceFilteredByACLV2 as UsecaseRemoteListConferenceFilteredByACLV2 } from 'domain/usecases/conference/remote';
import { ListConferenceFilteredByACLV2 as UsecaseReduxListConferenceFilteredByACLV2 } from 'domain/usecases/conference/redux';

import { RemoteListConferenceFilteredByACLV2 } from 'data/repository/conference';
import { ReduxListConferenceFilteredByACLV2 } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteListConferenceFilteredByACLV2 =
  (): UsecaseRemoteListConferenceFilteredByACLV2 =>
    new RemoteListConferenceFilteredByACLV2(
      makeApiUrl('/conferences/acl-filtered-v2'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListConferenceFilteredByACLV2 =
  (): UsecaseReduxListConferenceFilteredByACLV2 =>
    new ReduxListConferenceFilteredByACLV2();
