import { ListConference } from 'domain/usecases/conference/redux/ListConference';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  ConferenceTypes,
} from 'domain/interfaces/redux/conference/list';

export const listRequest = (payload: ListConference.Params): iActionList => ({
  type: ConferenceTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListConference.Model,
): iActionListSuccess => ({
  type: ConferenceTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: ConferenceTypes.LIST_FAILED,
});
