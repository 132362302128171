import { CircularProgress } from '@mui/material';
import { makeRemoteGetConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/GetConferenceWaitingRoomFactory';
import {
  IconCameraOff,
  IconCameraOn,
  IconMicrophoneOff,
  IconMicrophoneOn,
} from 'presentation/base/icons';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';
import WaitingRoomCard, {
  iWaitingRoomCardProps,
} from 'presentation/components/waitingRoomCard';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Body,
  Container,
  Left,
  Right,
  WaitingRoomContentBody,
  WaitingRoomContentFooter,
  WaitingRoomContentFooterButton,
  WaitingRoomContentFooterButtons,
  WaitingRoomContentStyle,
} from './styles/StyledWaitingRoom';

const WaitingRoom: React.FC = () => {
  /* TODO: Quando for integrar a sala de espera remover esse objeto feito para teste do componente */

  const [isMicOn, setIsMicOn] = useState<boolean>(true);
  const [isCamOn, setIsCamOn] = useState<boolean>(true);
  const { search } = useLocation();
  const [conference, setConference] = useState<
    iWaitingRoomCardProps['conference']
  >({} as iWaitingRoomCardProps['conference']);

  const params = useMemo(
    () => ({
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? null,
    }),
    [search],
  );

  useEffect(() => {
    if (params.t) {
      makeRemoteGetConferenceWaitingRoom()
        .get({
          conferenceShort: params.t,
          query: { userLocator: params.u ?? undefined },
        })
        .then(res => {
          const conferenceData = res.conference;
          setConference({
            title: conferenceData.title,
            descr: conferenceData.descr,
            type: conferenceData.type,
            createdAt: conferenceData.scheduled,
            mode: 'Frequencia normal',
            short: params.t as string,
            userLocator: params.u ?? undefined,
            roleUser: res.user.role,
            expectedDuration: conferenceData.expectedDuration,
          });
        });
    }
  }, [params]);

  const startCamera = () => {
    setTimeout(() => {
      const video: HTMLVideoElement | null = document.querySelector('#webcam');

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then(function (stream) {
            window.localStream = stream;

            if (!video) return;

            video.srcObject = stream;

            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
          })
          .catch(function (error) {
            // this.setState({ camStatus: false});
          });
      }
    }, 300);
  };

  const stopCamera = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar a câmera.',
        type: 'warning',
      });
    }
  };

  const toggleWebcam = () => {
    if (isCamOn) {
      stopCamera();
    } else {
      startCamera();
    }

    setIsCamOn(prevState => !prevState);
  };

  const stopMic = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      console.error('Não foi possível desativar o microfone.');
    }
  };

  const toggleMic = () => {
    try {
      if (isMicOn) {
        stopMic();
      }
      setIsMicOn(prevState => !prevState);
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar o microfone.',
        type: 'warning',
      });
    }
  };

  useEffect(() => {
    if (isCamOn) startCamera();
  }, [startCamera]);

  return conference?.title ? (
    <Container>
      <Body>
        <Left>
          <WaitingRoomContentStyle>
            <WaitingRoomContentBody>
              {isCamOn ? (
                <video autoPlay id="webcam">
                  <track kind="captions" />
                </video>
              ) : (
                <div />
              )}
            </WaitingRoomContentBody>
            <WaitingRoomContentFooter>
              <WaitingRoomContentFooterButtons>
                <WaitingRoomContentFooterButton
                  isActive={isMicOn}
                  onClick={toggleMic}
                  type="button"
                >
                  {isMicOn ? <IconMicrophoneOn /> : <IconMicrophoneOff />}
                </WaitingRoomContentFooterButton>
                <WaitingRoomContentFooterButton
                  isActive={isCamOn}
                  onClick={toggleWebcam}
                  type="button"
                >
                  {isCamOn ? <IconCameraOn /> : <IconCameraOff />}
                </WaitingRoomContentFooterButton>
              </WaitingRoomContentFooterButtons>
            </WaitingRoomContentFooter>
          </WaitingRoomContentStyle>
        </Left>
        <Right>
          <WaitingRoomCard
            conference={conference}
            isMicOn={isMicOn}
            isCamOn={isCamOn}
          />
        </Right>
      </Body>
    </Container>
  ) : (
    <CircularProgress color="secondary" />
  );
};

export default WaitingRoom;
