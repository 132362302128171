import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 450px;
  min-height: 616px;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
`;

export const Title = styled.div`
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  padding: 16px 24px;
  text-align: justify;
  font-size: 1.125rem;
  border-bottom: 1px solid #dedede;

  h1 {
    margin-top: 16px;
  }

  span {
    margin-top: 8px;
    font-weight: normal;
    font-size: 0.875rem;
    color: #656a6e;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentBody = styled.div`
  width: 100%;
  display: flex;
  margin-top: 46px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #dedede;
  padding: 0px 24px 16px 24px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 16px 24px;
  gap: 16px;
`;

export const User = styled.div`
  text-align: center;

  h2 {
    font-size: 1rem;
    font-weight: 600;
  }

  span {
    font-weight: 500;
    color: #656a6e;
  }
`;
