/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker, { DatePickerProps } from '@mui/lab/DatePicker';

import { IconCalendar } from 'presentation/base/icons';

import { Container, Info } from './styles/styled';

const ResponsiveCalendar: React.FC = (): JSX.Element => {
  const [value, setValue] = useState<Date | null>(new Date());

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Custom input"
          value={value}
          onChange={(newValue: Date | null) => {
            setValue(newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }: any) => (
            <Box
              data-testid={`input-calendar-${inputProps?.id}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                '.css-i4bv87-MuiSvgIcon-root': { display: 'none' },
                button: { padding: '20px', margin: 0 },
              }}
            >
              {InputProps?.endAdornment}
              <IconCalendar className="icon" />
              {value && (
                <Info>
                  <strong>{format(value, 'EEEE')}</strong>
                  <span>{format(value, ', dd ')}</span>
                  of
                  <span>{format(value, ' MMMM ')}</span>
                  of
                  <span>{format(value, ' yyyy')}</span>
                </Info>
              )}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Container>
  );
};

export default ResponsiveCalendar;
