import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { LeaveConference as UsecaseRemoteLeaveConference } from 'domain/usecases/conference/remote';
// import { LeaveConference as UsecaseReduxLeaveConference } from 'domain/usecases/conference/redux';

import { RemoteLeaveConference } from 'data/repository/conference';
// import { ReduxLeaveConference } from 'data/store/reducer/conference/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeaveConference = (): UsecaseRemoteLeaveConference =>
  new RemoteLeaveConference(
    makeApiUrl('/conferences/{conferenceShort}/leave/'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeaveConference =
  (): UsecaseReduxLeaveConference =>
    new ReduxLeaveConference(); */
