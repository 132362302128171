import React, { ButtonHTMLAttributes, useContext } from 'react';
import { ThemeContext } from 'App';

import { IconV4Hlogo } from 'presentation/base/icons';
import { withAccess } from 'presentation/hooks/access';
import { Container, Icon } from './styles/StyledButton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secundary';
  size?:
    | 'mini'
    | 'small'
    | 'medium'
    | 'large'
    | 'bigger'
    | 'icon'
    | 'even-bigger'
    | 'fit-content';
  color?: string;
  background?: string;
  height?: string;
  nowrap?: boolean;
  icon?: any;
  rounded?: boolean;
  active?: boolean;
  noFill?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color = '#fff',
  background = '',
  type = 'button',
  size = 'default',
  variant = 'primary',
  height = '40px',
  nowrap = false,
  icon,
  rounded = false,
  active = false,
  noFill = false,
  ...rest
}) => {
  const Logo = icon || IconV4Hlogo;

  const { theme } = useContext(ThemeContext);

  if (background === '') {
    background = theme.colors.primary.main;
  }

  return (
    <Container
      background={background}
      color={color}
      type={type}
      size={size}
      variant={variant}
      height={height}
      nowrap={nowrap}
      rounded={rounded}
      active={active}
      {...rest}
    >
      {icon && (
        <Icon noMargin={size === 'icon'}>
          <Logo fill={noFill ? 'none' : 'white'} />
        </Icon>
      )}
      {children}
    </Container>
  );
};

export default withAccess(Button);
