import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { IconArrowDown, IconArrowUp } from 'presentation/base/icons';
import { ButtonsDiv, Container, IconBorder, InputDiv } from './styles';

export interface RepetitionsRef {
  getRepetitions: () => number;
  setRepetitions: (repetitions: number) => void;
}

interface Props {
  disabled?: boolean;
  min?: number;
  max?: number;
  initialValue?: number;
}

const Repetitions: React.ForwardRefRenderFunction<RepetitionsRef, Props> = (
  { disabled, min = 1, max, initialValue = 1 },
  ref,
) => {
  const [value, setValue] = useState(initialValue ?? 1);

  const increment = () => {
    if (max && value >= max) return;
    setValue(value + 1);
  };

  const decrement = () => {
    if (value > 1 && value > min) {
      setValue(value - 1);
    }
  };

  const handleInputChange = (repetitions: number) => {
    if (Number.isNaN(repetitions)) return;

    if (max) {
      if (repetitions > max) {
        repetitions = max;
      }
    }

    setValue(repetitions);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        getRepetitions: () => value,
        setRepetitions: (repetitions: number) => {
          setValue(repetitions);
        },
      };
    },
    [value, setValue],
  );

  return (
    <Container>
      <InputDiv>
        <input
          value={value}
          disabled={disabled}
          onChange={e => handleInputChange(Number(e.target.value))}
        />
      </InputDiv>
      <ButtonsDiv>
        <IconBorder type="button" disabled={disabled} onClick={increment}>
          <IconArrowUp onClick={increment} />
        </IconBorder>
        <div style={{ width: '100%', height: 1, background: '#b4b7b9' }} />
        <IconBorder type="button" disabled={disabled} onClick={decrement}>
          <IconArrowDown onClick={decrement} />
        </IconBorder>
      </ButtonsDiv>
    </Container>
  );
};

export default forwardRef(Repetitions);
