import React, { useEffect, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import Translator from 'presentation/components/i18n/Translator';

import {
  Body,
  Container,
  Content,
  ButtonsContainer,
} from './styles/StyledconferenceAccessDenied';
import { ownProps } from '../interface';
import { Button } from '../../UI';

const ConferenceAccessDenied: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.conferenceAccessDenied;

  const { active, actionOk, actionCancel, content } = message;

  const [loading, setLoading] = useState<boolean>(false);

  const respondSoliciation = () => {
    console.log('respondSoliciation');
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content hasContent={!!content}>
              <div className="textContent">
                <div className="title">Acesso negado</div>
                <div className="description">
                  Sua solicitação de entrada foi recusada por um moderador desta
                  conferência. Clique no botão “Pedir para entrar” e tente
                  novamente.
                </div>
              </div>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                variant="primary"
                autoFocus
                onClick={actionCancel}
              >
                {Translator('Entendi')}
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default ConferenceAccessDenied;
