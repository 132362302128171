import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetConferenceWaitingRoom as UsecaseRemoteGetConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/remote';
// import { GetConferenceWaitingRoom as UsecaseReduxGetConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

import { RemoteGetConferenceWaitingRoom } from 'data/repository/conferenceWaitingRoom';
// import { ReduxGetConferenceWaitingRoom } from 'data/store/reducer/conferenceWaitingRoom/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetConferenceWaitingRoom =
  (): UsecaseRemoteGetConferenceWaitingRoom =>
    new RemoteGetConferenceWaitingRoom(
      makeApiUrl('/conferences/{conferenceShort}/waitingRoom'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetConferenceWaitingRoom =
  (): UsecaseReduxGetConferenceWaitingRoom =>
    new ReduxGetConferenceWaitingRoom(); */
