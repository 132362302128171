import { call, put, select } from 'redux-saga/effects';
import { iActionListOrgsFiltered } from 'domain/interfaces/redux/org/listOrgsFiltered';
import { GetFilteredOrgs } from 'domain/usecases/org/remote/GetFilteredOrgs';
import { makeRemoteGetFilteredOrgs } from 'main/factories/usecases/org/GetFilteredOrgs';
import {
  listOrgsFilteredSuccess,
  listOrgsFilteredFailed,
} from 'data/store/reducer/org/actions/listOrgFiltered';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';

export function* onListOrgsFiltered(action: iActionListOrgsFiltered) {
  const remoteGetFilteredOrgs = makeRemoteGetFilteredOrgs();

  try {
    const response: GetFilteredOrgs.Model = yield call(
      remoteGetFilteredOrgs.get,
      action.payload,
    );
    yield put(
      listOrgsFilteredSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listOrgsFilteredFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListOrgsFilteredSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListOrgsFilteredFailed() {}
