import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetInfoAuth as UsecaseRemoteGetInfoAuth } from 'domain/usecases/auth/remote';

import { RemoteGetInfoAuth } from 'data/repository/auth';

/**
 * send request via API.
 */
export const makeRemoteGetInfoAuth = (): UsecaseRemoteGetInfoAuth =>
  new RemoteGetInfoAuth(makeApiUrl('/auth/info'), makeHttpClient());
