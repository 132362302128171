/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import Pagination from 'presentation/components/Pagination';
import { ListMyConferences } from 'presentation/components/list';
import { Header, HeaderBack } from 'presentation/components/header';
import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';

import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { Drawer } from 'presentation/components/drawer';
import { ConferenceDetails } from 'presentation/components/conferenceDetails';

import { makeReduxListMyConferences } from 'main/factories/usecases/myConferences/ListMyConferencesFactory';
import Translator from 'presentation/components/i18n/Translator';
import {
  Container,
  ContentMyConferences,
  List,
} from './styles/StyledMyConferences';

const MyConferences: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 15;

  const { records: myConferenceRecords, metadata } = useSelector(
    (store: iStore) => store.myConference,
  );

  const { user } = useSelector((store: iStore) => store.auth);

  const showDetails = useSelector(
    (store: iStore) => store.conference.controller?.showDetails,
  );

  const handlePageUpdate = useCallback(
    (currentPage: number) => {
      if (user?.id && currentPage !== page) {
        makeReduxListMyConferences().list({
          owner: user?.id,
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        });
      }
      setPage(currentPage);
    },
    [user, page],
  );

  return (
    <>
      <Container>
        <Header title="Minhas Conferências" />
        <HeaderBack title={`${Translator('Minhas reuniões')}`} />
        <>
          {myConferenceRecords?.length ? (
            <>
              <div style={{ padding: '0 24px' }}>
                <ContentMyConferences>
                  <Pagination
                    currentPage={page}
                    pageSize={Math.ceil(
                      (metadata?.total ? metadata?.total : 0) / itemsPerPage,
                    )}
                    setPage={handlePageUpdate}
                    pageDynamicSize
                  />
                </ContentMyConferences>
                <List>
                  <ListMyConferences dataList={myConferenceRecords ?? []} />
                </List>
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '600px',
              }}
            >
              {`${Translator('Não possui reuniões')}`}
            </div>
          )}
        </>
      </Container>
      <Drawer
        anchor="right"
        isOpen={showDetails}
        handleClose={() =>
          makeReduxUpdateControllerConference().updateController({
            showDetails: false,
          })
        }
      >
        <ConferenceDetails />
      </Drawer>
    </>
  );
};

export default MyConferences;
