import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { ListAdmin } from 'domain/usecases/admin/remote';
import { makeRemoteDeleteByOrgAdmin } from 'main/factories/usecases/admin/DeleteByOrgAdminFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { ActionsList } from 'presentation/components/actionsList';
import { closeModal } from 'utils/closeModal';
import { formattedDate } from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import { iColumnsConfig } from './interface';
import Translator from '../i18n/Translator';

interface ownProps {
  dataList: ListAdmin.Model;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
    height: 'calc(100vh - 390px)',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

type iColumns = 'name' | 'login' | 'lastAcess' | 'actions';

interface iParams {
  id: string;
}

const Administrators: React.FC<ownProps> = ({ dataList }) => {
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'name',
      label: Translator('Nome'),
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'login',
      label: Translator('Login'),
      minWidth: 100,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'lastAcess',
      label: Translator('Último acesso'),
      minWidth: 50,
      align: 'right',
      alignTitle: 'right',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 20,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const params = useParams<iParams>();
  const { push } = useHistory();
  const { user: userRedux } = useSelector((store: iStore) => store.auth);

  const RenderComponents: React.FC<{
    id: iColumns;
    user: ListAdmin.Model['records'][0];
  }> = ({ id, user }) => {
    switch (id) {
      case 'name':
        return <div>{user.user.fullName}</div>;
      case 'login':
        return <div>{user.user.login}</div>;
      case 'lastAcess':
        return (
          <div>
            {user?.lastAccess
              ? formattedDate(new Date(user?.lastAccess))
              : '--'}
          </div>
        );
      case 'actions':
        return (
          <ActionsList
            actions={{
              deleteOrgUser: () => {
                if (params?.id)
                  makeReduxActiveMessage().active({
                    active: 'confirm',
                    title: Translator('Desvinculo de Usuário da organização'),
                    content: Translator(
                      'Deseja realmente desvincular esse usuário da organização?',
                    ),
                    actionOk: () => {
                      makeRemoteDeleteByOrgAdmin()
                        .deleteByOrg({
                          orgId: Number(params?.id),
                          userId: Number(user?.user?.id),
                        })
                        .then(res => {
                          if (userRedux?.administrator) {
                            const role = getRole(
                              userRedux.administrator,
                              userRedux.org.id,
                              userRedux.orgUnit.id,
                            );

                            UpdateListOrgUser(
                              role,
                              userRedux?.org?.id,
                              userRedux?.orgUnit?.id,
                            );
                          }

                          toast.success(
                            Translator('Usuário desassociado com sucesso'),
                          );
                          closeModal();
                        })
                        .catch(err =>
                          makeReduxActiveMessage().active({
                            active: 'error',
                            title: Translator(
                              'Desvinculo de Usuário da organização',
                            ),
                            content: Translator(
                              'Não foi possível desvincular o usuário da organização',
                            ),
                            actionOk: () => closeModal(),
                            actionCancel: () => closeModal(),
                          }),
                        );
                    },
                    actionCancel: () => closeModal(),
                  });
              },
            }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.records?.map((item: ListAdmin.Model['records'][0]) => (
            <TableRow>
              {columns.map((columnProps: any) => {
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} user={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Administrators;
