/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { IconEdit, IconGo } from 'presentation/base/icons';
import { makeRemoteJoinConference } from 'main/factories/usecases/conference/JoinConferenceFactory';
import { useHistory } from 'react-router-dom';
import { makeRemoteRequestConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/RequestConferenceWaitingRoomFactory';
import { makeReduxSetupConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/SetupConferenceWaitingRoomFactory';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteLeftConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/LeftConferenceWaitingRoomFactory';
import { CircularProgress } from '@mui/material';
import {
  Container,
  Header,
  Title,
  DescriptionDiv,
  Description,
  UserNameDiv,
  Subtitle,
} from './styles/StyledWaitingRoomCard';
import { Button, Input } from '../UI';
import Translator from '../i18n/Translator';

export interface iWaitingRoomCardProps {
  conference?: {
    title: string;
    descr: string;
    type: 'STANDARD' | 'RESTRICT';
    createdAt: string;
    mode: string;
    short: string;
    userLocator?: string;
    roleUser: string;
    expectedDuration: number;
  };
  isMicOn?: boolean;
  isCamOn?: boolean;
}

const WaitingRoomCard: React.FC<iWaitingRoomCardProps> = ({
  conference,
  isMicOn,
  isCamOn,
}) => {
  const [name, setName] = useState<string>('');
  const { requesting } = useSelector(
    (store: iStore) => store.conferenceWaitingRoom,
  );
  const { auth } = useSelector((store: iStore) => store);

  const { push } = useHistory();

  useEffect(() => {
    makeReduxSetupConferenceWaitingRoom().setup({
      mic: isMicOn || false,
      cam: isCamOn || false,
      name,
    });
  }, [isMicOn, isCamOn, name]);

  useEffect(() => {
    return () => {
      if (conference && conference?.roleUser === 'GUEST') {
        makeRemoteLeftConferenceWaitingRoom().left({
          short: conference.short,
          body: { userLocator: conference.userLocator, fullname: name },
        });
      }
    };
  }, [conference]);

  const joinConference = () => {
    if (conference)
      makeRemoteJoinConference()
        .join({
          conferenceShort: conference.short,
          userLocator: conference.userLocator,
        })
        .then(res => {
          push(
            `/join?t=${conference.short}&u=${res?.user?.fullName}&c=${isCamOn}&m=${isMicOn}`,
          );
        })
        .catch(err => {
          // toast.error('Não foi possível buscar dados da conferência.');
        });
  };

  const requestConference = () => {
    if (conference) {
      makeRemoteRequestConferenceWaitingRoom()
        .request({
          conferenceShort: conference.short,
          body: { fullname: name },
        })
        .then(() => {
          makeReduxSetupConferenceWaitingRoom().setup({
            requesting: true,
          });
        })
        .catch(e => {
          console.log('>>> err', e);
        });
    }
  };

  return (
    <Container>
      <Header>
        <Title>{conference?.title}</Title>
      </Header>
      <DescriptionDiv>
        <Subtitle>Descrição</Subtitle>
        <Description>{conference?.descr || 'Não informado'}</Description>
      </DescriptionDiv>
      <UserNameDiv>
        <Subtitle>Nome do participante</Subtitle>
        <Input
          autoFocus
          placeholder="Digite seu nome"
          onChange={e => setName(e.target.value)}
          value={auth?.access?.token ? auth?.user?.fullName : name}
          disabled={conference?.roleUser !== 'GUEST'}
        />

        <Button
          variant="primary"
          icon={requesting ? undefined : IconGo}
          disabled={
            (!name.length && conference?.roleUser === 'GUEST') || requesting
          }
          onClick={() =>
            conference?.type === 'RESTRICT' && conference?.roleUser === 'GUEST'
              ? requestConference()
              : joinConference()
          }
        >
          {requesting ? (
            <CircularProgress size={20} />
          ) : conference?.type === 'RESTRICT' &&
            conference?.roleUser === 'GUEST' ? (
            'Pedir para participar'
          ) : (
            `Entrar na ${Translator('reunião')}`
          )}
        </Button>
      </UserNameDiv>
    </Container>
  );
};

export default WaitingRoomCard;
