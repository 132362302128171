/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { iOneUser, iStore } from 'domain/interfaces/models';
import { makeReduxListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import { IconHealth } from 'presentation/base/icons';
import Pagination from 'presentation/components/Pagination';
import ProfileNavigator from 'presentation/components/PerfilNavigator/PerfilNavigator';
import { Button } from 'presentation/components/UI';
import { Header, HeaderBack } from 'presentation/components/header';
import Translator from 'presentation/components/i18n/Translator';
import { ListUsers } from 'presentation/components/list';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import {
  makeReduxListFilteredUser,
  makeRemoteGetFilteredUsers,
} from 'main/factories/usecases/user/GetFilteredUsers';
import { IOrderBy } from 'presentation/components/list/Users';
import { iFilterUsers } from 'presentation/components/filter/FilterUsers';
import { subYears } from 'date-fns';
import UserPage from '../Admin/Users';
import ChangePasswordPage from './ChangePassword';
import {
  Body,
  Container,
  ContentUser,
  Left,
  List,
  Right,
} from './styles/StyledUser';

const User: React.FC = () => {
  const url = window.location.pathname;
  const [navigation, setNavigation] = useState<number>(1);
  const [usersAmount, setUsersAmount] = useState<number>(0);

  const now = new Date();

  const [filterUsers, setFilterUsers] = useState<iFilterUsers>({
    user: {
      registered: {
        begin: subYears(
          new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
          5,
        ).toISOString(),
        end: new Date().toISOString(),
      },
      role: {
        orgAdmin: undefined,
        orgUnitAdmin: undefined,
        systemAdmin: undefined,
      },
    },
  });

  const [allOrderBy, setAllOrderBy] = useState<IOrderBy[]>([
    {
      order: 1,
      attribute: 'NAME',
      direction: 'DESC',
    },
    {
      order: 1,
      attribute: 'EMAIL',
      direction: 'DESC',
    },
  ]);

  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [haveUpdated, setHaveUpdated] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [isFilterActive, setIsFilterActive] = useState(false);

  const { records, loading } = useSelector((store: iStore) => store.user);
  const { user } = useSelector((store: iStore) => store.auth);

  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 15;

  const navigate = useHistory();
  const { pathname } = useLocation();

  const userFiltered = useMemo(
    () => records.find(item => item.id === selectedUserId),
    [records, selectedUserId],
  );

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return <UserPage userId={selectedUserId} editing />;
      case 2:
        return <ChangePasswordPage userId={selectedUserId} />;
      default:
        return <div />;
    }
  }, [navigation, selectedUserId]);

  const shouldHide = useCallback(
    (item: iOneUser, orgUser: iOneUser['orgUsers'][0]): boolean => {
      const { sysAdmin } = item;
      const orgAdmin = Boolean(
        item.administrator.org.length > 0 &&
          item.administrator.org.includes(orgUser?.orgUnit?.org?.id),
      );

      const unitAdmin = Boolean(
        item.administrator.orgUnit.length > 0 &&
          item.administrator.orgUnit.includes(orgUser?.orgUnit?.id),
      );

      const role = filterUsers?.user?.role;

      // If filtering only system admins, and the user is not a system admin, skip
      if (
        role?.systemAdmin &&
        role?.orgAdmin === false &&
        role?.orgUnitAdmin === false &&
        !sysAdmin
      )
        return true;

      // If filtering only org admins, and the user is not a org admin, skip
      if (
        role?.orgAdmin &&
        role?.systemAdmin === false &&
        role?.orgUnitAdmin === false &&
        !orgAdmin
      )
        return true;

      // If filtering only unit admins, and the user is not a unit admin, skip
      if (
        role?.orgUnitAdmin &&
        role?.systemAdmin === false &&
        role?.orgAdmin === false &&
        !unitAdmin
      )
        return true;

      // If filtering for unit admins and org admins, and the user is not a unit admin or org admin, skip
      if (
        role?.orgUnitAdmin &&
        role?.orgAdmin &&
        role?.systemAdmin === false &&
        !unitAdmin &&
        !orgAdmin
      )
        return true;

      // If filtering for unit admins and system admins, and the user is not a unit admin or system admin, skip
      if (
        role?.orgUnitAdmin &&
        role?.systemAdmin &&
        role?.orgAdmin === false &&
        !unitAdmin &&
        !sysAdmin
      )
        return true;

      // If filtering for org admins and system admins, and the user is not a org admin or system admin, skip
      if (
        role?.orgAdmin &&
        role?.systemAdmin &&
        role?.orgUnitAdmin === false &&
        !orgAdmin &&
        !sysAdmin
      )
        return true;

      // If filtering for org admins, system admins and unit admins, and the user is not a org admin, system admin or unit admin, skip
      if (
        role?.orgAdmin &&
        role?.systemAdmin &&
        role?.orgUnitAdmin &&
        !orgAdmin &&
        !sysAdmin &&
        !unitAdmin
      )
        return true;

      return false;
    },
    [filterUsers?.user?.role],
  );

  const displayData = useMemo(() => {
    const data: any = [];

    records.forEach(item => {
      item.orgUsers.forEach(orgUser => {
        const hide = shouldHide(item, orgUser);
        if (hide) return;

        const newObj = {
          administrator: item.administrator,
          user: {
            id: item.id,
            login: item.login,
            firstName: item.firstName,
            lastName: item.lastName,
            fullName: item.fullName,
            email: item.email,
            birthDate: item.birthDate,
            avatar: item.avatar,
          },
          orgUnit: {
            id: orgUser.orgUnit.id,
            shortname: orgUser.orgUnit.name,
            name: orgUser.orgUnit.name,
            descr: orgUser.orgUnit.descr,
            logo: orgUser.orgUnit.logo,
            org: orgUser.orgUnit.org,
          },
          sysAdmin: item.sysAdmin,
          orgAdmin: Boolean(
            item.administrator.org.length > 0 &&
              item.administrator.org.includes(orgUser?.orgUnit?.org?.id),
          ),
          unitAdmin: Boolean(
            item.administrator.orgUnit.length > 0 &&
              item.administrator.orgUnit.includes(orgUser?.orgUnit?.id),
          ),
        };

        data.push(newObj);
      });
    });

    setUsersAmount(data?.length);

    const start = (page - 1) * itemsPerPage;

    return data?.slice(start, start + itemsPerPage);
  }, [page, records]);

  const handleCreateUser = () => {
    navigate.push('/admin/user/create', {
      from: url.replaceAll('/', ''),
    });
  };

  const handleFilterUsers = useCallback(() => {
    if (search) {
      makeReduxListFilteredUser().list({
        user: {
          search,
        },
      });

      setIsFilterActive(true);
    }
  }, [search]);

  const handleOrderBy = (type?: string) => {
    let obj: IOrderBy = {} as IOrderBy;

    if (type === 'user' || type === undefined) {
      obj = {
        order: 1,
        attribute: 'NAME',
        direction:
          type === undefined
            ? 'ASC'
            : allOrderBy[0].direction === 'ASC'
            ? 'DESC'
            : 'ASC',
      };
      setAllOrderBy([obj, allOrderBy[1]]);
    } else {
      obj = {
        order: allOrderBy[1].order,
        attribute: 'EMAIL',
        direction: allOrderBy[1].direction === 'ASC' ? 'DESC' : 'ASC',
      };
      setAllOrderBy([allOrderBy[0], obj]);
    }

    const updatedFilterUsers = {
      ...filterUsers.user,
      org:
        filterUsers?.user?.org && filterUsers?.user?.org !== 0
          ? filterUsers?.user?.org
          : undefined,
      unit:
        filterUsers?.user?.unit && filterUsers?.user?.unit !== 0
          ? filterUsers?.user?.unit
          : undefined,
    };

    makeReduxListFilteredUser().list({
      dataControl: {
        limit: 999999,
        offset: 0,
        paging: true,
      },
      orderBy: [obj],
      user: type ? updatedFilterUsers ?? undefined : undefined,
    });
  };

  useEffect(() => {
    handleOrderBy();
  }, []);

  const { active } = useSelector((store: iStore) => store.message);

  useEffect(() => {
    if (!user?.administrator) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    UpdateListOrgUser(role, user?.org?.id, user?.orgUnit?.id);
  }, [haveUpdated, user, active]);

  useEffect(() => {
    const lastQuery = pathname.split('/').pop();

    if (Number(lastQuery)) {
      setSelectedUserId(Number(lastQuery));
    }
  }, [pathname]);

  useEffect(() => {
    if (!search) {
      makeReduxListFilteredUser().list({
        dataControl: {
          limit: 9999,
        },
      });

      setIsFilterActive(false);
    }
  }, [haveUpdated, user, search]);

  return (
    <Container>
      <Header title="teste" haveUpdated={haveUpdated} />
      {!selectedUserId && (
        <HeaderBack
          title={`${Translator('Usuários Cadastrados')}`}
          hasFilter
          search={search}
          setSearch={setSearch}
          onSearch={handleFilterUsers}
          filterUsers={filterUsers}
          updateFilterUsers={setFilterUsers}
          isFilterActive={isFilterActive}
        />
      )}
      {selectedUserId ? (
        <>
          <HeaderBack
            onBack={() => {
              setSelectedUserId(0);
              navigate.goBack();
            }}
            title={`${Translator('Detalhes do usuário')}: ${
              userFiltered?.fullName
            }`}
          />
          <Body>
            <Left>
              <ProfileNavigator
                user={userFiltered}
                active={navigation}
                nav={setNavigation}
                haveUpdated={() => setHaveUpdated(prevState => !prevState)}
              />
            </Left>
            <Right>{RenderSubPages}</Right>
          </Body>
        </>
      ) : (
        <>
          <ContentUser>
            <Pagination
              currentPage={page}
              pageSize={Math.ceil(usersAmount / itemsPerPage)}
              setPage={setPage}
            />
            <div>
              <Button
                id="newUser"
                icon={IconHealth}
                rounded
                variant="primary"
                size="medium"
                onClick={handleCreateUser}
                resourcers={['USER']}
                actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
              >
                {Translator('Novo Usuário')}
              </Button>
            </div>
          </ContentUser>
          <List>
            {loading ? (
              <>loading..</>
            ) : (
              <ListUsers dataList={displayData} setAllOrderBy={handleOrderBy} />
            )}
          </List>
        </>
      )}
    </Container>
  );
};

export default User;
