import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgAclFiltered as UsecaseRemoteListOrgAclFiltered } from 'domain/usecases/org/remote';
import { ListOrgAclFiltered as UsecaseReduxListOrgAclFiltered } from 'domain/usecases/org/redux';

import { RemoteListOrgAclFiltered } from 'data/repository/org';
import { ReduxListOrgAclFiltered } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOrgAclFiltered =
  (): UsecaseRemoteListOrgAclFiltered =>
    new RemoteListOrgAclFiltered(
      makeApiUrl('/orgs/acl-filtered/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOrgAclFiltered = (): UsecaseReduxListOrgAclFiltered =>
  new ReduxListOrgAclFiltered();
