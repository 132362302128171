import React, { useEffect, useMemo } from 'react';
import { Audit } from 'presentation/pages';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { getRole } from 'utils/getRole';
import { subYears } from 'date-fns';
import { makeReduxListLogsAudit } from '../../usecases/audit/ListLogsAuditFactory';
import { makeReduxListGroupsAudit } from '../../usecases/audit/ListGroupsAuditFactory';

export const MakeAuditFactory: React.FC = () => {
  const { user } = useSelector((store: iStore) => store.auth);

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  useEffect(() => {
    makeReduxListGroupsAudit().listGroups({
      body: {
        dataControl: {
          limit: 9999,
        },
      },
    });

    makeReduxListLogsAudit().listLogs({
      body: {
        log: {
          timestamp: {
            begin: subYears(new Date(), 5).toISOString(),
            end: new Date().toISOString(),
          },
        },
        user:
          role === 'SYSADMIN'
            ? undefined
            : {
                org: role === 'ORG_ADMIN' ? user?.org.id : undefined,
                orgUnit: role === 'UNIT_ADMIN' ? user?.orgUnit.id : undefined,
              },
        action: {
          changeData: true,
        },
        dataControl: {
          limit: 15,
          paging: true,
        },
      },
    });
  }, [role, user?.org.id, user?.orgUnit.id]);

  return <Audit />;
};
