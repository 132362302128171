/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { iStore } from 'domain/interfaces/models';
import { OrgInfos } from 'domain/usecases/org/remote';
import _ from 'lodash';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import { IconHealth } from 'presentation/base/icons';
import { OrgNavigator } from 'presentation/components/OrgNavigator';
import Pagination from 'presentation/components/Pagination';
import { Button } from 'presentation/components/UI';
import { Header, HeaderBack } from 'presentation/components/header';
import Translator from 'presentation/components/i18n/Translator';
import Org from 'presentation/components/list/Org';
import SectorsPage from 'presentation/pages/Admin/Sectors';
import SettingsPage from 'presentation/pages/Admin/Settings';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { makeReduxGetFilteredOrgs } from 'main/factories/usecases/org/GetFilteredOrgs';
import { IOrderBy } from 'presentation/components/list/Users';
import { ChildComponentRef } from 'presentation/components/header/BackButton';
import { subYears } from 'date-fns';
import { iFilterOrganization } from 'presentation/components/filter/FilterOrganization';
import BillingPage from './Billing';
import OrgPage from './Orgs';
import {
  Body,
  Container,
  Content,
  Left,
  ListOrgs,
  Right,
} from './styles/styledAdmin';

export interface OrgUnitInfos {
  id: 0;
  shortname: string;
  name: string;
  descr: string;
  logo: string;
  notificationChannels: {
    email: true;
    whatsapp: true;
    sms: true;
  };
  address: {
    type: string;
    zipcode: string;
    province: string;
    city: string;
    country: string;
    neighborhood: string;
    street: string;
    number: 0;
    complement: string;
  };
  org: {
    id: 0;
    shortname: string;
    name: string;
    type: string;
    reg: string;
    logo: string;
    notificationChannels: {
      email: true;
      whatsapp: true;
      sms: true;
    };
    skin: {
      id: 0;
      name: string;
      org: 0;
      descr: string;
      skin: {};
    };
  };
  parent: string;
}

interface iParams {
  id: string;
  orgUnitId: string;
}

const Admin: React.FC = (): JSX.Element => {
  const url = window.location.pathname;
  const params = useParams<iParams>();
  const filterRef = useRef<ChildComponentRef>(null);

  const now = new Date();

  const [filterOrganization, setFilterOrganization] =
    useState<iFilterOrganization>({
      organization: {
        registered: {
          begin: subYears(
            new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
            5,
          ).toISOString(),
          end: new Date().toISOString(),
        },
        linkedUsers: {
          min: 0,
          max: undefined,
        },
        recording: {
          enabled: undefined,
        },
      },
    });

  const [allOrderBy, setAllOrderBy] = useState<IOrderBy[]>([
    {
      order: 1,
      attribute: 'NAME',
      direction: 'ASC',
    },
    {
      order: 1,
      attribute: 'SHORTNAME',
      direction: 'DESC',
    },
  ]);

  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [updateRef, setUpdateRef] = useState<boolean>(false);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(0);
  const [selectedOrgUnitName, setSelectedOrgUnitName] = useState<string | null>(
    null,
  );
  const [selectedOrg, setSelectedOrg] = useState<OrgInfos>({} as OrgInfos);
  const responseOrg = useSelector((store: iStore) => store.org);

  const [navigation, setNavigation] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [haveUpdated, setHaveUpdated] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [isFilterActive, setIsFilterActive] = useState(false);

  const user = useSelector((store: iStore) => store.auth.user);

  const itemsPerPage = 15;

  const { pathname } = useLocation();
  const { push, goBack, replace } = useHistory();

  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;

    return {
      ...responseOrg.records,
      records: responseOrg.records?.slice(start, start + itemsPerPage) ?? [],
    };
  }, [page, responseOrg.records]);

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return (
          <OrgPage
            orgId={selectedOrgId}
            haveUpdated={() => {
              setHaveUpdated(prevState => !prevState);

              setSelectedOrgId(0);
              replace('/admin/orgs');
            }}
            editing
          />
        );
      case 2:
        return (
          <SectorsPage
            handleOrgUnitName={setSelectedOrgUnitName}
            haveUpdated={() => setHaveUpdated(prevState => !prevState)}
          />
        );
      case 3:
        return <SettingsPage />;
      case 4:
        return <BillingPage />;
      default:
        return <div />;
    }
  }, [navigation, selectedOrgId]);

  const handleGetHeaderNameFormatted = useMemo(() => {
    let headerName = `: ${selectedOrg.name}`;

    if (selectedOrgUnitName && selectedOrg) {
      headerName += ` - Setor: ${selectedOrgUnitName}`;
    }

    return headerName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg, selectedOrgUnitName, haveUpdated]);

  const handleFilterOrgs = useCallback(() => {
    if (search) {
      let obj: IOrderBy = {} as IOrderBy;

      obj = {
        order: 1,
        attribute: 'NAME',
        direction: 'ASC',
      };

      makeReduxGetFilteredOrgs().list({
        dataControl: {
          limit: 999999,
          offset: 0,
          paging: true,
        },
        orderBy: [obj],
        organization: {
          search,
        },
      });

      setUpdateRef(prevState => !prevState);
      setAllOrderBy([obj, allOrderBy[1]]);
      setIsFilterActive(true);
    }
  }, [search]);

  const handleOrderBy = (type?: string) => {
    setLoadingOrgs(true);
    let obj: IOrderBy = {} as IOrderBy;

    if (type === 'name' || type === undefined) {
      obj = {
        order: 1,
        attribute: 'NAME',
        direction:
          type === undefined
            ? 'ASC'
            : allOrderBy[0].direction === 'ASC'
            ? 'DESC'
            : 'ASC',
      };
      setAllOrderBy([obj, allOrderBy[1]]);
    } else {
      obj = {
        order: allOrderBy[1].order,
        attribute: 'SHORTNAME',
        direction: allOrderBy[1].direction === 'ASC' ? 'DESC' : 'ASC',
      };
      setAllOrderBy([allOrderBy[0], obj]);
    }

    makeReduxGetFilteredOrgs().list({
      dataControl: {
        limit: 999999,
        offset: 0,
        paging: true,
      },
      orderBy: [obj],
      organization: type
        ? filterOrganization.organization ?? undefined
        : undefined,
    });

    setLoadingOrgs(false);
  };

  useLayoutEffect(() => {
    if (Number(params.id)) setSelectedOrgId(Number(params?.id));
  }, [params.id]);

  useEffect(() => {
    if (!search) {
      makeReduxListOrgAclFilteredV2().list({
        limit: 9999,
      });

      setIsFilterActive(false);
    }
  }, [haveUpdated, user, search]);

  useEffect(() => {
    if (Number(params?.id)) {
      // setSelectedOrgId(Number(params?.id));

      const foundOrg = responseOrg.records?.find(
        org => org.id === Number(params?.id),
      );

      if (foundOrg) setSelectedOrg(foundOrg);
    }
  }, [params, pathname, responseOrg.records]);

  useEffect(() => {
    if (Number(params?.orgUnitId)) {
      setNavigation(2);
    }
  }, [params]);

  return (
    <Container>
      <Header title="teste" />
      {!selectedOrgId && (
        <HeaderBack
          title={Translator('Organizações cadastradas')}
          hasFilter
          search={search}
          setSearch={setSearch}
          onSearch={handleFilterOrgs}
          ref={filterRef}
          filterOrganization={filterOrganization}
          updateFilterOrganization={setFilterOrganization}
          isFilterActive={isFilterActive}
        />
      )}
      <div style={{ padding: !selectedOrgId ? '0 24px' : '0' }}>
        {selectedOrgId ? (
          <>
            <HeaderBack
              onBack={() => {
                if (!(navigation === 2 && params?.orgUnitId))
                  setSelectedOrgId(0);

                goBack();
              }}
              title={
                navigation === 4
                  ? `Informações de consumo e faturamento${handleGetHeaderNameFormatted}`
                  : `Detalhes da organização${handleGetHeaderNameFormatted}`
              }
            />
            <Body>
              <Left>
                <OrgNavigator
                  active={navigation}
                  haveUpdated={() => setHaveUpdated(prevState => !prevState)}
                  nav={setNavigation}
                  org={selectedOrg}
                  creating={0}
                />
              </Left>
              <Right>{RenderSubPages}</Right>
            </Body>
          </>
        ) : (
          <>
            <Content>
              <div>
                <Pagination
                  currentPage={page}
                  setPage={setPage}
                  pageSize={
                    responseOrg?.records?.length
                      ? Math.ceil(responseOrg?.records?.length / itemsPerPage)
                      : 0
                  }
                />
              </div>
              <div>
                <Button
                  data-testid="btn-create-org"
                  icon={IconHealth}
                  rounded
                  variant="primary"
                  size="medium"
                  onClick={() =>
                    push('/admin/orgs/create/new', {
                      from: url.replaceAll('/', ''),
                    })
                  }
                  resourcers={['ORG']}
                  actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
                >
                  {Translator('Nova Organização')}
                </Button>
              </div>
            </Content>
            <ListOrgs>
              <Org
                dataList={displayData}
                setAllOrderBy={handleOrderBy}
                haveUpdated={() => setHaveUpdated(prevState => !prevState)}
              />
            </ListOrgs>
          </>
        )}
      </div>
    </Container>
  );
};

export default Admin;
