import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreatePasswordRecovery as UsecaseRemoteCreatePasswordRecovery } from 'domain/usecases/password/remote';

import { RemoteCreatePasswordRecovery } from 'data/repository/password';

/**
 * send request via API.
 */
export const makeRemoteCreatePasswordRecovery =
  (): UsecaseRemoteCreatePasswordRecovery =>
    new RemoteCreatePasswordRecovery(
      makeApiUrl('/password/recovery'),
      makeHttpClient(),
    );
