import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  position: relative;
  height: 57px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
`;

export const Close = styled.div`
  position: absolute;
  right: 21px;
  top: 17px;
  cursor: pointer;
`;
