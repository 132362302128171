import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import { makeRemoteDeleteOrgUnit } from 'main/factories/usecases/orgUnit/DeleteOrgUnitFactory';
import { makeReduxListOrgUnitAclFilteredV2 } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';
import { History } from 'main/routes';
import { ActionsList } from 'presentation/components/actionsList';
import { closeModal } from 'utils/closeModal';
import { getRole } from 'utils/getRole';
import Translator from '../i18n/Translator';
import { iColumnsConfig, iListOrgUnits } from './interface';

interface ownProps {
  dataList: iListOrgUnits;
  handleSelected: (orgUnitId: number) => void;
  handleSelectedOrgId: (orgId: number) => void;
  handleSelectedOrgUnitName?: (name: string) => void;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

type iColumns = 'name' | 'logo' | 'organizer' | 'users' | 'actions';

interface iParams {
  id: string;
  orgUnitId: string;
}

const OrgUnits: React.FC<ownProps> = ({
  dataList,
  handleSelected,
  handleSelectedOrgId,
  handleSelectedOrgUnitName,
}) => {
  const url = window.location.pathname;
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'name',
      label: Translator('Nome'),
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'logo',
      label: Translator('Sigla'),
      minWidth: 50,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'users',
      label: Translator('Usuários lotados'),
      minWidth: 50,
      align: 'right',
      alignTitle: 'right',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 20,
      align: 'right',
      alignTitle: 'right',
      hiddenForRoles: [''],
    },
  ];

  const { auth } = useSelector((store: iStore) => store);

  const params = useParams<iParams>();

  const role = useMemo(
    () =>
      auth.user?.administrator
        ? getRole(
            auth.user.administrator,
            auth.user.org.id,
            auth.user.orgUnit.id,
          )
        : 'STANDARD',
    [auth.user?.administrator, auth.user?.org.id, auth.user?.orgUnit.id],
  );

  const RenderComponents: React.FC<{
    id: iColumns;
    orgUnits: iListOrgUnits['records'][0];
  }> = ({ id, orgUnits }) => {
    switch (id) {
      case 'name':
        return <div>{orgUnits.name}</div>;
      case 'logo':
        return <b>{orgUnits.shortname}</b>;
      case 'users':
        return <div>{orgUnits?.users}</div>;
      case 'actions':
        return (
          <ActionsList
            enablers={{
              isViewDetailsOrg: role === 'STANDARD',
            }}
            actions={{
              detailsOrgUnit: orgUnits?.permission?.read
                ? () => {
                    handleSelectedOrgId(orgUnits.org?.id as number);
                    handleSelectedOrgUnitName?.(orgUnits?.name as string);
                    handleSelected(orgUnits.id as number);
                    History.getHistory().push(
                      `/admin/orgs/${params?.id}/unit/${orgUnits.id}`,
                      {
                        from: url.replaceAll('/', ''),
                      },
                    );
                  }
                : undefined,
              deleteOrgUnit: orgUnits?.permission?.delete
                ? () =>
                    makeReduxActiveMessage().active({
                      active: 'confirm',
                      content: Translator('Deseja realmente excluir o setor?'),
                      title: Translator('Remoção de setor'),
                      actionOk: () => {
                        makeRemoteDeleteOrgUnit()
                          .delete({
                            orgId: Number(params?.id),
                            unitId: Number(orgUnits.id),
                          })
                          .then(res => {
                            closeModal();

                            makeReduxListOrgUnitAclFilteredV2().list({
                              limit: 9999,
                            });

                            makeReduxListOrgAclFilteredV2().list({
                              limit: 9999,
                            });

                            History.getHistory().push(
                              `/admin/orgs/${params?.id}`,
                            );
                            toast.success(
                              Translator('Setor excluído com sucesso!'),
                            );
                          })
                          .catch(err => {
                            makeReduxActiveMessage().active({
                              active: 'error',
                              title: Translator('Remoção de setor'),
                              content: Translator(
                                'Não foi possível remover setor.',
                              ),
                              actionOk: () => closeModal(),
                              actionCancel: () => closeModal(),
                            });
                          });
                      },
                      actionCancel: () => closeModal(),
                    })
                : undefined,
            }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              if (item.hiddenForRoles?.includes(role)) {
                return <></>;
              }
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.records?.map((item: iListOrgUnits['records'][0]) => (
            <TableRow>
              {columns.map((columnProps: any) => {
                if (columnProps.hiddenForRoles?.includes(role)) {
                  return <></>;
                }
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} orgUnits={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrgUnits;
