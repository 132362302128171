import { ListOrgUnit } from 'domain/usecases/orgUnit/redux/ListOrgUnit';
import {
  iActionListOrgUnit,
  iActionListOrgUnitFailed,
  iActionListOrgUnitSuccess,
  OrgUnitTypes,
} from 'domain/interfaces/redux/orgUnit/list';

export const listRequest = (
  payload: ListOrgUnit.Params,
): iActionListOrgUnit => ({
  type: OrgUnitTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListOrgUnit.Model,
): iActionListOrgUnitSuccess => ({
  type: OrgUnitTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListOrgUnitFailed => ({
  type: OrgUnitTypes.LIST_FAILED,
});
