import { call, put, select } from 'redux-saga/effects';
import { iActionLogout } from 'domain/interfaces/redux/auth/logout';
import { LogoutAuth } from 'domain/usecases/auth/remote/LogoutAuth';
import { makeRemoteLogoutAuth } from 'main/factories/usecases/auth/LogoutAuthFactory';
import {
  logoutSuccess,
  logoutFailed,
} from 'data/store/reducer/auth/actions/logout';

export function* onLogout(action: iActionLogout) {
  const remoteLogoutAuth = makeRemoteLogoutAuth();

  try {
    const response: LogoutAuth.Model = yield call(
      remoteLogoutAuth.logout,
      action.payload,
    );

    yield put(logoutSuccess(response));
  } catch (e) {
    yield put(logoutFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function onLogoutSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLogoutFailed() {}
