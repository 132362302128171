import { ListMyConferences } from 'domain/usecases/myConferences/redux/listMyConferences';
import {
  MyConferenceTypes,
  iActionListMyConference,
  iActionListMyConferenceFailed,
  iActionListMyConferenceSuccess,
} from 'domain/interfaces/redux/myConferences/myConference';

export const listMyConferenceRequest = (
  payload: ListMyConferences.Params,
): iActionListMyConference => ({
  type: MyConferenceTypes.LIST_MY_CONFERENCE,
  payload,
});

export const listMyConferenceSuccess = (
  params: ListMyConferences.Model,
): iActionListMyConferenceSuccess => ({
  type: MyConferenceTypes.LIST_MY_CONFERENCE_SUCCESS,
  payload: params,
});

export const listMyConferenceFailed = (): iActionListMyConferenceFailed => ({
  type: MyConferenceTypes.LIST_MY_CONFERENCE_FAILED,
});
