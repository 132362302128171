/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-destructuring */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import Translator from 'presentation/components/i18n/Translator';

import { IconBack, IconSearch } from 'presentation/base/icons';
import { useLocation } from 'react-router';
import Popover from 'presentation/components/UI/popover/Popover';
import { ListLogsAudit } from 'domain/usecases/audit/remote';
import IconFilter from '@mui/icons-material/FormatListBulleted';
import {
  Container,
  Drop,
  FilterIcon,
  RowContainer,
} from './styles/StyledBackButton';

import { Icon } from '../icon';
import Input from '../UI/input';
import FilterOrganization, {
  iFilterOrganization,
} from '../filter/FilterOrganization';
import FilterUsers, { iFilterUsers } from '../filter/FilterUsers';
import FilterAudit from '../filter/FilterAudit';

interface iStateParams {
  from: string;
}

interface namePages {
  [key: string]: string[];
}

interface ownProps {
  backDescription?: string;
  title?: string;
  hasFilter?: boolean;
  onBack?: () => void;
  id?: string;
  search?: string;
  setSearch?: Function;
  onSearch?: Function;
  filterOrganization?: iFilterOrganization;
  updateFilterOrganization?: (data: iFilterOrganization) => void;
  filterUsers?: iFilterUsers;
  updateFilterUsers?: (data: iFilterUsers) => void;
  filterAudit?: ListLogsAudit.Params['body'];
  updateFilterAudit?: (data: ListLogsAudit.Params['body']) => void;
  isFilterActive?: boolean;
}

type Props = ownProps;

const namePages = [
  { key: /^home$/, value: Translator('Agenda') },
  { key: /^adminusers$/, value: Translator('Lista de usuários') },
  { key: /^adminusers\d+$/, value: Translator('Detalhes do usuário') },
  { key: /^adminorgs$/, value: Translator('Lista de organizações') },
  { key: /^adminorgs\d+$/, value: Translator('Detalhes de organização') },
  { key: /^adminorgs\d+unit\d+$/, value: Translator('Detalhes do setor') },
  { key: /^appointmentcreate$/, value: Translator('Criação de reunião') },
  { key: /^adminusercreate$/, value: Translator('Criação de usuário') },
  { key: /^adminorgscreatenew$/, value: Translator('Criação de organização') },
  { key: /^appointmentedit\d+$/, value: Translator('Editar reunião') },
];

export interface ChildComponentRef {
  getState: () => any;
}

const HeaderBackButton: React.ForwardRefRenderFunction<any, Props> = (
  {
    backDescription,
    onBack,
    title,
    hasFilter,
    id,
    search,
    setSearch,
    onSearch,
    filterOrganization,
    updateFilterOrganization,
    filterUsers,
    updateFilterUsers,
    filterAudit,
    updateFilterAudit,
    isFilterActive = false,
  },
  ref,
): JSX.Element => {
  const [isClosed, setIsClosed] = useState(false);
  const from = useLocation<iStateParams>().state?.from;
  const data = Object.entries(namePages);
  const url = window.location.pathname;
  let namePageBack = Translator('Voltar');

  if (from) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in namePages) {
      if (from.match(namePages[key].key)) namePageBack = namePages[key].value;
    }
  }
  const backD = backDescription !== undefined ? backDescription : namePageBack;

  const iconRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const keyPressEvent = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        iconRef?.current?.click();
      }
    };

    document.addEventListener('keydown', keyPressEvent);

    return () => {
      document.removeEventListener('keydown', keyPressEvent);
    };
  }, []);

  const selectFilter = () => {
    if (url.includes('admin/orgs')) {
      return searchOrganization;
    }
    if (url.includes('admin/users')) {
      return searchUsers;
    }

    if (url.includes('admin/audit')) {
      return searchAudit;
    }

    return <div />;
  };

  const searchOrganization = (
    <div>
      <Drop disablePadding>
        <FilterOrganization
          filterOrganization={filterOrganization}
          setFilterOrganization={updateFilterOrganization}
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          ref={ref}
        />
      </Drop>
    </div>
  );

  const searchUsers = (
    <div>
      <Drop disablePadding>
        <FilterUsers
          filterUsers={filterUsers}
          setFilterUsers={updateFilterUsers}
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          ref={ref}
        />
      </Drop>
    </div>
  );

  const searchAudit = (
    <div>
      <Drop disablePadding>
        <FilterAudit
          filterAuditt={filterAudit}
          setFilterAuditt={updateFilterAudit}
          isClosed={isClosed}
          ref={ref}
          setIsClosed={setIsClosed}
        />
      </Drop>
    </div>
  );

  return (
    <Container id={id}>
      <RowContainer
        data-testid="header-back-btn"
        style={{ cursor: 'pointer' }}
        onClick={onBack}
      >
        {onBack && (
          <>
            <Icon src={IconBack} width="16px" height="16px" />
            {backD}
          </>
        )}
      </RowContainer>
      <RowContainer
        data-testid="header-title"
        style={{ justifyContent: 'center', width: '45%', fontWeight: 'bold' }}
      >
        {title}
      </RowContainer>
      <RowContainer style={{ justifyContent: 'flex-end' }}>
        {hasFilter && (
          <>
            <Input
              placeholder={Translator('Pesquisar')}
              icon={IconSearch}
              value={search}
              iconRef={iconRef}
              onChange={e => setSearch?.(e.target.value)}
              width="300px"
              height="33px"
              onClick={() => onSearch?.()}
            />
            <Popover content={selectFilter()}>
              <FilterIcon
                active={isFilterActive}
                onClick={() => setIsClosed(true)}
              >
                <IconFilter />
              </FilterIcon>
            </Popover>
          </>
        )}
      </RowContainer>
    </Container>
  );
};

export default forwardRef<ChildComponentRef, Props>(HeaderBackButton);
