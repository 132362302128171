import React, { useEffect } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { IconSuccessIcon } from 'presentation/base/icons';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import { Body, Container, Content, Header } from './styles/StyledSuccessModal';

const SuccessModal: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.success;

  const { active, actionOk, actionCancel, content, title } = message;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{title}</span>
            </Header>
            <Content hasContent={!!content}>
              <IconSuccessIcon width="60px" height="60px" />
              <>{content && <p>{content}</p>}</>
            </Content>
            <Button
              data-testid="btn_confirm"
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              autoFocus
              onClick={actionOk}
            >
              Concluir
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default SuccessModal;
