import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const DateAndHour = styled.div`
  width: 100%;

  display: flex;
  margin-top: 12px;
  /* justify-content: space-between; */
  align-items: center;
  gap: 12px;
`;

export const Required = styled.div`
  color: #dd0404;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const TextArea = styled.textarea``;

type LabelProps = {
  required?: boolean;
};

export const Label = styled.p<LabelProps>`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        color: #dd0404;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        word-wrap: break-word;
      }
    `}
`;

export const ContainerDaily = styled.div`
  /* Add your styles here */
`;

export const Text = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;

  margin-left: 8px;
  margin-right: 12px;

  user-select: none;
`;

export const ContainerMonthly = styled.div`
  /* display: flex;
  align-items: center; */

  margin-top: 20px;
`;
