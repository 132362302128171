import { call, put, select } from 'redux-saga/effects';
import { iActionGuest } from 'domain/interfaces/redux/auth/guest';
import { GuestAuth } from 'domain/usecases/auth/remote/GuestAuth';
import { makeRemoteGuestAuth } from 'main/factories/usecases/auth/GuestAuthFactory';
import {
  guestSuccess,
  guestFailed,
} from 'data/store/reducer/auth/actions/guest';

export function* onGuest(action: iActionGuest) {
  const remoteGuestAuth = makeRemoteGuestAuth();

  try {
    const response: GuestAuth.Model = yield call(
      remoteGuestAuth.guest,
      action.payload,
    );
    yield put(
      guestSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(guestFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGuestSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGuestFailed() {}
