import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;

  @media (max-width: 1140px) {
    flex-wrap: wrap;
  }
`;

export const CardsGroup = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1140px) {
    width: 100%;

    .card {
      max-width: 49%;
    }
  }
`;

interface iCard {
  width: string;
  height?: string;
  breakpoint?: string;
}

export const Card = styled.div<iCard>`
  height: ${({ height }) => height || '160px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${({ width }) => width};
  background: #ffffff;
  border: 1px solid rgba(105, 127, 150, 0.25);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #436385;

  .header {
    display: flex;
    width: 100%;
    height: 20px;

    span {
      text-align: end;
    }
  }

  .footer {
    display: flex;
    width: 100%;
    height: 25px;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  span {
    width: 102px;
    font-weight: 400;
    font-size: 14px;
    color: #a7a7a7;
  }

  .value {
    font-size: 32px;
    color: #233242;
  }

  .percent {
    height: 32px;
    display: flex;
    gap: 4px;
    background: #f3f3f3;
    border-radius: 38px;
    font-weight: 400;
    font-size: 14px;
    color: #233242;
    padding: 8px;
    line-height: 16px;
    letter-spacing: 0.160976px;
  }

  ${({ breakpoint }) =>
    breakpoint &&
    css`
      @media (max-width: ${breakpoint}) {
        max-width: 100%;
      }
    `}
`;

export const PieContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 24px;
`;

export const PieLegend = styled.div`
  width: 100%;
  padding-left: 12px;
`;

interface iLegendItem {
  color: string;
  border?: boolean;
}
export const LegendItem = styled.div<iLegendItem>`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ border }) => border && '1.25232px solid #EFEFEF'};

  .name {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      width: calc(100% - 20px);
      font-weight: 500;
      font-size: 16px;
      color: #436385;
    }
  }

  .color {
    width: 13px;
    height: 13px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
  }
`;
