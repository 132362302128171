import styled from 'styled-components';

export const Container = styled.div`
  width: 410px;
  height: 100%;
  border: 1px solid #dedede;
  background-color: #ffffff;
`;

export const Header = styled.header`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22.5px 24px 22.5px 0;
  border-bottom: 1px solid #dedede;
`;

export const UploadFiles = styled.div`
  padding: 24px;
  background-color: #f5faff;
  border-bottom: 1px solid #dedede;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const InputFile = styled.input`
  display: none;
`;

export const Label = styled.label`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #0565d9;
  height: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: center;
`;

export const Documents = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 70%;
  max-height: 70%;
  overflow-y: auto;
`;

export const DocumentsList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DocumentsItem = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border: 1px solid #dedede;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const DocumentsInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export const DocsEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

export const DocsEmptyIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  border: 1px solid #dedede;
  border-radius: 50%;
`;
