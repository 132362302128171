import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { SetupConferenceWaitingRoom as UsecaseReduxSetupConferenceWaitingRoom } from 'domain/usecases/conferenceWaitingRoom/redux';

import { ReduxSetupConferenceWaitingRoom } from 'data/store/reducer/conferenceWaitingRoom/usecases';

/**
 * send request via REDUX.
 */
export const makeReduxSetupConferenceWaitingRoom =
  (): UsecaseReduxSetupConferenceWaitingRoom =>
    new ReduxSetupConferenceWaitingRoom();
