import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 350px;
  width: 100%;
  height: 100%;
  z-index: 9000;
  border-radius: 10px;
`;

export const Body = styled.div`
  position: fixed;
  background: white;

  display: flex;
  flex-direction: column;

  max-width: 465px;
  max-height: 241px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  border: 1px solid #dedede;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 16px;

  .text-div {
    padding-top: 18px;

    p {
      padding-top: 4px;
      font-size: 14px;
      color: #656a6e;
      text-align: justify;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid #dedede;
  padding: 12px;
`;
